import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-email-verfication',
  templateUrl: './email-verfication.component.html',
  styleUrls: ['./email-verfication.component.scss'],
})
export class EmailVerficationComponent {
  public logoUrl: string = environment.assetDirectory.concat(
    '/assets/images/adtalem_logo.svg'
  );
  constructor(
    public router: Router,
    private readonly analytics: AngularFireAnalytics
  ) {}

  public backToLogin() {
    this.analytics.logEvent('click_forgot_password_verify');
    this.router.navigate(['/login']);
  }
}
