import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
})
export class RangeSliderComponent implements AfterViewInit {
  @ViewChild('handle') public handle: ElementRef;
  @ViewChildren('optionPoint') public optionPoint: QueryList<ElementRef>;
  @Input() public options: any = [];
  @Input() public selected: any;
  @Output() public selectedValue = new EventEmitter();
  public activeLabel: string;

  public ngOnInit(): void {
    this.activeLabel = this.options[0];
    this.options.sort((a: number, b: number) => a - b);
  }

  public positionHandle(i: number, currentItem?: any) {
    this.activeLabel = currentItem;
    this.selectedValue.emit(this.options[i]);
    const getBoundingClientRect = this.optionPoint
      .toArray()
      [i].nativeElement.getBoundingClientRect();
    this.handle.nativeElement.style.position = 'absolute';
    this.handle.nativeElement.style.left = `${
      getBoundingClientRect.left - 26
    }px`;
    this.handle.nativeElement.style.top = '22px';
  }

  public ngAfterViewInit(): void {
    if (this.selected && this.selected !== 'all') {
      this.activeLabel = this.selected;
      const index = this.options.findIndex((x: any) => x === this.selected);
      this.positionHandle(index, this.selected);
    } else {
      const getBoundingClientRect = this.optionPoint
        .toArray()[0]
        .nativeElement.getBoundingClientRect();
      this.handle.nativeElement.style.position = 'absolute';
      this.handle.nativeElement.style.left = `${
        getBoundingClientRect.left - 26
      }px`;
      this.handle.nativeElement.style.top = '22px';
    }
  }
}
