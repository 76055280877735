import { Injectable } from '@angular/core';
import {
  AnticipatedGrateDate,
  DashboardExcelItems,
  DashboardExcelSheetInfo,
  ExcelData,
  LearnerAnalyticsExcelDataModel,
  LearnerAnalyticsExport,
  ProgramDynamic,
  ProgramDynamicFilter,
} from '../../../components/model/customtypes-model.interface';
import {
  ExcelSheetItem,
  DashboardExcelSheetName,
} from '../../enum/shared.enum';
import { SharedService } from '../../shared.service';
import { ExcelExportService } from '../excel.export.service';
import {
  DataFilter,
  EnrollFilter,
  StudentFilter,
} from '../../class-type/chart-type';

@Injectable({
  providedIn: 'root',
})
export class DashboardExportService {
  constructor(
    public sharedService: SharedService,
    public excelExportService: ExcelExportService
  ) { }

  // Creating data for only the selected graphs
  public async getSelectedGraphData(
    exportedItems: DashboardExcelSheetInfo[],
    sessionData: any,
    yearFormat: boolean
  ): Promise<ExcelData[]> {
    let returnData: ExcelData[] = [];

    exportedItems.forEach(async (item: DashboardExcelSheetInfo) => {
      switch (item.name) {
        case ExcelSheetItem.learnerAnalytics: {
          returnData = await this.getLearnerAnalyticsExcelData(
            sessionData.learnerAnalyticsDynamicExport?.LearnerAnalytics,
            returnData,
            item
          );
          break;
        }
        case ExcelSheetItem.alumni: {
          returnData = await this.getDataWithProgram(
            sessionData.alumniByProgramDynamic?.data?.alumniByProgram,
            returnData,
            item,
            'Alumni',
            yearFormat
          );
          break;
        }
        case ExcelSheetItem.studetEnrollment: {
          returnData = await this.getDataWithProgram(
            sessionData.studentEnrollment?.data?.studentEnrollments,
            returnData,
            item,
            'StudentEnrollment',
            yearFormat
          );
          break;
        }
        case ExcelSheetItem.applicantsToEnrollment: {
          returnData = await this.getEnrollmentExcelData(
            sessionData.applicantsToEnrollment?.data?.ApplicantsToEnrollments,
            returnData,
            item,
            'ApplicantsToEnrollment',
            'programCount',
            yearFormat
          );
          break;
        }
        case ExcelSheetItem.studentProgress: {
          returnData = await this.getEnrollmentExcelData(
            sessionData.studentPersistence?.data?.studentPersistence,
            returnData,
            item,
            'programEnrollments',
            'studentCount',
            yearFormat
          );
          break;
        }
        case ExcelSheetItem.anticipatedGraduationDate: {
          returnData = await this.getDataWithProgram(
            sessionData.anticipatedGradDate?.data?.anticipatedGradDate,
            returnData,
            item,
            'AnticipatedGradYear',
            yearFormat
          );
          break;
        }
        default:
      }
    });

    return returnData;
  }

  //#region Program Data
  public async getDataWithProgram(
    programData: any[],
    returnData: ExcelData[],
    graphInfo: DashboardExcelSheetInfo,
    studentFilter: StudentFilter,
    yearFormat: boolean
  ):Promise<ExcelData[]> {
    let graphData = [];

    if (programData?.length) {
      let programs = await this.getAllProgramsList(programData);
      let minYear = this.sharedService.getYearFromFilter();

      programData.forEach(async (data) => {
        let newObj = {};

        switch (graphInfo.sheetName) {
          case DashboardExcelSheetName.alumni:
          case DashboardExcelSheetName.studetEnrollment: {
            newObj = await this.getProgramInfo(data, yearFormat);
            break;
          }
          case DashboardExcelSheetName.anticipatedGraduationDate: {
            newObj = await this.getGraduationInfo(data);
            break;
          }
          default: {
            newObj = {};
          }
        }

        programs.forEach((key: string) => {
          newObj[key] =
            data?.programYear?.find((program) =>
              program?.programsCount.hasOwnProperty(key)
            )?.programsCount[key] || 0;
        });
        if (
          (!yearFormat &&
            (studentFilter == 'StudentEnrollment' || studentFilter == 'Alumni') &&
            data?.filter?.fiscal_year > Number(minYear)) ||
          yearFormat ||
          studentFilter == 'AnticipatedGradYear'
        ) {
          graphData.push(newObj);
        }
      });
      if (graphData) {
        returnData.push({ sheetName: graphInfo.sheetName, data: graphData });
      }
    }

    return returnData;
  }

  public async getGraduationInfo(data: AnticipatedGrateDate) {
    return {
      Institution: data?.filter?.institution,
      Location: data?.filter?.location,
      EnrollmentYear: data?.programYear[0]?.enrollmentYear,
      GrauationYear: data?.programYear[0]?.anticipatedGradYear,
      Hospital: data?.filter?.hospital,
    };
  }

  public async getProgramInfo(
    data: ProgramDynamic,
    yearFormat: boolean
  ) {
    const filter = {
      'Start Year': data?.programYear[0]?.year,
      Institution: data?.filter?.institution,
      Location: data?.filter?.location,
    };
    filter['Month'] = data?.programYear[0]?.month;
    filter['Quarter'] = this.sharedService.getQuarterByMonth(
      data?.programYear[0]?.month,
      yearFormat
    );
    return filter;
  }

  public async getAllProgramsList(programData: ProgramDynamic[]) {
    return Array.from(
      new Set(
        programData.flatMap((item) =>
            item?.programYear?.[0]?.programsCount
            ? Object.keys(item.programYear[0].programsCount)
            : []
        )
      )
    );
  }
  //#endregion

  //#region LearnerAnalytics
  // Formatting the LearnerAnalytics data to the excel data format
  public async getLearnerAnalyticsExcelData(
    learnerAnalyticsData: LearnerAnalyticsExport[],
    returnData: ExcelData[],
    graphInfo: DashboardExcelSheetInfo
  ): Promise<ExcelData[]> {
    let excelData: LearnerAnalyticsExcelDataModel[] = [];

    if (learnerAnalyticsData?.length) {
      excelData = learnerAnalyticsData.map((data) => {
        return {
          Institution: data.filter?.Institution,
          Program: data.filter?.ProgramGroup,
          Modality: data.filter?.Modality,
          Location: data.filter?.State,
          Hospital: data.filter?.Hospital,
          Parent: data.filter?.Parent,
          Applicants: data.studentDetails?.Applicants,
          Students: data.studentDetails?.Students,
          Alumni: data.studentDetails?.Alumni,
        };
      });

      if (excelData) {
        returnData.push({ sheetName: graphInfo.sheetName, data: excelData });
      }
    }

    return returnData;
  }
  //#endregion

  //#region ApplicantsToEnrollment and Student Progress
  public async getEnrollmentExcelData(
    sessionData: any[],
    returnData: ExcelData[],
    graphInfo: DashboardExcelSheetInfo,
    dataFilter: DataFilter,
    programFilter: EnrollFilter,
    yearFormat: boolean
  ): Promise<ExcelData[]> {
    let excelData = [];

    if (sessionData?.length) {
      let minYear = this.sharedService.getYearFromFilter();
      sessionData.forEach((data) => {
        let applicantsToEnrollment = data[dataFilter];
        applicantsToEnrollment.forEach(async (enrollment) => {
          let newObj = await this.createFilterObj(
            data?.filter,
            dataFilter,
            yearFormat
          );
          newObj['ProgramGroup'] = enrollment.program;
          newObj = { ...newObj, ...enrollment[programFilter] };
          if (
            (!yearFormat &&
              dataFilter == 'ApplicantsToEnrollment' &&
              data?.filter?.fiscal_year > Number(minYear)) ||
            yearFormat ||
            dataFilter == 'programEnrollments'
          ) {
            excelData.push(newObj);
          }
        });
      });
      if (excelData) {
        returnData.push({ sheetName: graphInfo.sheetName, data: excelData });
      }
    }
    return returnData;
  }

  public async createFilterObj(
    filterObj: ProgramDynamicFilter,
    dataFilter,
    yearFormat: boolean
  ) {
    let YearLabel =
      dataFilter == 'ApplicantsToEnrollment' ? 'Year' : 'Start Year';
    const filter = {
      [YearLabel]: filterObj.fiscal_year,
      Institution: filterObj.institution,
      Location: filterObj.location,
    };
    if (dataFilter == 'ApplicantsToEnrollment') {
      filter['Month'] = filterObj.fiscal_month;
      filter['Quarter'] = this.sharedService.getQuarterByMonth(
        filterObj.fiscal_month,
        yearFormat
      );
    }
    return filter;
  }
  //#endregion

  public async getDashboardGrapahExcelData(
    exportPage: string,
    exportedItems: DashboardExcelSheetInfo[],
    yearFormat: boolean
  ) {
    let collectedData: ExcelData[] = [];

    let sessionData = this.sharedService.sessionOperations.get('filterData');
    collectedData = await this.getSelectedGraphData(
      exportedItems,
      sessionData,
      yearFormat
    );
    collectedData = await this.orderExcelSheet(collectedData);
    await this.excelExportService.exportToExcel(exportPage, collectedData);
  }

  // To order the excel sheet items defined in the enum DashboardExcelSheetName
  public async orderExcelSheet(
    collectedData: ExcelData[]
  ): Promise<ExcelData[]> {
    const customSort = (a: any, b: any) => {
      const indexA: number = Object.values(DashboardExcelSheetName).indexOf(
        a.sheetName
      );
      const indexB: number = Object.values(DashboardExcelSheetName).indexOf(
        b.sheetName
      );
      return indexA - indexB;
    };
    return collectedData.sort(customSort);
  }

  public getDashboardDisplayItem(): DashboardExcelItems[] {
    return [
      {
        displayName: 'all',
        name: ExcelSheetItem.all,
        sheetName: '',
        selected: true,
      },
      {
        displayName: 'Learner Analytics',
        name: ExcelSheetItem.learnerAnalytics,
        sheetName: DashboardExcelSheetName.learnerAnalytics,
        selected: false,
      },
      {
        displayName: 'Applicants to Enrollments',
        name: ExcelSheetItem.applicantsToEnrollment,
        sheetName: DashboardExcelSheetName.applicantsToEnrollment,
        selected: false,
      },
      {
        displayName: 'Student Enrollment',
        name: ExcelSheetItem.studetEnrollment,
        sheetName: DashboardExcelSheetName.studetEnrollment,
        selected: false,
      },
      {
        displayName: 'Anticipated Graduation Date',
        name: ExcelSheetItem.anticipatedGraduationDate,
        sheetName: DashboardExcelSheetName.anticipatedGraduationDate,
        selected: false,
      },
      {
        displayName: 'Alumni',
        name: ExcelSheetItem.alumni,
        sheetName: DashboardExcelSheetName.alumni,
        selected: false,
      },
      {
        displayName: 'Student Progress',
        name: ExcelSheetItem.studentProgress,
        sheetName: DashboardExcelSheetName.studentProgress,
        selected: false,
      },
    ];
  }
}
