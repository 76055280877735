<div
  class="half-doughnut-main-wrap"
  [ngClass]="{ lightColor: graphLightColor }"
>
  <div class="custom-legend">
    <ul>
      <li *ngFor="let item of programInfo">
        <span></span>
        <div>
          <small>{{ item.content }}</small
          >{{ item.name }}
        </div>
        <div
          *ngIf="item.name == 'Other'"
          class="question-align pointer-cursor margin-left-10 question-icon-container"
          (click)="openOtherProgramPopup()"
          (keydown)="openOtherProgramPopup()"
        >
          <span class="question-icon-wraper icon-Others-Icon"> </span>
        </div>
      </li>
    </ul>
  </div>
  <div class="canvas">
    <div class="custom-total">
      <div class="total">Total</div>
      <ul>
        <li *ngFor="let totals of totalCounts">{{ totals }}</li>
      </ul>
    </div>
    <canvas
      baseChart
      class="stacked-half-doughnut-chart"
      [data]="chartData"
      [plugins]="chartPlugin"
      [options]="chartOption"
      [type]="chartType"
    >
    </canvas>
    <div
      class="arrow-wrapper"
      *ngIf="disableArrowTrue"
      [ngClass]="{ arrowMarker: arrowMarker }"
    >
      <div
        class="arrow arrow-left"
        (click)="arrowLeftMove()"
        [ngClass]="{ disableLeft: disableLefttrue }"
      >
        <span class="icon-Left-Arrow-1"
          ><span class="path1"></span><span class="path2"></span
        ></span>
      </div>
      <div
        class="arrow arrow-right"
        (click)="arrowRightMove()"
        [ngClass]="{ disableRight: disableRighttrue }"
      >
        <span class="icon-Right-Arrow-3"
          ><span class="path1"></span><span class="path2"></span
        ></span>
      </div>
    </div>
  </div>
</div>
