<div
  class="filter-card card shadow-lg reusable"
  *ngIf="showPopup && this.currentWindowWidth < 500"
>
  <div class="popupHead">
    <div class="container title-container">
      <div class="d-flex flex-row">
        <div class="popupHead-title">
          <h5 class="filterHead">{{ title }}</h5>
          <p>Filter</p>
        </div>
      </div>
      <div class="popup-close-wrap">
        <button type="button" value (click)="popClose()">
          <span class="closetag">closetag</span>
          <img alt="close" [src]="closeButtonUrl" />
        </button>
      </div>
    </div>
    <div class="contant-container">
      <div>
        <div class="filterSubHead">
          <div class="tabs-container w-100">
            <!-- Tab Navigation -->
            <div class="tabs w-100">
              <div class="tabs-btn">
                <button
                  class="tab-button"
                  [ngClass]="{ active: activeTab === 'yearly' }"
                  (click)="setActiveTab('yearly')"
                >
                  Yearly Based
                </button>
                <button
                  class="tab-button"
                  [ngClass]="{ active: activeTab === 'quarterly' }"
                  (click)="setActiveTab('quarterly')"
                >
                  Quarterly Based
                </button>
                <button
                  class="tab-button"
                  [ngClass]="{ active: activeTab === 'monthly' }"
                  (click)="setActiveTab('monthly')"
                >
                  Monthly Based
                </button>
              </div>
            </div>
            <div *ngIf="activeTab === 'yearly'" class="tab-content">
              <div class="accordion-item">
                <ng-container
                  *ngFor="let filter of yearlyFilters; let i = index"
                >
                  <div class="mob-filterItems">
                    <div class="tooltip-wrap">
                      <small *ngIf="filter?.tooltip">{{
                        filter?.tooltip
                      }}</small>
                      <label for="filter">{{
                        filter?.filterLabel?.replace("_", " ")
                      }}</label>
                    </div>
                    <ng-select
                      [items]="filter?.filterItems | alphasort"
                      [clearable]="false"
                      (change)="getFieldValuesFromMobile($event)"
                      [(ngModel)]="filter.selected"
                      (click)="openMobileFilterPopup(filter, i)"
                      (keydown)="openMobileFilterPopup(filter, i)"
                    ></ng-select>
                    <span
                      *ngIf="
                        filteredItems[getIndex(filter.filterLabel)] != 'all' &&
                        filter.selected !== 'all'
                      "
                      >{{ filteredItems[getIndex(filter.filterLabel)] }}</span
                    >
                    <span
                      class="all-option"
                      *ngIf="
                        filteredItems[getIndex(filter.filterLabel)] == 'all'
                      "
                      >{{ filteredItems[getIndex(filter.filterLabel)] }}</span
                    >
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- Quarterly Based Tab Content -->
            <div *ngIf="activeTab === 'quarterly'" class="tab-content">
              <div class="accordion-item">
                <ng-container
                  *ngFor="let filter of quarterlyFilters; let i = index"
                >
                  <div class="mob-filterItems">
                    <div class="tooltip-wrap">
                      <small *ngIf="filter?.tooltip">{{
                        filter?.tooltip
                      }}</small>
                      <label for="filterLabel">{{
                        filter?.filterLabel.replace("_", " ")
                      }}</label>
                    </div>
                    <ng-select
                      [items]="filter?.filterItems | alphasort"
                      [clearable]="false"
                      (change)="getFieldValuesFromMobile($event)"
                      [(ngModel)]="filter.selected"
                      (click)="openMobileFilterPopup(filter, i)"
                      (keydown)="openMobileFilterPopup(filter, i)"
                    ></ng-select>
                    <span
                      *ngIf="
                        filteredItems[getIndex(filter.filterLabel)] != 'all' &&
                        filter.selected !== 'all'
                      "
                      >{{ filteredItems[getIndex(filter.filterLabel)] }}</span
                    >
                    <span
                      class="all-option"
                      *ngIf="
                        filteredItems[getIndex(filter.filterLabel)] == 'all'
                      "
                      >{{ filteredItems[getIndex(filter.filterLabel)] }}</span
                    >
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- Monthly Based Tab Content -->
            <div *ngIf="activeTab === 'monthly'" class="tab-content">
              <div class="accordion-item">
                <ng-container
                  *ngFor="let filter of monthlyFilters; let i = index"
                >
                  <div class="mob-filterItems">
                    <div class="tooltip-wrap">
                      <small *ngIf="filter?.tooltip">{{
                        filter?.tooltip
                      }}</small>
                      <label for="tooltip">{{
                        filter?.filterLabel.replace("_", " ")
                      }}</label>
                    </div>
                    <div *ngIf="filter?.filterLabel == 'Monthly'">
                      <ng-select
                        [items]="filter?.filterItems | alphasort"
                        [clearable]="false"
                        (change)="getFieldValuesFromMobile($event)"
                        [(ngModel)]="filter.selected"
                        (click)="openMobileFilterPopup(filter, i)"
                        (keydown)="openMobileFilterPopup(filter, i)"
                      ></ng-select>
                    </div>
                    <div *ngIf="filter?.filterLabel != 'Monthly'">
                      <ng-select
                        [items]="filter?.filterItems"
                        [clearable]="false"
                        (change)="getFieldValuesFromMobile($event)"
                        [(ngModel)]="filter.selected"
                        (click)="openMobileFilterPopup(filter, i)"
                        (keydown)="openMobileFilterPopup(filter, i)"
                      ></ng-select>
                    </div>
                    <span
                      *ngIf="
                        filteredItems[getIndex(filter.filterLabel)] != 'all' &&
                        filter.selected !== 'all'
                      "
                      >{{ filteredItems[getIndex(filter.filterLabel)] }}</span
                    >
                    <span
                      class="all-option"
                      *ngIf="
                        filteredItems[getIndex(filter.filterLabel)] == 'all'
                      "
                      >{{ filteredItems[getIndex(filter.filterLabel)] }}</span
                    >
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <button class="reset" (click)="resetFilters()">Clear All</button>
      <button (click)="applyFilters()" class="apply">Apply</button>
    </div>
  </div>
</div>
<ng-container #defaultFilterComp></ng-container>
<!-- <ng-template #customAppend> -->
<div
  *ngIf="showPopup && this.currentWindowWidth >= 500"
  #filterWrapper
  class="filter-card card reusable"
>
  <div class="filter-popup">
    <div class="popupHead">
      <div class="container p-0">
        <div class="w-100 align-items-center d-flex flex-row">
          <div class="filter-title">
            <h5 title="{{ title }}" class="filterHead">
              {{ title }} filter
              <span
                class="icon-close-icon"
                (click)="popClose()"
                (keydown)="popClose()"
              ></span>
            </h5>
          </div>
        </div>
      </div>

      <div class="container1">
        <div class="tabs-container w-100">
          <!-- Tab Navigation -->
          <div class="tabs w-100">
            <div class="tabs-btn">
              <button
                class="tab-button"
                [ngClass]="{ active: activeTab === 'yearly' }"
                (click)="setActiveTab('yearly')"
              >
                Yearly Based
              </button>
              <button
                class="tab-button"
                [ngClass]="{ active: activeTab === 'quarterly' }"
                (click)="setActiveTab('quarterly')"
              >
                Quarterly Based
              </button>
              <button
                class="tab-button"
                [ngClass]="{ active: activeTab === 'monthly' }"
                (click)="setActiveTab('monthly')"
              >
                Monthly Based
              </button>
            </div>
          </div>

          <!-- Yearly Based Tab Content -->
          <div *ngIf="activeTab === 'yearly'" class="tab-content">
            <ng-scrollbar [track]="'vertical'" class="my-scrollbar">
              <div class="accordion-item">
                <ng-container *ngFor="let filter of yearlyFilters">
                  <div class="filter-accordion">
                    <button
                      class="accordion-button"
                      (click)="toggleAccordion(filter?.filterLabel)"
                    >
                      {{ filter?.filterLabel.replace("_", " ") }}
                      <span
                        class="arrow-icon"
                        [ngClass]="{
                          open: isAccordionOpen[filter?.filterLabel]
                        }"
                      ></span>
                    </button>
                    <div
                      [ngClass]="{
                        'accordion-content': true,
                        active: isAccordionOpen[filter?.filterLabel]
                      }"
                    >
                      <div [class]="filter?.column" class="filterItems">
                        <div class="scroll-container">
                          <app-se-radio
                            [groupByAlphabet]="
                              alphaGroup === filter?.filterLabel
                            "
                            [activeAlphaChar]="clickedValue"
                            [filterLabel]="filter?.filterLabel"
                            [styleClass]="'cursor-pointer pb-3 mb-3'"
                            [(ngModel)]="filter.selected"
                            (change)="resetFilter(filter, 'yearly')"
                            [value]="filter.selected"
                            (activeAlphaButton)="makeActiveAlphaBtn($event)"
                            [options]="
                              iterateOptions(
                                filter?.filterItems,
                                filter?.filterLabel
                              )
                            "
                            [filterData]="filter.selected"
                          ></app-se-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-scrollbar>
          </div>

          <!-- Quarterly Based Tab Content -->
          <div *ngIf="activeTab === 'quarterly'" class="tab-content">
            <ng-scrollbar [track]="'vertical'" class="my-scrollbar">
              <div class="accordion-item">
                <ng-container *ngFor="let filter of quarterlyFilters">
                  <div class="filter-accordion">
                    <button
                      class="accordion-button"
                      (click)="toggleAccordion(filter?.filterLabel)"
                    >
                      {{ filter?.filterLabel.replace("_", " ") }}
                      <span
                        class="arrow-icon"
                        [ngClass]="{
                          open: isAccordionOpen[filter?.filterLabel]
                        }"
                      ></span>
                    </button>
                    <div
                      [ngClass]="{
                        'accordion-content': true,
                        active: isAccordionOpen[filter?.filterLabel]
                      }"
                    >
                      <div [class]="filter?.column" class="filterItems">
                        <div class="scroll-container">
                          <app-se-radio
                            [groupByAlphabet]="
                              alphaGroup === filter?.filterLabel
                            "
                            [activeAlphaChar]="clickedValue"
                            [filterLabel]="filter?.filterLabel"
                            [styleClass]="'cursor-pointer pb-3 mb-3'"
                            [(ngModel)]="filter.selected"
                            (change)="resetFilter(filter, 'quarterly')"
                            [value]="filter?.selected"
                            (activeAlphaButton)="makeActiveAlphaBtn($event)"
                            [options]="
                              iterateOptions(
                                filter?.filterItems,
                                filter?.filterLabel
                              )
                            "
                            [filterData]="filter?.selected"
                          ></app-se-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-scrollbar>
          </div>

          <!-- Monthly Based Tab Content -->
          <div *ngIf="activeTab === 'monthly'" class="tab-content">
            <ng-scrollbar [track]="'vertical'" class="my-scrollbar">
              <div class="accordion-item">
                <ng-container *ngFor="let filter of monthlyFilters">
                  <div class="filter-accordion">
                    <button
                      class="accordion-button"
                      (click)="toggleAccordion(filter?.filterLabel)"
                    >
                      {{ filter?.filterLabel.replace("_", " ") }}
                      <span
                        class="arrow-icon"
                        [ngClass]="{
                          open: isAccordionOpen[filter?.filterLabel]
                        }"
                      ></span>
                    </button>
                    <div
                      [ngClass]="{
                        'accordion-content': true,
                        active: isAccordionOpen[filter?.filterLabel]
                      }"
                    >
                      <div [class]="filter?.column" class="filterItems">
                        <div class="scroll-container">
                          <app-se-radio
                            [groupByAlphabet]="
                              alphaGroup === filter?.filterLabel
                            "
                            [activeAlphaChar]="clickedValue"
                            [filterLabel]="filter?.filterLabel"
                            [styleClass]="'cursor-pointer pb-3 mb-3'"
                            [(ngModel)]="filter.selected"
                            (change)="resetFilter(filter, 'monthly')"
                            [value]="filter?.selected"
                            (activeAlphaButton)="makeActiveAlphaBtn($event)"
                            [options]="
                              iterateOptions(
                                filter?.filterItems,
                                filter?.filterLabel
                              )
                            "
                            [filterData]="filter?.selected"
                          ></app-se-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-scrollbar>
          </div>

          <!-- Apply and Clear buttons -->
          <div class="filter-actions">
            <button class="clear-button" (click)="resetFilters()">
              Clear All
            </button>
            <button class="apply-button" (click)="applyFilters()">Apply</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #hospitalMobilefilter>
  <div
    class="hospital-mobile-popup"
    *ngIf="showMobileFilterPopup"
    [ngClass]="{ popUpHide: mobilePopupClose }"
  >
    <div class="hospital-mobile-popup-sub-wrap">
      <div class="modal-header">
        <h4>{{ filterLabel.replace("_", " ") }}</h4>
        <button
          (click)="
            mobileHospitalSelection(filterData[indexVal].selected, indexVal)
          "
        >
          <img alt="close" [src]="closeButtonUrl" />
        </button>
      </div>
      <div
        *ngIf="filterData[indexVal] != null"
        [class]="filterData.column"
        class="filterItems"
      >
        <div
          class="scroll-container"
          [ngClass]="{ 'alpha-border': filterLabel === 'Hospital' }"
        >
          <app-se-radio
            [groupByAlphabet]="
              alphaGroup === filterLabel && filterLabel !== 'Modality'
            "
            [activeAlphaChar]="clickedValue"
            [filterLabel]="filterLabel"
            [styleClass]="'cursor-pointer pb-3 mb-3'"
            [(ngModel)]="filterData[indexVal].selected"
            (change)="resetFilter(filterData[indexVal], activeTab)"
            [value]="filterData[indexVal].selected"
            (activeAlphaButton)="makeActiveAlphaBtn($event)"
            [options]="
              iterateOptions(filterData[indexVal].filterItems, filterLabel)
            "
            [filterData]="filterData[indexVal].selected"
          ></app-se-radio>
        </div>
        <div class="continue-btn">
          <button
            (click)="
              mobileHospitalSelection(filterData[indexVal].selected, indexVal)
            "
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
