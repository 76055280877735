import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-year-format',
  templateUrl: './year-format.component.html',
  styleUrls: ['./year-format.component.scss'],
})
export class YearFormatComponent implements OnInit {
  public fiscalYear: boolean = false;
  public calendarYear: boolean = false;
  public hideButtons: boolean = false;
  public userEmail: string;

  constructor(public sharedService: SharedService) {}

  public async ngOnInit() {
    this.userEmail = await this.sharedService.getUserDetails();
    const role = localStorage.getItem('role');
    if (role == 'admin') {
      this.hideButtons = true;
    }
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      if (format === true && !localStorage.getItem('fiscalYear')) {
        this.fiscalYear = true;
        this.calendarYear = false;
        setTimeout(() => {
          this.closePopup();
        }, 2000);
      } else if (format === false && !localStorage.getItem('calendarYear')) {
        this.calendarYear = true;
        this.fiscalYear = false;
        setTimeout(() => {
          this.closeCalendarPopup();
        }, 2000);
      }
    });
  }

  public closePopup() {
    this.fiscalYear = false;
    localStorage.setItem('fiscalYear', 'true');
    this.sharedService.yearFormatChange.next(false);
  }

  public closeCalendarPopup() {
    this.calendarYear = false;
    localStorage.setItem('calendarYear', 'true');
    this.sharedService.yearFormatChange.next(false);
  }
}
