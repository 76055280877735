import {
  Component,
  Input,
  ViewChild,
  OnInit,
} from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import {
  ChartNumberData,
  Program,
  AnticipatedGradDataSet,
  ChartOtherProgramInfo,
  OtherProgramTitle,
} from '../../model/customtypes-model.interface';
import { SharedService } from '../../../services/shared.service';
import { OtherProgramService } from '../../../services/other.program.service';
import {
  ChartType,
  studentEnrollmentChartCount,
} from '../../../services/enum/shared.enum';
import { ArrowChartOptions } from '../../../services/entity/shared.entity';
@Component({
  selector: 'app-vertical-rounded-bar-chart',
  templateUrl: './vertical-rounded-bar-chart.component.html',
  styleUrls: ['./vertical-rounded-bar-chart.component.scss'],
})
export class VerticalRoundedBarChartComponent implements OnInit {
  public totalCounts: Array<number>;
  @ViewChild(BaseChartDirective)
  public chart?: BaseChartDirective;
  public ChartLabels: Array<string> = ['554', '5533', '31231'];
  public colors = ['#84C7F0', '#F3D278', '#FA9885', '#D9A9F4', '#F5B171'];
  public datasets = this.colors.map((color) => ({
    backgroundColor: color,
    barThickness: 40,
    borderColor: 'white',
    borderWidth: 1,
    data: [],
    hoverBackgroundColor: color,
    hoverBorderColor: 'white',
    label: '',
  }));
  public chartData: ChartData<'bar'> = {
    datasets: this.datasets,
    labels: this.ChartLabels,
  };
  public chartType: ChartConfiguration<'bar'>['type'] = 'bar';
  public chartPlugin: any = [ChartDataLabels];
  public chartOption: any = {
    animation: false,
    layout: {
      padding: window.innerWidth > 700 ? 10 : 0,
    },
    maintainAspectRatio: window.innerWidth > 490,
    plugins: {
      align: 'start',
      border: false,
      datalabels: {
        color: 'black',
        font: {
          size: 12,
          weight: 'normal',
        },
      },
      legend: {
        display: false,
        labels: {
          boxHeight: window.innerWidth > 700 ? 15 : 10,
          boxWidth: window.innerWidth > 700 ? 15 : 10,
          pointStyle: 'rectRounded',
          color: '#526289',
          textAlign: 'center',
          usePointStyle: true,
        },
        padding: {
          bottom: 20,
        },
        position: 'top',
      },
      text: false,
      text1: false,
      doughnutLabelsLine: false,
      customCanvasBackgroundColor: false,
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        min: 0,
        max: 3,
        border: {
          borderDash: [1, 1],
          color: '#1F356C',
          display: true,
          width: 1,
        },
        grid: {
          display: false,
          offset: true,
        },
        stacked: true,
        ticks: {
          color: '#1F356C',
          font: {
            weight: 'bold',
          },
        },
        title: {
          align: 'center',
          color: '#526289',
          display: true,
          text: '',
        },
      },
      y: {
        border: {
          dash: [2, 4],
          display: false,
        },
        stacked: true,
        suggestedMax: 10,
        ticks: {
          color: '#1F356C',
          font: {
            weight: 'bold',
          },
          maxTicksLimit: window.innerWidth > 700 ? 10 : 50,
        },
        title: {
          align: 'start',
          color: '#526289',
          display: true,
          text: 'No.of Students',
        },
      },
    },
  };
  public graphBackgroundColor: string[];
  public graphLightColor: boolean;
  public programInfo: Program[];
  public tempChartLabels: string[] = [];
  public tempChartData: ChartData<'bar'>;
  public totalCountsFull: number[] = [];
  public roundChartOption: ArrowChartOptions = new ArrowChartOptions();
  public startX: number = 0;
  public yearPerChart: number = 0;

  @Input() public otherProgramDetails: ChartOtherProgramInfo[] = [];
  @Input() public labelInfo: string[] = [];
  @Input() public totalInfo: number[] = [];
  @Input() public totalYearWiseInfo: number[] = [];
  @Input() public chartNumbers: ChartNumberData[] = [];

  constructor(
    public sharedService: SharedService,
    public otherProgramService: OtherProgramService
  ) {
    this.tempChartLabels = JSON.parse(JSON.stringify(this.ChartLabels));
    this.tempChartData = JSON.parse(JSON.stringify(this.chartData));
  }

  @Input() set totals(data: any) {
    this.totalCounts = data;
  }

  // Getting dataset chart number

  @Input() set chartDatSets(data: AnticipatedGradDataSet) {
    if (data) {
      this.yearPerChart = data.labels.length;
      const transformedData = this.transformToQuarterYearPairs(data.labels);
      this.chartData.labels = transformedData;
      const count: Array<string> = [];
      data.chartData.forEach((x: any, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.datasets[i].label = x?.label;
          this.chartData.datasets[i].data = x.data.map((q: number) =>
            !q ? null : q
          );
        }
        count.push(x.data);
        this.chart?.chart?.update();
      });
      this.totalCounts = this.getSumOfYValues(count).slice(0, 4);
      this.totalCountsFull = this.getSumOfYValues(count);
    } else {
      this.totalCounts = [];
      this.totalCountsFull = [];
      this.yearPerChart = 0;
      this.chartData.datasets.forEach((x, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.labels = [];
          this.chartData.datasets[i].label = undefined;
          this.chartData.datasets[i].data = [];
        }
      });
      this.chart?.chart?.update();
    }

    this.setDefaultMinMaxValue(data);
    if (this.yearPerChart > 0) {
      if (this.yearPerChart > studentEnrollmentChartCount.chartYearLength) {
        this.roundChartOption.setChartArrow(true, false, false);
        this.updateChartData(
          this.roundChartOption.chartOptionXMin,
          this.roundChartOption.chartOptionXMax
        );
      } else {
        this.roundChartOption.setChartArrow(true, false, true);
        this.updateChartData(
          this.roundChartOption.chartOptionXMin,
          this.roundChartOption.chartOptionXMax
        );
      }
    } else {
      this.roundChartOption.setChartArrow(true, true, true);
      this.updateChartData(0, 0);
    }
  }
  // Getting Sum value of chart data sets
  public getSumOfYValues(arr: any) {
    const numRows = arr.length;
    const numCols = arr[0]?.length;
    const sums = [];
    for (let j = 0; j < numCols; j++) {
      let sum = 0;
      for (let i = 0; i < numRows; i++) {
        sum += arr[i][j];
      }
      sums.push(sum);
    }
    return sums;
  }

  public ngOnInit(): void {
    this.sharedService.lightColorMode$.subscribe((NavVal: boolean) => {
      if (NavVal === true) {
        this.graphBackgroundColor = [
          '#D0AC62',
          '#9BE2BE',
          '#9797B9',
          '#E9838B',
          '#85D6EC',
        ];
        this.graphLightColor = true;
      } else {
        this.graphBackgroundColor = [
          '#84C7F0',
          '#F3D278',
          '#FA9885',
          '#D9A9F4',
          '#F5B171',
        ];
        this.graphLightColor = false;
      }
      this.chartData.datasets.forEach((x, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.datasets[i].backgroundColor =
            this.graphBackgroundColor[i];
          this.chartData.datasets[i].hoverBackgroundColor =
            this.graphBackgroundColor[i];
        }
      });
      this.chart?.chart?.update();
    });
    this.programInfo = this.otherProgramService.getProgramWithContent();
  }

  public openOtherProgramPopup() {
    if (window.innerWidth < 575) {
      this.sharedService.openOtherProgramMob.next(true);
      this.sharedService.FloatingMenuEvent.next(false);
    } else {
      this.sharedService.openOtherProgram.next(true);
    }

    if (this.tempChartLabels.length + 1 != this.totalInfo.length) {
      this.totalInfo.push(0);
    }
    let titleInfo: OtherProgramTitle = {
      popupTotalTitle: 'All Programs Total',
      popupTitle: ChartType.anticipatedGradYear,
    };

    this.otherProgramService.bindDataToStorage(
      titleInfo,
      this.otherProgramDetails,
      this.labelInfo,
      this.totalInfo,
      this.totalYearWiseInfo,
      true,
      true
    );
  }

  public async arrowRightMove() {
    this.roundChartOption.setChartArrow(
      false,
      this.roundChartOption.isDisableRightArrow
    );
    this.roundChartOption.setChartMinMax(
      this.roundChartOption.chartOptionXMin + 1,
      this.roundChartOption.chartOptionXMax + 1
    );

    if (
      this.roundChartOption.chartOptionXMax >=
      this.totalCountsFull?.length - 1
    ) {
      this.roundChartOption.setChartMinMax(
        this.totalCountsFull.length - 4,
        this.totalCountsFull.length - 1
      );
      this.roundChartOption.setChartArrow(false, true);
    }

    this.updateChartData(
      this.roundChartOption.chartOptionXMin,
      this.roundChartOption.chartOptionXMax
    );
  }

  public async arrowLeftMove() {
    this.roundChartOption.setChartArrow(
      this.roundChartOption.isDisableLeftArrow,
      false
    );
    this.roundChartOption.setChartMinMax(
      this.roundChartOption.chartOptionXMin - 1,
      this.roundChartOption.chartOptionXMax - 1
    );

    if (this.roundChartOption.chartOptionXMin <= 0) {
      this.roundChartOption.setChartMinMax(0, 3);
      this.roundChartOption.setChartArrow(true, false);
    }

    this.updateChartData(
      this.roundChartOption.chartOptionXMin,
      this.roundChartOption.chartOptionXMax
    );
  }

  public updateChartData(min: number, max: number) {
    this.totalCounts = this.totalCountsFull.slice(min, max + 1);
    let newLabelSet = JSON.parse(JSON.stringify(this.tempChartLabels));
    let newDataSet = JSON.parse(JSON.stringify(this.tempChartData));
    this.chartData.labels = [...newLabelSet.slice(min, max + 1)];
    if (!!newDataSet && Array.isArray(newDataSet) && newDataSet.length > 0) {
      newDataSet?.forEach((x: any, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.datasets[i].label = x?.label;
          this.chartData.datasets[i].data = x.data
            .slice(min, max + 1)
            .map((q: number) => (!q ? null : q));
        }
      });
    }
    this.chart?.chart?.update();
  }

  public transformToQuarterYearPairs(data: string[]): string[][] {
    return data.map((item) => {
      const [quarter, year] = item.split(' '); // Split each item into quarter and year
      return [quarter, year]; // Return the quarter and year as an array
    });
  }

  public async setDefaultMinMaxValue(data: AnticipatedGradDataSet) {
    if (this.chartData.labels) {
      this.tempChartLabels = JSON.parse(JSON.stringify(this.chartData.labels));
    } else {
      this.tempChartLabels = [];
    }

    if (data?.chartData) {
      this.tempChartData = JSON.parse(JSON.stringify(data?.chartData));
    } else {
      this.tempChartData = null;
    }

    this.roundChartOption.setChartMinMax(
      studentEnrollmentChartCount.chartOptionInitXMin,
      studentEnrollmentChartCount.chartOptionInitXMax
    );
  }
}
