<div class="slider">
  <div #handle class="slider-handle"></div>
  <div
    [class.active]="activeLabel === labels"
    class="items"
    *ngFor="let labels of options; let i = index"
  >
    <span class="label-text" (click)="positionHandle(i, labels)"
    (keydown)="positionHandle(i,labels)">{{
      labels
    }}</span>
    <span
      #optionPoint
      (click)="positionHandle(i, labels)"
      class="points"
      (keydown)="positionHandle(i,labels)"
    ></span>
  </div>
  <div class="slider-track"></div>
</div>
