import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { SharedService } from '../../services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
})
export class MobileNavComponent implements OnInit, OnDestroy {
  public subscription: Subscription;
  public openModal: boolean;
  public popUpDataObj: any;
  @Output() public closeAll = new EventEmitter();

  constructor(public auth: AuthService, private readonly sharedService: SharedService) {}
  @Input() set data(data: any) {
    if (data) {
      this.popUpDataObj = data;
    }
  }

  public ngOnInit() {
    this.subscription = this.sharedService.mobNavEvent.subscribe(
      (message: any) => {
        this.openModal = message?.profile;
      }
    );
  }

  public closeModal($event: any) {
    if (typeof $event === 'boolean') {
      this.openModal = $event;
    } else {
      this.openModal = false;
      this.closeAll.emit(true);
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
