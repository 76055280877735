import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  public async generatePdf(
    elementId: any,
    filename: string,
    backgroundColor: string,
    margin: number
  ) {
    const element = document.getElementById(elementId);
    if (element) {
      html2canvas(element).then((canvas) => {
        const imgWidth = 200;
        let pdfSize;
        if (window.innerWidth > 1199) {
          pdfSize = 'a4';
        } else if (window.innerWidth < 1199 && window.innerWidth >= 991) {
          pdfSize = [200, 900];
        } else {
          pdfSize = [200, 1350];
        }
        const pdf = new jsPDF('p', 'mm', pdfSize);
        const imgHeight =
          window.innerWidth > 1199
            ? pdf.internal.pageSize.height - 2 * margin
            : (canvas.height * 200) / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        const position = 0;
        pdf.setFillColor(backgroundColor);
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        pdf.save(filename);
      });
    }
  }
}
