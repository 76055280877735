import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { ApiService } from '../../../../shared/services/api.service';
import { SharedService } from '../../../../shared/services/shared.service';
import { CommonComponent } from '../common-component';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CdnService } from '../../../../shared/services/cdn.service';
import {
  ChartOtherProgramInfo,
  FilterData,
  FilterItems,
  OrgDetail,
  ProgramDynamicFilter,
  SelectedFilters,
  StudentPersistenceAllData,
  StudentProgress,
  StudentProgressDynamic,
  StudentProgressProgram,
} from '../../../../shared/components/model/customtypes-model.interface';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
import { ChartType } from '../../../../shared/services/enum/shared.enum';
import { ProgramDiversityService } from '../../../../shared/services/diversity/program-diversity/program-diversity.service';

@Component({
  selector: 'app-student-persistence',
  templateUrl: './student-persistence.component.html',
  styleUrls: ['./student-persistence.component.scss'],
})
export class StudentPersistenceComponent
  extends CommonComponent
  implements OnInit {
  public chartDataCount: any;
  public selectedFilters: SelectedFilters;
  public filterLabels: FilterItems[];
  public loading: boolean;
  public otherProgramDetails: ChartOtherProgramInfo[] = [];
  public filterCombinations: any = {};
  public filterData: FilterData;
  public userName: string;
  public hospitalList: StudentProgress[];
  public sessionData: StudentProgressDynamic;
  public yearFormat: boolean = false;
  public persistenceDynamicAll: StudentPersistenceAllData;
  public persistenceChartType: ChartType = ChartType.studentProgress;
  public diversityInfo: any[] = [];
  public isSmallerDevice: boolean = false;
  public withDrawnReason: StudentProgressProgram[] = [];
  constructor(
    apiService: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private readonly sharedService: SharedService,
    private readonly analytics: AngularFireAnalytics,
    private readonly cdnService: CdnService,
    private readonly analyticsService: AnalyticsService,
    private readonly programDiversity: ProgramDiversityService
  ) {
    super(apiService, tss, ar);
  }

  @Input() set studentPersistenceData(data: SelectedFilters) {
    if (data) {
      this.filterData = data;
    }
  }

  public ngOnInit(): void {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.filterLabels = this.sharedService.removeYearFromFilter(
        this.cdnService.appendSelectedAllToFilterData(
          'studentPersistenceFilter'
        ),
        format
      );
    });
    this.filterLabels = this.cdnService.changeTheLabel(
      this.filterLabels,
      'Year',
      'Start_Year'
    );
    // getting filter data studentPersistence
    this.persistenceDynamicAll = this.cdnService.getTheInitialAllFilterData(
      'studentPersistenceAll'
    );

    this.getYearFormat();

    const defaultFilter = this.filterCombinations?.data?.studentPersistence[0];
    this.setDiversityDetails(defaultFilter?.programEnrollments);
    this.setWithDrawnReason(defaultFilter?.programEnrollments);
    this.chartDataCount = this.defineChartObj(
      defaultFilter?.programEnrollments
    );
    if (window.innerWidth < 575) {
      this.isSmallerDevice = true;
    }
    this.sessionData = this.cdnService.getSessionData('studentPersistence');
    if (this.filterData) {
      this.getSelectedValues(this.filterData);
    }
  }

  // Get and arrange the data according to predefined programs, and calculate values of Continuing, Withdrawn, Graduated
  public defineChartObj(data: StudentProgressProgram[]) {
    const predefinedPgms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];
    const chartLabels: string[] = [...predefinedPgms, 'Other'];

    this.createOtherProgramDetails(data || []);

    if (data && Array.isArray(data)) {
      const otherPrograms = data?.reduce(
        (accumulator, currentProgram) => {
          if (!predefinedPgms.includes(currentProgram?.program)) {
            if (!accumulator['Other']) {
              accumulator['Other'] = {
                Continuing: 0,
                Withdrawn: 0,
                Graduated: 0,
                total: 0,
              };
            }
            accumulator['Other'].Continuing +=
              currentProgram?.studentCount?.New;
            accumulator['Other'].Continuing +=
              currentProgram?.studentCount?.Continuing;
            accumulator['Other'].Withdrawn +=
              currentProgram?.studentCount?.Withdrawn;
            accumulator['Other'].Graduated +=
              currentProgram?.studentCount?.Graduated;
            accumulator['Other'].total =
              accumulator['Other']?.Continuing +
              accumulator['Other']?.Withdrawn +
              accumulator['Other']?.Graduated;
          }
          return accumulator;
        },
        {
          Other: {
            Continuing: 0,
            Withdrawn: 0,
            Graduated: 0,
            total: 0,
          },
        }
      );

      const chartData = predefinedPgms.map((program) => {
        const entry = data.find((item) => item.program === program);

        if (entry) {
          const continuing =
            entry.studentCount.Continuing + entry.studentCount.New;
          const total =
            continuing +
            entry.studentCount.Withdrawn +
            entry.studentCount.Graduated;
          const { New, ...remainingStudentCount } = entry.studentCount;

          return {
            data: {
              ...remainingStudentCount,
              Continuing: continuing,
              total: total,
            },
          };
        } else {
          return {
            data: {
              New: 0,
              Continuing: 0,
              Withdrawn: 0,
              Graduated: 0,
              total: 0,
            },
          };
        }
      });

      chartData.push({ data: otherPrograms?.Other });
      return {
        labels: chartLabels,
        chartData,
      };
    } else {
      return null;
    }
  }
  // get selected filter data for user selected combinations
  public getSelectedValues($event: any) {
    this.selectedFilters = $event;
    let filterData: string = localStorage.getItem('filters');
    if (filterData == null) {
      filterData = '{}';
    }
    let selectedFilters = JSON.parse(filterData);
    selectedFilters['studentPersistence'] = { ...this.selectedFilters };
    localStorage.setItem('filters', JSON.stringify(selectedFilters));
    this.loading = true;
    this.analytics.logEvent(
      `Student_persistence_Filter_${$event?.Start_Year}_${$event?.Institution}_${$event?.Location}_${$event?.Hospital}`
    );

    const orderOfKeys = ['fiscal_year', 'institution', 'location', 'hospital'];

    if (this.filterCombinations?.data?.studentPersistence?.length === 1) {
      this.filterCombinations?.data?.studentPersistence?.push(
        ...(this.sessionData?.data?.studentPersistence) || []
      );
      this.filterLabels = this.filterLabels.map((x: any, i: number) => {
        return { ...x, selected: Object.values($event)[i] };
      });
    }

    this.sharedService
      .getSelectedFilterData(
        this.selectedFilters,
        JSON.parse(
          JSON.stringify(this.filterCombinations?.data?.studentPersistence)
        ),
        orderOfKeys,
        'studentPersistence',
        this.yearFormat
      )
      .then((filter: StudentProgress) => {
        this.getDataForChart(filter);
      });
    const orgDetails: OrgDetail = JSON.parse(
      localStorage.getItem('userDetailId')
    );
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Student_Persistence_Filter_${this.analyticsService.convertArrayToString(
          $event?.Start_Year
        )}_${this.analyticsService.convertArrayToString(
          $event?.Institution
        )}_${this.analyticsService.convertArrayToString(
          $event?.Location
        )}_${this.analyticsService.convertArrayToString($event?.Hospital)}`,
        time,
        sessionId
      );
    }
  }

  public async getDataForChart(filter: StudentProgress) {
    if (filter) {
      this.loading = false;
      this.chartDataCount = this.defineChartObj(filter.programEnrollments);

      if (filter?.filteredData) {
        let diversityInfo =
          await this.programDiversity.createStudentProgressDiversityDetails(
            JSON.parse(JSON.stringify(filter?.filteredData))
          );
        this.setDiversityDetails(diversityInfo);
        const programEnrollmentsArray = filter?.filteredData.flatMap(item => item.programEnrollments);
        this.setWithDrawnReason(programEnrollmentsArray)
      } else {
        this.setDiversityDetails(filter.programEnrollments);
        this.setWithDrawnReason(filter.programEnrollments)
      }
    } else {
      this.loading = false;
      this.chartDataCount = {
        labels: ['RNBSN', 'RNMSN', 'MSN', 'DNP', 'Other'],
        chartData: [
          { label: 'Continuing', data: [0, 0, 0] },
          { label: 'Withdrawn', data: [0, 0, 0] },
          { label: 'Graduated', data: [0, 0, 0] },
        ],
      };
    }
  }
  // Reset filter data
  public reset($event: boolean) {
    if ($event) {
      this.filterLabels = this.filterLabels.map((x: FilterItems) => {
        return { ...x, selected: 'all' };
      });
    }
  }
  // Display the filter labels in column according number of filter labels
  public getPopUpState($event: string) {
    if ($event === 'open') {
      this.hospitalList = this.sessionData?.data.studentPersistence;
      this.filterLabels = this.filterLabels.map(
        (filterItems: FilterItems, i: number) => {
          if (i === 0) {
            return { ...filterItems, column: 'col-2' };
          } else if (i === 3) {
            return { ...filterItems, column: 'col-4' };
          } else {
            return { ...filterItems, column: 'col-3' };
          }
        }
      );
    }
  }

  public closedFilters($event: Object) {
    this.getSelectedValues({ ...this.selectedFilters, ...$event });
    const index: number = this.filterLabels.findIndex(
      (x: FilterItems) => x.filterLabel === Object.keys($event)[0]
    );
    if (this.filterLabels[index]) {
      this.filterLabels[index].selected = 'all';
    }
  }

  public createOtherProgramDetails(
    applicantsEnrollments: StudentProgressProgram[]
  ) {
    this.otherProgramDetails = this.getOtherProgramDetails(
      applicantsEnrollments
    );
  }

  public getOtherProgramDetails(data) {
    const excludedPrograms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];

    return data
      .filter((entry) => !excludedPrograms.includes(entry.program))
      .map((obj) => {
        const {
          Continuing = 0,
          Withdrawn = 0,
          Graduated = 0,
        } = obj.studentCount;
        const Total = Continuing + Withdrawn + Graduated;
        return {
          ...obj,
          Continuing,
          Withdrawn,
          Graduated,
          Total,
        };
      })
      .map(({ studentCount, ...rest }) => rest);
  }

  public checkYearFormat() {
    let tempStudentPersistenceAll = JSON.parse(
      JSON.stringify(this.persistenceDynamicAll.data.studentPersistence[0])
    );
    if (this.yearFormat) {
      delete tempStudentPersistenceAll.calendar;
      tempStudentPersistenceAll = {
        ...tempStudentPersistenceAll,
        programEnrollments: tempStudentPersistenceAll.fiscal.programEnrollments,
      };
      delete tempStudentPersistenceAll.fiscal;
    } else {
      delete tempStudentPersistenceAll.fiscal;
      tempStudentPersistenceAll = {
        ...tempStudentPersistenceAll,
        programEnrollments:
          tempStudentPersistenceAll.calendar.programEnrollments,
      };
      delete tempStudentPersistenceAll.calendar;
    }
    if (Object.keys(this.filterCombinations).length == 0) {
      this.filterCombinations = {
        data: {
          studentPersistence: [tempStudentPersistenceAll],
        },
      };
    } else {
      const index = this.filterCombinations.data.studentPersistence.findIndex(
        (item) => {
          const filter: ProgramDynamicFilter = item.filter;
          return (
            filter.fiscal_year == 'all' &&
            filter.institution == 'all' &&
            filter.location == 'all' &&
            filter.hospital == 'all'
          );
        }
      );
      if (index > -1) {
        this.filterCombinations.data.studentPersistence[index] =
          tempStudentPersistenceAll;
      }
    }
  }

  public getYearFormat() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.yearFormat = format;
      this.checkYearFormat();
    });
  }

  @HostListener('document:salesForceLogin', ['$event'])
  public onSalesforceLogin() {
    this.ngOnInit();
  }

  public setDiversityDetails(sessionData: StudentProgressProgram[]) {
    this.diversityInfo = sessionData;
  }

  public setWithDrawnReason(sessionData: StudentProgressProgram[]) {
    this.withDrawnReason = sessionData;
  }
}
