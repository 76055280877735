<div class="student-enrollments-wrap">
  <div class="filter-main-wrap">
    <div class="filter-title-wrap">
      <h5>Student Enrollment</h5>
      <p>
        Employees who have started with<br />
        Chamberlain University or Walden University
      </p>
    </div>
    <div class="d-flex flex-column align-self-lg-stretch filter-sec">
      <div
        class="filter d-flex justify-content-end align-items-center"
        *ngIf="!isSmallerDevice; else diversityMobile"
      >
        <app-diversity-modal
          [chartType]="enrollmentsChartType"
          [diversityInfo]="diversityInfo"
          [loading]="false"
          [graphTitle]="'Student Enrollments'"
        ></app-diversity-modal>
        <app-pp-button
          #button
          [loading]="loading"
          label="Filter"
          (click)="openPopup($event)"
          (keydown)="openPopup($event)"
        >
        </app-pp-button>
      </div>
      <ng-template #diversityMobile>
        <div class="diversity-align">
          <app-pp-button
            #button
            [loading]="loading"
            label="Filter"
            (click)="openPopup($event)"
            (keydown)="openPopup($event)"
          >
          </app-pp-button>
          <app-diversity-modal
            [chartType]="enrollmentsChartType"
            [diversityInfo]="diversityInfo"
            [loading]="false"
            [graphTitle]="'Student Enrollments'"
          ></app-diversity-modal>
        </div>
      </ng-template>
    </div>
  </div>
  <app-filter-chips
    class="chip-container mt-3 mb-3"
    (emitRemovedFilter)="closedFilters($event)"
    [selectedFilters]="selectedFilters"
  ></app-filter-chips>
  <app-info-graphic
    [chartData]="chartDataCount"
    [chartType]="'bar'"
    [otherProgramDetails]="otherProgramDetails"
    [totalInfo]="totalInfo"
    [totalYearWiseInfo]="totalYearWiseInfo"
    [selectedFilters]="selectedFilters"
    [headerLabel]="headerLabel"
  >
  </app-info-graphic>
</div>
