import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() public filterLabel: string;
  @Input() public label: string;
  @Input() public filterData: any;
  @Output() public onRadioButtonChange = new EventEmitter();
  @Input()
  public alphabetGroups: any = {
    'A-D': [],
    'E-I': [],
    'J-O': [],
    'P-T': [],
    'U-Z': [],
  };
  public alphabetGroupIterator: string[] = ['A-D', 'E-I', 'J-O', 'P-T', 'U-Z'];
  @ViewChildren('alphaSection') public alphaSection: QueryList<ElementRef>;
  @ViewChild(NgScrollbar, { static: true }) public scrollable: NgScrollbar;
  @ViewChild('scrollContainer', { read: ElementRef })
  public scrollContainer: ElementRef;
  @Input() public value: string;
  @Input() public groupByAlphabet: boolean;
  @Input() public styleClass: string;
  @Input() public activeAlphaChar: string;
  @Output() public activeAlphaButton = new EventEmitter();
  public selectedOption: string[];
  public _options: any = [];
  public filteredData: string[] = [];
  public shouldApplyAlphasort: boolean = true;
  @Input()
  public get options(): any {
    return this._options;
  }
  public set options(value: any) {
    this._options = value;
    const pgmData = this._options.map((key) => {
      try {
        return key.replace('-TP', '');
      } catch (e) {}
      return key;
    });
    this.filteredData = Array.from(new Set(pgmData));
  }
  public onChange: any = () => {};
  public onTouched: any = () => {};
  public writeValue(value: string) {
    this.value = value;
  }

  public ngOnInit() {
    this.checkTheLabel();
  }

  public checkTheLabel() {
    if (this.filterLabel === 'Modality') {
      this.shouldApplyAlphasort = false;
      this.sortModalityByNumber();
    }
  }

  public sortModalityByNumber() {
    if (this.filteredData) {
      let isContainsUnderScore = this.filteredData.some(function (item) {
        return item.includes('_');
      });

      if (isContainsUnderScore) {
        this.filteredData = this.filteredData
          .map((str) => {
            const [numStr, letter] = str.split('_');
            return { num: parseInt(numStr), letter };
          })
          .sort((a, b) => a.num - b.num)
          .map((obj) => obj.letter);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.groupByAlphabet) {
      if (
        changes['activeAlphaChar']?.currentValue &&
        this.alphaSection?.toArray()?.length
      ) {
        const index = this.alphabetGroupIterator.indexOf(
          changes['activeAlphaChar'].currentValue
        );
        this.scrollable.scrollToElement(this.alphaSection?.toArray()[index]);
        if (changes['value']?.currentValue) {
          this.selectedOption = changes['value']?.currentValue;
          if (!Array.isArray(this.selectedOption)) {
            this.selectedOption = [this.selectedOption];
          }
        }
      } else if (changes['value']?.currentValue) {
        this.selectedOption = changes['value']?.currentValue;
        if (!Array.isArray(this.selectedOption)) {
          this.selectedOption = [this.selectedOption];
        }
      }

      if (changes['options']?.isFirstChange()) {
        Object.keys(this.alphabetGroups).forEach((x: string) => {
          this.alphabetGroups[x] = [];
        });
        const all = this.options[0];
        this.options = this.options.slice(1, this.options.length);
        this.options = this.options.sort();
        this.options.unshift(all);
        this.options = [...this.options].sort((a, b) => {
          if (a === 'all') {
            return -1;
          }
          if (b === 'all') {
            return 1;
          }
          return a.localeCompare(b);
        });
        this.options.forEach((items: string) => {
          const firstChar = items.charAt(0).toUpperCase();
          if (firstChar >= 'A' && firstChar <= 'D') {
            this.alphabetGroups['A-D'].push(items);
          } else if (firstChar >= 'E' && firstChar <= 'I') {
            this.alphabetGroups['E-I'].push(items);
          } else if (firstChar >= 'J' && firstChar <= 'O') {
            this.alphabetGroups['J-O'].push(items);
          } else if (firstChar >= 'P' && firstChar <= 'T') {
            this.alphabetGroups['P-T'].push(items);
          } else if (firstChar >= 'U' && firstChar <= 'Z') {
            this.alphabetGroups['U-Z'].push(items);
          }
        });
      }
    } else if (changes['value']?.currentValue) {
      this.selectedOption = changes['value']?.currentValue;
      if (!Array.isArray(this.selectedOption)) {
        this.selectedOption = [this.selectedOption];
      }
    }
  }

  public checkBoxChecked(data: string) {
    return this.selectedOption?.find((option) => data == option);
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public selectOption(option) {
    try {
      if (option.target.checked) {
        this.handleCheckedOption(option);
      } else {
        this.handleUncheckedOption(option);
      }
    } catch (e) {}
    this.onChange(this.selectedOption);
    this.onRadioButtonChange.emit(this.selectedOption);
  }

  // Handle the logic when an option is checked
  public handleCheckedOption(option) {
    if (this.selectedOption.includes(option.target.value)) {
      this.selectedOption = this.selectedOption.filter(
        (item) => item !== option.target.value
      );
    } else {
      this.manageAllSelection(option);
      this.selectedOption.push(option.target.value);
    }
  }

  // Handle the logic when an option is unchecked
  public handleUncheckedOption(option) {
    const index = this.selectedOption.indexOf(option.target.value);
    if (index !== -1) {
      this.selectedOption.splice(index, 1);
    }
    this.manageAllDeselection();
  }

  public manageAllSelection(option) {
    if (this.selectedOption.includes('all')) {
      this.selectedOption.shift();
    }
    if (option.target.value === 'all') {
      this.selectedOption = [];
    }
  }

  public manageAllDeselection() {
    if (this.selectedOption.includes('all')) {
      this.selectedOption.shift();
    }
    if (this.selectedOption.length === 0) {
      this.selectedOption.push('all');
    }
  }
}
