import {
  Component,
  Inject,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../../shared/services/api.service';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-sf-loader',
  templateUrl: './sf-loader.component.html',
  styleUrls: ['./sf-loader.component.scss'],
})
export class SfLoaderComponent {
  @ViewChild('loader') public loaderTemp: TemplateRef<any>;
  private readonly viewContainerRef: ViewContainerRef;
  constructor(
    viewContainerRef: ViewContainerRef,
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly analytics: AngularFireAnalytics,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.viewContainerRef = viewContainerRef;
  }

}
