<nav class="nav-bar-content">
  <div class="col-12 row nav-web-wrap">
    <div class="loader">
      <output
        class="spinner-border"
        aria-live="polite"
        *ngIf="contactLoader"
      ></output>
    </div>
    <div class="col-3" *ngIf="!contactLoader">
      <div class="quest-left-wrap">
        <div class="ques-title-wrap">
          <div
            class="ques-title"
            *ngFor="let faqDataTitle of faqTitleContent; let i = index"
            [ngClass]="{ active: i === count }"
          >
            <button (click)="faqQuestionClick(i)">
              {{ faqDataTitle }}
              <img
                [ngClass]="{ 'img-active': i === count }"
                alt="close"
                [src]="arrowImageUrl"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9 desc" *ngIf="!contactLoader">
      <button class="button">{{ faqData[count]?.title }}</button>
      <div class="col-8 nav-bar-description">
        <div
          *ngFor="
            let dummy of ' '.repeat(questionCount).split('');
            let x = index
          "
        >
          <div
            class="faq-question"
            (click)="openDescription(x)"
            [ngClass]="
              x === descriptionCount && descriptionClose === false
                ? 'faq-question-active'
                : 'faq-question'
            "
            (keydown)="openDescription(x)"
          >
            <p>{{ faqData[count].content[x] }}</p>
            <div class="arrow-align">
              <span class="icon-arrow-down"></span>
            </div>
            <div class="description">
              <p
                [innerHTML]="getDescription(faqData[count]?.description[x])"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nav-mob-wrap">
    <div class="col-12 desc">
      <div class="loader">
        <output
          class="spinner-border"
          *ngIf="contactLoader"
          aria-live="polite"
        ></output>
      </div>
      <div
        class="desc-wrap"
        *ngFor="let faqDataTitle of faqTitleContent; let i = index"
        [ngClass]="{ descActive: descButtonActive && i === count }"
      >
        <div
          class="button-wrap"
          (click)="faqMobQuestionClick(i)"
          (keydown)="faqMobQuestionClick(i)"
        >
          <button class="button">{{ faqDataTitle }}</button>
          <div class="arrow-align">
            <span class="icon-arrow-down"></span>
          </div>
        </div>
        <div
          *ngFor="
            let dummy of ' '.repeat(questionCount).split('');
            let x = index
          "
        >
          <div class="nav-bar-description">
            <div
              class="faq-question"
              (click)="openMobDescription(x)"
              [ngClass]="
                x === mobDescriptionCount && descriptionClose === false
                  ? 'faq-question-active'
                  : 'faq-question'
              "
              (keydown)="openMobDescription(x)"
            >
              <div class="que-title">
                <p>{{ faqData[count]?.content[x] }}</p>
                <div class="arrow-align">
                  <span class="icon-arrow-down"></span>
                </div>
              </div>
              <div class="description">
                <p
                  [innerHTML]="getDescription(faqData[count]?.description[x])"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
