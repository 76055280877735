import { Component, Input } from '@angular/core';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SharedService } from '../../services/shared.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { getFormattedCurrentDateTime } from '../../services/functional-handler/functional-handler';
import { NavItem, OrgDetail } from '../model/customtypes-model.interface';

@Component({
  selector: 'app-secondary-nav',
  templateUrl: './secondary-nav.component.html',
  styleUrls: ['./secondary-nav.component.scss'],
})
export class SecondaryNavComponent {
  @Input() public navLinks: NavItem[] = [];
  @Input() public activeLink: string = '';
  public navDetail: boolean = false;
  public showPdfPopup = false;
  public disableExport = false;
  public exportRemoval = [];
  public exportVisible = true;
  public userName: string;
  public userEmail: string;
  public hideButtons: boolean = false;
  public showClinicalInsights: boolean;
  public filterednav: NavItem[] = [];
  public partnershipInfo: any[] = [];
  constructor(
    public router: Router,
    public analytics: AngularFireAnalytics,
    public sharedService: SharedService,
    private readonly analyticsService: AnalyticsService
  ) {}

  public async ngOnInit() {
    this.exportRemoval = [
      '/dashboard/partnership-overview',
      '/dashboard/lad-information-materials',
      `/dashboard/clinical-insights/${this.sharedService.getOrgId()}`,
    ];
    const state: RouterState = this.router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;
    this.exportVisible = !this.exportRemoval.find(
      (url) => url === snapshot.url
    );
    this.sharedService.exportDisabled$.subscribe((NavVal: boolean) => {
      this.disableExport = NavVal;
    });

    const role = localStorage.getItem('role');
    if (role == 'admin') {
      this.hideButtons = true;
      this.navLinks.forEach((item) => {
        switch (item.name) {
          case 'Admin':
          case 'Clinical Status':
          case 'Users':
          case 'Roles':
            item.isVisible = true;
            break;
          default:
            item.isVisible = false;
        }
      });
    }
  }

  public navClick(page: string) {
    this.navDetail = !this.navDetail;
    this.sharedService.isNgScrollReachedMiddle.next(false);
    this.analytics.logEvent(page);
    const orgDetails: OrgDetail = JSON.parse(
      localStorage.getItem('userDetailId')
    );

    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (page === 'Partnership Overview') {
      if (localStorage.getItem('providerId') === 'firebase') {
        this.analyticsService.addAnalytics(
          this.userName,
          email,
          orgName,
          'Partnership Overview Page',
          time,
          sessionId
        );
      }
    }
    if (page === 'Clinical Insights') {
      if (localStorage.getItem('providerId') === 'firebase') {
        this.analyticsService.addAnalytics(
          this.userName,
          email,
          orgName,
          'Clinical Insights Page',
          time,
          sessionId
        );
      }
    }
  }

  public openPdfDownloadPopup() {
    this.analytics.logEvent('click_PDF_Export_button');
    this.showPdfPopup = true;
    this.sharedService.allExportClick.next(true);
  }
}
