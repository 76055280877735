<div
  class="divider text-center mt-4"
  *ngIf="
    router.url.includes('learner-insights') ||
    router.url.includes('clinical-insights')
  "
>
  <h6 *ngIf="calendarYear">
    * All graphs represents Academic data from Jan to Dec for all the years
    except current year, current year data is in progress.
  </h6>
  <h6 *ngIf="fiscalYear">
    * All graphs represents Academic data from July to June for all the years
    except current year, current year data is in progress.
  </h6>
</div>
<footer [class.customFooter]="!router.url.includes('lad')">
  <small
    >The trademarks / service marks used herein are owned by Adtalem Global
    Education or one of its subsidiaries / divisions.</small
  >
</footer>
