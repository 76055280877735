import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alphasort',
})
export class SortPipe implements PipeTransform {
  public transform(value: any) {
    const allIndex = value.indexOf('all') || value.indexOf('All');
    const firstItem = value[0];
    if (allIndex !== -1) {
      value[0] = value[allIndex];
      value[allIndex] = firstItem;
    }
    if (value?.every((x) => typeof x === 'string')) {
      value = value.sort((a, b) => a.localeCompare(b));
    } else {
      value.shift();
      value.sort();
      value.unshift('all');
    }
    return this.moveAllLabelToFirstIndex(value);
  }

  public moveAllLabelToFirstIndex(filterLabelInfo: any[]) {
    if (
      filterLabelInfo?.length
    ) {
      const index = filterLabelInfo.findIndex(
        (item) => item.toString().toLowerCase() === 'all'
      );
      if (index !== -1) {
        filterLabelInfo.splice(index, 1); // Remove the 'all' element
        filterLabelInfo.unshift('all'); // Add 'all' to the start of the array
      }

      return filterLabelInfo;
    } else {
      return [];
    }
  }
}
