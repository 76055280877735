<div class="information-block-main-wrap">
  <output class="spinner-border mx-auto" aria-live="polite" *ngIf="contactLoader"></output>
  <div
    class="information-block"
    *ngFor="let item of listOfPrograms; let i = index"
  >
    <h4>{{ item.pdfTitle | titlecase }}</h4>
    <a
      (click)="openPdfInNewTab(env + '/image/', item?.dataUrl, item)"
      [ngClass]="{ 'loader-active': item === activeLoader }"
      class="information-image-wrap"
    >
      <output
        class="spinner-border mx-auto"
        aria-live="polite"
        *ngIf="item === activeLoader"
      ></output>
      <span>{{ item.mimeType }}</span>
      <img [src]="imageThumbnailUrl[i]" alt="{{ item?.imageTitle }}" />
    </a>
    <div class="information-block-bottom-wrap">
      <p>Updated {{ item.dateTime | getLastUpdatedString }}</p>
      <div class="share-options">
        <span>
          <a
            title="Download"
            href="javascript:void(0)"
            (click)="
              downloadPdf(
                env + '/image/',
                item?.dataUrl,
                item?.imageTitle,
                item?.mimeType
              )
            "
            class="icon-download"
          ></a
        ></span>
        <span>
          <a
            title="Share"
            href="javascript:void(0)"
            (click)="
              shareFile(env + '/image/', item?.dataUrl, item?.imageTitle)
            "
            class="icon-Share"
          ></a
        ></span>
      </div>
    </div>
  </div>
</div>
