import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'getLastUpdatedString',
  pure: true,
})
export class DataAgoPipe implements PipeTransform {
  public transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      const realDate = value.split('T')[0];
      const datePipe = new DatePipe('en-US');
      const updatedDate = datePipe.transform(realDate, 'MM-dd-yyyy');

      if (seconds < 29) {
        // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      }

      const intervals: any = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };

      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          switch (i) {
            case 'hour':
            case 'day':
              return counter === 1
                ? `${counter} ${i} ago`
                : `${counter} ${i}s ago`;
            default:
              return `On ${updatedDate}`;
          }
        }
      }
    }
    return value;
  }
}
