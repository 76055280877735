import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { DOCUMENT } from '@angular/common';
import { EmailService } from '../../services/email/email.service';
import { environment } from '../../../../../src/environments/environment';
import { EmailRequest, OrgDetail } from '../model/customtypes-model.interface';

@Component({
  selector: 'app-faq-modal',
  templateUrl: './faq-modal.component.html',
  styleUrls: ['./faq-modal.component.scss'],
})
export class FaqModalComponent implements OnInit {
  @ViewChild('fileInput') public fileInput: ElementRef;
  public showQueryDiv = false;
  public showQuerySubmission = false;
  public userName: string;
  public userEmail: string = '';
  public selectedFileNames: {
    name: string;
    size: number;
    type: string;
    content: string;
    file: string;
  }[] = [];
  public noFile: boolean = false;
  public totalFileSize: number = 0;
  public MAX_TOTAL_SIZE_MB: number = 10;
  public fileSize: boolean = false;
  public isTextareaEmpty: boolean = false;
  public questions: string = '';
  public smallFeedback: boolean = false;
  public env = environment;
  public buttonText: boolean = true;
  public btnLoader: boolean = false;
  public disableBtn: boolean = false;
  constructor(
    private readonly sharedService: SharedService,
    @Inject(DOCUMENT) public document: Document,
    private readonly emailService: EmailService
  ) {}

  public async ngOnInit() {
    this.sharedService.faqModalEvent$.subscribe((x: boolean) => {
      this.showQueryDiv = x;
    });
    this.sharedService.userDetails.subscribe((data) => {
      if (data) {
        const orgDetails: OrgDetail = JSON.parse(
          localStorage.getItem('userDetailId')
        );
        this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
      }
    });
    this.userEmail = await this.sharedService.getUserDetails();
  }

  public closePopup() {
    this.sharedService.faqModalEvent.next(false);
    const dropdown: any = this.document.querySelector('.faq-query-popup');
    dropdown?.remove();
    this.showQuerySubmission = false;
    this.selectedFileNames = [];
    this.fileSize = false;
    this.noFile = false;
    this.questions = '';
    this.isTextareaEmpty = false;
    this.smallFeedback = false;
  }

  public querySubmission() {
    if (this.questions.trim() === '') {
      this.isTextareaEmpty = true;
    } else {
      this.isTextareaEmpty = false;
      this.buttonText = false;
      this.btnLoader = true;
      this.disableBtn = true;
      const emailRequest: EmailRequest = {
        from: this.userEmail,
        to: this.env.mailTo,
        subject: `Partner Portal Feedback from ${localStorage.getItem(
          'organization'
        )}`,
        body: `<p style="font-size: 14px">Hello Team,</p><p style="text-transform: capitalize;line-height: 25px;font-size: 14px">&emsp;${this.questions}</p><p style="margin-bottom: 0;font-size: 14px">Regards,</p><p style="margin-top: 5px;font-size: 14px">${this.userName}</p>`,
        attachment: this.selectedFileNames.map((file) => ({
          type: file.type,
          name: file.name,
          content: file.name,
          file: file.file,
        })),
      };
      this.emailService.sendEmail(emailRequest).subscribe((response) => {
        this.questions = '';
        this.selectedFileNames = [];
        this.showQueryDiv = false;
        this.showQuerySubmission = !this.showQuerySubmission;
        this.buttonText = true;
        this.btnLoader = false;
        this.disableBtn = false;
        this.totalFileSize = 0;
      });
    }
  }

  public triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  public onFileSelected(event: any) {
    const files: FileList = event.target.files;
    let newTotalFileSize = this.totalFileSize;
    const promises: Promise<{
      name: string;
      size: number;
      content: string;
      type: string;
      file: string;
    }>[] = [];
    const filesArray = Array.from(files); 
    for (let file of filesArray) {
      const fileType = file.type;
      const fileName = file.name;
      const fileSize = file.size;
      switch (fileType) {
        case 'image/png':
        case 'application/pdf':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          newTotalFileSize += fileSize;
          if (!this.selectedFileNames.find((f) => f.name === fileName)) {
            promises.push(
              this.convertFileToBase64(file).then((base64) => ({
                name: fileName,
                size: fileSize,
                content: fileName,
                type: fileType,
                file: base64.split(',')[1], // Remove the "data:<mime-type>;base64," prefix
              }))
            );
          }
          if (newTotalFileSize <= this.MAX_TOTAL_SIZE_MB * 1024 * 1024) {
            this.noFile = false;
          } else {
            this.fileSize = true;
          }
          break;
        default:
          this.noFile = true;
      }
    }
    Promise.all(promises).then((filesBase64) => {
      this.selectedFileNames = [...this.selectedFileNames, ...filesBase64];
      this.totalFileSize = newTotalFileSize;
    });
  }

  public removeFile(index: number) {
    const removedFile = this.selectedFileNames[index];
    this.selectedFileNames.splice(index, 1);
    this.totalFileSize -= removedFile.size;
    if (
      this.selectedFileNames.length == 0 ||
      this.totalFileSize < this.MAX_TOTAL_SIZE_MB * 1024 * 1024
    ) {
      this.fileSize = false;
    }
  }

  public onClickPopupSubWrapper(e: any) {
    e.stopPropagation();
  }

  public onClickPopupWrapper() {
    this.closePopup();
  }

  public checkLength() {
    const minLength = 10;
    if (this.questions.length >= 1) {
      this.isTextareaEmpty = false;
    }
    if (this.questions.length < minLength) {
      this.smallFeedback = true;
    } else {
      this.smallFeedback = false;
    }
  }

  public convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }
}
