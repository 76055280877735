import { Component, Input, OnInit, inject } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../services/shared.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [DatePipe],
})
export class DatePickerComponent implements OnInit {
  public today = inject(NgbCalendar).getToday();
  public model: NgbDateStruct;
  public date: { year: number; month: number };
  public selectedDate: NgbDateStruct;
  public formattedDate: string;
  public minDate: NgbDateStruct;
  public initialStartYear: string;
  public maxDate: NgbDateStruct;
  public greaterDate: boolean = false;

  constructor(
    public sharedService: SharedService,
    private readonly datePipe: DatePipe
  ) {}

  @Input() set startYear(data: any) {
    const formattedDate = new Date(data).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    this.initialStartYear = formattedDate;
  }

  public ngOnInit(): void {
    const initialStartYear = new Date(this.initialStartYear).getFullYear();
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const currentDate = date.getDate();
    this.minDate = { year: initialStartYear + 1, month: 1, day: 1 };
    this.sharedService.yearFormat$.subscribe((format: any) => {
      if (format === true) {
        this.maxDate = { year: currentYear + 1, month: 1, day: 1 };
      } else {
        this.maxDate = {
          year: currentYear,
          month: currentMonth,
          day: currentDate,
        };
      }
    });
  }

  public onDateSelect(date: NgbDateStruct) {
    this.selectedDate = date;
    this.formattedDate = this.formatDate(this.selectedDate);
  }

  public adddate(event: any) {
    const currentDate = new Date().toLocaleDateString();
    const formattedDate = new Date(currentDate).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    let [month, day, year] = formattedDate.split('/');
    let todayDate = `${day}/${month}/${year}`;
    if (new Date(this.formattedDate) > new Date(todayDate)) {
      this.greaterDate = true;
    } else {
      this.sharedService.calenderDateEvent.next(this.formattedDate);
      this.sharedService.calenderPopupEvent.next(false);
      event.stopPropagation();
    }
  }

  public cancelCalender(event: any) {
    this.sharedService.calenderPopupEvent.next(false);
    event.stopPropagation();
  }

  public formatDate(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return this.datePipe.transform(jsDate, 'dd/MM/yyyy');
  }
}
