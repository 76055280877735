//  Common interfaces

import { AbstractControl } from '@angular/forms';
import { ValueType } from '../../services/class-type/chart-type';
import { DiversityInfo } from '../../services/entity/shared.entity';
import { ChartType } from '../../services/enum/shared.enum';

export interface ChartNumberItem {
  labels: string;
  data: (number | null | string)[];
}

export interface AnticipatedChartNumberItem {
  labels: string;
  data: (number | null)[];
}

// Horizontal bar chart

export interface ChartNumberData {
  [index: number]: ChartNumberItem;
  labels: string[];
}

export interface ChartDataSet {
  chartData: ChartDataItems[];
  labels: string[];
  chartConfig: any[]
}

export interface ChartDataItems {
  data: {
    Enrollment: number;
    Applicants: number;
  };
}

export interface StaticFilterData {
  Total: number;
  Applicants: number;
  Enrollment: number;
}

export interface DataSetResponse {
  backgroundColor: (context: any) => string;
  barThickness: number;
  borderColor: string;
  borderRadius: number;
  borderWidth: number;
  data: number[];
  hoverBackgroundColor: string;
  hoverBorderColor: string;
  label: string;
}

export interface DataItemArray {
  value: number;
}

export interface ChartResponse {
  labels: string[];
  datasets: DataSetResponse[];
}

// Vertical Bar chart

export interface VerticalChartDataSet {
  labels: any;
  chartData: ChartNumberItem[];
}

// Anticipated grad chart
export interface AnticipatedGradDataSet {
  labels: any;
  chartData: AnticipatedChartNumberItem[];
}

// Student progress bar chart
export interface StudentData {
  data: {
    Continuing: number;
    Withdrawn: number;
    Graduated: number;
    total: number;
  };
}
export interface SPStudentData {
  chartData: StudentData[] | any[];
  labels: string[] | any[];
}

export interface SPBarChartData {
  barThickness: number;
  label: string;
  data: (number | null)[];
  borderColor: string;
  borderWidth: number;
  hoverBorderColor: string;
  hoverBackgroundColor: string;
  borderRadius: number;
}

export interface SPChartData {
  datasets: SPBarChartData[];
  labels: string[];
}

export interface NumberArray {
  [index: number]: number;
}
export interface ChartArea {
  left: number;
  top: number;
  right: number;
  bottom: number;
}
export interface ChartDataSets {
  label: string;
  data: (number | null)[];
  barThickness: number;
  borderColor: string;
  borderWidth: number;
  hoverBorderColor: string;
  hoverBackgroundColor: string;
  borderRadius: number;
}

export interface User {
  uid: string;
  email: string;
  emailVerified: boolean;
  isAnonymous: boolean;
  providerData: ProviderData[];
  stsTokenManager: StsTokenManager;
  createdAt: string;
  lastLoginAt: string;
  apiKey: string;
  appName: string;
}

export interface ProviderData {
  providerId: string;
  uid: string;
  displayName: string | null;
  email: string;
  phoneNumber: string | null;
  photoURL: string | null;
}

export interface StsTokenManager {
  refreshToken: string;
  accessToken: string;
  expirationTime: number;
}

export interface NavItem {
  route: string;
  key: string;
  name: string;
  content: string;
  isVisible: boolean;
  isClinicalVisible: boolean;
}

export interface Program {
  content: string;
  name: string;
}

export interface TableHeader {
  value: string;
  name: string;
  color?: string;
}

export interface ExcelData {
  data: any[];
  sheetName: string;
}

export interface LearnerAnalyticsExcelDataModel {
  Institution: string;
  Program: string;
  Location: string;
  Hospital: string;
  Parent: string;
  Modality: string;
  Applicants: number;
  Students: number;
  Alumni: number;
}

export interface LearnerInfo {
  Applicants: number;
  Students: number;
  Alumni: number;
}

export interface LearnerAnalyticsExport {
  filter: LearnerAnalyticsExportFilter;
  studentDetails: LearnerAnalyticsExportStudentDetails;
}

export interface LearnerAnalyticsExportFilter {
  Institution: string;
  ProgramGroup: string;
  State: string;
  Hospital: string;
  Parent: string;
  Modality: string;
}

export interface LearnerAnalyticsExportStudentDetails {
  Applying?: number;
  Applicants?: number;
  Students: number;
  Alumni: number;
}

export interface Diversity {
  name: string;
  data: DiversityInfo;
}

export interface ProgramDynamic {
  orgId?: string;
  orgName?: string;
  filter?: ProgramDynamicFilter;
  programYear: DynamicProgramYear[];
  filterString?: string;
  filteredData?: any;
  otherPrograms?: any;
}

export interface ProgramDynamicFilter {
  fiscal_year?: number | string;
  fiscal_month?: number | string;
  institution: string;
  location: string;
  hospital: string;
  hospitalId?: string;
  month?: number;
  year?: number;
}

export interface DynamicProgramYear {
  year: number;
  month?: number;
  programsCount: {
    [key: string]: ValueType;
  };
}

export interface ApplicantsToEnrollment {
  orgId?: string;
  orgName?: string;
  filter?: ProgramDynamicFilter;
  ApplicantsToEnrollment: ApplicantsToEnrollmentProgram[];
  filterString?: string;
  filteredData?: any;
}

export interface ApplicantsToEnrollmentProgram {
  program: string;
  programCount: {
    Enrollment: number;
    Applicants: number;
  };
  diversityDetails?: ProgramDiversity;
  percentage?: string;
  applicantsreason?: any;
}

export interface StudentProgress {
  orgId?: string;
  orgName?: string;
  filter?: ProgramDynamicFilter;
  programEnrollments: StudentProgressProgram[];
  filterString?: string;
  filteredData?: any;
}

export interface StudentProgressProgram {
  program: string;
  studentCount: {
    New: number;
    Continuing: number;
    Withdrawn: number;
    Graduated: number;
  };
  diversityDetails?: ProgramDiversity;
  withdrawnReason?: any;
}

export interface DashboardExcelItems {
  displayName: string;
  name: string;
  sheetName: string;
  selected: boolean;
}

export interface DashboardExcelSheetInfo {
  name: string;
  sheetName: string;
}

export interface ChartOtherProgramInfo {
  program: string;
  [key: string]: ValueType;
}

export interface ClinicalInsight {
  orgId?: string;
  orgName?: string;
  filter?: {
    institution: string;
    clinicalSite: string;
    startDate: string;
  };
  programLocation: ClinicalInsightLocationInfo[];
}

export interface ClinicalInsightLocationInfo {
  clinicalSite: string;
  [key: string]: ValueType;
}

export interface FirebaseResponse {
  isSuccess: boolean;
  isFileExist?: boolean;
  response: any;
}

export interface CustomCardConfig {
  id: number;
  order: number;
  component: string;
}

export interface AnticipatedGrateDate {
  orgId?: string;
  orgName?: string;
  programYear: GraduationProgramYear[];
  filter?: {
    fiscal_year: string;
    location: string;
    institution: string;
    hospital: string;
  };
}

export interface GraduationProgramYear {
  enrollmentYear: number;
  anticipatedGradYear: number;
  anticipatedGradQuarter: string;
  programsCount: {
    [key: string]: ValueType;
  };
  diversityDetails?: ProgramDiversity;
}

export interface ProgramDiversity {
  ethnicity: {
    hispanicLatino: number;
    notHispanicLatino: number;
    unspecified: number;
  };
  race: {
    americanIndianOrAlaskanNative: number;
    asian: number;
    blackOrAfricanAmerican: number;
    hawaiianOrPacificIslander: number;
    white: number;
    multiRace: number;
    unspecified: number;
  };
  gender: {
    male: number;
    female: number;
    unspecified: number;
  };
  age: {
    '15_19': number;
    '20_24': number;
    '25_29': number;
    '30_34': number;
    '35_39': number;
    '40_44': number;
    '45_49': number;
    '50_54': number;
    '55_59': number;
    '60+': number;
  };
}

export interface LearnerAnalytics {
  orgId?: string;
  orgName?: string;
  filter?: LearnerAnalyticsFilter;
  studentDetails: LearnerAnalyticsExportStudentDetails;
  funnelStudentDetails?: any;
  diversityDetails?: LearnerDiversity;
  filteredData?: any;
}

export interface LearnerDiversity {
  ethnicity: LearnerEthnicityDiversity;
  age: LearnerAgeDiversity;
  race: LearnerRaceDiversity;
  gender: LearnerGenderDiversity;
}

export interface LearnerEthnicityDiversity {
  hispanicLatino: LearnerAnalyticsExportStudentDetails;
  notHispanicLatino: LearnerAnalyticsExportStudentDetails;
  unspecified: LearnerAnalyticsExportStudentDetails;
}

export interface LearnerAgeDiversity {
  '15_19': LearnerAnalyticsExportStudentDetails;
  '20_24': LearnerAnalyticsExportStudentDetails;
  '25_29': LearnerAnalyticsExportStudentDetails;
  '30_34': LearnerAnalyticsExportStudentDetails;
  '35_39': LearnerAnalyticsExportStudentDetails;
  '40_44': LearnerAnalyticsExportStudentDetails;
  '45_49': LearnerAnalyticsExportStudentDetails;
  '50_54': LearnerAnalyticsExportStudentDetails;
  '55_59': LearnerAnalyticsExportStudentDetails;
  '60+': LearnerAnalyticsExportStudentDetails;
  unspecified: LearnerAnalyticsExportStudentDetails;
}

export interface LearnerGenderDiversity {
  male: LearnerAnalyticsExportStudentDetails;
  female: LearnerAnalyticsExportStudentDetails;
  unspecified: LearnerAnalyticsExportStudentDetails;
}

export interface LearnerRaceDiversity {
  aboveRace: LearnerAnalyticsExportStudentDetails;
  americanIndianOrAlaskanNative: LearnerAnalyticsExportStudentDetails;
  asian: LearnerAnalyticsExportStudentDetails;
  blackOrAfricanAmerican: LearnerAnalyticsExportStudentDetails;
  hawaiianOrPacificIslander: LearnerAnalyticsExportStudentDetails;
  multiRace: LearnerAnalyticsExportStudentDetails;
  white: LearnerAnalyticsExportStudentDetails;
  unspecified: LearnerAnalyticsExportStudentDetails;
}

export interface LearnerAnalyticsDynamicAll {
  orgId?: string;
  orgName?: string;
  filter?: LearnerAnalyticsFilter;
  calendar?: {
    studentDetails: LearnerAnalyticsExportStudentDetails;
  };
  fiscal?: {
    studentDetails: LearnerAnalyticsExportStudentDetails;
  };
  funnelStudentDetails?: any;
}

export interface LearnerAnalyticsData {
  LearnerAnalytics: LearnerAnalyticsDynamicAll[];
}

export interface StudentEnrollment {
  orgId?: string;
  orgName?: string;
  filter?: {
    institution: string;
    fiscal_year: string;
    location: string;
  };
  programYear: StudentEnrollmentProgram[];
}

export interface StudentEnrollmentProgram {
  year: number | string;
  programsCount: {
    [key: string]: ValueType;
  };
  diversityDetails?: ProgramDiversity;
}

export interface InfoCards {
  name: string;
  count: number;
  content: string;
  icon: string;
  logo: string;
  logoColor: string;
}

export interface FilterItems {
  filterItems?: string[] | number[];
  filterLabel: string;
  selected?: string;
  column?: string;
  tooltip?: string;
}

export interface AlumniDiversity {
  program: string;
  diversityDetails: ProgramDiversity;
}

export interface Alumni {
  orgId?: string;
  orgName?: string;
  filter?: {
    institution: string;
    location: string;
    fiscal_year?: string | number;
    hospital: string;
    hospitalId?: string;
  };
  otherPrograms?: AlumniDiversityProgram[];
  programYear: AlumniDiversityProgram[];
}

export interface AlumniDiversityProgram {
  year: number;
  month?: number;
  programsCount: {
    [key: string]: ValueType;
  };
  diversityProgram?: AlumniDiversity[];
}

export interface OtherProgramTitle {
  popupTotalTitle: string;
  popupTitle: ChartType;
}

export interface LearnerAnalyticsFilter {
  institution: string;
  program_group: string;
  state: string;
  hospital: string;
  modality?: string;
  hospitalId: string;
  year?: number;
  month?: number;
}

export interface LearnerAnalyticsFilterMap {
  institution: string | string[];
  program_group?: string | string[];
  state?: string | string[];
  hospital: string | string[];
  modality?: string | string[];
  hospitalId: string | string[];
}

export interface LearnerAnalyticsDynamic {
  LearnerAnalytics: LearnerAnalytics[];
}

export interface ApplicantsToEnrollments {
  ApplicantsToEnrollments: ApplicantsToEnrollment[];
}

export interface ApplicantsToEnrollmentDynamic {
  data: ApplicantsToEnrollments;
}

export interface StudentEnrollments {
  studentEnrollments: StudentEnrollment[];
}

export interface StudentEnrollmentDynamic {
  data: StudentEnrollments;
}

export interface StudentProgresses {
  studentPersistence: StudentProgress[];
}

export interface StudentProgressDynamic {
  data: StudentProgresses;
}

export interface AlumniProgram {
  alumniByProgram: Alumni[];
}

export interface AlumniDynamic {
  data: AlumniProgram;
}

export interface AnticipatedGradDate {
  anticipatedGradDate: AnticipatedGrateDate[];
}

export interface AnticipatedGradDateDynamic {
  data: AnticipatedGradDate;
}

export interface FilterData {
  Institution?: string;
  Location?: string;
  Year?: string | number;
  Start_Year?: string | number;
  Quarter?: string | number;
  Month?: string | number;
  Program?: string | number;
}

export interface LAFilterData {
  Hospital: string;
  Institution: string | string[];
  Location: string;
  Modality: string;
  Program: string;
}

export interface Attachment {
  type: string;
  name: string;
  content: string;
  file: string;
}

export interface EmailRequest {
  from: string;
  to: string[];
  subject: string;
  body: string;
  attachment: Attachment[];
}

export interface CommonFilter {
  hospital: string | string[];
  institution: string | string[];
  modality?: string | string[];
  program_group?: string | string[];
  state?: string | string[];
  fiscal_year?: string | number;
  location?: string | string[];
  hospitalId: string | string[];
}

export interface ApplicantsToEnrollmentDynamicAll {
  orgId?: string;
  orgName?: string;
  filter?: ProgramDynamicFilter;
  calendar?: {
    ApplicantsToEnrollment: ApplicantsToEnrollmentProgram;
  };
  fiscal?: {
    ApplicantsToEnrollment: ApplicantsToEnrollmentProgram;
  };
}

export interface ApplicantsToEnrollmentAllData {
  data: {
    ApplicantsToEnrollments: ApplicantsToEnrollmentDynamicAll[];
  };
}

export interface StudentPersistenceDynamicAll {
  orgId?: string;
  orgName?: string;
  filter?: ProgramDynamicFilter;
  calendar?: {
    programEnrollments: StudentProgressProgram;
  };
  fiscal?: {
    programEnrollments: StudentProgressProgram;
  };
}

export interface StudentPersistenceAllData {
  data: {
    studentPersistence: StudentPersistenceDynamicAll[];
  };
}

export interface StudentEnrollmentDynamicAll {
  orgId?: string;
  orgName?: string;
  filter?: ProgramDynamicFilter;
  calendar?: {
    programYear: DynamicProgramYear;
  };
  fiscal?: {
    programYear: DynamicProgramYear;
  };
}

export interface StudentEnrollmentAllData {
  data: {
    studentEnrollments: StudentEnrollmentDynamicAll[];
  };
}

export interface UserResponse {
  data: {
    users: string[];
  };
}

export interface AlumniAllData {
  data: {
    alumniByProgram: StudentEnrollmentDynamicAll[];
  };
}

export interface AnticipatedAllData {
  data: {
    anticipatedGradDate: StudentEnrollmentDynamicAll[];
  };
}

export interface UserData {
  data: Organization[];
  Users: UserSummary;
  Orgs: OrganizationSummary;
}

export interface AnticipatedCalendar {
  programYear: GraduationProgramYear[];
}

export interface AnticipatedFiscal {
  programYear: GraduationProgramYear[];
}

export interface AnticipatedGradDateData {
  orgId?: string;
  orgName?: string;
  filter?: {
    fiscal_year: string | number;
    institution: string;
    location: string;
    hospital: string;
    hospitalId?: string;
  };
  calendar?: AnticipatedCalendar;
  fiscal?: AnticipatedFiscal;
  programYear?: GraduationProgramYear[];
}

export interface AnticipatedStudentDetails {
  data?: {
    anticipatedGradDate: AnticipatedGradDateData[];
  };
}

export interface Organization {
  orgName: string;
  users: string[];
}

export interface UserSummary {
  count: number;
  userNames: string[];
}

export interface OrganizationSummary {
  count: number;
  orgNames: string[];
}

export interface AlumniStudentDetails {
  data?: AlumniByProgramData;
}

export interface AlumniByProgramData {
  alumniByProgram: AlumniByProgram[];
}

export interface AlumniByProgram {
  orgId?: string;
  orgName?: string;
  filter?: ProgramFilter;
  programYear: ProgramYear[];
  otherPrograms?: ProgramYear[];
  fiscal?: {
    programYear?: ProgramYear[];
  };
  calendar?: {
    programYear?: ProgramYear[];
  };
  [key: string]: any;
}

export interface ProgramFilter {
  fiscal_year?: string | number;
  institution: string;
  location: string;
  hospital: string;
  hospitalId?: string;
}

export interface ProgramYear {
  year: number;
  programsCount: {
    [key: string]: ValueType;
  };
  alumniByProgram?: Alumni[];
}

export interface NewOrganization {
  global_unique_id_c: string;
  id: string;
  name: string;
}

export interface ProgramCount {
  [key: string]: ValueType;
}

export interface ProgramLocation {
  clinicalSite: string;
  programsCount: ProgramCount;
}

export interface ClinicalFilter {
  institution: string;
  clinicalSite: string;
  startDate?: string;
}

export interface Calendar {
  programLocation: ProgramLocation[];
}

export interface Fiscal {
  programLocation: ProgramLocation[];
}

export interface ClinicalInsightData {
  orgId: string;
  orgName: string;
  filter: ClinicalFilter;
  programLocation?: ProgramLocation[];
  calendar?: Calendar;
  fiscal?: Fiscal;
}

export interface Data {
  clinicalInsights: ClinicalInsightData[];
}

export interface ClinicalInsightDetails {
  data?: Data;
}

export interface ClinicalAllData {
  data: {
    clinicalInsights: ClinicalInsightData[];
  };
}

export interface ChartDataCount {
  chartData: {
    data: number;
    labels: string;
  }[];
}

export interface SelectedFilters {
  Institution?: string;
  Location?: string;
  Year?: string | number;
  Start_Year?: string | number;
  Quarter?: string | number;
  Month?: string | number;
  Hospital?: string;
}

interface ChartData {
  data: {
    Continuing: number;
    Withdrawn: number;
    Graduated: number;
    total: number;
  };
}

export interface StudentChartDataCount {
  labels: string[];
  chartData: ChartData[];
}

export interface StudentEnrollChartDataCount {
  chartData: {
    data: number[];
    labels: string;
  }[];
  labels: number[];
}

export interface ApplicantChartDataCount {
  chartData: {
    data: {
      Enrollments: number;
      Applicants: number;
    };
  }[];
  labels: string[];
}

export interface AnticipatedChartDataCount {
  chartData: {
    data: number[];
    labels: string;
  }[];
  labels: number[];
}

interface Account {
  attributes: {
    type: string;
    url: string;
  };
  Id: string;
  Name: string;
  Global_Unique_ID__c: string;
}

export interface OrgDetail {
  Account: Account;
  AccountId: string;
  FirstName: string;
  Id: string;
  LastName: string;
  RecordTypeId: string;
  Status__c: string;
  attributes: {
    type: string;
    url: string;
  };
}

export interface FilterLabels {
  filterLabels: any[];
}

export interface DiversityInformation {
  diversityInfo: any[];
}

export interface AppToEntrolInfoItem {
  name: string;
  content: any;
}

export interface LoginForm {
  email: AbstractControl;
  password: AbstractControl;
}

export interface ForgotPasswordForm {
  email: AbstractControl;
}

export interface FilterChip {
  closable: boolean;
  key: string;
  value: string[] | string;
}

export interface PopupData {
  popupData: any;
}

export interface Items {
  items: any[];
}

export interface FilteredData {
  filteredData: any;
}

export interface FilterCombinations {
  filterCombinations: any;
}

export interface StudentPersistenceChartData {
  chartDataCount: any;
}

export interface InitialYear {
  initialStartYear: any;
}

export interface FilteredDataConfig {
  year?: number;
  month?: string;
  program?: string;
  quarter?: string;
}

export interface ProgramData {
  program: string | number;
  programCount: ProgramCount[] | {};
  programConfig?: ProgramConfig;
}

export interface ProgramDataCount {
  programCount: {
    Enrollment?: number;
    Applicants?: number;
  };
}

export interface ProgramConfig {
  year?: number;
  program?: string;
  quarter?: string;
  month?: string;
}

export interface ChartElement {
  height?: number;
  x?: number;
}

export interface TourPopupData {
  Body: string;
  Description: string;
  Number: string;
  title: string;
  url: string;
  Mobileurl?: string;
}

export interface SafeUrl {
  changingThisBreaksApplicationSecurity: string;
}

export interface ImageUrl {
  SafeResourceUrlImpl: SafeUrl;
}

export interface BlobData {
  Blob:{
    size: number;
    type: string
  }
}

export interface OrgData {
  id: string;
  status: boolean
}

export interface OrganizationDetails {
  [organizationName: string]: {
    id: string;
    status: boolean;
  };
}