import { Injectable } from '@angular/core';
import { GeneralConfigurarion } from '../entity/shared.entity';
import { FirebaseStorageService } from '../firebase/firebase-storage-service';
import { SharedService } from '../shared.service';
import { CustomCardConfig } from '../../components/model/customtypes-model.interface';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  CustomCardDefaultOrder,
  CustomCardWebDefaultOrder,
} from '../enum/shared.enum';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralConfigurationService {
  public userEmail: string = '';
  public organizationInfo: any;
  public generalConfigurartion: GeneralConfigurarion =
    new GeneralConfigurarion();
  public folderName: string = 'partner-portal/assets/generalConfig';
  public fileName: string = '';

  constructor(
    public firebaseStorageService: FirebaseStorageService,
    public sharedService: SharedService,
    private readonly http: HttpClient
  ) {}

  public async getUserDetails() {
    this.userEmail = await this.sharedService.getUserDetails();

    if (
      this.userEmail != null &&
      this.userEmail != undefined &&
      this.userEmail != ''
    ) {
      await this.setFileName(this.userEmail);
    }
  }

  public async getGeneralConfiguration(
    isSalesForceLogin: boolean
  ): Promise<GeneralConfigurarion> {
    if (!isSalesForceLogin) {
      await this.getUserDetails();
    }

    let configurartionResponse =
      await this.firebaseStorageService.getStorageFile(
        this.folderName,
        this.fileName,
        3
      );

    if (configurartionResponse.isSuccess) {
      this.generalConfigurartion = configurartionResponse.response;
    } else {
      this.generalConfigurartion = new GeneralConfigurarion();
      const orgDetails = localStorage.getItem('userDetailId');
      this.organizationInfo = JSON.parse(orgDetails);

      if (this.organizationInfo) {
        this.generalConfigurartion.setUserInfo(
          this.organizationInfo[0]?.FirstName,
          this.userEmail
        );
      }
    }

    await this.setColorMode(this.generalConfigurartion);
    await this.setYearFormat(this.generalConfigurartion);
    return this.generalConfigurartion;
  }

  public async saveUserSelectedColorMode(isLightColorMode: boolean) {
    this.generalConfigurartion = await this.getGeneralConfiguration(false);
    this.generalConfigurartion.isLightColorMode = !isLightColorMode;
    await this.firebaseStorageService.saveUserFile(
      this.fileName,
      this.folderName,
      this.generalConfigurartion
    );
    await this.setColorMode(this.generalConfigurartion);
  }

  public async setColorMode(sessionContent: GeneralConfigurarion) {
    this.sharedService.lightColorMode.next(!sessionContent.isLightColorMode);
  }

  public async saveUserSelectedYearFormat(isYearFormat: boolean) {
    this.generalConfigurartion = await this.getGeneralConfiguration(false);
    this.generalConfigurartion.isFiscalYear = isYearFormat;
    await this.firebaseStorageService.saveUserFile(
      this.fileName,
      this.folderName,
      this.generalConfigurartion
    );
    await this.setYearFormat(this.generalConfigurartion);
  }

  public async setYearFormat(sessionContent: GeneralConfigurarion) {
    if (!sessionContent.hasOwnProperty('isFiscalYear')) {
      this.sharedService.yearFormat.next(false);
    } else {
      this.sharedService.yearFormat.next(sessionContent.isFiscalYear);
    }
  }

  public async saveUserLoginCount() {
    this.generalConfigurartion = await this.getGeneralConfiguration(false);
    if (this.generalConfigurartion.loggedInCount < 4) {
      this.generalConfigurartion.loggedInCount =
        this.generalConfigurartion.loggedInCount + 1;
      await this.firebaseStorageService.saveUserFile(
        this.fileName,
        this.folderName,
        this.generalConfigurartion
      );
    }
  }

  public async saveUserLoginCountFromSalesForce(email: string) {
    await this.setFileName(email);

    this.generalConfigurartion = await this.getGeneralConfiguration(true);

    if (this.generalConfigurartion.loggedInCount < 4) {
      this.generalConfigurartion.loggedInCount =
        this.generalConfigurartion.loggedInCount + 1;
      await this.firebaseStorageService.saveUserFile(
        this.fileName,
        this.folderName,
        this.generalConfigurartion
      );
    }
  }

  public async saveUserCardInfo(cardInfo: CustomCardConfig[]) {
    this.generalConfigurartion = await this.getGeneralConfiguration(false);
    if (window.innerWidth <= 1199) {
      this.generalConfigurartion.items = cardInfo;
    } else {
      this.generalConfigurartion.webItems = cardInfo;
    }
    await this.firebaseStorageService.saveUserFile(
      this.fileName,
      this.folderName,
      this.generalConfigurartion
    );
  }

  public async getDataFromExternalJSONFile(): Promise<any> {
    const currentOrg: string = localStorage.getItem('organization');
    let transformedUrl: string | null;
    if (/\u00A0/g.test(currentOrg)) {
      const nonBreakingSpace = currentOrg
        ? currentOrg.replace(/\u00A0/g, ' ')
        : null;
      transformedUrl = nonBreakingSpace
        ? nonBreakingSpace.replace(/ /g, '_')
        : null;
    } else {
      transformedUrl = currentOrg ? currentOrg.replace(/ /g, '_') : null;
    }

    const url = `${environment.assetDirectory}/assets/apiData/${transformedUrl}.json`;

    try {
      const res = await firstValueFrom(this.http.get(url));
      return res as any;
    } catch (error) {
      return {};
    }
  }

  public async getDefaultOrderForDashboard() {
    if (window.innerWidth <= 1199) {
      return Object.keys(CustomCardDefaultOrder).map((key, index) => ({
        id: index,
        order: index,
        component:
          CustomCardDefaultOrder[key as keyof typeof CustomCardDefaultOrder],
      }));
    }
    return Object.keys(CustomCardWebDefaultOrder).map((key, index) => ({
      id: index,
      order: index,
      component:
        CustomCardWebDefaultOrder[
          key as keyof typeof CustomCardWebDefaultOrder
        ],
    }));
  }

  public async setFileName(email: string) {
    this.folderName = 'partner-portal/assets/generalConfig';
    this.fileName = `${email}.json`;
  }
}
