import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  AfterViewInit,
  Input,
  HostListener,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { ApiService } from '../../../../shared/services/api.service';
import { SharedService } from '../../../../shared/services/shared.service';
import { CommonComponent } from '../common-component';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CdnService } from '../../../../shared/services/cdn.service';
import { ChartType } from '../../../../shared/services/enum/shared.enum';
import { LearnerDiversityService } from '../../../../shared/services/diversity/learner-analytics/learner-diversity.service';
import {
  LAFilterData,
  FilterItems,
  InfoCards,
  LearnerAnalytics,
  LearnerAnalyticsDynamic,
  LearnerAnalyticsFilter,
  LearnerAnalyticsData,
  SelectedFilters,
  LearnerAnalyticsExportStudentDetails,
  OrgDetail,
} from '../../../../shared/components/model/customtypes-model.interface';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
@Component({
  selector: 'app-learner-analytics',
  templateUrl: './learner-analytics.component.html',
  styleUrls: ['./learner-analytics.component.scss'],
})
export class LearnerAnalyticsComponent
  extends CommonComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('chips', { read: TemplateRef })
  public chipTemplate: TemplateRef<any>;
  @ViewChild('forDesktop', { read: ViewContainerRef })
  public forDesktop: ViewContainerRef;
  @ViewChild('forMobile', { read: ViewContainerRef })
  public forMobile: ViewContainerRef;
  public isSmallerDevice = false;
  /**
   * Array for display card with icon, logo, color and count.
   */
  public infoCards: InfoCards[] = [
    {
      name: 'Applicants',
      count: 0,
      content:
        'Employees who have completed or submitted an application for Chamberlain University or Walden University within the past 12 months but have not started a class',
      icon: 'assets/images/Applying.svg',
      logo: 'icon-Applying-icon',
      logoColor: 'rgb(42, 121, 217)',
    },
    {
      name: 'Students',
      count: 0,
      content:
        'Employees who started classes at Chamberlain University or Walden University between January 1, 2020, and present and are currently eligible to register',
      icon: 'assets/images/Students.svg',
      logo: 'icon-Students-icon',
      logoColor: 'rgb(136, 188, 64)',
    },
    {
      name: 'Alumni',
      count: 0,
      content:
        'Employees who graduated from Chamberlain University or Walden University between January 1, 2020, and present',
      icon: 'assets/images/Alumni.svg',
      logo: 'icon-Alumni-icon',
      logoColor: 'rgb(244, 131, 34)',
    },
  ];
  public funnelCards: InfoCards[] = [
    {
      name: 'Inquiries',
      count: 0,
      content:
        'Employees who are actively applying to Chamberlain University or Walden University',
      icon: 'assets/images/Applying.svg',
      logo: 'icon-Inquiries',
      logoColor: 'rgb(42, 121, 217)',
    },
    {
      name: 'Applicants',
      count: 0,
      content:
        'Employees who are actively applying to Chamberlain University or Walden University',
      icon: 'assets/images/Applying.svg',
      logo: 'icon-Students',
      logoColor: 'rgb(0,128,127)',
    },
    {
      name: 'Students',
      count: 0,
      content:
        'Employees who are actively enrolled at Chamberlain University or Walden University',
      icon: 'assets/images/Students.svg',
      logo: 'icon-Students-icon',
      logoColor: 'rgb(136, 188, 64)',
    },
    {
      name: 'Alumni',
      count: 0,
      content:
        'Employees who graduated from Chamberlain University or Walden University',
      icon: 'assets/images/Alumni.svg',
      logo: 'icon-Alumni-icon',
      logoColor: 'rgb(244, 131, 34)',
    },
  ];

  public filterLabels: FilterItems[];
  public hospitalList: LearnerAnalytics[];
  public filterCombinations: any = {};
  public chartDataCount: LearnerAnalyticsExportStudentDetails;
  public loading: boolean;
  public selectedFilters: SelectedFilters;
  public diversityInfo: any[] = [];
  public learnerChartType: ChartType = ChartType.learnerAnalytics;
  public checked: boolean = false;
  public chartFunnelDataCount: any;
  public deviceWidth: number;
  public swipeRightActive: boolean = true;
  public swipeLeftActive: boolean = false;
  public learnerSessionData: LearnerAnalyticsDynamic;
  public filterData: LAFilterData;
  public userName: string;
  public learnerDynamicAll: LearnerAnalyticsData;
  public yearFormat: boolean = false;
  public minYear: number;
  public hideTooltip: boolean;

  constructor(
    as: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private readonly sharedService: SharedService,
    public analytics: AngularFireAnalytics,
    private readonly cdnService: CdnService,
    private readonly analyticsService: AnalyticsService,
    private readonly leanerDiversityService: LearnerDiversityService
  ) {
    super(as, tss, ar);
  }

  @Input() set learnerAnalyticsData(data: LAFilterData) {
    if (data) {
      this.filterData = data;
    }
  }

  public insertTemplateBasedOnDevice() {
    if (this.deviceWidth < 500) {
      this.isSmallerDevice = true;
      this.forMobile.insert(this.chipTemplate.createEmbeddedView(''));
    } else {
      this.isSmallerDevice = false;
      this.forDesktop.insert(
        this.chipTemplate?.createEmbeddedView('mt-3 mb-3')
      );
    }
  }
  /**
   * Method to update Chart with Current Organization.
   */
  public updateChartWithCurrentOrg() {
    if (this.filterCombinations) {
      this.chartDataCount =
        this.filterCombinations?.LearnerAnalytics?.[0]?.studentDetails;
    }
    if (this.chartDataCount) {
      this.infoCards.forEach((card: InfoCards, i: number) => {
        card.count = Object.values(this.chartDataCount)[i];
      });
    }
  }

  public updateFunnelChartWithCurrentOrg() {
    if (this.filterCombinations) {
      this.chartFunnelDataCount =
        this.filterCombinations?.LearnerAnalytics?.[0]?.funnelStudentDetails;
    }
    if (this.chartFunnelDataCount) {
      this.funnelCards.forEach((card: any, i: number) => {
        card.count = Object.values(this.chartFunnelDataCount)[i];
      });
    }
  }
  /**
   * Get labels for current organisation for learnerAnalytics.
   *
   * getting filter data learnerAnalytics.
   */
  public ngOnInit(): void {
    this.deviceWidth = window.innerWidth;
    this.filterLabels = this.cdnService.appendSelectedAllToFilterData(
      'partnerLearnerAnalyticsFilter'
    );

    this.filterLabels = this.cdnService.changeTheLabel(
      this.filterLabels,
      'State',
      'Location'
    );
    localStorage.removeItem('ClickedAlphaValue');
    localStorage.setItem('FilterData', JSON.stringify(this.filterLabels));
    this.learnerDynamicAll = this.cdnService.getTheInitialAllFilterData(
      'learnerAnalyticsDynamicAll'
    );

    this.getYearFormat();
    this.setDiversityDetails(
      this.filterCombinations?.LearnerAnalytics?.[0]?.diversityDetails
    );
    this.updateChartWithCurrentOrg();
    this.updateFunnelChartWithCurrentOrg();

    const infoCardDefaultColors = [
      'rgb(42, 121, 217)',
      'rgb(136, 188, 64)',
      'rgb(244, 131, 34)',
    ];
    const infoCardLightColors = [
      'rgb(68, 87, 114)',
      'rgb(193, 154, 71)',
      'rgb(137, 137, 182)',
    ];

    const infoCardFunnelDefaultColors = [
      'rgb(42, 121, 217)',
      'rgb(0, 128, 127)',
      'rgb(136, 188, 64)',
      'rgb(244, 131, 34)',
    ];
    const infoCardFunnelLightColors = [
      'rgb(68, 87, 114)',
      'rgb(107, 45, 125)',
      'rgb(193, 154, 71)',
      'rgb(137, 137, 182)',
    ];
    this.sharedService.lightColorMode$.subscribe((NavVal: boolean) => {
      if (NavVal) {
        this.infoCards.forEach((card: InfoCards, i: number) => {
          card.logoColor = Object.values(infoCardLightColors)[i];
        });
        this.funnelCards.forEach((card: InfoCards, i: number) => {
          card.logoColor = Object.values(infoCardFunnelLightColors)[i];
        });
      } else {
        this.infoCards.forEach((card: InfoCards, i: number) => {
          card.logoColor = Object.values(infoCardDefaultColors)[i];
        });
        this.funnelCards.forEach((card: InfoCards, i: number) => {
          card.logoColor = Object.values(infoCardFunnelDefaultColors)[i];
        });
      }
    });

    this.learnerSessionData = this.cdnService.getSessionData(
      'partnerLearnerAnalytics'
    );
    if (this.filterData) {
      this.getSelectedValues(this.filterData);
    }
    this.sharedService.exportClicked$.subscribe((isOpened: boolean) => {
      this.hideTooltip = isOpened;
    });
  }
  /**
   * get selected filter data for user selected combinations.
   */
  public getSelectedValues($event: any) {
    this.selectedFilters = $event;
    let filterData: string = localStorage.getItem('filters');
    if (filterData == null) {
      filterData = '{}';
    }
    let selectedFilters = JSON.parse(filterData);
    selectedFilters['learnerAnalytics'] = this.selectedFilters;
    localStorage.setItem('filters', JSON.stringify(selectedFilters));
    this.loading = true;
    this.analytics.logEvent(
      `Learner_analytics_filter_${$event.Institution}_${$event.Program}_${$event?.Location}_${$event?.Hospital}`
    );
    const orgDetails: OrgDetail = JSON.parse(
      localStorage.getItem('userDetailId')
    );
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Learner_analytics_filter_${this.analyticsService.convertArrayToString(
          $event.Institution
        )}_${this.analyticsService.convertArrayToString(
          $event.Modality
        )}_${this.analyticsService.convertArrayToString(
          $event.Program
        )}_${this.analyticsService.convertArrayToString(
          $event?.Location
        )}_${this.analyticsService.convertArrayToString($event?.Hospital)}`,
        time,
        sessionId
      );
    }
    const orderOfKeys = [
      'institution',
      'program_group',
      'modality',
      'state',
      'hospital',
    ];
    if (this.filterCombinations?.LearnerAnalytics?.length === 1) {
      this.filterCombinations?.LearnerAnalytics.push(
        ...(this.learnerSessionData?.LearnerAnalytics || [])
      );
      this.filterLabels = this.filterLabels.map((x: any, i: number) => {
        return { ...x, selected: Object.values($event)[i] };
      });
    }
    if (this.filterCombinations?.LearnerAnalytics?.length) {
      this.sharedService
        .getSelectedFilterData(
          this.selectedFilters,
          this.filterCombinations?.LearnerAnalytics,
          orderOfKeys,
          'learnerAnalytics',
          this.yearFormat
        )
        .then((filter: LearnerAnalytics) => {
          this.getDataForChart(filter);
        });
    }
  }
  /**
   * Method to update studentDetails in chartDataCount based on the availability.
   */
  public async getDataForChart(filter: LearnerAnalytics) {
    if (filter) {
      this.loading = false;
      this.chartDataCount = filter?.studentDetails;
      this.chartFunnelDataCount = filter?.funnelStudentDetails;
      this.infoCards.forEach((card: InfoCards, i: number) => {
        card.count = Object.values(this.chartDataCount)[i];
      });
      if (filter.filteredData) {
        let diversityInfo =
          await this.leanerDiversityService.createDiversityDetails(
            filter.filteredData
          );
        this.setDiversityDetails(diversityInfo);
      } else {
        this.setDiversityDetails(filter.diversityDetails);
      }
    } else {
      this.loading = false;
      const emptyChartData = {
        Inquiries: 0,
        Applying: 0,
        Students: 0,
        Alumni: 0,
      };
      this.chartFunnelDataCount = this.chartDataCount = emptyChartData;
      this.infoCards.forEach((card: InfoCards, i: number) => {
        card.count = Object.values(emptyChartData)[i];
      });
      this.funnelCards.forEach((card: InfoCards, i: number) => {
        card.count = Object.values(emptyChartData)[i];
      });
    }
  }

  /**
   * Reset filter data.
   * returns Selected Value as all.
   */

  public reset($event: boolean) {
    if ($event) {
      this.filterLabels = this.filterLabels.map((x: FilterItems) => {
        return { ...x, selected: 'all' };
      });
    }
  }
  /**
   * Display the filter labels in column according to number of filter labels.
   */
  public getPopUpState($event: string) {
    if (!this.checked) {
      if ($event === 'open') {
        this.hospitalList = this.learnerSessionData?.LearnerAnalytics;
        this.filterLabels = this.filterLabels?.map(
          (x: FilterItems, i: number) => {
            if (i === 1) {
              return { ...x, column: 'col-2' };
            } else if (i === 4) {
              return { ...x, column: 'col-4' };
            } else {
              return { ...x, column: 'col-2' };
            }
          }
        );
      }
    } else if (this.checked) {
      if ($event === 'open') {
        this.hospitalList = this.learnerSessionData?.LearnerAnalytics;
        this.filterLabels = this.filterLabels?.map(
          (x: FilterItems, i: number) => {
            if (i === 1) {
              return { ...x, column: 'col-3' };
            } else if (i === 4) {
              return { ...x, column: 'col-4' };
            } else {
              return { ...x, column: 'col-2' };
            }
          }
        );
      }
    }
  }
  public closedFilters($event: Object) {
    this.getSelectedValues({ ...this.selectedFilters, ...$event });
    const index: number = this.filterLabels.findIndex(
      (x: FilterItems) => x.filterLabel === Object.keys($event)[0]
    );
    this.filterLabels[index].selected = 'all';
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.insertTemplateBasedOnDevice();
    });
  }

  public async setDiversityDetails(sessionData) {
    this.diversityInfo = sessionData;
  }

  public toggle() {
    this.swipeLeftActive = !this.swipeLeftActive;
    this.swipeRightActive = !this.swipeRightActive;
    this.checked = !this.checked;
  }

  public checkYearFormat() {
    if (this.learnerDynamicAll?.LearnerAnalytics?.length) {
      let tempLearnerDynamicAll = JSON.parse(
        JSON.stringify(this.learnerDynamicAll?.LearnerAnalytics[0])
      );
      if (this.yearFormat) {
        delete tempLearnerDynamicAll.calendar;
        tempLearnerDynamicAll = {
          ...tempLearnerDynamicAll,
          studentDetails: tempLearnerDynamicAll.fiscal.studentDetails,
          diversityDetails: tempLearnerDynamicAll.fiscal.diversityDetails,
        };
        delete tempLearnerDynamicAll.fiscal;
      } else {
        delete tempLearnerDynamicAll.fiscal;
        tempLearnerDynamicAll = {
          ...tempLearnerDynamicAll,
          studentDetails: tempLearnerDynamicAll.calendar.studentDetails,
          diversityDetails: tempLearnerDynamicAll.calendar.diversityDetails,
        };
        delete tempLearnerDynamicAll.calendar;
      }
      if (Object.keys(this.filterCombinations).length == 0) {
        this.filterCombinations = { LearnerAnalytics: [tempLearnerDynamicAll] };
      } else {
        const index = this.filterCombinations.LearnerAnalytics.findIndex(
          (item) => {
            const filter: LearnerAnalyticsFilter = item.filter;
            return (
              filter.institution == 'all' &&
              filter.program_group == 'all' &&
              filter.state == 'all' &&
              filter.hospital == 'all' &&
              filter.modality == 'all'
            );
          }
        );
        if (index > -1) {
          this.filterCombinations.LearnerAnalytics[index] =
            tempLearnerDynamicAll;
        }
      }
    }
  }

  public getYearFormat() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.yearFormat = format;
      this.checkYearFormat();
    });
  }

  @HostListener('document:salesForceLogin', ['$event'])
  public onSalesforceLogin() {
    this.ngOnInit();
  }
}
