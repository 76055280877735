<div
  class="export-filter-main-wrapper"
  *ngIf="isShowExportPopup"
  #exportwrapper
  id="exportwrapper"
  (click)="onClickOutside($event)"
>
  <div
    class="export-filter-wrapper"
    (click)="onClickInside($event)"
    [ngClass]="{ isScroll: headerActive ? true : null }"
  >
    <div class="modal-body">
      <div class="display-flex-row">
        <div class="popup-title-wrap">
          <h6>Export Dashboard Details</h6>
        </div>
        <div class="modal-header">
          <button (click)="closePopup()">
            <img alt="close" [src]="closeButtonUrl" />
          </button>
        </div>
      </div>
      <div class="content-wrap">
        <ul class="tabs">
          <li
            [class.active]="activeTab === 'pdf'"
            (click)="setActiveTab('pdf')"
            (keydown)="setActiveTab('pdf')"
          >
            Graph Export (PDF)
          </li>
          <li
            [class.active]="activeTab === 'excel'"
            (click)="setActiveTab('excel')"
            (keypress)="setActiveTab('excel')"
          >
            Data Export (Excel)
          </li>
        </ul>
        <div *ngIf="activeTab === 'pdf'">
          <div class="pdf-button-wrapper">
            <button
              type="button"
              class="btn btn-primary width-100"
              (click)="downloadPDF()"
            >
              Download Pdf File
            </button>
          </div>
        </div>
        <div *ngIf="activeTab === 'excel'">
          <div *ngTemplateOutlet="excelTemplate"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #excelTemplate>
  <div class="content-wrapper">
    <div class="after-border" *ngFor="let option of filterItems; let i = index">
      <label class="filter-checkbox mb-3 pb-3">
        <input
          (change)="selectOption(option)"
          [checked]="option.selected"
          [value]="option.name"
          class="d-none"
          type="checkbox"
          name="filter"
        />
        <span class="checkbox"></span>
        {{ option.displayName }}
      </label>
    </div>
  </div>
  <div class="pdf-button-wrapper">
    <button
      type="button"
      class="btn btn-primary width-100"
      (click)="downloadExcel(yearFormat)"
    >
      Download Excel File
    </button>
  </div>
</ng-template>
