import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { environment } from '../../../../../environments/environment';
import { UrlTransformPipe } from '../../../../shared/pipe/url-transform.pipe';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, Subscription, switchMap, of, catchError } from 'rxjs';
import { SharedService } from '../../../../shared/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ref, listAll } from 'firebase/storage';

@Component({
  selector: 'app-information-materials',
  templateUrl: './information-materials.component.html',
  styleUrls: ['./information-materials.component.scss'],
})
export class InformationMaterialsComponent implements OnInit {
  public partnershipProgram: any;
  public listOfPrograms: any;
  public env = environment.sfBaseURL;
  public contactLoader = true;
  public activeLoader: any = null;
  public subscription: Subscription;
  public shortenedUrl = '';
  public imageThumbnailUrl: any;
  public itemRefe: string;
  public page = 1;
  public totalPage: number;
  public itemsPerPage = 12;
  public tinyUrlApiUrl = 'https://tinyurl.com/api-create.php?url=';
  constructor(
    private readonly apiService: ApiService,
    private readonly http: HttpClient,
    public urlTrs: UrlTransformPipe,
    private readonly sharedService: SharedService,
    public sanitizer: DomSanitizer,
    public storage: AngularFireStorage,
    private readonly analytics: AngularFireAnalytics
  ) {}

  public ngOnInit(): void {
    this.setupScrollSubscription();
    this.fetchCombinedLdData();
  }

  public setupScrollSubscription(): void {
    this.sharedService.isNgScrollReachedBottom$.subscribe(
      (isReachedBottom: any) => {
        if (isReachedBottom) {
          this.handleScrollReachedBottom();
        }
      }
    );
  }

  public handleScrollReachedBottom(): void {
    if (this.page < this.totalPage && this.listOfPrograms?.length) {
      this.page++;
      this.listOfPrograms = this.sharedService.paginateData(
        this.page,
        this.itemsPerPage,
        this.partnershipProgram
      );
    }
  }

  public fetchCombinedLdData(): void {
    const userDetailId: any = localStorage.getItem('userDetailId');
    const Id = userDetailId ? JSON.parse(userDetailId)[0]?.Account?.Id : null;

    this.subscription = this.apiService
      .getCombinedLdData(Id)
      .subscribe((data: any) => {
        this.handleCombinedLdDataResponse(data);
      });
  }

  public handleCombinedLdDataResponse(data: any): void {
    this.contactLoader = false;
    this.partnershipProgram = data;
    this.imageThumbnailUrl = [];

    this.partnershipProgram.forEach((program) => {
      this.getFileFromUrl(program.imageThumbnailUrl);
    });

    this.calculateTotalPages();
    this.paginateList();
  }

  public calculateTotalPages(): void {
    this.totalPage =
      (this.partnershipProgram.length / this.itemsPerPage) % 1 === 0
        ? this.partnershipProgram.length / this.itemsPerPage
        : Math.floor(this.partnershipProgram.length / this.itemsPerPage) + 1;
  }

  public paginateList(): void {
    this.listOfPrograms = this.sharedService.paginateData(
      this.page,
      this.itemsPerPage,
      this.partnershipProgram
    );
  }

  public openPdfInNewTab(
    baseUrl: string,
    resourceUrl: string,
    item: any
  ): void {
    this.analytics.logEvent('click_document_open_new_tab');
    this.activeLoader = item;
    const apiHeader: HttpHeaders = new HttpHeaders(environment.sfSubKey);
    const url: any = baseUrl + this.urlTrs.transform(resourceUrl);
    this.http
      .get(url, { headers: apiHeader, responseType: 'blob' })
      .subscribe((data: any) => {
        const downloadUrl = URL.createObjectURL(data);
        const anchorTag = document.createElement('a');
        anchorTag.href = downloadUrl;
        anchorTag.target = '_blank';
        anchorTag.click();
        this.activeLoader = false;
      });
  }

  public downloadPdf(
    baseUrl: string,
    resourceUrl: string,
    fileName: string,
    type: string
  ): void {
    this.analytics.logEvent('download_btn_click');
    const apiHeader: HttpHeaders = new HttpHeaders(environment.sfSubKey);
    const url: any = baseUrl + this.urlTrs.transform(resourceUrl);
    this.http
      .get(url, { headers: apiHeader, responseType: 'blob' })
      .subscribe((data: any) => {
        const downloadUrl = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadUrl; // Replace with the actual path or URL of the file
        link.download = `${fileName}.${type}`; // Replace with the desired file name and extension
        link.target = '_self';
        link.click();
      });
  }

  public shareFile(sourceUrl: string, dataUrl: string, pdfTitle: any) {
    this.analytics.logEvent('share_btn_click');
    const apiHeader: HttpHeaders = new HttpHeaders(environment.sfSubKey);
    const url: any = sourceUrl + this.urlTrs.transform(dataUrl);
    const storage = this.storage.storage;
    const listRef = ref(storage, 'partner-portal/assets/lad');
    // Find all the prefixes and items.
    listAll(listRef)
      .then((res) => {
        const file = res.items.find(
          (itemRef) => pdfTitle === itemRef['_location']['path_']?.split('/')[3]
        );
        // All the items under listRef.
        if (!file) {
          this.http
            .get(url, { headers: apiHeader, responseType: 'blob' })
            .subscribe((res: any) => {
              this.storage
                .upload(`partner-portal/assets/lad/${pdfTitle}`, res)
                .then((res: any) => {
                  this.createDownloadURL(pdfTitle);
                });
            });
        } else {
          this.createDownloadURL(pdfTitle);
        }
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  }

  public getFileFromUrl(url: any) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.sfSubKey);
    this.http
      .get(`${this.env}/image/${this.urlTrs.transform(url)}`, {
        headers: apiHeader,
        responseType: 'blob',
      })
      .subscribe((blob: Blob) => {
        this.convertBlobToBase64(blob);
      });
  }

  public tinyUrlConversion(url: any, pdfTitle: any) {
    // Construct the full TinyURL API URL with the URL to shorten
    const apiUrl = `${this.tinyUrlApiUrl}${encodeURIComponent(url)}`;
    // Perform the HTTP GET request to shorten the URL
    this.http
      .get(apiUrl, { responseType: 'text' })
      ?.pipe(first())
      .subscribe(
        (response) => {
          this.shortenedUrl = response; // Directly use the response (which is the shortened URL)
          this.sendEmailWithShortenedUrl(this.shortenedUrl, pdfTitle);
        },
        (error) => {
          console.error('Error shortening URL:', error);
        }
      );
  }
  public sendEmailWithShortenedUrl(
    shortenedUrl: string,
    pdfTitle: string
  ): void {
    const anchorTag = document.createElement('a');
    const bodyContent = `Hi,
            Please click the Link to download the file: ${shortenedUrl}
            Thank You.`;
    const encodedBodyContent = encodeURIComponent(bodyContent);
    anchorTag.href = `mailto:?subject=${pdfTitle}&body=${encodedBodyContent}`;
    anchorTag.click();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public createDownloadURL(pdfTitle: string) {
    const storageRef = this.storage.ref(
      `partner-portal/assets/lad/${pdfTitle}`
    );
    return storageRef.getDownloadURL().pipe(
      switchMap((url: string) => {
        this.tinyUrlConversion(url, pdfTitle);
        return of(url);
      }),
      catchError((error) => {
        console.error('Error getting download URL:', error);
        return of(null);
      })
    );
  }

  public convertBlobToBase64(blob: Blob) {
    const reader = new FileReader();
    reader.onload = () => {
      this.imageThumbnailUrl.push(reader.result as string);
    };
    reader?.readAsDataURL(blob);
  }
}
