import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  public adminUser: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public adminUser$ = this.adminUser.asObservable();

  constructor(private readonly firestore: AngularFirestore) {}
  // Method to get users list from Firestore
  public getUsers(): Observable<any[]> {
    return this.firestore.collection('users').valueChanges();
  }

  public getUserRole(uid: string): Observable<string | undefined> {
    return this.firestore
      .collection('users')
      .doc(uid)
      .valueChanges()
      .pipe(map((user: any) => user?.role));
  }
}
