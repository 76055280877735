import {
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../services/shared.service';
import { DOCUMENT } from '@angular/common';
import { OtherProgramInfo } from '../../services/entity/shared.entity';
import { HeaderColor } from '../../services/enum/shared.enum';
import { Subscription } from 'rxjs';
import { ChartOtherProgramInfo } from '../model/customtypes-model.interface';

@Component({
  selector: 'app-other-popup-modal',
  templateUrl: './other-popup-modal.component.html',
  styleUrls: ['./other-popup-modal.component.scss'],
})
export class OtherPopupModalComponent implements OnInit, OnDestroy {
  @ViewChild('otherPopupTemp')
  public otherPopupTemp: TemplateRef<any>;
  public showOtherProgram: boolean = false;
  public viewContainerRef: ViewContainerRef;
  public otherProgramInfo: OtherProgramInfo = new OtherProgramInfo();
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  public isDisableLeftArrow: boolean = true;
  public isDisableRightArrow: boolean = false;
  public subscriptionRefArray: Subscription[] = [];
  public isSorted: boolean = false;
  public currentSortHeader: string;
  public otherProgramList: ChartOtherProgramInfo[] = [];

  constructor(
    public sharedService: SharedService,
    @Inject(DOCUMENT) public document: Document,
    viewContainerRef: ViewContainerRef,
    private readonly ngZone: NgZone
  ) {
    this.viewContainerRef = viewContainerRef;
  }

  public ngOnInit() {
    this.sharedService.openOtherProgram$.subscribe((data: boolean) => {
      this.showOtherProgram = data;

      this.ngZone.run(async () => {
        if (this.showOtherProgram) {
          this.viewContainerRef.clear();
          if (!document.getElementById('otherProgram')) {
            const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
              this.otherPopupTemp
            );

            if (embeddedViewRef != null) {
              for (const node of embeddedViewRef.rootNodes) {
                this.document.body.appendChild(node);
              }
            }

            await this.getStorageData();
          }
        }
      });
    });
  }

  public closePopup() {
    this.showOtherProgram = false;
    this.sharedService.openOtherProgram.next(false);
    this.otherProgramInfo = new OtherProgramInfo();
    const dropdown: any = this.document.querySelector('.other-prg-wrapper');
    dropdown?.remove();
    this.sharedService.otherProgramData.next({});
    this.viewContainerRef.clear();
    this.unSubscribeSubscription();
    this.isSorted = false;
  }

  public getLabelClass(headerName: string): string {
    if (Object.keys(HeaderColor).includes(headerName as HeaderColor)) {
      return 'highlight-header '.concat(HeaderColor[headerName]);
    } else {
      return '';
    }
  }

  public onClickPopupSubWrapper(e: Event) {
    e.stopPropagation();
  }

  public onClickPopupWrapper() {
    this.closePopup();
  }

  public setArrowState(
    isDisableLeftArrow: boolean,
    isDisableRightArrow: boolean
  ) {
    this.isDisableLeftArrow = isDisableLeftArrow;
    this.isDisableRightArrow = isDisableRightArrow;
  }

  public scroll(direction: number) {
    const table = document.getElementById('otherProgramTable');
    const container = table.parentElement;

    const scrollStep = 500;
    const newScrollLeft = container.scrollLeft + scrollStep * direction;
    container.scrollLeft = newScrollLeft;
    this.checkTheTableContainer(container);
  }

  public checkTheTableContainer(container: any) {
    if (container.scrollLeft == 0) {
      this.setArrowState(true, false);
    } else if (
      container.scrollLeft ===
      container.scrollWidth - container.clientWidth
    ) {
      this.setArrowState(false, true);
    } else {
      this.setArrowState(false, false);
    }
  }

  public onListenScroll() {
    setTimeout(() => {
      let table = this.document?.getElementById('otherProgramTable');
      const container: HTMLElement = table?.parentElement;
      container?.addEventListener('scroll', () => {
        this.checkTheTableContainer(container);
      });
    }, 1000);
  }

  public async getStorageData() {
    this.subscriptionRefArray.push(
      this.sharedService.otherProgramData$.subscribe(
        (programInfo: OtherProgramInfo) => {
          this.setArrowState(true, false);
          if (programInfo && Object.keys(programInfo).length) {
            this.otherProgramInfo = new OtherProgramInfo();
            this.otherProgramInfo = programInfo;
            this.otherProgramList = this.otherProgramInfo.otherPrograms;
            this.onListenScroll();
          } else {
            this.otherProgramInfo = new OtherProgramInfo();
          }
        }
      )
    );
  }

  public sortingProgramsCount(headerName: string) {
    if (
      !this.otherProgramInfo.otherPrograms ||
      this.otherProgramInfo.otherPrograms.length === 0
    ) {
      return;
    }
    const defaultOrder = JSON.parse(
      JSON.stringify(this.otherProgramInfo.otherPrograms)
    );
    this.isSorted = this.otherProgramList.every((value, index, array) => {
      return index === 0 || array[index - 1][headerName] >= value[headerName];
    });

    if (this.isSorted && this.currentSortHeader === headerName) {
      this.otherProgramList = JSON.parse(
        JSON.stringify(this.otherProgramInfo.otherPrograms)
      );
      this.isSorted = false;
    } else {
      this.otherProgramList = defaultOrder.sort((a, b) => {
        if (a[headerName] < b[headerName]) {
          return 1;
        }
        if (a[headerName] > b[headerName]) {
          return -1;
        }
        this.isSorted = true;
        this.currentSortHeader = headerName;
        return 0;
      });
    }
  }

  public isHeaderSorted(headerName: string) {
    if (this.currentSortHeader === headerName) {
      if (this.isSorted === true) {
        return true
      }
    }
    else {
      return false
    }
    return false
  }

  public unSubscribeSubscription() {
    this.subscriptionRefArray.forEach((s) => s.unsubscribe());
  }

  public ngOnDestroy(): void {
    this.unSubscribeSubscription();
  }
}
