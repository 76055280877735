import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { GeneralConfigurationService } from '../../shared/services/general-config/general-config-service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent {
  public callboolean = false;
  constructor(
    private readonly ar: ActivatedRoute,
    private readonly auth: AuthService,
    public router: Router,
    public generalConfigService: GeneralConfigurationService
  ) {}

  public async ngOnInit() {
    const qParams: any = this.ar.snapshot.queryParams;
    try {
      if (typeof qParams.state === 'string') {
        localStorage.clear();
        const stateArray = qParams.state.split('=');
        const filteredSet = stateArray.filter((item: any) => item !== '');
        const i = filteredSet.indexOf('jwtToken');
        const jwtToken = `${filteredSet[i + 1]}${filteredSet[i + 2]}`;
        const mail = filteredSet[0];
        await this.generalConfigService.saveUserLoginCountFromSalesForce(mail);
        localStorage.setItem('ppUserEmail', mail);
        localStorage.setItem('ppUserToken', decodeURIComponent(jwtToken));
        this.callboolean = true;
        if (this.callboolean) {
          this.auth.salesforceLogin();
          let event = new CustomEvent('salesForceLogin');
          document.dispatchEvent(event);
        }
      }
    } catch (error) {
      console.log('callback component', error);
    }
  }
}
