import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/services/auth.service';
import { ApiService } from '../../services/api.service';
import { SharedService } from '../../services/shared.service';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Subscription, concatMap, from, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlTransformPipe } from '../../pipe/url-transform.pipe';
import { DOCUMENT } from '@angular/common';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import DOMPurify from 'dompurify';
import { TourPopupData } from '../model/customtypes-model.interface';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss'],
})
export class CommonModalComponent implements OnInit, OnDestroy {
  @Output() public searchEvent: EventEmitter<string> =
    new EventEmitter<string>();
  public ShowChangePasswordPopup = false;
  public showhospitalAnalyticsPopup = false;
  public ShowCheckMailPopup = false;
  public emailAddress: string;
  public mailNotSend = false;
  public filteredHospitals: any[] = [];
  public hospitalListData: string[];
  public characterCount: number;
  public selectedHospital: string;
  public isPopupVisible = false;
  public showTourNavigate = false;
  public exploreWindow = false;
  public filterData: string[];
  public placeholderValue = 'Type Hospital Name';
  public env = environment.sfBaseURL;
  public showTourWelcome = false;
  @ViewChild('productTourPopup') public productTourPopup: TemplateRef<any>;
  @ViewChild('changePasswordPopup')
  public changePasswordPopup: TemplateRef<any>;
  public tourPopupData: TourPopupData[];
  public tourPopupMobData: TourPopupData[];
  public subscriptionRefArray: Subscription[] = [];
  public currentIndex = 0;
  public contentItems: string[];
  public contentMobItems: string[];
  public contentDescriptionItems: string[];
  public contentMobDescriptionItems: string[];
  public bodyContentItems: string[];
  public imageUrls: any[];
  public imageMobUrls: any[];
  public lastSlideActive = false;
  public loaderTrue = true;
  public hostError: boolean = false;
  public hostErrorMsg: string;
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  private readonly viewContainerRef: ViewContainerRef;

  constructor(
    public auth: AuthService,
    public apiService: ApiService,
    public sharedService: SharedService,
    private readonly sanitizer: DomSanitizer,
    private readonly url: UrlTransformPipe,
    private readonly http: HttpClient,
    private readonly analytics: AngularFireAnalytics,
    @Inject(DOCUMENT) private readonly document: Document,
    viewContainerRef: ViewContainerRef
  ) {
    this.viewContainerRef = viewContainerRef;
  }

  public ngOnInit(): void {
    this.sharedService.changePasswordEvent$.subscribe((NavVal: boolean) => {
      this.ShowChangePasswordPopup = NavVal;
    });
    this.sharedService.hospitalAnalyticsEvent$.subscribe((NavVal: boolean) => {
      this.showhospitalAnalyticsPopup = NavVal;
    });
    this.sharedService.productTourEvent$.subscribe((NavVal: boolean) => {
      this.showTourWelcome = NavVal;
      this.currentIndex = 0;
    });
    this.subscriptionRefArray.push(
      this.sharedService.userDetails.subscribe((data) => {
        if (data) {
          const userDetailId: string = localStorage.getItem('userDetailId');
          const Id = userDetailId
            ? JSON.parse(userDetailId)[0]?.Account?.Id
            : null;
          this.subscriptionRefArray.push(
            this.apiService
              .getCombinedTourData(Id)
              .subscribe((data: TourPopupData[]) => {
                this.tourPopupData = data;

                // Sort by name in ascending alphabetical order
                this.tourPopupData.sort(this.sortByNumber);
                this.contentDescriptionItems = this.tourPopupData.map((item) =>
                  item.Description.replace(/&amp;/g, '&')
                );

                this.contentDescriptionItems = this.contentDescriptionItems.map(
                  (desc) => desc.replace(/&#39;/g, "'")
                );

                this.bodyContentItems = this.tourPopupData.map(
                  (item) => item.Body
                );
                const parser = new DOMParser();
                this.bodyContentItems = this.bodyContentItems.map(
                  (htmlEncodedText) => {
                    // Sanitize the HTML-encoded text
                    const sanitizedHtml = DOMPurify.sanitize(htmlEncodedText);
                    // Parse the sanitized HTML
                    const doc = parser.parseFromString(
                      sanitizedHtml,
                      'text/html'
                    );
                    // Retrieve and return the text content
                    return doc.body.textContent || '';
                  }
                );
                this.bodyContentItems = this.bodyContentItems.map(
                  (htmlEncodedText) =>
                    htmlEncodedText.replace(/<\/?p>|<br>/g, '')
                );
                this.contentItems = this.tourPopupData.map((item) =>
                  item.title.replace(/&amp;/g, '&')
                );
                this.imageUrls = [];
                const obsArray: Observable<any> = from(
                  this.tourPopupData.map((x) => x.url)
                );
                const chainedObservable = obsArray.pipe(
                  concatMap((value) => this.getFileFromUrl(value))
                );
                chainedObservable.subscribe((data: any) => {
                  const blob = new Blob([data]);
                  const url = URL.createObjectURL(blob);
                  this.imageUrls.push(
                    this.sanitizer.bypassSecurityTrustResourceUrl(url)
                  );
                  this.loaderTrue = false;
                });
                this.imageUrls = this.imageUrls.map(
                  (Item) => Item.changingThisBreaksApplicationSecurity
                );
              })
          );
          this.subscriptionRefArray.push(
            this.apiService
              .getCombinedMobileTourData(Id)
              .subscribe((data: TourPopupData[]) => {
                this.tourPopupMobData = data;

                // Sort by name in ascending alphabetical order
                this.tourPopupMobData.sort(this.sortByNumber);
                this.contentMobDescriptionItems = this.tourPopupMobData.map(
                  (item) => item.Description.replace(/&amp;/g, '&')
                );
                this.contentMobItems = this.tourPopupMobData.map((item) =>
                  item.title.replace(/&amp;/g, '&')
                );

                this.imageMobUrls = [];
                const obsArray: Observable<any> = from(
                  this.tourPopupMobData?.map((x) => x.Mobileurl)
                );
                const chainedObservable = obsArray?.pipe(
                  concatMap((value) => this.getFileFromUrl(value))
                );
                chainedObservable?.subscribe((data: any) => {
                  const blob = new Blob([data]);
                  const url = URL?.createObjectURL(blob);
                  this.imageMobUrls?.push(
                    this.sanitizer?.bypassSecurityTrustResourceUrl(url)
                  );
                  this.loaderTrue = false;
                });
                this.imageMobUrls = this.imageMobUrls?.map(
                  (Item) => Item.changingThisBreaksApplicationSecurity
                );
              })
          );
        }
      })
    );
  }

  public sortByNumber(a: TourPopupData, b: TourPopupData): number {
    const numA = parseInt(a.Number, 10);
    const numB = parseInt(b.Number, 10);
    return numA - numB;
  }

  public getFileFromUrl(url: string) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.sfSubKey);
    return this.http.get(`${this.env}/image/${this.url?.transform(url)}`, {
      headers: apiHeader,
      responseType: 'blob',
    });
  }

  public search() {
    this.selectedHospital = '';
    this.placeholderValue = '';
  }
  public onSearch(event: any) {
    this.placeholderValue = '';
    this.filterData = JSON.parse(localStorage.getItem('FilterData'));
    this.hospitalListData = this.filterData;
    if (this.characterCount > 2) {
      this.isPopupVisible = true;
      const searchTerm = event.target.value;
      // Convert the search term to lowercase for case-insensitive search
      const lowerCaseTerm = searchTerm.toLowerCase();
      // Filter the hospitals based on the search term
      this.filteredHospitals = this.hospitalListData.filter((hospital) => {
        const searchData = hospital.toLowerCase().includes(lowerCaseTerm);
        return searchData;
      });
    }
    if (this.characterCount === 0) {
      this.isPopupVisible = false;
      this.placeholderValue = 'Type Hospital Name';
    }
  }

  public onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputText = inputElement.value;
    this.characterCount = inputText.length;
  }

  public selectHospital(hospital: string) {
    this.selectedHospital = hospital;
    this.isPopupVisible = false;
    // Get the input element and update its value
    const inputElement = document.getElementById('searchBar');
    if (inputElement) {
      (inputElement as HTMLInputElement).value = this.selectedHospital;
    }
    this.closePopup();
  }
  public getStarted() {
    this.analytics.logEvent('click_get_started');
    this.currentIndex = 1;
    this.showTourNavigate = true;
    this.showTourWelcome = false;
    this.lastSlideActive = false;
    this.sharedService.mobNavEvent.next({
      profile: false,
      contact: false,
    });
  }
  public nextNavigation() {
    this.showTourWelcome = false;
    this.currentIndex = (this.currentIndex + 1) % this.contentItems.length;
    if (this.currentIndex === this.contentItems.length - 1) {
      this.exploreWindow = true;
      this.lastSlideActive = true;
    }
  }
  public previousNavigation() {
    this.currentIndex = (this.currentIndex - 1) % this.contentItems.length;
    if (this.currentIndex === 0) {
      this.showTourNavigate = false;
      this.showTourWelcome = true;
    }
  }
  public explore() {
    this.showTourWelcome = false;
    this.showTourNavigate = false;
    this.exploreWindow = false;

    const event = new CustomEvent('closePopup', {});
    // Dispatch the custom event
    document.dispatchEvent(event);
  }
  public clearAll() {
    this.selectedHospital = '';
    this.isPopupVisible = false;
    this.placeholderValue = 'Type Hospital Name';
  }
  // Function for logout and set organization id null and firebase authstate is SignOut

  public logout() {
    this.analytics.logEvent('click_backtologin_btn');
    this.apiService.hasOrgInfo.next(null);
    this.sharedService.lightColorMode.next(false);
    this.auth?.SignOut();
    this.mailNotSend = false;
  }

  public closePopup() {
    this.sharedService.hospitalData(this.selectedHospital);
    this.ShowChangePasswordPopup = false;
    this.showhospitalAnalyticsPopup = false;
    this.ShowCheckMailPopup = false;
    this.mailNotSend = false;
    this.showTourWelcome = false;
    this.showTourNavigate = false;
    this.hostError = false;
    this.sharedService.mobNavEvent.next({
      profile: false,
      contact: false,
    });
    const event = new CustomEvent('closePopup', {});
    // Dispatch the custom event
    document.dispatchEvent(event);
  }

  public checkMail() {
    this.analytics.logEvent('click_change_password_btn');
    const auth = getAuth();
    this.emailAddress = this.auth.userData?.email;
    sendPasswordResetEmail(auth, this.emailAddress)
      .then(() => {
        // Password reset email sent!
        this.ShowChangePasswordPopup = false;
        this.ShowCheckMailPopup = true;
      })
      .catch((error) => {
        const splitted = error?.message.split(':');
        this.hostErrorMsg = splitted[1];
        this.hostError = true;
      });
  }

  public getDescription(index: number): string {
    let description = this.contentDescriptionItems[index];

    if (index === 1) {
      return description.replace('Adtalem', '<i>Adtalem</i>');
    } else if (index === 6) {
      const splitted = description.split('.');
      let contentToModify = splitted[1].concat('.');
      let italicContent = '<br><i class="italic-justify-end">'
        .concat(contentToModify)
        .concat('</i>');
      let modifiedDescription = description.replace(
        contentToModify,
        italicContent
      );
      return modifiedDescription;
    } else {
      return description;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptionRefArray.forEach((s) => s.unsubscribe());
  }
}
