import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { CdnService } from '../../services/cdn.service';
import { Subscription } from 'rxjs';
import { CommonComponent } from '../../../modules/dashboard/partner-dashboard/common-component';
import { ApiService } from '../../services/api.service';
import { TransferStateService } from '@scullyio/ng-lib';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import {
  CalenderMonth,
  CalenderMonthIndex,
} from '../../services/enum/shared.enum';
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  ClinicalFilter,
  ClinicalFilterChip,
} from '../model/customtypes-model.interface';

@Component({
  selector: 'app-clinical-filter-chip',
  templateUrl: './clinical-filter-chip.component.html',
  styleUrls: ['./clinical-filter-chip.component.scss'],
})
export class ClinicalFilterChipComponent extends CommonComponent {
  public subscriptionRefArray: Subscription[] = [];
  public chip: any = [];
  public initialStartYear: any;
  public formattedStartYear: any;

  @Output() public emitRemovedFilter = new EventEmitter();
  @Input() public customClass: string;
  @Input() public showYear: string;
  @Input() public currentYear: string;
  @HostBinding('style.display') public blockElement: string = 'block';
  public yearFilterSelected: boolean;
  constructor(
    apiService: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private readonly cdnService: CdnService,
    private readonly datePipe: DatePipe,
    public sharedService: SharedService
  ) {
    super(apiService, tss, ar);
  }
  @Input() set selectedFilters(data: ClinicalFilter) {
    if (data) {
      this.chip = [];
      Object.entries(data).forEach(([key, value]) => {
        if (key == 'startDate') {
          let formattedDate = this.formatDateForFilterChips(
            this.initialStartYear
          );
          let selectedDate = this.formatDateToString(formattedDate);
          let selectedFormatedDate = selectedDate['__zone_symbol__value'];
          if (selectedFormatedDate != this.initialStartYear) {
            this.chip.push({
              key: key,
              value: `Start Date : ${formattedDate}`,
              closable: key == 'startDate' ? false : true,
            });
            this.yearFilterSelected = true;
          } else {
            this.chip.push({
              key: key,
              value: `Start Date : ${formattedDate}`,
              closable: false,
            });
            this.yearFilterSelected = true;
          }
        } else {
          if (!Array.isArray(value)) {
            value = [value];
          }
          if (value[0] !== 'all') {
            if (value['length'] <= 1) {
              this.chip.push({ key: key, value: value, closable: true });
            } else {
              this.chip.push({
                key: key,
                value: `${value['length']} ${key}s`,
                closable: true,
              });
            }
          }
        }
      });
    }
  }

  @Input() set startYear(data: string) {
    if (data != undefined && data != null && data != '') {
      const formattedDate = new Date(data).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      this.initialStartYear = formattedDate;
      this.formattedStartYear = this.formatDateForFilterChips(
        this.initialStartYear
      );
      if (
        !this.yearFilterSelected &&
        !this.chip.some((x) => x.key == 'startDate')
      ) {
        this.chip.unshift({
          key: 'startDate',
          value: `Start Date : ${this.formattedStartYear}`,
          closable: false,
        });
      } else {
        const startDate = this.chip.findIndex((x) => x.key == 'startDate');
        if (startDate > -1) {
          this.chip[
            startDate
          ].value = `Start Date : ${this.formattedStartYear}`;
        }
      }
    }
  }

  public ngOnInit() {
    // Get labels for current organization's studentPersistence chart
    const labelsSubscription = this.cdnService.appendSelectedAllToFilterData(
      'clinicalInsightsFilter'
    );
    this.formattedStartYear = this.formatDateForFilterChips(
      this.initialStartYear
    );
    if (
      labelsSubscription &&
      this.formattedStartYear != undefined &&
      this.formattedStartYear != null &&
      this.formattedStartYear != ''
    ) {
      if (!this.showYear && !this.yearFilterSelected) {
        this.chip.unshift({
          key: 'Start Date',
          value: `Start Date : ${this.formattedStartYear}`,
          closable: false,
        });
      }
    }
  }

  public async removeFilter(obj: ClinicalFilterChip, index: number) {
    if (!obj?.key.includes('startDate')) {
      this.chip.splice(index, 1);
      this.emitRemovedFilter.emit({ [obj?.key]: 'all' });
    } else {
      this.emitRemovedFilter.emit({ [obj?.key]: `${this.initialStartYear}` });
      const modifiedString = this.chip[index].value
        .replace('Start Date: ', '')
        .trim();
      let selectedDate = this.formatDateToString(modifiedString);
      if (selectedDate != this.initialStartYear) {
        this.chip[index].value = 'Start Date : ' + `${this.formattedStartYear}`;
        this.chip[index].closable = false;
        this.sharedService.calenderDateEvent.next(this.initialStartYear);
      }
    }
  }

  public formatDate(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return this.datePipe.transform(jsDate, 'dd/MM/yyyy');
  }

  public formatDateForFilterChips(dateStr: string) {
    if (dateStr) {
      // Split the input date string into parts
      const [day, month, year] = dateStr.split('/');
      // Create a Date object using the parts in YYYY-MM-DD format
      const date = new Date(`${year}-${month}-${day}`);
      // Get the formatted date components
      const formattedDay = String(date.getDate()).padStart(2, '0'); // Ensure two digits
      const formattedMonth = CalenderMonth[date.getMonth()]; // Month names are zero-indexed
      const formattedYear = date.getFullYear();
      // Return the formatted date string
      return `${formattedDay} ${formattedMonth} ${formattedYear}`;
    }
    return '';
  }

  public async formatDateToString(dateStr) {
    if (dateStr) {
      // Split the input date string into parts
      const [day, monthName, year] = dateStr.split(' ');
      // Get the month number
      const month = CalenderMonthIndex[monthName];
      // Return the formatted date string
      return `${day.padStart(2, '0')}/${month}/${year}`;
    }
    return '';
  }
}
