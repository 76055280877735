import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import {
  doughnutInnerText1,
  doughnutInnerText2,
  drawBorder,
} from '../chart-custom-plugins';
import { SharedService } from '../../../services/shared.service';
@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit {
  @ViewChild(BaseChartDirective)
  public chart: BaseChartDirective;
  public graphBackgroundColor;
  public chartData: ChartData<'doughnut'> = {
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: [
          'rgb(42, 121, 217)',
          'rgb(136, 188, 64)',
          'rgb(244, 131, 34)',
        ],
        hoverBackgroundColor: [
          'rgb(42, 121, 217)',
          'rgb(136, 188, 64)',
          'rgb(244, 131, 34)',
        ],
        hoverBorderColor: 'white',
        borderWidth: 0,
      },
    ],
  };
  public chartType: ChartConfiguration<'doughnut'>['type'] = 'doughnut';

  public chartPlugin: any = [
    ChartDataLabels,
    doughnutInnerText2,
    doughnutInnerText1,
    drawBorder,
  ];
  public chartOption: any = {
    responsive: true,
    animation: false,
    layout: {
      padding: window.innerWidth > 700 ? 10 : 0,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold',
          size: window.innerWidth > 700 ? 20 : 10,
        },
      },
      doughnutLabelsLine: false,
      customCanvasBackgroundColor: false,
    },
  };
  constructor(private readonly sharedService: SharedService) {}

  // Getting chart data set number
  @Input() set doughnutChartData(data: any) {
    if (data) {
      this.chartData.datasets[0].data = Object.values(data).map((x: any) =>
        !x ? null : x
      );
      this.chart?.chart?.update();
    }
  }

  public ngOnInit(): void {
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      if (window.innerWidth > 700) {
        this.sharedService.lightColorMode$.subscribe((NavVal: any) => {
          if (NavVal === true) {
            this.graphBackgroundColor = [
              'rgb(68, 87, 114)',
              'rgb(193, 154, 71)',
              'rgb(137, 137, 182)',
            ];
          } else {
            this.graphBackgroundColor = [
              'rgb(42, 121, 217)',
              'rgb(136, 188, 64)',
              'rgb(244, 131, 34)',
            ];
          }
          this.chartOption.responsive = false;
          this.chartData.datasets[0].backgroundColor =
            this.graphBackgroundColor;
          this.chartData.datasets[0].hoverBackgroundColor =
            this.graphBackgroundColor;
          this.chart?.chart?.update();
        });
      }
    }
    this.sharedService.lightColorMode$.subscribe((NavVal: any) => {
      if (NavVal === true) {
        this.graphBackgroundColor = [
          'rgb(68, 87, 114)',
          'rgb(193, 154, 71)',
          'rgb(137, 137, 182)',
        ];
      } else {
        this.graphBackgroundColor = [
          'rgb(42, 121, 217)',
          'rgb(136, 188, 64)',
          'rgb(244, 131, 34)',
        ];
      }
      this.chartData.datasets[0].backgroundColor = this.graphBackgroundColor;
      this.chartData.datasets[0].hoverBackgroundColor =
        this.graphBackgroundColor;
      this.chart?.chart?.update();
    });
  }
}
