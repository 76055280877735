import { HttpClient } from '@angular/common/http';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isScullyRunning } from '@scullyio/ng-lib';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { Subscription } from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDrag,
  CdkDragStart,
} from '@angular/cdk/drag-drop';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import 'firebase/storage';
import {
  DescriptionTabTooltip,
  CustomCardDefaultOrder,
} from '../../../shared/services/enum/shared.enum';
import { GeneralConfigurationService } from '../../../shared/services/general-config/general-config-service';
import { GeneralConfigurarion } from '../../../shared/services/entity/shared.entity';
import { AnalyticsService } from '../../../shared/services/analytics/analytics.service';
import { getFormattedCurrentDateTime } from '../../../shared/services/functional-handler/functional-handler';
import { OrgDetail } from '../../../shared/components/model/customtypes-model.interface';

@Component({
  selector: 'app-partner-dashboard',
  styleUrls: ['./partner-dashboard.component.scss'],
  templateUrl: './partner-dashboard.component.html',
})
export class PartnerDashboardComponent implements OnInit, OnDestroy {
  public subscriptionRefArray: Subscription[] = [];
  public tooltipContent: string = DescriptionTabTooltip.partnerDashboard;
  @ViewChildren(CdkDrag) public draggables: QueryList<CdkDrag>;
  @ViewChild('leanerTemplate') public leanerTemplate: TemplateRef<any>;
  @ViewChild('applicantTemplate') public applicantTemplate: TemplateRef<any>;
  @ViewChild('studentEnrollmentTemplate')
  public studentEnrollmentTemplate: TemplateRef<any>;
  @ViewChild('alumniTemplate') public alumniTemplate: TemplateRef<any>;
  @ViewChild('studentTemplate') public studentTemplate: TemplateRef<any>;
  @ViewChild('anticipatedGradDateTemplate')
  public anticipatedGradDateTemplate: TemplateRef<any>;

  public items: any[] = [];
  public userEmailId: string;
  public isDropListDisabled: boolean = true;
  public editOrderTrue: boolean = true;
  public cancelOrderFalse: boolean = false;
  public contactLoader: boolean = true;
  public disableExport: boolean = false;
  public countMoreTrue: boolean = false;
  public filteredData: any;
  public currentWindowWidth: number;
  public userName: string;

  constructor(
    private readonly ar: ActivatedRoute,
    public http: HttpClient,
    public router: Router,
    public sharedService: SharedService,
    public analytics: AngularFireAnalytics,
    public storage: AngularFireStorage,
    private readonly generalConfigService: GeneralConfigurationService,
    private readonly analyticsService: AnalyticsService
  ) {
    // checking with scully, if not running then find from pre defined org and set to navigate
    if (!isScullyRunning()) {
      const currentOrg: string | null = sessionStorage.getItem('organization');
      const orgId = JSON.parse(environment.partnerOrganizations).find(
        (x: any) => x.name === currentOrg
      )?.id;
      this.subscriptionRefArray.push(
        this.ar?.params?.subscribe((params: any) => {
          if (orgId && params?.org !== orgId) {
            const org: any = JSON.parse(environment.partnerOrganizations).find(
              (x: any) => x.name === currentOrg
            );
            const returnUrl = `/dashboard/learner-insights/${org?.id}`;
            this.router.navigate([returnUrl]);
          }
        })
      );
    }
  }

  @HostListener('document : SaveEvent', ['$event'])
  public saveOrderParent(event: any) {
    this.saveOrder();
  }

  @HostListener('document : ResetEvent', ['$event'])
  public resetOverParent(event: any) {
    this.resetOrder();
  }

  @HostListener('document : CancelEvent', ['$event'])
  public cancelOverParent(event: any) {
    this.cancelOrder();
  }

  public async ngOnInit() {
    this.currentWindowWidth = window.innerWidth;
    this.contactLoader = true;

    await this.getDashboardStorageInfo();
    await this.getDataFromExternalJSONFile();
    this.contactLoader = false;

    const isFirstPageView = !localStorage.getItem('first_page_view');

    const orgDetails: OrgDetail = JSON.parse(
      localStorage.getItem('userDetailId')
    );
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    setTimeout(async () => {
      if (localStorage.getItem('providerId') === 'firebase') {
        await this.analyticsService.addAnalytics(
          this.userName,
          email,
          orgName,
          'Learner Insights Page',
          time,
          sessionId
        );
      }
    }, 2000);
    if (isFirstPageView) {
      this.analytics.logEvent(`first_page_viewed_by_user_Partner_Analytics`);
      localStorage.setItem('first_page_view', 'true');
    }
    this.sharedService.EditOrderEvent$.subscribe((NavVal: boolean) => {
      this.isDropListDisabled = NavVal;
      this.editOrderTrue = NavVal;
    });
    this.sharedService.CancelOrderEvent$.subscribe((NavVal: boolean) => {
      this.cancelOrderFalse = NavVal;
    });
    this.sharedService.exportDisabled$.subscribe((NavVal: boolean) => {
      this.disableExport = NavVal;
    });
    const filterData = localStorage.getItem('filters');
    if (filterData != null) {
      try {
        const filters = JSON.parse(filterData);
        this.filteredData = filters;
      } catch (e) {
        console.error('Error parsing filter data from localStorage', e);
      }
    }
    this.sharedService.yearFormatChange$.subscribe((yearFormat: boolean) => {
      if (yearFormat) {
        this.contactLoader = true;
      } else {
        this.contactLoader = false;
      }
    });
  }

  public async getDashboardStorageInfo() {
    let savedItems = await this.generalConfigService.getGeneralConfiguration(
      false
    );
    if (savedItems) {
      await this.checkWindowSize(savedItems);
    } else {
      this.items =
        await this.generalConfigService.getDefaultOrderForDashboard();
    }
  }

  public async checkWindowSize(
    savedItems: GeneralConfigurarion
  ): Promise<void> {
    const isMobile = window.innerWidth <= 1199;
    const itemsToCheck = isMobile ? savedItems.items : savedItems.webItems;

    if (itemsToCheck) {
      this.items = itemsToCheck;
    } else {
      this.items =
        await this.generalConfigService.getDefaultOrderForDashboard();
    }
  }

  public async getDataFromExternalJSONFile() {
    await this.generalConfigService.getDataFromExternalJSONFile();
  }

  public onDragStarted(event: CdkDragStart<string[]>, index: number) {
    this.sharedService.CancelOrderEvent.next(true);
  }

  public drop(event: CdkDragDrop<any[]>) {
    const nodeToMove = event.item.element.nativeElement;
    const { previousContainer, container, previousIndex, currentIndex } = event;

    if (previousContainer === container) {
      this.moveWithinContainer(
        container.element.nativeElement,
        previousIndex,
        currentIndex
      );
    } else {
      this.transferNodeToContainer(
        nodeToMove,
        container.element.nativeElement,
        currentIndex
      );
    }

    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.items.forEach((x: any) => {
      x.order = this.items.indexOf(x);
    });
  }

  public async cancelOrder() {
    this.isDropListDisabled = true;
    this.editOrderTrue = true;
    this.cancelOrderFalse = false;
    this.sharedService.CancelOrderEvent.next(false);
    if (window.innerWidth <= 1199) {
      this.items = (
        await this.generalConfigService.getGeneralConfiguration(false)
      )?.items;
    } else {
      this.items = (
        await this.generalConfigService.getGeneralConfiguration(false)
      )?.webItems;
    }
    this.sharedService.exportDisabled.next(false);
  }

  public async resetOrder() {
    this.items = await this.generalConfigService.getDefaultOrderForDashboard();
    this.saveOrder();
  }

  public editOrder() {
    this.cancelOrderFalse = true;
    this.editOrderTrue = false;
    this.isDropListDisabled = false;
    this.sharedService.exportDisabled.next(true);
  }

  public async saveOrder() {
    this.sharedService.EditOrderEvent.next(true);
    this.sharedService.CancelOrderEvent.next(false);
    this.cancelOrderFalse = false;
    await this.generalConfigService.saveUserCardInfo(this.items);
    this.sharedService.exportDisabled.next(false);
  }

  public ngOnDestroy() {
    if (this.subscriptionRefArray.length) {
      this.subscriptionRefArray.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }

  public getTemplateRef(type: string): TemplateRef<any> {
    switch (type) {
      case CustomCardDefaultOrder.learner:
        return this.leanerTemplate;
      case CustomCardDefaultOrder.applicant:
        return this.applicantTemplate;
      case CustomCardDefaultOrder.student_enrollment:
        return this.studentEnrollmentTemplate;
      case CustomCardDefaultOrder.alumni:
        return this.alumniTemplate;
      case CustomCardDefaultOrder.student:
        return this.studentTemplate;
      case CustomCardDefaultOrder.anticipated_grad_date:
        return this.anticipatedGradDateTemplate;
      default:
        return null;
    }
  }

  public moveWithinContainer(container, fromIndex, toIndex) {
    if (fromIndex === toIndex) {
      return;
    }
    const nodeToMove = container.children[fromIndex];
    const targetNode = container.children[toIndex];

    if (fromIndex < toIndex) {
      targetNode.parentNode.insertBefore(nodeToMove, targetNode.nextSibling);
    } else {
      targetNode.parentNode.insertBefore(nodeToMove, targetNode);
    }
  }

  public transferNodeToContainer(node, container, toIndex) {
    if (toIndex === container.children.length) {
      container.appendChild(node);
    } else {
      const targetItem = container.children[toIndex];
      targetItem.parentNode.insertBefore(node, targetItem);
    }
  }
}
