import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-funnel-chart',
  templateUrl: './funnel-chart.component.html',
  styleUrls: ['./funnel-chart.component.scss'],
})
export class FunnelChartComponent implements OnInit {
  public graphBackgroundColor = {
    Inquiries: 'rgb(42, 121, 217)',
    Applicants: 'rgb(0, 128, 127)',
    Students: 'rgb(136, 188, 64)',
    Alumni: 'rgb(244, 131, 34)',
  };
  public chartSeries: any[];
  public chartType: string;
  public sortedData: object = {};
  public updatedData: any[] = [];

  constructor(private readonly sharedService: SharedService) {}

  @Input() set funnelChartData(data: any) {
    if (data) {
      const dataArray = Object.entries(data);
      dataArray.sort((a: any, b: any) => b[1] - a[1]);
      this.sortedData = Object.fromEntries(dataArray);
      this.updateData(this.sortedData);
    }
  }

  public updateData(data: any) {
    this.updatedData = Object.entries(data).map(([name, value]) => ({
      name,
      value,
      color: this.graphBackgroundColor[name],
    }));
  }

  public ngOnInit(): void {
    this.sharedService.lightColorMode$.subscribe((NavVal: any) => {
      if (NavVal === true) {
        this.graphBackgroundColor = {
          Inquiries: 'rgb(68, 87, 114)',
          Applicants: 'rgb(107, 45, 125)',
          Students: 'rgb(193, 154, 71)',
          Alumni: 'rgb(137, 137, 182)',
        };
      } else {
        this.graphBackgroundColor = {
          Inquiries: 'rgb(42,121,217)',
          Applicants: 'rgb(0, 128, 127)',
          Students: 'rgb(136, 188, 64)',
          Alumni: 'rgb(244, 131, 34)',
        };
      }
      this.updateData(this.sortedData);
    });
  }
}
