<div class="faq-query-popup" *ngIf="showPopup" #popupContainer>
  <div class="faq-query-popup-sub-wrap">
    <div class="modal-header">
      <div class="modal-title">
        <h4>Add User</h4>
      </div>
      <button (click)="closePopup()">
        <span class="icon-close-icon close"></span>
      </button>
    </div>
    <div class="modal-body row">
      <div class="form-field mb-2 col-md-4">
        <label for="">Enter Name <span>*</span></label>
        <input type="text" class="form-control" [(ngModel)]="name" />
      </div>
      <div class="form-field mb-2 col-md-4">
        <label for="">Enter EmailId <span>*</span></label>
        <input
          type="email"
          class="form-control"
          [(ngModel)]="email"
          autocomplete="off"
        />
      </div>
      <div class="form-field mb-2 form-group-password col-md-4">
        <label for="">Enter Password <span>*</span></label>
        <input
          type="password"
          class="form-control"
          [(ngModel)]="password"
          autocomplete="new-password"
          [type]="fieldPasswordType ? 'text' : 'password'"
        />
        <span
          class="eye-icon"
          (click)="toggleTypePassword()"
          (keydown)="toggleTypePassword()"
        ></span>
      </div>
      <div class="form-field mb-2 col-md-6">
        <label for="">Select Category <span>*</span></label>
        <select
          class="form-control"
          [(ngModel)]="selectedCategory"
          (change)="onCategoryChange()"
        >
          <option value="" disabled selected>Select a Role</option>
          <option *ngFor="let category of uniqueCategories" [value]="category">
            {{ category | titlecase }}
          </option>
        </select>
      </div>
      <div class="form-field col-md-6">
        <label for="">Select Role <span>*</span></label>
        <select
          class="form-control"
          [(ngModel)]="selectedRole"
          (change)="onRoleChange()"
          [disabled]="!selectedCategory"
        >
          <option value="" disabled selected>Select a Role</option>
          <option *ngFor="let role of filteredRoles" [value]="role.role_name">
            {{ role.role_name | titlecase }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedRole" class="mt-4 form-field">
        <h5 for="">Page access for the selected role</h5>
        <ul>
          <li *ngFor="let access of pageAccess">
            {{ formatKey(access) | titlecase }}
          </li>
        </ul>
      </div>
      <div class="text-success" *ngIf="successMsg">
        <span class="text-success">{{ successContent }}</span>
      </div>
      <div class="text-danger mt-2" *ngIf="errorMsg">
        <span class="text-danger">{{ errorContent }}</span>
      </div>
      <div class="col-12">
        <div class="disclaimer alert alert-info">
          <span
            ><b>Disclaimer:</b> To access details in the partner portal, users
            must be assigned to a contact and associated with an organization in
            Salesforce. Only enabled roles can access these details; without an
            account, users will see empty data.</span
          >
        </div>
      </div>
      <div class="form-button">
        <button (click)="insertUser()">Submit</button>
      </div>
    </div>
  </div>
</div>
