import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../shared/services/shared.service';
import { ApiService } from '../../../../shared/services/api.service';

@Component({
  selector: 'app-add-org',
  templateUrl: './add-org.component.html',
  styleUrls: ['./add-org.component.scss'],
})
export class AddOrgComponent implements OnInit {
  public showPopup: boolean = false;
  public guid: string = '';
  public errorMsg: boolean = false;
  public errorContent: string;
  public successMsg: boolean = false;
  public successContent: string;

  constructor(
    public sharedService: SharedService,
    public apiService: ApiService
  ) {}

  public ngOnInit(): void {
    this.sharedService.addOrg$.subscribe((data: any) => {
      this.showPopup = data;
    });
  }

  public closePopup() {
    this.sharedService.addOrg.next(false);
    this.guid = '';
    this.errorMsg = false;
    this.successMsg = false;
  }

  public insertOrg() {
    const requestBody = {
      global_unique_id_c: this.guid,
    };
    this.apiService.insertOrganization(requestBody).subscribe({
      next: (data: any) => {
        if (data.success === 'true') {
          this.successContent = data.message;
          this.successMsg = true;
          this.errorMsg = false;
        }
      },
      error: (error) => {
        if (error.error.error_code === 404) {
          this.errorContent = error.error.message;
          this.errorMsg = true;
          this.successMsg = false;
        }
        if (error.status === 400) {
          this.errorContent = error.error.message;
          this.errorMsg = true;
          this.successMsg = false;
        }
      },
    });
    this.guid = '';
  }
}
