import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  HostBinding,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../shared/services/api.service';
import { SharedService } from '../../../shared/services/shared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { DOCUMENT } from '@angular/common';
import { GeneralConfigurationService } from '../../../shared/services/general-config/general-config-service';
import { User } from '../../interfaces/user.interface';
import { getFormattedCurrentDateTime } from '../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../shared/services/analytics/analytics.service';
import {
  NavItem,
  OrgDetail,
} from 'src/app/shared/components/model/customtypes-model.interface';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { HttpClient } from '@angular/common/http';
import { FirestoreService } from '../../../shared/services/firebase/firebase-service';

@Component({
  selector: 'app-pp-nav',
  templateUrl: './pp-nav.component.html',
  styleUrls: ['./pp-nav.component.scss'],
})
export class PpNavComponent implements OnInit, OnDestroy {
  @HostBinding('class') public onPopUpOpen = '';
  public dropdown = true;
  public accountOpen: boolean;
  public subscription: Subscription = new Subscription();
  public loading = false;
  public usernameData: OrgDetail[];
  public popUpDataObj: any;
  public showResponsiveMenu = false;
  public openModal: boolean;
  public openFAQModal: boolean;
  public showMenuBar = true;
  public ShowChangePasswordPopup = false;
  public loaderShow = true;
  public profile: boolean = true;
  public firebaseUser: string;
  public adtalemLogo: string = environment.assetDirectory.concat(
    '/assets/images/adtalem_logo.svg'
  );
  public avatar: string;
  public role: string[] = [
    'Talent Solutions Consultant',
    'Manager Talent Solutions',
    'Strategic Partner Executive',
  ];
  // Function for set nevigation/route page link
  public navItems: any[] = [];
  public faqImageUrl: string = environment.assetDirectory.concat(
    '/assets/images/Faq.svg'
  );
  public profileImageUrl: string = environment.assetDirectory.concat(
    '/assets/images/profile-bg.svg'
  );
  public profileAvatarUrl: string = environment.assetDirectory.concat(
    '/assets/images/profile-pic.png'
  );
  public isLightColorMode: boolean = false;
  public isYearFormat: boolean = false;
  @Output() public logoutOutput = new EventEmitter();
  public accountSettings: boolean;
  public settings: boolean = true;
  public user: User;
  public userName: string;
  public userEmail: string;
  public orgName: string;
  public hideButtons: boolean = false;
  public partnershipInfo: any[];
  public fireBaseStorageRef: any;
  public isCheckboxDisabled: boolean = false;
  public roles: any[] = [];
  public roleName: string;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly apiService: ApiService,
    public router: Router,
    public auth: AuthService,
    private readonly sharedService: SharedService,
    private readonly analytics: AngularFireAnalytics,
    public generalConfigurationService: GeneralConfigurationService,
    public analyticsService: AnalyticsService,
    private readonly storage: AngularFireStorage,
    private readonly http: HttpClient,
    private readonly firestoreService: FirestoreService
  ) {}

  @Input() set userData(data: any) {
    if (data) {
      this.loaderShow = false;
      this.usernameData = data;
    }
  }

  // getting popup data
  @Input() set popUpData(data: any) {
    this.loading = true;
    if (data) {
      this.loading = false;
      this.popUpDataObj = data;
      this.analytics.logEvent('Contact_View_Successfully');
    } else {
      this.loading = false;
      this.analytics.logEvent('Contact_View_Unsuccessfully');
    }
  }

  public async ngOnInit() {
    this.navItems = [
      {
        route: `/dashboard/learner-insights/${this.sharedService.getOrgId()}`,
        key: 'learner_insights',
        name: 'Learner Insights',
        isVisible: true,
        isClinicalVisible: true,
      },
      {
        route: `/dashboard/clinical-insights/${this.sharedService.getOrgId()}`,
        key: 'clinical_insights',
        name: 'Clinical Insights',
        isVisible: true,
        isClinicalVisible: false,
      },
      {
        route: '/dashboard/partnership-overview',
        key: 'partnership_overview',
        name: 'Partnership Overview',
        isVisible: true,
        isClinicalVisible: true,
      },
      {
        route: '/dashboard/lad-information-materials',
        key: 'lad-information-materials',
        name: 'L&D Information Materials',
        isVisible: true,
        isClinicalVisible: true,
      },
      {
        route: '/dashboard/admin/org-list',
        key: 'admin',
        name: 'Admin',
        content: '',
        isVisible: false,
        isClinicalVisible: true,
      },
      {
        route: '/dashboard/admin/clinical-status',
        key: 'clinical_status',
        name: 'Clinical Status',
        content: '',
        isVisible: false,
        isClinicalVisible: true,
      },
      {
        route: '/dashboard/admin/user',
        key: 'user',
        name: 'Users',
        content: '',
        isVisible: false,
        isClinicalVisible: true,
      },
      {
        route: '/dashboard/admin/role',
        key: 'role',
        name: 'Roles',
        content: '',
        isVisible: false,
        isClinicalVisible: true,
      },
    ];
    const role = localStorage.getItem('role');
    if (role) {
      this.user = JSON.parse(localStorage.getItem('user'));
      const uid = this.user.uid;
      this.firestoreService.getUserRoleName(uid).subscribe((role) => {
        this.apiService.rolesList().subscribe(async (data) => {
          this.roleName = data;
          if (data && Array.isArray(data.roles)) {
            const loggedInUserRole = role;
            this.roles = data.roles.filter(
              (role) => role.role_name === loggedInUserRole
            );
            await this.filterNavItems(loggedInUserRole);
            this.checkClinicalInsights(this.partnershipInfo);
            await this.redirectIfNeeded();
          } else {
            this.roles = [];
          }
        });
      });
    }
    if (role == 'admin') {
      this.hideButtons = true;
      this.navItems.forEach((item) => {
        switch (item.name) {
          case 'Admin':
          case 'Clinical Status':
          case 'Users':
          case 'Roles':
            item.isVisible = true;
            break;
          default:
            item.isVisible = false;
        }
      });
    }
    this.subscription.add(
      this.sharedService.mobNavEvent.subscribe((message: any) => {
        this.accountOpen = message?.profile;
        this.openModal = message?.contact;
        this.accountSettings = message?.settings;

        if (this.accountOpen) {
          this.profile = this.accountOpen;
        }
        if (this.accountSettings) {
          this.settings = this.accountSettings;
        }
      })
    );

    this.subscription.add(
      this.sharedService.menuBarEvent.subscribe((menu: boolean) => {
        this.showMenuBar = menu;
      })
    );
    this.sharedService.assetUrl$.subscribe((NavVal: boolean) => {
      if (NavVal) {
        this.toDataURL(
          `${environment.assetDirectory}/assets/images/adtalem_logo.svg`,
          (dataUrl) => {
            this.adtalemLogo = dataUrl;
          }
        );
      }
    });
    this.toDataURL(
      `${environment.assetDirectory}/assets/images/avatar.svg`,
      (dataUrl) => {
        this.avatar = dataUrl;
      }
    );

    this.subscription.add(
      this.sharedService.lightColorMode.subscribe((isLightColor: boolean) => {
        this.isLightColorMode = isLightColor;
      })
    );

    this.subscription.add(
      this.sharedService.yearFormat.subscribe((yearFormat: boolean) => {
        this.isYearFormat = yearFormat;
      })
    );

    this.firebaseUser = localStorage.getItem('providerId');
    if (this.firebaseUser === 'firebase') {
      const orgDetails: OrgDetail = JSON.parse(
        localStorage.getItem('userDetailId')
      );
      this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
      this.userEmail = this.user.email;
      this.orgName = localStorage.getItem('organization');
    }
    const userDetailId: any = localStorage.getItem('userDetailId');
    const Id = userDetailId ? JSON.parse(userDetailId)[0]?.Account?.Id : null;
    const partnerInfo = localStorage.getItem('partnerInfo');
    if (partnerInfo) {
      this.partnershipInfo = await this.filterPartnershipInfo(
        JSON.parse(partnerInfo)
      );
      this.checkClinicalInsights(this.partnershipInfo);
      if (role) {
        await this.redirectIfNeeded();
      }
    } else {
      this.apiService
        .getOverviewObservables(Id)
        .subscribe(async (data: any) => {
          localStorage.setItem('partnerInfo', JSON.stringify(data));
          this.partnershipInfo = await this.filterPartnershipInfo(data);
          this.checkClinicalInsights(this.partnershipInfo);
          if (role) {
            await this.redirectIfNeeded();
          }
        });
    }
  }
  public async filterNavItems(roleName) {
    if (roleName === 'Super Admin') {
      return this.navItems;
    } else {
      const userRole = this.roles.find((role) => role.role_name === roleName);
      if (userRole) {
        this.navItems = Object.keys(userRole.access)
          .filter((key) => userRole.access[key]) // Only true values
          .map((key) => this.navItems.find((item) => item.key === key))
          .filter(Boolean);
      } else {
        this.navItems = [];
      }
    }
    return [];
  }
  public async redirectIfNeeded() {
    const currentUrl = this.router.url;
    if (currentUrl != '/dashboard/faq') {
      const currentRoute = this.router.url;
      const currentPage = this.navItems.find(
        (item) => item.route === currentRoute && item.isClinicalVisible === true
      );
      const navItems = this.navItems.filter(
        (item) => item.isClinicalVisible === true
      );
      if (!currentPage) {
        const nextRoute = navItems.length
          ? navItems[0].route
          : '/dashboard/home';
        this.router.navigate([nextRoute]);
      }
    }
  }

  public toDataURL(url, callback) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  // Function for logout and set organization id null and firebase authstate is SignOut
  public logout() {
    const sessionId = localStorage.getItem('sessionId');
    const time = getFormattedCurrentDateTime();
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        this.userEmail,
        this.orgName,
        'Logout',
        time,
        sessionId
      );
    }
    this.logoutOutput.emit(true);
    const notifyPortal = new CustomEvent('logout', {
      detail: 'logouttriggered',
    });
    window.dispatchEvent(notifyPortal);
    this.sharedService.routeCache.clear();
    this.sharedService.cacheCleared = true;
    this.sharedService.lightColorMode.next(false);
    this.apiService.hasOrgInfo.next(null);
    this.sharedService.faqMob.next(false);
    this.sharedService.assetUrl.next(false);
    if (window.innerWidth < 575) {
      this.sharedService.mobNavEvent.next({
        profile: false,
        settings: false,
      });
      this.sharedService.openOtherProgramMob.next(false);
    }
    this.auth?.SignOut();
  }
  // Function for open adtalem contact modal
  public openContact() {
    this.analytics.logEvent('Adtalem_Contact_View');
    this.openModal = true;
    const sessionId = localStorage.getItem('sessionId');
    const time = getFormattedCurrentDateTime();
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        this.userEmail,
        this.orgName,
        'Viewed Adtalem Contacts',
        time,
        sessionId
      );
    }
  }
  // Function for open FAQ modal
  public faq() {
    if (window.innerWidth < 575) {
      this.profile = false;
      this.settings = false;
      this.sharedService.openOtherProgramMob.next(false);
    }
    this.sharedService.faqMob.next(true);
    this.sharedService.exportDisabled.next(false);
    this.router.navigate(['dashboard/faq']);
    const sessionId = localStorage.getItem('sessionId');
    const time = getFormattedCurrentDateTime();
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        this.userEmail,
        this.orgName,
        'FAQ page',
        time,
        sessionId
      );
    }
  }
  // Function for close modal
  public closeModal($event: any) {
    this.openModal = $event;
  }
  // Function for hide and show mobile menu
  public openMobileMenu() {
    this.showResponsiveMenu = !this.showResponsiveMenu;
  }
  // Funtion for close mobile nav

  public trackByFn(item: any) {
    return item.Id;
  }

  public defineHeight(offsetHeight: number) {
    return `calc(100% - ${offsetHeight})`;
  }

  public toggleDropdown() {
    this.dropdown = !this.dropdown;
  }
  public makeProfileActive() {
    this.profile = true;
    this.sharedService.productTourEvent.next(false);
    this.document
      .querySelector(`.mob-burger-wrap>ul>li>a.active`)
      ?.classList?.remove('active');
    this.document
      .querySelector(`.mob-burger-wrap>ul>li>a.profile`)
      ?.classList?.add('active');
    if (window.innerWidth < 575) {
      this.sharedService.openOtherProgramMob.next(false);
      this.sharedService.faqMob.next(false);
      this.sharedService.mobNavEvent.next({
        profile: true,
      });
    }
  }
  public sideNav() {
    this.showResponsiveMenu = false;
    this.sharedService.isNgScrollReachedMiddle.next(false);
    this.sharedService.mobNavEvent.next({
      profile: false,
      contact: false,
      settings: false,
    });
  }

  public openChangePasswordPopup() {
    this.analytics.logEvent('click_changepassword_btn');
    this.ShowChangePasswordPopup = true;
    this.sharedService.changePasswordEvent.next(true);
    const sessionId = localStorage.getItem('sessionId');
    const time = getFormattedCurrentDateTime();
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        this.userEmail,
        this.orgName,
        'Open Change Password',
        time,
        sessionId
      );
    }
  }

  public openProductTourPopup() {
    if (window.innerWidth < 575) {
      this.profile = false;
    }
    sessionStorage.setItem('showTour', 'true');
    this.analytics.logEvent('click_product_tour');
    this.sharedService.productTourEvent.next(true);
    const sessionId = localStorage.getItem('sessionId');
    const time = getFormattedCurrentDateTime();
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        this.userEmail,
        this.orgName,
        'Visited Product Tour',
        time,
        sessionId
      );
    }
  }

  public async lightColorMode(event) {
    let selectedvalue: boolean = event.target.checked;
    await this.generalConfigurationService.saveUserSelectedColorMode(
      selectedvalue
    );
    const sessionId = localStorage.getItem('sessionId');
    const time = getFormattedCurrentDateTime();
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        this.userEmail,
        this.orgName,
        `Color_Accessibility_Mode_${selectedvalue}`,
        time,
        sessionId
      );
    }
  }

  public backTodashboard() {
    this.router.navigate([
      `dashboard/learner-insights/${this.sharedService.getOrgId()}`,
    ]);
    this.sharedService.faqMob.next(false);
    const sessionId = localStorage.getItem('sessionId');
    const time = getFormattedCurrentDateTime();
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        this.userEmail,
        this.orgName,
        `Back to Learner Insights Page`,
        time,
        sessionId
      );
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public feedback() {
    this.sharedService.faqModalEvent.next(true);
    const sessionId = localStorage.getItem('sessionId');
    const time = getFormattedCurrentDateTime();
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        this.userEmail,
        this.orgName,
        `Questions/Feedback Mobile Clicked`,
        time,
        sessionId
      );
    }
  }

  public async yearFormat(event) {
    this.isCheckboxDisabled = true;
    this.settings = false;
    let selectedvalue: boolean = event.target.checked;
    let yearFormat: string;
    await this.generalConfigurationService.saveUserSelectedYearFormat(
      selectedvalue
    );
    this.sharedService.yearFormatChange.next(true);
    if (selectedvalue === true) {
      localStorage.setItem('fiscalYear', 'true');
      localStorage.removeItem('calendarYear');
      yearFormat = 'Fiscal';
    } else {
      localStorage.setItem('calendarYear', 'true');
      localStorage.removeItem('fiscalYear');
      yearFormat = 'Calendar';
    }
    setTimeout(() => {
      this.isCheckboxDisabled = false;
      this.settings = true;
    }, 2000);
    if (localStorage.getItem('providerId') === 'firebase') {
      const sessionId = localStorage.getItem('sessionId');
      const time = getFormattedCurrentDateTime();
      this.analyticsService.addAnalytics(
        this.userName,
        this.userEmail,
        this.orgName,
        `Year format changed to ${yearFormat}`,
        time,
        sessionId
      );
    }
  }
  public async filterPartnershipInfo(data: any): Promise<any[]> {
    return data[0].items
      .filter((items: any) => items.contentNodes?.excerpt?.value === 'Logo')
      .map((items: any) => ({
        ...items,
        status: data[1].contractList?.filter(
          (x: any) =>
            x.RecordType.Name.split(' ')[0] === 'CU' && x.Stage__c === 'Active'
        ),
      }));
  }

  public async checkClinicalInsights(data: any) {
    if (!data || !Array.isArray(data)) {
      return;
    }
    const univName = data.find(
      (university) => university.title == 'Chamberlain University'
    );
    if (univName != null && univName != undefined) {
      const clinicalAgreement = univName.status?.filter((x: any) =>
        x.RecordType.Name.includes('CU Clinical Agreement')
      );
      const clinicalStatus: any = await this.getClinicalStatus();
      const orgName = localStorage.getItem('organization');
      const status = clinicalStatus[orgName]?.status;
      this.navItems.forEach((item: NavItem) => {
        if (item.name === 'Clinical Insights') {
          if (clinicalAgreement?.length) {
            switch (status) {
              case true:
                item.isClinicalVisible = true;
                break;
              case false:
                item.isClinicalVisible = false;
                break;
              default:
                item.isClinicalVisible = true;
            }
          } else {
            item.isClinicalVisible = status ? status : false;
          }
        }
      });
    }
  }
  public async getStatusData() {
    let res: any;
    const filePath = 'partner-portal/clinical-status';
    this.fireBaseStorageRef = this.storage.ref(`${filePath}`);
    const fileRef = `clinical-status.json`;
    const objectRef = this.fireBaseStorageRef?.child(`${fileRef}`);
    await objectRef
      .getDownloadURL()
      .toPromise()
      .then(async (url) => {
        const parsedUrl = new URL(url);
        const fileUrl = parsedUrl.href;
        res = await this.http.get(fileUrl).toPromise();
      })
      .catch((err) => (res = ''));
    return res;
  }

  public async getClinicalStatus() {
    const orgName = localStorage.getItem('organization');
    const status = await this.getStatusData();
    const filteredEntries = Object.entries(status).filter(
      ([key]) => key === orgName
    );
    return Object.fromEntries(filteredEntries);
  }
}
