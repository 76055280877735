import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PdfService } from '../../services/pdf.service';
import { SharedService } from '../../services/shared.service';
import { getFormattedCurrentDateTime } from '../../services/functional-handler/functional-handler';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { OrgDetail } from '../model/customtypes-model.interface';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss'],
})
export class PdfModalComponent implements OnInit {
  public ShowPdfDownload = false;
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  public userName: string;
  constructor(
    private readonly pdfService: PdfService,
    private readonly sharedService: SharedService,
    private readonly analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    this.sharedService.pdfDownload$.subscribe((NavVal: boolean) => {
      this.ShowPdfDownload = NavVal;
    });
  }

  public closePdfPopup() {
    this.ShowPdfDownload = false;
    this.sharedService.exportClicked.next(false);
  }

  public downloadPDF() {
    this.ShowPdfDownload = false;
    this.sharedService.assetUrl.next(true);
    setTimeout(() => {
      this.pdfService.generatePdf(
        'partner-dashboard',
        'Learner Insights.pdf',
        '#f5f5f6',
        5
      );
      this.sharedService.exportClicked.next(false);
      this.sharedService.assetUrl.next(false);
    }, 200);
    const orgDetails: OrgDetail = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        'Downloaded PDF',
        time,
        sessionId
      );
    }
  }
}
