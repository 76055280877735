export enum FilterItemTooltip {
  institution = 'The university the employee attends',
  Program = 'The program the employee has applied to or enrolled in',
  location = 'The state of the site for which the employee works',
  hospital = 'The name of the site for which the employee works',
  StartYear = 'The year the employee started classes',
  Year = 'The year the employee started/submitted an application or started classes',
}

export enum Agreement {
  educational = 'educational',
  clinical = 'clinical',
}

export enum HeaderColor {
  Enrollment = 'enrollment',
  Applicants = 'applicants',
  Continuing = 'continuing',
  Withdrawn = 'withdrawn',
  Graduated = 'graduated',
}

export enum AgreementTooltip {
  educational = 'Contractual agreement between your organization and one or more Adtalem institutions providing a partner tuition rate for specified programs',
  clinical = 'Contractual agreement between your organization and one or more Adtalem institutions providing access for students to complete their field experience at organization locations',
}

export enum AggrementStatus {
  active = 'Active',
  expired = 'Expired',
  terminated = 'Terminated',
  noAgreement = 'No Agreement',
}

export enum AggrementStatusTooltip {
  active = 'The Partnership Contract has been signed and has not expired',
  expired = 'The Partnership Contract has expired',
  terminated = 'The Partnership Contract has been terminated',
  noAgreement = 'No Partnership Contract has been signed',
}

export enum RenewalTooltip {
  renewalDate = 'Date the contract is scheduled to be renewed',
  notAvailable = 'NA means the agreement does not have an end date and it will stay Active infinitely',
}

export enum ApplicantsToEntrollment {
  applicants = 'Employees who have started or submitted an application for Chamberlain University or Walden University between January 1, 2020, and present',
  entrollment = 'Employees who started classes at Chamberlain University or Walden University between January 1, 2020, and present',
}

export enum StudenProgramTooltip {
  rnbsn = 'Nursing - RN to BSN Option',
  rnmsn = 'Nursing - RN to BSN to MSN Option and Accelerated RN to MSN Option',
  msn = 'Master of Science in Nursing',
  dnp = 'Doctor of Nursing Practice',
  other = 'All programs offered excluding RNBSN, RNMSN, MSN and DNP',
}

export enum StudentProgressTooltip {
  continuing = 'Employees who have started classes at Chamberlain University or Walden University within the specified time range who have not withdrawn or graduated',
  withdrawn = 'Employees who have started classes at Chamberlain University or Walden University within the specified time range who have withdrawn',
  graduated = 'Employees who have started classes at Chamberlain University or Walden University within the specified time range who have graduated',
}

export enum DescriptionTabTooltip {
  partnerDashboard = 'Explore how the partnership with Chamberlain University and Walden University is enhancing the learning and development of your employees',
  partnershipOverview = 'Your partnership agreements with Chamberlain University and Walden University',
}

export enum ChartType {
  applicantsToEnrollment = 'Applicants To Enrollments',
  studentEnrollment = 'Student Enrollments',
  alumni = 'Alumni',
  studentProgress = 'Student Progress',
  anticipatedGradYear = 'Anticipated Grad Year',
  learnerAnalytics = 'Learner Analytics',
  clinicalInsight = 'Clinical Placements',
}

export enum FilterItem {
  institution = 'Institution',
  Program = 'Program',
  location = 'Location',
  hospital = 'Hospital',
  StartYear = 'Start_Year',
  Year = 'Year',
}

export enum CustomCardDefaultOrder {
  learner = 'learner',
  applicant = 'applicant',
  student_enrollment = 'student_enrollment',
  student = 'student',
  anticipated_grad_date = 'anticipated_grad_date',
  alumni = 'alumni',
}

export enum CustomCardWebDefaultOrder {
  learner = 'learner',
  applicant = 'applicant',
  student = 'student',
  alumni = 'alumni',
}

// The excel sheet name and the order of the sheet
export enum DashboardExcelSheetName {
  learnerAnalytics = 'Learner Analytics',
  applicantsToEnrollment = 'Applicants to Enrollments',
  studetEnrollment = 'Student Enrollment',
  studentProgress = 'Student Progress',
  anticipatedGraduationDate = 'Anticipated Graduation Date',
  alumni = 'Alumni',
}

export enum ExportPage {
  dashBoard = 'PP_Learner_Insights',
}

export enum ExcelSheetItem {
  applicantsToEnrollment = 'ApplicantsToEnrollment',
  learnerAnalytics = 'LearnerAnalytics',
  studetEnrollment = 'StudentEnrollment',
  alumni = 'Alumni',
  studentProgress = 'StudentProgress',
  anticipatedGraduationDate = 'AnticipatedGraduationDate',
  clinicalInsigntsChamberlain = 'ClinicalInsignts-Chamberlain',
  all = 'all',
}

export enum YearQuarter {
  Q1 = 'Jan-Mar',
  Q2 = 'Apr-Jun',
  Q3 = 'Jul-Sep',
  Q4 = 'Oct-Dec',
}

export enum studentEnrollmentChartCount {
  chartOptionInitXMin = 0,
  chartOptionInitXMax = 3,
  chartYearLength = 4,
}

export enum DiversityEthinicityHeaders {
  hispanicLatino = 'Hispanic / Latino',
  notHispanicLatino = 'Not Hispanic / Latino',
  unspecified = 'Unspecified',
  totalCount = 'Total Count',
}

export enum DiversityRaceHeaders {
  americanIndianOrAlaskanNative = `American Indian Or Alaskan Native`,
  asian = 'Asian',
  blackOrAfricanAmerican = 'Black Or African American',
  hawaiianOrPacificIslander = 'Hawaiian Or Pacific Islander',
  white = 'White',
  multiRace = 'Multi-Race',
  unspecified = 'Unspecified',
  totalCount = 'Total Count',
}

export enum DiversityGenderHeaders {
  male = 'Male',
  female = 'Female',
  unspecified = 'Unspecified',
  totalCount = 'Total Count',
}

export enum DiversityAgeHeaders {
  '15_19' = '15-19',
  '20_24' = '20-24',
  '25_29' = '25-29',
  '30_34' = '30-34',
  '35_39' = '35-39',
  '40_44' = '40-44',
  '45_49' = '45-49',
  '50_54' = '50-54',
  '55_59' = '55-59',
  '60+' = '60+',
  totalCount = 'Total Count',
}

export enum DiversityFilter {
  ethnicity = 'Ethnicity',
  race = 'Race',
  gender = 'Gender',
  age = 'Age',
}

export enum CalenderMonth {
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
}

export enum CalenderMonthIndex {
  'Jan' = '01',
  'Feb' = '02',
  'March' = '03',
  'April' = '04',
  'May' = '05',
  'June' = '06',
  'July' = '07',
  'Aug' = '08',
  'Sep' = '09',
  'Oct' = '10',
  'Nov' = '11',
  'Dec' = '12',
}

export enum FiscalYear {
  startMonth = 7,
  endMonth = 6,
}

export const GraphColor = [
  '#2A79D9',
  '#88BC3F',
  '#F48322',
  '#3AA8EB',
  '#EFB92B',
  '#D691FC',
  '#FB5A6B',
  '#D4EB61',
  '#FF9BCE',
  '#75F0C9',
];
