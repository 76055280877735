import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { isScullyRunning } from '@scullyio/ng-lib';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(public auth: AuthService, public router: Router) {}
  public canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    //  Checking scully is running and authenticate routes
    if (isScullyRunning()) {
      return true;
    }
    //  Redirect to login when authenticate failed
    try {
      if (typeof route.queryParams['token'] === 'string') {
        this.router.navigate(['login'], {
          queryParams: { token: route.queryParams['token'] },
        });
        return false;
      }

      //  Redirect to login when firebase authentication failed
      if (!this.auth.isAuthenticated()) {
        this.router.navigate(['login']);
        return false;
      }
    } catch (error) {
      console.log('Token error - auth guard', error);
    }

    return true;
  }
}
