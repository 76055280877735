import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, map, Observable, Subject, zip } from 'rxjs';
import { ChartFilterDataUrlType, ChartUrlType } from './class-type/chart-type';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public isLoading = new Subject<boolean>();
  public hasOrgInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  public loading: boolean;
  public orgObservable = this.hasOrgInfo.asObservable();
  /**
   * List of all URL for getting lebal for different chart.
   */
  public filterLabelUrlObj: any = {
    learnerAnalytics: {
      url: `${environment.daasBaseURL}partnerLearnerAnalyticsFilter`,
    },
    learnerAnalyticsFunnel: {
      url: `${environment.daasBaseURL}partnerLearnerAnalyticsFilter`,
    },
    applicantsToEnrollments: {
      url: `${environment.daasBaseURL}applicantsToEnrollmentFilter`,
    },
    studentEnrollments: {
      url: `${environment.daasBaseURL}studentEnrollmentFilter`,
    },
    studentPersistence: {
      url: `${environment.daasBaseURL}studentPersistenceFilter`,
    },
    alumniPrograms: {
      url: `${environment.daasBaseURL}alumniByProgramFilter`,
    },
    anticipatedGradDate: {
      url: `${environment.daasBaseURL}anticipatedGradDateFilter`,
    },
  };
  /**
   * List of all URL for getting data for different chart.
   */
  public filterDataUrlObj: any = {
    applicantsToEnrollment: {
      url: `${environment.daasBaseURL}applicantsToEnrollment`,
      urlForALL: `${environment.daasBaseURL}applicantsToEnrollmentAll`,
    },
    partnerLearnerAnalytics: {
      url: `${environment.daasBaseURL}partnerLearnerAnalytics`,
      urlForALL: `${environment.daasBaseURL}learnerAnalyticsDynamicAll`,
    },
    partnerLearnerAnalyticsFunnel: {
      url: `${environment.daasBaseURL}funnelView`,
      urlForALL: `${environment.daasBaseURL}funnelViewDynamicAll`,
    },
    studentEnrollment: {
      url: `${environment.daasBaseURL}studentEnrollment`,
      urlForALL: `${environment.daasBaseURL}studentEnrollmentDynamicAll`,
    },
    partnerStudentPersistence: {
      url: `${environment.daasBaseURL}studentPersistence`,
      urlForALL: `${environment.daasBaseURL}studentPersistenceAll`,
    },
    alumniProgram: {
      url: `${environment.daasBaseURL}alumniByProgramDynamic`,
      urlForALL: `${environment.daasBaseURL}alumniByProgramDynamicAll`,
    },
    anticipatedGraduationDate: {
      url: `${environment.daasBaseURL}anticipatedGradDate`,
      urlForALL: `${environment.daasBaseURL}anticipatedGradDateAll`,
    },
  };
  constructor(private readonly http: HttpClient) { }

  public show() {
    this.isLoading.next(true);
  }

  public hide() {
    this.isLoading.next(false);
  }

  public getUserInfo(email: string) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    return this.http.get(
      `${environment.sfBaseURL}/getContactDetails?email=${email}`,
      {
        headers: apiHeader,
      }
    );
  }

  public getUserDetails(email: string) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    return this.http.get(
      `${environment.sfBaseURL}/PartnerPortalApi/getUserDetails?email=${email}`,
      {
        headers: apiHeader,
      }
    );
  }

  public async getUserDetailsByEmail(email: string): Promise<any> {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${environment.sfBaseURL}/PartnerPortalApi/getUserDetails?email=${email}`,
          {
            headers: apiHeader,
          }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve({});
        });
    });
  }

  public getServices(id: any) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    const url = `/PartnerPortalApi/getContact?site=www.adtalem.com&organizationId=${id}`;
    return this.http.get(environment.sfBaseURL + url, {
      headers: apiHeader,
    });
  }

  /**
   * Getting initial filter combination(all) on basis of org.
   */
  public getInitialFilterCombsByOrg(
    org: string,
    chart: ChartFilterDataUrlType
  ) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    return this.http.get(this.filterDataUrlObj[chart].urlForALL, {
      params: { organization: org },
      headers: apiHeader,
    });
  }
  /**
   * Getting filter labels on basis of org.
   */
  public getFilterLabelsByOrg(org: string, chart: ChartUrlType) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    return this.http.get(this.filterLabelUrlObj[chart].url, {
      params: { organization: org },
      headers: apiHeader,
    });
  }
  /**
   * Partnership content and status API calling and mapping data.
   */
  public getCombinedSliderObservables(id: string) {
    return zip(
      this.getContentForPartnerShips(),
      this.getStatusOfPartnerships(id)
    ).pipe(
      map((data: any) => {
        return data[0].items
          .filter(
            (items: any) =>
              items.contentNodes?.Tab?.value === 'PartnershipOverivew'
          )
          .map((items: any) => {
            return {
              ...this.removeSpecialCharsAndReturnObj(
                items?.contentNodes?.excerpt?.value
              ),
              Number: items?.contentNodes?.SerialNumber?.value,
              url: items?.contentNodes?.Image?.resourceUrl,
              pgmname: items?.contentNodes?.ProgramName?.value,
              university: items?.contentNodes?.University?.value,
              percentage:
                items?.contentNodes?.University?.value === 'Chamberlain'
                  ? data[1].contractList?.find(
                    (x: any) =>
                      items?.contentNodes?.RecordType?.value ===
                      x.RecordType.Name
                  )?.Account?.[items?.contentNodes?.AccountType?.value]
                  : data[1].contractList.find(
                    (x: any) =>
                      items?.contentNodes?.RecordType?.value ===
                      x.RecordType.Name
                  )?.Reduction_Amount__c,
            };
          });
      })
    );
  }

  /**
   * L&D Infromation content and status API calling and mapping data
   */

  public getCombinedLdData(org) {
    return this.getContentForPartnerShips().pipe(
      map((data: any) => {
        return data.items
          .filter(
            (items: any) =>
              items.contentNodes?.Tab?.value === 'L_D_Materials' &&
              items.contentNodes?.OrgId?.value.includes(org)
          )
          .map((dataItems: any) => {
            return {
              dataUrl: data.items.find(
                (x: any) =>
                  x.contentKey === dataItems.contentNodes?.MaterialsKey?.value
              )?.contentNodes?.source?.resourceUrl,
              dateTime: data.items.find(
                (x: any) => x.contentKey === dataItems.contentKey
              )?.publishedDate,
              pdfTitle: data.items.find(
                (x: any) => x.contentKey === dataItems.contentKey
              )?.contentNodes?.Description?.value,
              imageThumbnailUrl: data.items.find(
                (x: any) => x.contentKey === dataItems.contentKey
              )?.contentNodes?.Image?.resourceUrl,
              mimeType: data.items
                .find(
                  (x: any) =>
                    x.contentKey === dataItems.contentNodes?.MaterialsKey?.value
                )
                ?.contentNodes?.source?.fileName?.split('.')[1],
              imageTitle: data.items.find(
                (x: any) =>
                  x.contentKey === dataItems.contentNodes?.MaterialsKey?.value
              )?.contentNodes?.title?.value,
            };
          });
      })
    );
  }

  public getContentForPartnerShips() {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    const url = `${environment.sfBaseURL}/content/${environment.channelId}`;
    return this.http.get(url, {
      headers: apiHeader,
    });
  }
  public getStatusOfPartnerships(id: string) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    const url = `${environment.sfBaseURL}/PartnerPortalApi/getContract?organizationId=${id}`;
    return this.http.get(url, {
      headers: apiHeader,
    });
  }
  public getCombinedObservables(id: string) {
    return zip(
      this.getContentForPartnerShips(),
      this.getStatusOfPartnerships(id)
    ).pipe(
      map((data: any) => {
        return data[0].items
          .filter((items: any) => items.contentNodes?.excerpt?.value === 'Logo')
          .map((items: any) => {
            return {
              ...items,
              status: data[1].contractList?.filter(
                (x: any) => 'CU' || 'WU' === x.RecordType.Name.split(' ')[0]
              ),
            };
          });
      })
    );
  }

  public removeSpecialCharsAndReturnObj(stringObj: any) {
    return stringObj?.replace(/&quot;/g, '"');
  }

  /**
   * Partnership overview veiw all hospital list
   */
  public getPartnershipChildDetails(id: any) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    return this.http.get(
      `${environment.sfBaseURL}/PartnerPortalApi/getChildHierarchy?organizationId=${id}`,
      {
        headers: apiHeader,
      }
    );
  }

  public getCombinedTourData(id: string) {
    return zip(
      this.getContentForPartnerShips(),
      this.getStatusOfPartnerships(id)
    ).pipe(
      map((data: any) => {
        return data[0].items
          .filter(
            (items: any) => items.contentNodes?.Tab?.value === 'Welcome_Page'
          )
          .map((items: any) => {
            return {
              Description: items?.contentNodes?.Description?.value,
              url: items?.contentNodes?.Image?.resourceUrl,
              title: items?.title,
              Body: items?.contentNodes?.Body?.value,
              Number: items?.contentNodes?.SerialNumber?.value,
            };
          });
      })
    );
  }

  public getCombinedMobileTourData(id: string) {
    return zip(
      this.getContentForPartnerShips(),
      this.getStatusOfPartnerships(id)
    ).pipe(
      map((data: any) => {
        return data[0].items
          .filter(
            (items: any) =>
              items.contentNodes?.Tab?.value === 'Welcome_Page_mobile'
          )
          .map((items: any) => {
            return {
              Number: items?.contentNodes?.SerialNumber?.value,
              title: items?.title,
              Description: items?.contentNodes?.Description?.value,
              Mobileurl: items?.contentNodes?.Image?.resourceUrl,
              Body: items?.contentNodes?.Body?.value,
            };
          });
      })
    );
  }

  public getFAQData(id: string) {
    return zip(
      this.getContentForPartnerShips(),
      this.getStatusOfPartnerships(id)
    ).pipe(
      map((data: any) => {
        return data[0].items
          .filter((items: any) => items.type === 'FAQ')
          .map((items: any) => {
            return {
              description: items?.contentNodes?.Description?.value
                .replace(/&lt;\/?p&gt;/g, '')
                .replace(/&lt;\/?br&gt;/g, ''),
              title: items?.contentNodes?.Title?.value.replace(/&amp;/g, '&'),
              content: items?.contentNodes?.Content?.value,
              number: items?.contentNodes?.SerialNumber?.value,
              contentSortNumber:
                items?.contentNodes?.ContentSerialNumber?.value,
            };
          });
      })
    );
  }

  public getOverviewObservables(id: string) {
    return zip(
      this.getContentForPartnerShips(),
      this.getStatusOfPartnerships(id)
    ).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public async getUserList() {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.mailHeader);
    return this.http.get(`${environment.mailUrl}/getFirebaseUserList`, {
      headers: apiHeader,
    });
  }

  public async getOrganizationList() {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    return this.http.get(`${environment.daasBaseURL}/onboardOrganizationList`, {
      headers: apiHeader,
    });
  }

  public insertOrganization(requestBody): Observable<any> {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    return this.http
      .post(`${environment.daasBaseURL}onboardOrganization`, requestBody, {
        headers: apiHeader,
      })
      .pipe(map((response) => response));
  }
}
