import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ApplicantsToEnrollmentProgram,
  ChartOtherProgramInfo,
} from '../model/customtypes-model.interface';
@Component({
  selector: 'app-info-graphic',
  templateUrl: './info-graphic.component.html',
  styleUrls: ['./info-graphic.component.scss'],
})
export class InfoGraphicComponent implements OnChanges {
  public datasets: any;
  public totalCount: any;
  public chartOtherProgramDetails: any[] = [];
  public chartTotalInfo: any[] = [];
  public chartLabelInfo: any[] = [];

  @Input() public chartType: string = '';
  @Input() public otherProgramDetails: ChartOtherProgramInfo[] = [];
  @Input() public totalInfo: any = [];
  @Input() public labelInfo: any = [];
  @Input() public totalYearWiseInfo: any = [];
  @Input() public selectedFilters: any = [];
  @Input() public headerLabel: any = [];
  @Input() public withDrawnReason: any = [];
  @Input() public applicantsReason: ApplicantsToEnrollmentProgram[] = [];
  @Input() public yearFormat: boolean;
  // Getting chart data numbers
  @Input() set chartData(data: any) {
    this.datasets = data || null;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalInfo']) {
      this.totalInfo = changes['totalInfo'].currentValue;
    }
  }
}
