import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import {
  ChartDataItems,
  ChartDataSet,
  StaticFilterData,
  DataSetResponse,
  ChartNumberData,
  Program,
  ChartOtherProgramInfo,
  OtherProgramTitle,
} from '../../model/customtypes-model.interface';
import { SharedService } from '../../../services/shared.service';
import { OtherProgramService } from '../../../../shared/services/other.program.service';
import {
  ChartType,
  studentEnrollmentChartCount,
} from '../../../../shared/services/enum/shared.enum';

@Component({
  selector: 'app-horizontal-bar-chart',
  styleUrls: ['./horizontal-bar-chart.component.scss'],
  templateUrl: './horizontal-bar-chart.component.html',
})
export class HorizontalBarChartComponent implements OnInit {
  @ViewChild('canvasEl') public canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('tooltip', { static: true }) public tooltip: ElementRef;
  public bgColorsForLegend: string[] = ['#56D0E0', '#4C7DED'];
  public ChartLabels: string[] = [];
  public chartData: ChartData<'bar'> = {
    datasets: [
      this.createDataset(['#88BC40', '#88BC40'], '#88BC40', 'Enrollments'),
      this.createDataset(['#2A79D9', '#2A79D9'], '#2A79D9', 'Applicants'),
    ],
    labels: this.ChartLabels,
  };
  public chartType: ChartConfiguration<'bar'>['type'] = 'bar';
  public chartPlugin: any = [ChartDataLabels];
  public chartOption: any = {
    animation: false,
    layout: {
      padding: 0,
    },
    maintainAspectRatio: window.innerWidth > 490,
    plugins: {
      border: false,
      datalabels: {
        color: 'white',
        font: {
          size: 10,
          weight: 'normal',
        },
      },
      legend: {
        align: 'start',
        display: false,
        labels: {
          boxHeight: 15,
          boxWidth: 15,
          color: '#526289',
          pointStyle: 'rectRounded',
          textAlign: 'center',
          usePointStyle: true,
        },
        position: 'top',
      },
      text: false,
      text1: false,
      doughnutLabelsLine: false,
      customCanvasBackgroundColor: false,
      tooltip: {
        backgroundColor: 'rgba(240, 240, 240, 0.8)',
        bodyColor: '#000000',
        enabled: true,
        footerColor: '#000000',
        titleColor: '#000000',
        xAlign: 'bottom',
        yAlign: 'center',
      },
    },
    responsive: true,
    scales: {
      x: {
        border: {
          borderDash: [1, 1],
          color: '#1F356C',
          display: true,
          width: 1,
        },
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          color: '#1F356C',
          font: {
            weight: 'bold',
          },
        },
      },
      y: {
        border: {
          dash: [2, 4],
          display: false,
        },
        suggestedMax: 10,
        ticks: {
          color: '#1F356C',
          font: {
            weight: 'bold',
          },
        },
        title: {
          align: 'start',
          color: '#526289',
          display: true,
          text: 'No.of Students',
        },
      },
    },
  };
  public graphBackgroundColor;
  public emptyFilter: StaticFilterData[] = [
    { Total: 0, Applicants: 0, Enrollment: 0 },
    { Total: 0, Applicants: 0, Enrollment: 0 },
    { Total: 0, Applicants: 0, Enrollment: 0 },
    { Total: 0, Applicants: 0, Enrollment: 0 },
  ];
  @Input() public chartNumbers: ChartNumberData[] = [];
  @Input() public otherProgramDetails: ChartOtherProgramInfo[] = [];

  @ViewChild(BaseChartDirective)
  public chart: BaseChartDirective;
  public datas: ChartDataSet;
  public isActive: boolean;
  public programInfo: Program[];
  public tooltipPositionX: number = 0;
  public tooltipPositionY: number = 0;
  public tooltipContent: string = '';
  public filteredData = {};
  public isSelectedAll = true;
  public totalCounts: any[] = [];
  public chartOptionXMin: number =
    parseInt(`${new Date().getFullYear()}`.slice(3, 4), 10) - 2;
  public chartOptionXMax: number =
    parseInt(`${new Date().getFullYear()}`.slice(3, 4), 10) + 1;
  public isDisableLeft: boolean = false;
  public isDisableRight: boolean = true;
  public isArrowVisible: boolean = false;
  public showCurrentyearLength: any;
  public tempChartLabels: any[] = [];
  public tempChartData: ChartData<'bar'>;

  constructor(
    private readonly sharedService: SharedService,
    private readonly otherProgramService: OtherProgramService
  ) {
    this.tempChartLabels = JSON.parse(JSON.stringify(this.ChartLabels));
    this.tempChartData = JSON.parse(JSON.stringify(this.chartData));
  }

  // Getting chart data set number

  @Input() set chartDatSets(data: ChartDataSet) {
    if (data) {
      this.datas = data;
      this.chartData.labels = data.labels;
      this.showCurrentyearLength = data.labels.length;
      this.isActive = true;
      this.chartData.datasets[0].data = data.chartData.map(
        (x: ChartDataItems) => x.data?.Enrollment
      );
      this.chartData.datasets[1].data = data.chartData.map(
        (x: ChartDataItems) => x.data?.Applicants
      );
      const hasData = this.chartData.datasets.every(
        (q: DataSetResponse) =>
          q.data.length === 0 || q.data.every((a: number) => a === 0)
      );
      if (hasData) {
        this.chartData.datasets.forEach((x: DataSetResponse, i: number) => {
          this.chartData.datasets[i].data = x.data.map((x: number) => 0);
        });
      }
    } else {
      this.chartData.datasets.forEach((x: DataSetResponse, i: number) => {
        this.chartData.datasets[i].data = x.data.map((x: number) => 0);
      });
      this.chartData.labels = ['RNBSN', 'RNMSN', 'MSN', 'DNP', 'Other'];
      this.isActive = false;
    }
    this.chart?.chart?.update();
    this.setTempValues(data);
    this.setChartOptions();
  }

  @Input() set selectedFilters(data: any) {
    this.filteredData = data;
    this.isSelectedAll = false;
    if (data && Object.keys(data).length > 0) {
      this.getFilteredData();
      if (
        Object.values(data).every((x: any) => x === 'all' || x[0] === 'all')
      ) {
        this.isSelectedAll = false;
      } else {
        this.isSelectedAll = true;
      }
    }
  }

  public ngOnInit(): void {
    this.sharedService.lightColorMode$.subscribe((NavVal: any) => {
      if (NavVal === true) {
        this.graphBackgroundColor = ['#C29D4D', '#4A5C76'];
      } else {
        this.graphBackgroundColor = ['#88BC40', '#2A79D9'];
      }
      this.chartData.datasets.forEach((x: any, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.datasets[i].backgroundColor =
            this.graphBackgroundColor[i];
          this.chartData.datasets[i].hoverBackgroundColor =
            this.graphBackgroundColor[i];
        }
      });
      this.chart?.chart?.update();
    });
    this.programInfo = this.otherProgramService.getProgramWithContent();
  }

  public ngAfterViewInit(): void {
    this.setupTooltip();
  }

  public createGradient(
    ctx: CanvasRenderingContext2D,
    colors: string[],
    startY: number,
    endY: number
  ) {
    const gradient = ctx.createLinearGradient(0, startY, 0, endY);
    colors.forEach((color, index) => {
      gradient.addColorStop(index, color);
    });
    return gradient;
  }

  public createDataset(
    backgroundColor: string[],
    hoverBackgroundColor: string,
    label: string
  ) {
    return {
      backgroundColor: function (context: any) {
        const chart = context.chart;
        const { chartArea } = chart;
        if (!chartArea) {
          return null;
        }
        return this.createGradient(context.chart.ctx, backgroundColor, 300, 0);
      }.bind(this),
      barThickness: window.innerWidth > 767 ? 30 : 20,
      borderColor: 'white',
      borderRadius: 20,
      borderWidth: 0.1,
      data: [],
      hoverBackgroundColor,
      hoverBorderColor: 'white',
      label,
    };
  }

  public setupTooltip(): void {
    const canvasEl = this.canvas.nativeElement;
    const ctx = canvasEl.getContext('2d');
    canvasEl.addEventListener('mousemove', (event) => {
      const rect = canvasEl.getBoundingClientRect();
      let mouseX;
      if (window.innerWidth <= 755) {
        mouseX = event.clientX - rect.left + 40;
      } else {
        mouseX = event.clientX - rect.left;
      }
      const mouseY = event.clientY - rect.top;
      const xAxisHeight = this.chart.chart.chartArea.bottom;

      if (mouseY >= xAxisHeight) {
        this.handleXAxisHover(event, rect, mouseX, mouseY);
      } else {
        this.hideTooltip();
      }
    });

    canvasEl.addEventListener('mouseleave', () => {
      ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
      this.hideTooltip();
      this.chart.chart.update();
    });
  }

  public filterProgramByName(name: string): Program {
    const defaultProgram: Program = {
      name: '',
      content: '',
    };

    return this.programInfo.find((prg) => prg.name === name)
      ? this.programInfo.find((prg) => prg.name === name)
      : defaultProgram;
  }

  public openOtherProgramPopup() {
    if (window.innerWidth < 575) {
      this.sharedService.openOtherProgramMob.next(true);
      this.sharedService.FloatingMenuEvent.next(false);
    } else {
      this.sharedService.openOtherProgram.next(true);
    }
    let titleInfo: OtherProgramTitle = {
      popupTotalTitle: '',
      popupTitle: ChartType.applicantsToEnrollment,
    };
    this.otherProgramService.bindDataToStorage(
      titleInfo,
      this.otherProgramDetails,
      ['Enrollment', 'Applicants', 'Conversion Rate'],
      [],
      [],
      false,
      false
    );
  }

  public handleXAxisHover(event, rect, mouseX, mouseY) {
    const labels = this.chart.chart.scales['x'].getLabels();
    const labelIndex = this.calculateLabelIndex(rect, mouseX, labels.length);
    if (labelIndex < labels.length && labels[labelIndex]) {
      this.updateTooltipContent(labels[labelIndex]);
      this.updateTooltipPosition(event, rect, mouseX, mouseY);
    } else {
      this.hideTooltip();
    }
  }

  public calculateLabelIndex(rect, mouseX, labelsLength) {
    if (window.innerWidth <= 755) {
      // For mobile devices or small screens
      return Math.floor(
        ((mouseX + 2 * this.chart.chart.chartArea.top) / rect.height) *
        labelsLength
      );
    } else {
      // For desktop or larger screens
      return Math.floor(
        (mouseX /
          (this.chart.chart.chartArea.right -
            this.chart.chart.chartArea.left)) *
        labelsLength
      );
    }
  }

  public updateTooltipContent(label) {
    this.tooltipContent = this.filterProgramByName(label).content;
    this.tooltip.nativeElement.style.display = 'block';
    this.tooltip.nativeElement.classList.add('prg-tooltip');
  }

  public updateTooltipPosition(event, rect, mouseX, mouseY) {
    this.tooltipPositionX = this.calculateTooltipPositionY(mouseX);
    this.tooltipPositionY = mouseY + 150;
  }

  public calculateTooltipPositionY(mouseY) {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1366) {
      return mouseY + 50;
    } else if (screenWidth < 1600 && screenWidth > 1450) {
      return mouseY + 80;
    } else if (screenWidth < 1750 && screenWidth > 1601) {
      return mouseY + 100;
    } else if (screenWidth < 1900 && screenWidth > 1751) {
      return mouseY + 120;
    } else if (screenWidth < 2100 && screenWidth > 1901) {
      return mouseY + 140;
    } else {
      return mouseY + 220;
    }
  }

  public hideTooltip() {
    this.tooltip.nativeElement.style.display = 'none';
    this.tooltip.nativeElement.classList.remove('prg-tooltip');
  }

  public async setChartOptions() {
    this.isArrowVisible = false;
    this.isDisableRight = true;
    this.isDisableLeft = true;

    this.chartOptionXMin = studentEnrollmentChartCount.chartOptionInitXMin;
    this.chartOptionXMax = 4;
    this.updateChartData(this.chartOptionXMin, this.chartOptionXMax);
    await this.getFilteredData();
  }

  public async getFilteredData() {
    const chartOptions = JSON.parse(JSON.stringify(this.chartOption));
    let hasNonAllValue = false;

    if (
      this.filteredData != undefined &&
      this.filteredData != null &&
      Object.keys(this.filteredData).length > 0
    ) {
      hasNonAllValue = Object.values(this.filteredData).some((value) => {
        return (
          value !== 'all' &&
          !(Array.isArray(value) && value.every((item) => item === 'all'))
        );
      });
    }

    if (hasNonAllValue) {
      this.chartOptionXMin = studentEnrollmentChartCount.chartOptionInitXMin;
      this.chartOptionXMax = window.innerWidth > 500 ? 7 : 3;
      this.isArrowVisible = true;

      if (this.showCurrentyearLength - 1 <= this.chartOptionXMax) {
        this.isArrowVisible = false;
      }
      this.isDisableRight = false;
      this.isDisableLeft = true;
    } else {
      this.chartOptionXMin = studentEnrollmentChartCount.chartOptionInitXMin;
      this.chartOptionXMax = 4;
      this.isArrowVisible = false;
    }
    chartOptions.scales.x.max = this.chartOptionXMax;
    this.updateChartData(this.chartOptionXMin, this.chartOptionXMax);
    this.chartOption = chartOptions;
  }

  public async updateChartData(min: number, max: number) {
    const clonedData = JSON.parse(JSON.stringify(this.tempChartData));
    if (Array.isArray(clonedData)) {
      let newLabelSet = JSON.parse(JSON.stringify(this.tempChartLabels));
      let newDataSet = clonedData.slice(min, max + 1);
      this.chartData.labels = [...newLabelSet.slice(min, max + 1)];
      if (!!newDataSet && Array.isArray(newDataSet) && newDataSet.length > 0) {
        this.chartData.datasets[0].data = newDataSet.map(
          (x: ChartDataItems) => x.data?.Enrollment
        );
        this.chartData.datasets[1].data = newDataSet.map(
          (x: ChartDataItems) => x.data?.Applicants
        );
      }
      this.totalCounts = await this.getTotalCounts(this.chartData);
      this.chart?.chart?.update();
    }
  }

  public async getTotalCounts(jsonData: ChartData<'bar'>) {
    const length = jsonData.datasets[0].data.length;

    // Create a new array where each element is calculated as (firstValue/secondValue) * 100
    return Array.from({ length }, (_, index) => {
      const firstValue: any = jsonData.datasets[0].data[index]
        ? jsonData.datasets[0].data[index]
        : 0;
      const secondValue: any = jsonData.datasets[1].data[index]
        ? jsonData.datasets[1].data[index]
        : 0;

      // Calculate the percentage, handle nulls, and prevent division by zero
      if (firstValue !== null && secondValue !== null && secondValue !== 0) {
        return Math.round((firstValue / secondValue) * 100); // Calculate the percentage
      } else {
        return null;  // Return null if values are null or division by zero
      }
    });
  }

  public async setTempValues(data: ChartDataSet) {
    if (this.chartData.labels) {
      this.tempChartLabels = JSON.parse(JSON.stringify(this.chartData.labels));
    } else {
      this.tempChartLabels = [];
    }

    if (data?.chartData) {
      this.tempChartData = JSON.parse(JSON.stringify(data?.chartData));
    } else {
      this.tempChartData = null;
    }
  }

  public arrowRightMove() {
    if (!this.isDisableRight) {
      let hasNonAllValue = false;
      if (
        this.filteredData != undefined &&
        this.filteredData != null &&
        Object.keys(this.filteredData).length > 0
      ) {
        hasNonAllValue = Object.values(this.filteredData).some((value) => {
          return (
            value !== 'all' &&
            !(Array.isArray(value) && value.every((item) => item === 'all'))
          );
        });
      }
      let count = 7;
      if (hasNonAllValue) {
        count = window.innerWidth > 500 ? 7 : 3;
      }
      this.isDisableLeft = false;
      this.chartOptionXMin = this.chartOptionXMin + count;
      this.chartOptionXMax = this.chartOptionXMin + count;
      if (this.chartOptionXMax >= this.showCurrentyearLength - 1) {
        this.isDisableRight = true;
        this.chartOptionXMax = this.showCurrentyearLength - 1;
      }
      this.updateChartData(this.chartOptionXMin, this.chartOptionXMax);
    }
  }

  public arrowLeftMove() {
    if (!this.isDisableLeft) {
      let hasNonAllValue = false;
      if (
        this.filteredData != undefined &&
        this.filteredData != null &&
        Object.keys(this.filteredData).length > 0
      ) {
        hasNonAllValue = Object.values(this.filteredData).some((value) => {
          return (
            value !== 'all' &&
            !(Array.isArray(value) && value.every((item) => item === 'all'))
          );
        });
      }
      let count = 7;
      if (hasNonAllValue) {
        count = window.innerWidth > 500 ? 7 : 3;
      }
      this.chartOptionXMax = this.chartOptionXMin;
      this.chartOptionXMin = this.chartOptionXMax - count;
      this.isDisableRight = false;

      if (this.chartOptionXMin <= 0) {
        this.isDisableLeft = true;
        this.chartOptionXMin = 0;
        this.chartOptionXMax = count;
      }
      this.updateChartData(this.chartOptionXMin, this.chartOptionXMax);
    }
  }
}
