import { Injectable } from '@angular/core';
import {
  DiversityAgeHeaders,
  DiversityFilter,
  DiversityEthinicityHeaders,
  DiversityGenderHeaders,
  DiversityRaceHeaders,
  GraphColor,
  ChartType,
} from '../../enum/shared.enum';
import {
  Diversity,
  TableHeader,
  AlumniDiversityProgram,
  Alumni,
  AlumniDiversity,
  ApplicantsToEnrollment,
  StudentProgress,
} from '../../../components/model/customtypes-model.interface';
import { DiversityInfo } from '../../entity/shared.entity';
import {
  DiversityHeaderTypes,
  DiversityType,
} from '../../class-type/chart-type';

@Injectable({
  providedIn: 'root',
})
export class ProgramDiversityService {
  public predefinedPgms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];
  public clinicalPredefinedPgms = ['BSN', 'RNMSN', 'MSN', 'DNP'];

  public async createProgramDiversityTableInfo(
    chartType: string,
    sessionData: AlumniDiversity[]
  ): Promise<Diversity[]> {
    let tableData: Diversity[] = [];

    for (let filterType of Object.values(DiversityFilter)) {
      switch (filterType) {
        case DiversityFilter.ethnicity: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityEthinicityHeaders,
            'ethnicity'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.race: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityRaceHeaders,
            'race'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.gender: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityGenderHeaders,
            'gender'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.age: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityAgeHeaders,
            'age'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        default:
          this.handleDefaultCase();
      }
    }

    return tableData;
  }

  public async createTableHeaders(
    tableHeaders: DiversityHeaderTypes
  ): Promise<TableHeader[]> {
    let headerLabels: TableHeader[] = [];

    const raceHeaderArray = Object.keys(tableHeaders).map((key, index) => ({
      name: tableHeaders[key],
      value: key,
      color: GraphColor[index],
    }));

    headerLabels = [...raceHeaderArray];
    return headerLabels;
  }

  public async createDiversityInfo(
    diversityType: DiversityFilter,
    chartType: string,
    sessionData: AlumniDiversity[],
    headers: DiversityHeaderTypes,
    objToFid: DiversityType
  ): Promise<DiversityInfo> {
    let diversityInfo = new DiversityInfo();
    let tableHeaderInfo = await this.createTableHeaders(headers);

    let tableDataInfo = await this.createProgramDiversityData(
      sessionData,
      tableHeaderInfo,
      objToFid,
      chartType
    );

    diversityInfo.composeDiversityData(
      diversityType,
      tableDataInfo,
      tableHeaderInfo,
      chartType
    );
    return diversityInfo;
  }

  public async createProgramDiversityData(
    data: AlumniDiversity[],
    tableHeaderInfo: TableHeader[],
    objToFid: DiversityType,
    chartType
  ) {
    let programObj = await this.getProgramObjectWithTableHeader(
      tableHeaderInfo,
      chartType
    );

    data.forEach((entry) => {
      let { program } = entry;

      program =
        (chartType === 'Clinical Placements' &&
          !this.clinicalPredefinedPgms.includes(program)) ||
        (chartType !== 'Clinical Placements' &&
          !this.predefinedPgms.includes(program))
          ? 'Other'
          : program;
      tableHeaderInfo.forEach(({ value }) => {
        if (value !== 'diversityName' && value !== 'totalCount') {
          programObj[program][value] +=
            entry.diversityDetails?.[objToFid]?.[value] || 0;
        }
      });

      programObj[program].totalCount = Object.entries(
        programObj[program]
      ).reduce((r, [k, v]) => {
        if (k != 'diversityName' && k != 'totalCount') {
          r += programObj[program][k];
        }
        return r;
      }, 0);
    });

    return Object.values(programObj);
  }

  public async createApplicatsToEnrollmentDiversityDetails(
    data: ApplicantsToEnrollment[]
  ) {
    return this.aggregateDiversityDetails(
      data,
      (obj) => obj.ApplicantsToEnrollment,
      (existingProgram, curr) => {
        existingProgram.programCount.Enrollment += curr.programCount.Enrollment;
        existingProgram.programCount.Applicants += curr.programCount.Applicants;
      }
    );
  }

  public async createStudentProgressDiversityDetails(data: StudentProgress[]) {
    return this.aggregateDiversityDetails(
      data,
      (obj) => obj.programEnrollments
    );
  }

  public async createAlumniDiversityDetails(data: Alumni[]) {
    return this.aggregateDiversityDetails(
      data,
      (obj) => obj.programYear[0].diversityProgram
    );
  }

  public async createClinicalDiversityDetails(data: any[]) {
    return this.aggregateDiversityDetails(
      data,
      (obj) => obj.programLocation[0].diversityProgram
    );
  }

  public async aggregateDiversityDetails(
    data: any[],
    extractor: (obj: any) => any[],
    additionalProcessing?: (existingProgram: any, curr: any) => void
  ) {
    return data.flatMap(extractor).reduce((acc, curr) => {
      const existingProgram = this.findExistingProgram(acc, curr);

      if (existingProgram) {
        additionalProcessing?.(existingProgram, curr);
        this.updateDiversityDetails(
          existingProgram.diversityDetails,
          curr.diversityDetails
        );
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  }

  public findExistingProgram(acc: any[], curr: any) {
    return acc.find((item) => item.program === curr.program);
  }

  public updateDiversityDetails(targetDetails, sourceDetails) {
    for (const key in sourceDetails) {
      if (Object.hasOwnProperty.call(sourceDetails, key)) {
        const element = sourceDetails[key];
        this.updateElementDetails(targetDetails[key], element);
      }
    }
  }

  public updateElementDetails(targetElement, sourceElement) {
    for (const subKey in sourceElement) {
      if (Object.hasOwnProperty.call(sourceElement, subKey)) {
        const value = sourceElement[subKey];
        targetElement[subKey] += value;
      }
    }
  }

  public async getProgramObjectWithTableHeader(
    tableHeaderInfo: TableHeader[],
    chartType: ChartType
  ) {
    let keysToAdd = {};
    tableHeaderInfo.forEach((header) => {
      if (header.value !== 'diversityName') {
        keysToAdd[header.value] = 0;
      }
    });
    let result = {};

    switch (chartType) {
      case ChartType.alumni:
      case ChartType.applicantsToEnrollment:
      case ChartType.studentProgress: {
        result = {
          RNBSN: {
            diversityName: 'RNBSN',
            ...keysToAdd,
          },
          RNMSN: {
            diversityName: 'RNMSN',
            ...keysToAdd,
          },
          MSN: {
            diversityName: 'MSN',
            ...keysToAdd,
          },
          DNP: {
            diversityName: 'DNP',
            ...keysToAdd,
          },
          Other: {
            diversityName: 'Other',
            ...keysToAdd,
          },
        };
        break;
      }
      case ChartType.clinicalInsight: {
        result = {
          BSN: {
            diversityName: 'BSN',
            ...keysToAdd,
          },
          RNMSN: {
            diversityName: 'RNMSN',
            ...keysToAdd,
          },
          MSN: {
            diversityName: 'MSN',
            ...keysToAdd,
          },
          DNP: {
            diversityName: 'DNP',
            ...keysToAdd,
          },
          Other: {
            diversityName: 'Other',
            ...keysToAdd,
          },
        };
        break;
      }
      default:
        this.handleDefaultCase();
    }
    return result;
  }

  public async createAllAlumniDiversityDetails(data: AlumniDiversityProgram[]) {
    if (!data || data.length <= 0) {
      return [];
    }

    let diversityInfo = data.reduce((acc, { diversityProgram }) => {
      diversityProgram?.forEach(({ program, diversityDetails }) => {
        if (!acc[program]) {
          acc[program] = { program, diversityDetails: {} };
        }
        Object.entries(diversityDetails).forEach(
          ([category, subcategories]) => {
            if (!acc[program].diversityDetails[category]) {
              acc[program].diversityDetails[category] = {};
            }
            Object.entries(subcategories).forEach(([subcategory, count]) => {
              acc[program].diversityDetails[category][subcategory] =
                (acc[program].diversityDetails[category][subcategory] || 0) +
                count;
            });
          }
        );
      });
      return acc;
    }, {});

    return Object.values(diversityInfo);
  }

  public async createAllClinicalDiversityDetails(data: any[]) {
    if (!data || data.length <= 0) {
      return [];
    }

    let diversityInfo = data.reduce((acc, { diversityProgram }) => {
      diversityProgram?.forEach(({ program, diversityDetails }) => {
        if (!acc[program]) {
          acc[program] = { program, diversityDetails: {} };
        }
        Object.entries(diversityDetails).forEach(
          ([category, subcategories]) => {
            if (!acc[program].diversityDetails[category]) {
              acc[program].diversityDetails[category] = {};
            }
            Object.entries(subcategories).forEach(([subcategory, count]) => {
              acc[program].diversityDetails[category][subcategory] =
                (acc[program].diversityDetails[category][subcategory] || 0) +
                count;
            });
          }
        );
      });
      return acc;
    }, {});
    return Object.values(diversityInfo);
  }

  public handleDefaultCase() {
    return [];
  }
}
