import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import {
  ProviderData,
  NavItem,
  PopupData,
} from '../../shared/components/model/customtypes-model.interface';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { DescriptionTabTooltip } from '../../shared/services/enum/shared.enum';
import { AnalyticsService } from '../../shared/services/analytics/analytics.service';
import { ApiService } from '../../shared/services/api.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewInit, OnDestroy, OnInit {
  public navItems: NavItem[] = [
    {
      route: `/dashboard/learner-insights/${this.sharedService.getOrgId()}`,
      key: 'learner-insights',
      name: 'Learner Insights',
      content: DescriptionTabTooltip.partnerDashboard,
      isVisible: true,
      isClinicalVisible: true
    },
    {
      route: `/dashboard/clinical-insights/${this.sharedService.getOrgId()}`,
      key: 'clinical-insights',
      name: 'Clinical Insights',
      content: '',
      isVisible: true,
      isClinicalVisible: false
    },
    {
      route: '/dashboard/partnership-overview',
      key: 'partnership-overview',
      name: 'Partnership Overview',
      content: DescriptionTabTooltip.partnershipOverview,
      isVisible: true,
      isClinicalVisible: true
    },
    {
      route: '/dashboard/lad-information-materials',
      key: 'lad-information-materials',
      name: 'L&D Information Materials',
      content: '',
      isVisible: true,
      isClinicalVisible: true
    },
    {
      route: '/dashboard/admin/org-list',
      key: 'admin',
      name: 'Admin',
      content: '',
      isVisible: false,
      isClinicalVisible: true
    },
    {
      route: '/dashboard/admin/clinical-status',
      key: 'clinicalstatus',
      name: 'Clinical Status',
      content: '',
      isVisible: false,
      isClinicalVisible: true
    }
  ];

  public currentScroll: number;
  public floatingNavHide = true;
  public headerActive: boolean = false;
  public headerAreaHeight = 0;
  public loading = true;
  public popupData: PopupData;
  public subscriptionRefArray: Subscription[] = [];
  public productTourActive: boolean;
  public exportClicked: boolean;
  public disableExport = false;
  public exportRemoval = [
    '/dashboard/partnership-overview',
    '/dashboard/lad-information-materials',
    '/dashboard/clinical-insights',
  ];
  public exportVisible = true;
  public isDisableScroll: boolean = true;
  public fiscalYearActive: boolean;
  public partnershipInfo: any[];

  public zoomLevel: number = 1;

  @ViewChild(NgScrollbar) public scrollbarRef: NgScrollbar;
  @ViewChild('top') public scrollableEl: ElementRef;
  public fireBaseStorageRef: any;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    public sharedService: SharedService,
    public router: Router,
    private readonly auth: AuthService,
    private readonly analytics: AngularFireAnalytics,
    public analyticsService: AnalyticsService,
    private readonly apiService: ApiService,
    private readonly storage: AngularFireStorage,
    private readonly http: HttpClient,
  ) { }

  public async initAnalytics(): Promise<void> {
    await this.analytics.setCurrentScreen(window.location.pathname);
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '=' || event.key === '-' || event.key === '_')) {
      switch (event.key) {
        case '+':
        case '=':
          this.zoomLevel = Math.round(window.devicePixelRatio * 100);
          if (this.zoomLevel >= 175) {
            event.preventDefault();
          }
          break;
        case '-':
        case '_':
          this.zoomLevel = Math.round(window.devicePixelRatio * 100);
          if (this.zoomLevel <= 50) {
            event.preventDefault();
          }
          break;
        default:
      }
    }
  }

  // after page init then set to scroll top and bottom,depend on condition
  public ngAfterViewInit(): void {
    this.initAnalytics();
    this.headerAreaHeight = window.innerHeight - 120;
    this.subscriptionRefArray.push(
      this.scrollbarRef.scrolled.subscribe((e) => {
        this.currentScroll = e.target.scrollTop;
        if (this.currentScroll >= 20) {
          this.sharedService.isNgScrollReachedMiddle.next(true);
        } else {
          this.sharedService.isNgScrollReachedMiddle.next(false);
        }
        if (
          e.target.scrollTop + e.target.clientHeight >=
          e.target.scrollHeight
        ) {
          this.sharedService.isNgScrollReachedBottom.next(true);
        } else {
          this.sharedService.isNgScrollReachedBottom.next(false);
        }
      })
    );
  }
  public async ngOnInit() {
    const userDetailId: any = localStorage.getItem('userDetailId');
    const Id = userDetailId ? JSON.parse(userDetailId)[0]?.Account?.Id : null;
    const partnerInfo = localStorage.getItem('partnerInfo');
    if (partnerInfo) {
      this.partnershipInfo = await this.filterPartnershipInfo(
        JSON.parse(partnerInfo)
      )
      this.checkClinicalInsights(this.partnershipInfo)
    } else {
      this.apiService
        .getOverviewObservables(Id)
        .subscribe(async (data: any) => {
          localStorage.setItem('partnerInfo', JSON.stringify(data))
          this.partnershipInfo = await this.filterPartnershipInfo(data)
          this.checkClinicalInsights(this.partnershipInfo)
        });
    }
    const state: RouterState = this.router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;
    this.exportVisible = !this.exportRemoval.find(
      (url) => url === snapshot.url
    );
    this.auth.afAuth.authState.subscribe((data: ProviderData) => {
      this.loading = !data;
    });
    this.sharedService.FloatingMenuEvent$.subscribe((NavVal: boolean) => {
      this.floatingNavHide = NavVal;
    });
    this.sharedService.exportClicked$.subscribe((NavVal: boolean) => {
      this.exportClicked = NavVal;
    });

    this.sharedService.isNgScrollReachedMiddle$.subscribe((x: boolean) => {
      this.headerActive = x;
    });

    this.sharedService.exportDisabled$.subscribe((NavVal: boolean) => {
      this.disableExport = NavVal;
    });

    this.sharedService.openOtherProgramMob$.subscribe((isOpen: boolean) => {
      this.isDisableScroll = !isOpen;
    });
    if (!this.router.url.includes('faq')) {
      this.sharedService.faqMob.next(false);
    }
    setTimeout(() => {
      if (!sessionStorage.getItem('showTour')) {
        this.productTourActive = true;
      }
    }, 45000);
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      if (format === true) {
        this.fiscalYearActive = true;
      }
    });

    (await this.analyticsService.getAnalyticsData()).subscribe((analytics) => {
      this.analyticsService.uploadJsonToFirebase(analytics, 'analytics-data');
    });
    const orgAnalytics = await this.analyticsService.getAnalytics(
      'organization',
      'organization-user'
    );
    if (orgAnalytics.exists) {
      this.analyticsService.uploadOrgUsersToFirebase(
        orgAnalytics.data(),
        'organization_users'
      );
    }
  }

  public async filterPartnershipInfo(data: any): Promise<any[]> {
    return data[0].items
      .filter((items: any) => items.contentNodes?.excerpt?.value === 'Logo')
      .map((items: any) => ({
        ...items,
        status: data[1].contractList?.filter(
          (x: any) =>
            x.RecordType.Name.split(' ')[0] === 'CU' && x.Stage__c === 'Active'
        ),
      }));
  }

  public async checkClinicalInsights(data: any) {
    const univName = data.find(
      university => university.title == 'Chamberlain University'
    );
    if (univName != null && univName != undefined) {
      const clinicalAgreement = univName.status?.filter((x: any) =>
        x.RecordType.Name.includes('CU Clinical Agreement')
      );
      const clinicalStatus: any = await this.getClinicalStatus();
      const orgName = localStorage.getItem('organization');
      const status = clinicalStatus[orgName]?.status;
      this.navItems.forEach((item: NavItem) => {
        if (item.name === 'Clinical Insights') {
          if (clinicalAgreement.length) {
            switch (status) {
              case true:
                item.isClinicalVisible = true;
                break;
              case false:
                item.isClinicalVisible = false;
                break;
              default:
                item.isClinicalVisible = true;
            }
          } else {
            item.isClinicalVisible = status ? status : false;
          }
        }
      });
    }
  }

  // remove fitler data from session storage
  public ngOnDestroy() {
    if (this.subscriptionRefArray.length) {
      this.subscriptionRefArray.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }

  public async getStatusData() {
    let res: any;
    const filePath =  'partner-portal/clinical-status';
    this.fireBaseStorageRef = this.storage.ref(`${filePath}`);
    const fileRef = `clinical-status.json`;
    const objectRef = this.fireBaseStorageRef?.child(`${fileRef}`);
    await objectRef
      .getDownloadURL()
      .toPromise()
      .then(async (url) => {
        const parsedUrl = new URL(url);
        const fileUrl = parsedUrl.href;
        res = await this.http.get(fileUrl).toPromise();
      }).catch(err => res = '');
    return res;
  }

  public async getClinicalStatus() {
    const orgName = localStorage.getItem('organization');
    const status = await this.getStatusData();
    const filteredEntries = Object.entries(status).filter(([key]) => key === orgName);
    return Object.fromEntries(filteredEntries);
  }
}
