import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ViewChild,
  TemplateRef,
  Inject,
  ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { ScrollbarTrack } from 'ngx-scrollbar';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
})
export class PopupModalComponent {
  @ViewChild('popupContainer') public popupContainer: TemplateRef<any>;
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public url: string;
  @Input() public scrollDirection: ScrollbarTrack;
  @ViewChild('modalBox') public modalBox: ElementRef;
  @ViewChild('modalFade') public modalFade: ElementRef;
  public modalOpen = false;
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  @Output() public closeModal: EventEmitter<boolean> = new EventEmitter();
  private readonly viewContainerRef: ViewContainerRef;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly sharedService: SharedService,
    viewContainerRef: ViewContainerRef
  ) {
    this.viewContainerRef = viewContainerRef;
  }
  @Input() set openModal(data: boolean) {
    this.modalOpen = data;
    if (this.modalOpen) {
      // Render the TemplateRef as a SIBLING to THIS component.
      const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
        this.popupContainer
      );
      embeddedViewRef.detectChanges();
      for (const node of embeddedViewRef.rootNodes) {
        this.document.body.appendChild(node);
      }
    } else {
      this.close('close');
    }
  }

  @HostListener('click', ['$event'])
  public onClick(event: any) {
    event.stopPropagation();
    if (event.target === this.modalFade.nativeElement) {
      this.modalOpen = false;
      this.closeModal.emit(this.modalOpen);
    }
  }

  public close(type: string) {
    if (type === 'close') {
      this.modalOpen = false;
      this.closeModal.emit(this.modalOpen);
      const popup: any = this.document.querySelector('.modal-fade');
      this.sharedService.FloatingMenuEvent.next(true);
      popup?.remove();
      this.sharedService.menuBarEvent.next(false);
    } else {
      this.modalOpen = false;
      const popup: any = this.document.querySelector('.modal-fade');
      popup?.remove();
    }
  }
}
