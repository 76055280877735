<div class="chart-main-wrap">
  <div *ngIf="isActive" class="pe-0 ps-0 side-data">
    <div class="conversion-wrap">
      <span
        >Employees who have started classes as a percentage of employees who
        have applied (started or submitted an application)</span
      >
      <p>Conversion Rate</p>
    </div>
    <ul class="side-data-inner-wrap">
      <li *ngFor="let data of totalCounts">
        <p class="data-percent">{{ data ? data : 0 }}%</p>
      </li>
    </ul>
  </div>
  <div *ngIf="!isActive" class="pe-0 ps-0 side-data">
    <div class="conversion-wrap">
      <span
        >Employees who have started classes as a percentage of employees who
        have applied (started or submitted an application)</span
      >
      <p>Conversion Rate</p>
    </div>
    <ul class="side-data-inner-wrap">
      <li *ngFor="let data of emptyFilter">
        <p class="data-percent">{{ data.Total }}%</p>
      </li>
    </ul>
  </div>
  <div class="chart-data-main">
    <canvas
      #canvasEl
      [data]="chartData"
      [plugins]="chartPlugin"
      [options]="chartOption"
      [type]="chartType"
      baseChart
    >
    </canvas>
    <div class="arrow-wrapper" *ngIf="isArrowVisible">
      <div
        class="arrow arrow-left"
        (click)="arrowLeftMove()"
        [ngClass]="{ disableLeft: isDisableLeft }"
        (keydown)="arrowLeftMove()"
      >
        <span class="icon-Left-Arrow-1"
          ><span class="path1"></span><span class="path2"></span
        ></span>
      </div>
      <div
        class="arrow arrow-right"
        (click)="arrowRightMove()"
        [ngClass]="{ disableRight: isDisableRight }"
        (keydown)="arrowRightMove()"
      >
        <span class="icon-Right-Arrow-3"
          ><span class="path1"></span><span class="path2"></span
        ></span>
      </div>
    </div>
    <div
      class="question-align pointer-cursor margin-left-10 question-icon-container"
      (click)="openOtherProgramPopup()"
      *ngIf="!isSelectedAll"
      (keydown)="openOtherProgramPopup()"
    >
      <span class="question-icon-wraper icon-Others-Icon"> </span>
    </div>
  </div>
</div>
<div
  #tooltip
  class="tooltip"
  [style.left.px]="tooltipPositionX"
  [style.top.px]="tooltipPositionY"
  [ngClass]="{ disableTooltip: isSelectedAll }"
>
  {{ tooltipContent }}
</div>
