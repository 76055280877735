import {
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewChild,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { DiversityInfo } from '../../services/entity/shared.entity';
import { ChartType, DiversityFilter } from '../../services/enum/shared.enum';
import { Diversity } from '../model/customtypes-model.interface';
import { DiversityService } from '../../services/diversity/diversity.service';
import { Subscription } from 'rxjs';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-diversity-modal',
  templateUrl: './diversity-modal.component.html',
  styleUrls: ['./diversity-modal.component.scss'],
})
export class DiversityModalComponent implements OnInit, OnDestroy {
  @ViewChild('diversityDetailsTemp')
  public diversityDetailsTemp: TemplateRef<any>;
  @ViewChild('diversityDetailsMobileTemp')
  public diversityDetailsMobileTemp: TemplateRef<any>;
  @Input() public loading = false;
  @Input() public chartType: ChartType;
  @Input() public diversityInfo: any[] = [];
  @Input() public graphTitle: string;
  public currentWindowWidth: number;
  public showDiversityDetails: boolean = false;
  public viewContainerRef: ViewContainerRef;
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  public dataInfo: Diversity[] = [];
  public filterItems: DiversityFilter[] = [];
  public activeTab: DiversityFilter = DiversityFilter.ethnicity;
  public diversityData: DiversityInfo = new DiversityInfo();
  public showMobileDiversityDetails: boolean = false;
  public subscriptionRefArray: Subscription[] = [];
  @ViewChild(BaseChartDirective)
  public chart?: BaseChartDirective;
  public colors = ['#2A79D9'];
  public chartTypes: ChartConfiguration<'bar'>['type'] = 'bar';
  public chartOption: any = {
    animation: false,
    layout: {
      padding: {
        top: 25,
      },
    },
    maintainAspectRatio: window.innerWidth > 490,
    plugins: {
      align: 'start',
      border: false,
      datalabels: {
        color: '#526289',
        anchor: 'end',
        align: 'end',
        font: {
          size: 12,
          weight: 'normal',
        },
      },
      legend: {
        display: false,
        labels: {
          boxHeight: window.innerWidth > 700 ? 15 : 10,
          boxWidth: window.innerWidth > 700 ? 15 : 10,
          pointStyle: 'rectRounded',
          color: '#526289',
          textAlign: 'center',
          usePointStyle: true,
        },
        padding: {
          bottom: 20,
        },
        position: 'top',
      },
      text: false,
      text1: false,
      doughnutLabelsLine: false,
      customCanvasBackgroundColor: false,
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        display: false,
        border: {
          borderDash: [1, 1],
          color: '#1F356C',
          display: true,
          width: 1,
        },
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          color: '#1F356C',
          font: {
            weight: 'bold',
          },
        },
      },
      y: {
        border: {
          dash: [2, 4],
          display: false,
        },
        suggestedMax: 10,
        ticks: {
          color: '#1F356C',
          font: {
            weight: 'bold',
          },
          maxTicksLimit: 10,
          stepSize: 10,
        },
        title: {
          align: 'start',
          color: '#526289',
          display: true,
          text: 'No.of Students',
        },
      },
    },
  };
  public canvasInfo = [];

  constructor(
    public sharedService: SharedService,
    public diversityService: DiversityService,
    @Inject(DOCUMENT) public document: Document,
    viewContainerRef: ViewContainerRef,
    private readonly ngZone: NgZone
  ) {
    this.viewContainerRef = viewContainerRef;
  }

  public async ngOnInit() {
    this.filterItems = await this.diversityService.getDiversityFilter();
    this.subscriptionRefArray.push(
      this.sharedService.diversityDetails$.subscribe(async (data: boolean) => {
        this.checkTheScreenWidth(data);
      })
    );
  }

  public closePopup() {
    this.showDiversityDetails = false;
    this.sharedService.diversityDetails.next(false);
    const dropdown: any = this.document.querySelector(
      '.diversity-details-wraper'
    );
    dropdown?.remove();
    this.sharedService.otherProgramData.next({});
    this.viewContainerRef.clear();
    this.unSubscribeSubscription();
  }

  public async setActiveTab(tabName: DiversityFilter) {
    this.activeTab = tabName;
    await this.getActiveTabData(tabName);
  }

  public async getActiveTabData(activeTab: DiversityFilter) {
    if (this.dataInfo?.length) {
      let diversityData = this.dataInfo.find((item) => item.name == activeTab);

      if (diversityData) {
        this.diversityData = diversityData.data;
        this.canvasInfo = [];
        await this.getChartData();

        if (this.showMobileDiversityDetails) {
          this.diversityData.labelInfo = await this.formatMobileResponse();
        }
      }
    }
  }

  public onClickPopupSubWrapper(e: Event) {
    e.stopPropagation();
  }

  public onClickPopupWrapper() {
    this.closePopup();
  }

  public async createProgramView() {
    const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
      this.diversityDetailsMobileTemp
    );

    if (embeddedViewRef) {
      for (const node of embeddedViewRef.rootNodes) {
        this.document.body.appendChild(node);
      }
    }
  }

  public async getStorageData() {
    this.subscriptionRefArray.push(
      this.sharedService.otherProgramData$.subscribe(async (diversityInfo) => {
        if (diversityInfo && Object.keys(diversityInfo).length) {
          this.dataInfo = diversityInfo['data'];
          await this.getActiveTabData(this.activeTab);
        }
      })
    );
  }

  public async formatMobileResponse() {
    this.diversityData.labelInfo = this.diversityData.labelInfo.filter(
      (label) => label.value != 'diversityName'
    );

    return this.diversityData.labelInfo.map((obj) => ({
      ...obj,
      name: obj.name.replace(/<br>/g, ''),
    }));
  }

  public async onOpenDiversity(event: boolean) {
    this.checkTheScreenWidth(event);

    this.ngZone.run(async () => {
      if (this.showDiversityDetails) {
        this.viewContainerRef.clear();

        if (!document.getElementById('diversityModal')) {
          const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
            this.diversityDetailsTemp
          );

          if (embeddedViewRef != null) {
            for (const node of embeddedViewRef.rootNodes) {
              this.document.body.appendChild(node);
            }
          }

          await this.getStorageData();
        }
      } else if (this.showMobileDiversityDetails) {
        if (!document.getElementById('diversityCard')) {
          await this.getStorageData();
          await this.createProgramView();
        }
      }
    });
  }

  public async checkTheScreenWidth(isOpen: boolean) {
    this.dataInfo = [];
    this.activeTab = DiversityFilter.ethnicity;

    if (window.innerWidth < 575) {
      this.showMobileDiversityDetails = isOpen;
    } else {
      this.showDiversityDetails = isOpen;
    }
  }

  public ngOnDestroy(): void {
    this.unSubscribeSubscription();
  }

  public unSubscribeSubscription() {
    this.subscriptionRefArray.forEach((s) => s.unsubscribe());
  }

  public async getChartData() {
    setTimeout(() => {
      this.canvasInfo = [];
      let labelInfo = this.diversityData.labelInfo.slice(
        0,
        this.diversityData.labelInfo.length - 1
      );
      this.diversityData.diversityInfo.forEach((diversity, index) => {
        let dataSetList = [];
        let labelList = [];
        let labelObject = {
          backgroundColor: [],
          barThickness: 15,
          borderColor: 'white',
          borderWidth: 1,
          hoverBorderColor: 'white',
          hoverBackgroundColor: [],
          label: '',
          data: [],
        };
        labelInfo.forEach((label) => {
          const diversityValue = diversity[label.value]; 
          labelObject.data.push(
            (diversityValue === 0 ? null : diversityValue)
          );
          labelObject.backgroundColor.push(label.color);
          labelObject.hoverBackgroundColor.push(label.color);
          dataSetList.push(labelObject);
          labelList.push(label);
        });
        let canvasInfo = { name: '', totalCount: 0 };
        canvasInfo.name = diversity.diversityName;
        canvasInfo.totalCount = diversity.totalCount;
        Object.assign(canvasInfo, {
          data: { datasets: dataSetList, labels: labelList },
        });
        this.canvasInfo.push(canvasInfo);
      });
      this.chart?.chart?.update();
    });
  }

  public diversityTrack(rowData) {
    this.chart?.chart?.update();
    return rowData.data;
  }
}
