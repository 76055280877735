<app-pp-button
  #button
  [loading]="loading"
  label="Filter"
  (click)="openPopup($event)"
>
</app-pp-button>
<ng-template #filterDropdown>
  <div class="filter-card card shadow-lg reusable">
    <div class="popupHead">
      <div class="container title-container">
        <div class="d-flex flex-row">
          <div class="popupHead-title">
            <h5 class="filterHead">{{ title }}</h5>
            <p>Filter</p>
          </div>
        </div>
        <div class="popup-close-wrap">
          <button type="button" value (click)="popClose()">
            <span class="closetag">closetag</span>
            <img alt="close" [src]="closeButtonUrl" />
          </button>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="hospitalSearch"></ng-container>
      <div class="contant-container">
        <div>
          <div *ngIf="filterData" class="filterSubHead">
            <ng-container *ngFor="let filter of filterData; let i = index">
              <div class="mob-filterItems">
                <div class="tooltip-wrap">
                  <small *ngIf="filter?.tooltip">{{ filter?.tooltip }}</small>
                  <label for="filter">{{
                    filter?.filterLabel.replace("_", " ")
                  }}</label>
                </div>
                <div *ngIf="filter.filterLabel !== 'Modality'">
                  <ng-select
                    [items]="filter?.filterItems | alphasort"
                    [clearable]="false"
                    (change)="getFieldValuesFromMobile($event)"
                    [(ngModel)]="filter.selected"
                    (click)="openMobileFilterPopup(i)"
                    (keydown)="openMobileFilterPopup(i)"
                  ></ng-select>
                </div>
                <div *ngIf="filter.filterLabel === 'Modality'">
                  <ng-select
                    [items]="filter?.filterItems"
                    [clearable]="false"
                    (change)="getFieldValuesFromMobile($event)"
                    [(ngModel)]="filter.selected"
                    (click)="openMobileFilterPopup(i)"
                    (keydown)="openMobileFilterPopup(i)"
                  ></ng-select>
                </div>
                <span
                  *ngIf="
                    filteredItems[i]?.length > 1 &&
                    filteredItems[i] != 'all' &&
                    filter.selected !== 'all'
                  "
                  >{{
                    filteredItems[i]?.length + " " + filter?.filterLabel + "s"
                  }}</span
                >
                <span
                  *ngIf="
                    filteredItems[i]?.length <= 1 &&
                    filteredItems[i] != 'all' &&
                    filter.selected !== 'all'
                  "
                  >{{ filteredItems[i] }}</span
                >
                <span class="all-option" *ngIf="filteredItems[i] == 'all'">{{
                  filteredItems[i]
                }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="bottom-container">
        <button class="reset" (click)="closePopup()">Reset</button>
        <button (click)="applyFilters()" class="apply">Apply</button>
      </div>
    </div>
    <ng-container *ngIf="calenderShowTrue">
      <div class="calender-wrap">
        <app-date-picker [startYear]="initialStartYear"></app-date-picker>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-container #defaultFilterComp></ng-container>
<!-- <ng-template #customAppend> -->
<div
  *ngIf="showPopup && this.currentWindowWidth >= 500"
  #filterWrapper
  class="filter-card card reusable"
  [ngClass]="{ clinicalFilter: title.includes('Clinical') }"
>
  <div class="popupHead">
    <div class="container p-0">
      <div class="w-100 align-items-center d-flex justify-content-end flex-row">
        <div class="me-auto">
          <h5 title="{{ title }}" class="filterHead">
            {{ title }} <span *ngIf="!title.includes('Clinical')">filter</span>
          </h5>
        </div>
        <ng-container *ngTemplateOutlet="hospitalSearch"></ng-container>
        <div class="border-start-p">
          <button class="reset" (click)="closePopup()">Reset</button>
          <button (click)="applyFilters()" class="apply">Apply</button>
        </div>
      </div>
    </div>
    <div class="container1">
      <div>
        <div
          *ngIf="filterData"
          class="d-flex flex-row filterSubHead"
          [ngClass]="{ clinicalSubHead: title.includes('Clinical') }"
        >
          <ng-container *ngFor="let filter of filterData; let i = index">
            <div [class]="filter.column" class="ps-2 pe-2 filterItems">
              <div class="tooltip-wrap">
                <small *ngIf="filter?.tooltip">{{ filter?.tooltip }}</small>
                <h5>
                  {{
                    filter?.filterLabel === "clinicalSite"
                      ? "Clinical Site"
                      : filter?.filterLabel.replace("_", " ")
                  }}
                </h5>
              </div>
              <hr />
              <div
                class="scroll-container"
                [ngClass]="{
                  'alpha-border': filter?.filterLabel === 'Hospital'
                }"
              >
                <app-radio
                  [groupByAlphabet]="alphaGroup === filter?.filterLabel"
                  [activeAlphaChar]="clickedValue"
                  [filterLabel]="filter?.filterLabel"
                  [styleClass]="'cursor-pointer pb-3 mb-3'"
                  [(ngModel)]="filter.selected"
                  (change)="resetFilter(filter)"
                  [value]="filter.selected"
                  (activeAlphaButton)="makeActiveAlphaBtn($event)"
                  [options]="
                    iterateOptions(filter?.filterItems, filter?.filterLabel)
                  "
                  [filterData]="filter.selected"
                ></app-radio>
                <div
                  class="alpha-filter"
                  *ngIf="
                    filter?.filterLabel === 'Hospital' &&
                    title === 'Learner Analytics'
                  "
                >
                  <button
                    type="button"
                    [ngClass]="{ 'alpha-selection': clickedValue === 'A-D' }"
                    class="button"
                    (click)="alphaFilter('A-D')"
                  >
                    A-D
                  </button>
                  <button
                    type="button"
                    [ngClass]="{ 'alpha-selection': clickedValue === 'E-I' }"
                    class="button"
                    (click)="alphaFilter('E-I')"
                  >
                    E-I
                  </button>
                  <button
                    type="button"
                    [ngClass]="{ 'alpha-selection': clickedValue === 'J-O' }"
                    class="button"
                    (click)="alphaFilter('J-O')"
                  >
                    J-O
                  </button>
                  <button
                    type="button"
                    [ngClass]="{ 'alpha-selection': clickedValue === 'P-T' }"
                    class="button"
                    (click)="alphaFilter('P-T')"
                  >
                    P-T
                  </button>
                  <button
                    type="button"
                    [ngClass]="{ 'alpha-selection': clickedValue === 'U-Z' }"
                    class="button"
                    (click)="alphaFilter('U-Z')"
                  >
                    U-Z
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="title.includes('Clinical') && calenderShowTrue">
    <div class="calender-wrap">
      <app-date-picker [startYear]="initialStartYear"></app-date-picker>
    </div>
  </ng-container>
</div>
<ng-template #hospitalMobilefilter>
  <div
    class="hospital-mobile-popup"
    *ngIf="showMobileFilterPopup"
    [ngClass]="{ popUpHide: mobilePopupClose }"
  >
    <div class="hospital-mobile-popup-sub-wrap">
      <div class="modal-header">
        <button
          (click)="
            mobileHospitalSelection(filterData[indexVal].selected, indexVal)
          "
        >
          <img alt="close" [src]="closeButtonUrl" />
        </button>
      </div>
      <h4>Select {{ filterLabel }}</h4>
      <div
        *ngIf="filterData[indexVal] != null"
        [class]="filterData.column"
        class="ps-2 pe-2 filterItems"
      >
        <div
          class="scroll-container"
          [ngClass]="{ 'alpha-border': filterLabel === 'Hospital' }"
        >
          <app-radio
            [groupByAlphabet]="
              alphaGroup === filterLabel && filterLabel !== 'Modality'
            "
            [activeAlphaChar]="clickedValue"
            [filterLabel]="filterLabel"
            [styleClass]="'cursor-pointer pb-3 mb-3'"
            [(ngModel)]="filterData[indexVal].selected"
            (change)="resetFilter(filterData[indexVal])"
            [value]="filterData[indexVal].selected"
            (activeAlphaButton)="makeActiveAlphaBtn($event)"
            [options]="
              iterateOptions(filterData[indexVal].filterItems, filterLabel)
            "
            [filterData]="filterData[indexVal].selected"
          ></app-radio>
          <div class="alpha-filter" *ngIf="filterLabel === 'Hospital'">
            <button
              type="button"
              [ngClass]="{ 'alpha-selection': clickedValue === 'A-D' }"
              class="button"
              (click)="alphaFilter('A-D')"
            >
              A-D
            </button>
            <button
              type="button"
              [ngClass]="{ 'alpha-selection': clickedValue === 'E-I' }"
              class="button"
              (click)="alphaFilter('E-I')"
            >
              E-I
            </button>
            <button
              type="button"
              [ngClass]="{ 'alpha-selection': clickedValue === 'J-O' }"
              class="button"
              (click)="alphaFilter('J-O')"
            >
              J-O
            </button>
            <button
              type="button"
              [ngClass]="{ 'alpha-selection': clickedValue === 'P-T' }"
              class="button"
              (click)="alphaFilter('P-T')"
            >
              P-T
            </button>
            <button
              type="button"
              [ngClass]="{ 'alpha-selection': clickedValue === 'U-Z' }"
              class="button"
              (click)="alphaFilter('U-Z')"
            >
              U-Z
            </button>
          </div>
        </div>
        <div class="continue-btn">
          <button
            (click)="
              mobileHospitalSelection(filterData[indexVal].selected, indexVal)
            "
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #hospitalSearch>
  <ng-container *ngIf="title === 'Learner Analytics'">
    <ng-select
      [class]="selectStyle"
      [items]="items$ | async"
      [appendTo]="'ng-select'"
      [dropdownPosition]="'bottom'"
      [typeahead]="searchInput$"
      [loading]="hospitalsLoading"
      typeToSearchText="Please enter 1 or more character"
      placeholder="Type Hospital Names"
      (change)="getSelectedValues($event)"
      [(ngModel)]="selectedHospital"
    >
      <ng-template ng-label-tmp let-item="item">
        <b class="pp-text-primary">{{ item?.filter?.hospital }}</b>
        <span class="text-secondary ms-2">({{ item?.filter?.state }})</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <b class="pp-text-primary">{{ item?.filter?.hospital }}</b>
        <span class="text-secondary ms-2">({{ item?.filter?.state }})</span>
      </ng-template>
    </ng-select>
  </ng-container>
</ng-template>
