import { Injectable } from '@angular/core';
import {
  DiversityAgeHeaders,
  DiversityFilter,
  DiversityEthinicityHeaders,
  DiversityGenderHeaders,
  DiversityRaceHeaders,
  GraphColor,
} from '../../enum/shared.enum';
import {
  Diversity,
  ProgramDiversity,
  StudentEnrollment,
  StudentEnrollmentProgram,
  TableHeader,
} from '../../../components/model/customtypes-model.interface';
import { DiversityInfo } from '../../entity/shared.entity';
import {
  DiversityHeaderTypes,
  DiversityType,
} from '../../class-type/chart-type';

@Injectable({
  providedIn: 'root',
})
export class StudentEnrollmentsService {
  public async createStudentEnrollmentDiversityTableInfo(
    chartType: string,
    sessionData: StudentEnrollmentProgram[]
  ): Promise<Diversity[]> {
    let tableData: Diversity[] = [];

    for (let filterType of Object.values(DiversityFilter)) {
      switch (filterType) {
        case DiversityFilter.ethnicity: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityEthinicityHeaders,
            'ethnicity'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.race: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityRaceHeaders,
            'race'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.gender: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityGenderHeaders,
            'gender'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.age: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityAgeHeaders,
            'age'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        default:
          this.handleDefaultCase();
      }
    }

    return tableData;
  }

  public async createTableHeaders(
    tableHeaders: DiversityHeaderTypes
  ): Promise<TableHeader[]> {
    let headerLabels: TableHeader[] = [];

    const raceHeaderArray = Object.keys(tableHeaders).map((key, index) => ({
      name: tableHeaders[key],
      value: key,
      color: GraphColor[index],
    }));

    headerLabels = [...raceHeaderArray];
    return headerLabels;
  }

  public async createDiversityInfo(
    diversityType: DiversityFilter,
    chartType: string,
    sessionData: StudentEnrollmentProgram[],
    headers: DiversityHeaderTypes,
    objToFid: DiversityType
  ): Promise<DiversityInfo> {
    let diversityInfo = new DiversityInfo();
    let tableHeaderInfo = await this.createTableHeaders(headers);

    let tableDataInfo = await this.createProgramDiversityData(
      sessionData,
      tableHeaderInfo,
      objToFid
    );

    diversityInfo.composeDiversityData(
      diversityType,
      tableDataInfo,
      tableHeaderInfo,
      chartType
    );
    return diversityInfo;
  }

  public async createProgramDiversityData(
    data: StudentEnrollmentProgram[],
    tableHeaderInfo: TableHeader[],
    objToFid: DiversityType
  ) {
    const result = {};
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    data.forEach((entry) => {
      let { year } = entry;
      if (currentYear == year) {
        year = year?.toString().concat('* YTD');
      }
      if (!result[year]) {
        result[year] = { diversityName: year, totalCount: 0 };
      }
      tableHeaderInfo.forEach(({ value }) => {
        if (value !== 'diversityName' && value !== 'totalCount') {
          result[year][value] =
            entry.diversityDetails?.[objToFid]?.[value] || 0;
        }
      });

      result[year].totalCount = Object.entries(result[year]).reduce(
        (r, [k, v]) => {
          if (k != 'diversityName') {
            r += result[year][k];
          }
          return r;
        },
        0
      );
    });
    return Object.values(result);
  }

  public async createStudentEnrollmentDiversityDetails(
    data: StudentEnrollment[]
  ) {
    const result: StudentEnrollmentProgram[] = [];

    data.forEach((studentEnrollment) => {
      studentEnrollment.programYear.forEach((program) => {
        const existingProgram = result.find(
          (item) => item.year === program.year
        );

        if (existingProgram) {
          this.mergeDiversityDetails(
            existingProgram.diversityDetails,
            program.diversityDetails
          );
        } else {
          result.push({
            ...program,
            diversityDetails: { ...program.diversityDetails },
          });
        }
      });
    });

    return result;
  }

  public mergeDiversityDetails(
    target: ProgramDiversity,
    source: ProgramDiversity
  ) {
    Object.keys(source).forEach((key) => {
      if (!target[key]) {
        target[key] = { ...source[key] };
      } else {
        Object.keys(source[key]).forEach((subKey) => {
          if (!target[key][subKey]) {
            target[key][subKey] = source[key][subKey];
          } else {
            target[key][subKey] += source[key][subKey];
          }
        });
      }
    });
  }

  public handleDefaultCase() {
    return [];
  }
}
