import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { ApiService } from '../../../shared/services/api.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  public count: number = 0;
  public questionCount: any;
  public descriptionCount = -1;
  public mobDescriptionCount = 0;
  public previousValue: any;
  public descButtonActive: boolean = false;
  public descriptionClose: boolean = true;
  public faqInfo: any;
  @ViewChild('popupContainer') public popupContainer: TemplateRef<any>;
  public faqDataTitle = [];
  public faqTitleContent = [];
  public contactLoader = true;
  public faqGeneralContent = [];
  public faqAnalyticsContent = [];
  public faqOverviewContent = [];
  public faqInfoContent = [];
  public faqGeneralDescription = [];
  public faqAnalyticsDescription = [];
  public faqOverviewDescription = [];
  public faqInfoDescription = [];
  public faqData: any;
  public arrowImageUrl: string = environment.assetDirectory.concat(
    '/assets/images/rightArrow.svg'
  );

  constructor(
    public sharedService: SharedService,
    public apiService: ApiService
  ) {}

  public ngOnInit(): void {
    const userDetailId: any = localStorage.getItem('userDetailId');
    const Id = userDetailId ? JSON.parse(userDetailId)[0]?.Account?.Id : null;
    this.sharedService.isNgScrollReachedMiddle.next(false);
    this.sharedService.faqMob.next(true);

    this.apiService.getFAQData(Id).subscribe((data: any) => {
      this.contactLoader = false;
      this.processFAQData(data);
    });
  }

  public processFAQData(data: any): void {
    this.faqInfo = data;
    this.faqInfo.sort(this.sortByNumber);
    this.faqInfo.sort(this.sortByContentNumber);

    this.extractFaqData();

    let combinedContent: any[] = [];
    combinedContent.push(
      this.faqGeneralContent,
      this.faqAnalyticsContent,
      this.faqOverviewContent,
      this.faqInfoContent
    );
    let combinedDescription: any[] = [];
    combinedDescription.push(
      this.faqGeneralDescription,
      this.faqAnalyticsDescription,
      this.faqOverviewDescription,
      this.faqInfoDescription
    );

    this.createCombinedFaqData(combinedContent, combinedDescription);
  }

  public extractFaqData(): void {
    this.faqDataTitle = this.faqInfo.map((data) => data.title);

    this.faqTitleContent = this.faqDataTitle.filter(
      (item, index) => this.faqDataTitle.indexOf(item) === index
    );

    this.faqInfo.forEach((data) => {
      data.description = this.replaceSpecialCharacters(data?.description);
      data.content = this.replaceSpecialCharacters(data?.content);

      switch (data.title) {
        case 'General Questions':
          this.faqGeneralContent.push(data.content);
          this.faqGeneralDescription.push(data.description);
          break;
        case 'Learner Insights':
          this.faqAnalyticsContent.push(data.content);
          this.faqAnalyticsDescription.push(data.description);
          break;
        case 'Partnership Overview':
          this.faqOverviewContent.push(data.content);
          this.faqOverviewDescription.push(data.description);
          break;
        case 'L&D Information Materials':
          this.faqInfoContent.push(data.content);
          this.faqInfoDescription.push(data.description);
          break;
        default:
      }
    });
  }

  public replaceSpecialCharacters(text: string | undefined): string {
    if (!text) {
      return '';
    }
    return text
      .replace(/&amp;nbsp;/g, ' ')
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'");
  }

  public createCombinedFaqData(
    combinedContent: any[],
    combinedDescription: any[]
  ): void {
    this.faqData = combinedContent.map((item, index) => ({
      title: this.faqTitleContent[index],
      content: item,
      description: combinedDescription[index],
    }));
    this.questionCount = this.faqData[this.count].content.length;
  }

  public faqQuestionClick(index: number): void {
    this.count = index;
    this.descriptionCount = -1;
    this.questionCount = this.faqData[this.count].content.length;
    this.descriptionClose = true;
  }

  public faqMobQuestionClick(index: number): void {
    this.count = index;
    this.mobDescriptionCount = -1;
    this.questionCount = this.faqData[this.count].content.length;
    this.descButtonActive = true;
  }

  public openDescription(x) {
    this.descriptionCount = x;
    if (
      this.descriptionClose === true ||
      this.previousValue !== this.descriptionCount
    ) {
      this.previousValue = this.descriptionCount;
      this.descriptionClose = false;
    } else {
      this.descriptionClose = true;
    }
  }

  public openMobDescription(x) {
    this.mobDescriptionCount = x;
    if (
      this.descriptionClose === true ||
      this.previousValue !== this.mobDescriptionCount
    ) {
      this.previousValue = this.mobDescriptionCount;
      this.descriptionClose = false;
    } else {
      this.descriptionClose = true;
    }
  }

  public openQueryBox() {
    this.sharedService.faqModalEvent.next(true);
  }

  public sortByNumber(a: any, b: any): number {
    const numA = parseInt(a.number, 10);
    const numB = parseInt(b.number, 10);
    return numA - numB;
  }

  public sortByContentNumber(a: any, b: any): number {
    const numA = parseInt(a.contentSortNumber, 10);
    const numB = parseInt(b.contentSortNumber, 10);
    return numA - numB;
  }

  public getDescription(description: string): string {
    return description.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  }
}
