<nav
  class="navbar shadow bg-light"
  [ngClass]="{ NavActive: navDetail }"
  *ngIf="!router.url.includes('faq')"
>
  <ul class="nav">
    <li
      *ngFor="let links of navLinks"
      [class.active]="router.url.includes(links?.route)"
      class="nav-item"
      [ngClass]="{
        linkVisible: links?.isVisible,
        clinicalVisible: !links?.isClinicalVisible
      }"
    >
      <small *ngIf="links.content">{{ links.content }}</small>
      <a
        [routerLink]="links?.route"
        [class.active]="router.url.includes(links?.route)"
        class="nav-link"
        (click)="navClick(links?.name)"
        >{{ links?.name }}</a
      >
    </li>
  </ul>
  <ul class="nav export-nav" *ngIf="exportVisible">
    <li>
      <button
        class="export-btn"
        (click)="openPdfDownloadPopup()"
        [disabled]="disableExport"
        *ngIf="!hideButtons"
      >
        <span class="icon-download"></span> Export
      </button>
    </li>
  </ul>
</nav>
