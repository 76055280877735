import { map, switchMap } from 'rxjs';
import { ApiService } from '../../../shared/services/api.service';
import { TransferStateService } from '@scullyio/ng-lib';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {
  ChartFilterDataUrlType,
  ChartUrlType,
} from '../../../shared/services/class-type/chart-type';

export class CommonComponent {
  public partnerOrganizations: any = JSON.parse(
    environment.partnerOrganizations
  );
  protected apiService: ApiService;
  protected ar: ActivatedRoute;
  private readonly tss: TransferStateService;
  /**
   * @ignore
   */
  constructor(
    apiService: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute
  ) {
    this.apiService = apiService;
    this.tss = tss;
    this.ar = ar;
  }
  /**
   * Find the organization name from org id.
   */
  public findOrgById(id: any) {
    const orgObj: any = this.partnerOrganizations?.find(
      (x: any) => x.id === id
    );
    return orgObj?.id;
  }
  /**
   * Getting filter label on basis of org id.
   */
  public getLabelsByOrg(chart: ChartUrlType) {
    return this.tss.useScullyTransferState(
      chart,
      this.ar.params
        .pipe(
          map((params:any) => params.org),
          switchMap((org) =>
            this.apiService.getFilterLabelsByOrg(this.findOrgById(org), chart)
          )
        )
        .pipe(
          map((data: any) => {
            return data?.data?.map((x: any) => ({ ...x, selected: 'all' }));
          })
        )
    );
  }
  /**
   * Getting all initial filter data based on org id.
   */
  public getFilterData(chart: ChartFilterDataUrlType) {
    return this.tss?.useScullyTransferState(
      chart,
      this.ar?.params?.pipe(
        map((params:any) => params.org),
        switchMap((org) =>
          this.apiService?.getInitialFilterCombsByOrg(
            this.findOrgById(org),
            chart
          )
        )
      )
    );
  }
}
