import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { ApiService } from '../../../../shared/services/api.service';
import { SharedService } from '../../../../shared/services/shared.service';
import { CommonComponent } from '../common-component';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  ApplicantsToEntrollment,
  ChartType,
} from '../../../../shared/services/enum/shared.enum';
import { CdnService } from '../../../../shared/services/cdn.service';
import { ProgramDiversityService } from '../../../../shared/services/diversity/program-diversity/program-diversity.service';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
import {
  ApplicantChartDataCount,
  ApplicantsToEnrollment,
  ApplicantsToEnrollmentAllData,
  ApplicantsToEnrollmentDynamic,
  ApplicantsToEnrollmentProgram,
  AppToEntrolInfoItem,
  ChartOtherProgramInfo,
  FilterData,
  FilterItems,
  OrgDetail,
  ProgramData,
  ProgramDynamicFilter,
  SelectedFilters,
} from '../../../../shared/components/model/customtypes-model.interface';

@Component({
  selector: 'app-applicant-enrollment',
  templateUrl: './applicants-to-enrollment.component.html',
  styleUrls: ['./applicants-to-enrollment.component.scss'],
})
export class ApplicantsToEnrollmentComponent
  extends CommonComponent
  implements OnInit {
  public chartDataCount: ApplicantChartDataCount;
  public selectedFilters: SelectedFilters;
  public filterCombinations: any = {};
  public filterLabels: FilterItems[];
  public loading: boolean;
  public graphLightColor: boolean;
  public yearFormat: boolean = false;
  public applicantDynamicAll: ApplicantsToEnrollmentAllData;
  public appToEntrolInfo: AppToEntrolInfoItem[] = [
    {
      name: 'Enrollments',
      content: ApplicantsToEntrollment.entrollment,
    },
    {
      name: 'Applicants',
      content: ApplicantsToEntrollment.applicants,
    },
  ];
  public otherProgramDetails: ChartOtherProgramInfo[] = [];
  public totalInfo: any[] = [];
  public labelInfo: number[] = [];
  public applicantsChartType: ChartType = ChartType.applicantsToEnrollment;
  public diversityInfo: any[] = [];
  public isSmallerDevice: boolean = false;
  public filterData: FilterData;
  public userName: string;
  public hospitalList: ApplicantsToEnrollment[];
  public applicantsSessionData: ApplicantsToEnrollmentDynamic;
  public filterType: any = 'yearly';
  public currentWindowWidth: number;
  public headerLabel: any[] = [];
  public applicantsReason: ApplicantsToEnrollmentProgram[] = [];

  constructor(
    apiService: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private readonly sharedService: SharedService,
    private readonly analytics: AngularFireAnalytics,
    private readonly cdnService: CdnService,
    private readonly applicantsToEnrollmentDiversityService: ProgramDiversityService,
    private readonly analyticsService: AnalyticsService
  ) {
    super(apiService, tss, ar);
    this.sharedService.applyFilters.subscribe((data) => {
      this.applyAeFilters(data);
    });
  }

  @Input() set applicantsToEnrollmentData(data: SelectedFilters) {
    if (data) {
      this.filterData = data;
    }
  }

  public ngOnInit(): void {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.filterLabels = this.sharedService.removeYearFromFilter(
        this.cdnService.appendSelectedAllToFilterData(
          'applicantsToEnrollmentFilter'
        ),
        format
      );
    });
    this.filterLabels = this.cdnService.changeTheLabel(
      this.filterLabels,
      'Year',
      'Start_Year'
    );

    // Getting filter data for applicantsToEnrollment
    this.applicantDynamicAll = this.cdnService.getTheInitialAllFilterData(
      'applicantsToEnrollmentAll'
    );
    // Getting the Fiscal or Calendar Year Format
    this.getYearFormat();

    const defaultFilter =
      this.filterCombinations?.data?.ApplicantsToEnrollments[0];
    this.setDiversityDetails(defaultFilter?.ApplicantsToEnrollment);
    this.getApplicantsNotStartedReason(defaultFilter?.ApplicantsToEnrollment);
    if (defaultFilter) {
      Object.assign(defaultFilter, {
        otherPrograms: defaultFilter?.ApplicantsToEnrollment,
      });
      this.chartDataCount = this.defineChartObj(defaultFilter);
    }

    this.sharedService.lightColorMode$.subscribe((NavVal: boolean) => {
      this.graphLightColor = NavVal;
    });

    if (window.innerWidth < 575) {
      this.isSmallerDevice = true;
    }
    this.applicantsSessionData = this.cdnService.getSessionData(
      'applicantsToEnrollment'
    );
    if (this.filterData) {
      this.getSelectedValues(this.filterData, this.filterType);
    }
    this.currentWindowWidth = window.innerWidth;
  }
  // Get and arrange the data according to predefined programs, and calculate values of Enrollment, Applications
  public defineChartObj(data: any) {
    const predefinedPgms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];
    const prgmData = data.ApplicantsToEnrollment;
    this.createOtherProgramDetails(
      data.otherPrograms ? data.otherPrograms : []
    );
    const isAllSelected =
      this.selectedFilters != null
        ? Object.values(this.selectedFilters).every(
            (x: any) => x === 'all' || x[0] === 'all'
          )
        : true;
    if (isAllSelected) {
      if (prgmData && Array.isArray(prgmData)) {
        // Calculate total for others program except predefinedPgms
        const otherPrograms = prgmData.reduce((accumulator, currentProgram) => {
          if (!predefinedPgms.includes(currentProgram.program)) {
            if (!accumulator['Other']) {
              accumulator['Other'] = {
                Enrollment: 0,
                Applicants: 0,
              };
            }
            accumulator['Other'].Enrollment +=
              currentProgram?.programCount?.Enrollment;
            accumulator['Other'].Applicants +=
              currentProgram?.programCount?.Applicants;
          }
          return accumulator;
        }, {});
        // --Calculate total for predefinedPgms program
        const chartLabels: string[] = [...predefinedPgms, 'Other'];
        const chartData = predefinedPgms.map((program) => {
          const entry = prgmData.find((item) => item.program === program);
          return entry
            ? { data: entry.programCount }
            : { data: { Enrollment: 0, Applicants: 0 } };
        });

        // --Adding other program count in chart label and data
        chartData.push({ data: otherPrograms.Other });
        return {
          labels: chartLabels,
          chartData,
        };
      } else {
        return {
          labels: [],
          chartData: [],
        };
      }
    } else {
      if (prgmData?.length > 1) {
        prgmData.sort(
          (y: any, x: any) => parseInt(y.program) - parseInt(x.program)
        );
      }

      if (prgmData) {
        const chartLabels = prgmData.map((year: ProgramData) => year.program);
        const chartConfig = prgmData.map((year: ProgramData) => year.programConfig);
        const programCounts = prgmData.map((prg) => {
          const programCount = prg?.programCount?.[0]?.programCount;
          return { data: programCount ?? {} };
        });
        return {
          labels: chartLabels,
          chartData: programCounts,
          chartConfig
        };
      } else {
        return null;
      }
    }
  }
  public async getDataForChart(data: ApplicantsToEnrollment) {
    if (data) {
      this.loading = false;
      this.chartDataCount = this.defineChartObj(data);
      if (data.filteredData) {
        let diversityInfo =
          await this.applicantsToEnrollmentDiversityService.createApplicatsToEnrollmentDiversityDetails(
            JSON.parse(JSON.stringify(data.filteredData))
          );
        this.setDiversityDetails(diversityInfo);
        const programEnrollmentsArray = data?.filteredData.flatMap(item => item.ApplicantsToEnrollment);
        this.getApplicantsNotStartedReason(programEnrollmentsArray)
      } else {
        this.setDiversityDetails(data.ApplicantsToEnrollment);
        this.getApplicantsNotStartedReason(data.ApplicantsToEnrollment)
      }
    } else {
      this.loading = false;
      this.chartDataCount = null;
    }
  }

  // get selected filter data for user selected combinations
  public getSelectedValues($event: any, type: string) {
    this.selectedFilters = $event;
    let filterData: any = localStorage.getItem('filters');
    if (filterData == null) {
      filterData = '{}';
    }
    let selectedFilters = JSON.parse(filterData);
    selectedFilters['applicantsToEnrollment'] = { ...this.selectedFilters };
    localStorage.setItem('filters', JSON.stringify(selectedFilters));
    this.loading = true;
    this.analytics.logEvent(
      `Applicant_to_enrollments_filter_${$event.Year}_${$event?.Institution}_${$event?.Location}`
    );
    const orderOfKeys = [
      'fiscal_year',
      'fiscal_month',
      'program_group',
      'institution',
      'location',
      'hospital',
    ];
    if (this.filterCombinations?.data?.ApplicantsToEnrollments?.length === 1) {
      this.filterCombinations?.data?.ApplicantsToEnrollments?.push(
        ...(this.applicantsSessionData?.data?.ApplicantsToEnrollments) || []
      );
      this.filterLabels = this.filterLabels.map((x: any, i: number) => {
        return { ...x, selected: $event[x.filterLabel] };
      });
    }

    this.sharedService
      .getSelectedFilterData(
        this.selectedFilters,
        JSON.parse(
          JSON.stringify(this.filterCombinations?.data?.ApplicantsToEnrollments)
        ),
        orderOfKeys,
        'applicantsEnrollment',
        this.yearFormat,
        type
      )
      .then((data: ApplicantsToEnrollment) => {
        this.getDataForChart(data);
      });
    const orgDetails: OrgDetail = JSON.parse(
      localStorage.getItem('userDetailId')
    );
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Applicants_to_enrollments_filter_${this.analyticsService.convertArrayToString(
          $event?.Year
        )}_${this.analyticsService.convertArrayToString(
          $event?.Institution
        )}_${this.analyticsService.convertArrayToString(
          $event?.Location
        )}_${this.analyticsService.convertArrayToString($event?.Hospital)}`,
        time,
        sessionId
      );
    }
  }
  // Reset filter data
  public reset($event: boolean) {
    if ($event) {
      this.filterLabels = this.filterLabels.map((x: FilterItems) => {
        return { ...x, selected: 'all' };
      });
    }
  }
  // Display the filter labels in column according to the number of filter labels
  public getState($event: string) {
    if ($event === 'open') {
      this.hospitalList =
        this.applicantsSessionData?.data.ApplicantsToEnrollments;
      this.filterLabels = this.filterLabels.map(
        (filterItems: FilterItems, i: number) => {
          if (i === 0) {
            return { ...filterItems, column: 'col-2' };
          } else if (i === 3) {
            return { ...filterItems, column: 'col-4' };
          } else {
            return { ...filterItems, column: 'col-3' };
          }
        }
      );
    }
  }
  public closedFilters($event: Object) {
    this.getSelectedValues(
      { ...this.selectedFilters, ...$event },
      this.filterType
    );
    const index: number = this.filterLabels.findIndex(
      (x: FilterItems) => x.filterLabel === Object.keys($event)[0]
    );
    this.filterLabels[index].selected = 'all';
  }

  public createOtherProgramDetails(
    applicantsEnrollments: ApplicantsToEnrollmentProgram[]
  ) {
    this.otherProgramDetails = this.getOtherProgramDetails(
      applicantsEnrollments
    );
  }

  public getOtherProgramDetails(data) {
    const excludedPrograms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];

    return data
      .filter((entry) => !excludedPrograms.includes(entry.program))
      .map((entry) => {
        let conversionRate = 0;

        if (entry.programCount.Applicants > 0) {
          conversionRate = parseInt(
            (
              (entry.programCount.Enrollment / entry.programCount.Applicants) *
              100
            ).toFixed(0)
          );
        }
        let conversionRateInPercentage = conversionRate.toString().concat(`%`);
        Object.assign(entry, { 'Conversion Rate': conversionRateInPercentage });
        const { programCount, ...rest } = entry;
        return { ...rest, ...programCount };
      });
  }

  public getTotalOtherProgramCount(yearsToSum, result) {
    this.totalInfo = yearsToSum.map((year) => {
      const sum = result.reduce((acc, obj) => acc + (obj[year] || 0), 0);
      return sum;
    });
    this.totalInfo.unshift('Total');
  }

  public checkYearFormat() {
    let tempApplicantsToEnrollmentAll = JSON.parse(
      JSON.stringify(this.applicantDynamicAll.data.ApplicantsToEnrollments[0])
    );
    if (this.yearFormat) {
      delete tempApplicantsToEnrollmentAll.calendar;
      tempApplicantsToEnrollmentAll = {
        ...tempApplicantsToEnrollmentAll,
        ApplicantsToEnrollment:
          tempApplicantsToEnrollmentAll.fiscal.ApplicantsToEnrollment,
      };
      delete tempApplicantsToEnrollmentAll.fiscal;
    } else {
      delete tempApplicantsToEnrollmentAll.fiscal;
      tempApplicantsToEnrollmentAll = {
        ...tempApplicantsToEnrollmentAll,
        ApplicantsToEnrollment:
          tempApplicantsToEnrollmentAll.calendar.ApplicantsToEnrollment,
      };
      delete tempApplicantsToEnrollmentAll.calendar;
    }
    if (Object.keys(this.filterCombinations).length == 0) {
      this.filterCombinations = {
        data: {
          ApplicantsToEnrollments: [tempApplicantsToEnrollmentAll],
        },
      };
    } else {
      const index =
        this.filterCombinations.data.ApplicantsToEnrollments.findIndex(
          (item) => {
            const filter: ProgramDynamicFilter = item.filter;
            return (
              filter.fiscal_year == 'all' &&
              filter.institution == 'all' &&
              filter.location == 'all' &&
              filter.hospital == 'all'
            );
          }
        );
      if (index > -1) {
        this.filterCombinations.data.ApplicantsToEnrollments[index] =
          tempApplicantsToEnrollmentAll;
      }
    }
  }

  public getYearFormat() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.yearFormat = format;
      this.checkYearFormat();
    });
  }

  @HostListener('document:salesForceLogin', ['$event'])
  public onSalesforceLogin() {
    this.ngOnInit();
  }

  public async setDiversityDetails(sessionData) {
    this.diversityInfo = sessionData;
  }

  public applyAeFilters(data) {
    const { type, filters, title } = data;
    if (title != 'Applicants to Enrollments') {
      return;
    }
    this.selectedFilters = filters;
    this.filterType = type;
    this.getSelectedValues(filters, this.filterType);
  }

  public openPopup(event) {
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Applicants to Enrollments Graph`,
        time,
        sessionId
      );
    }
    this.hospitalList =
      this.applicantsSessionData?.data.ApplicantsToEnrollments;
    const data = {
      status: true,
      title: 'Applicants to Enrollments',
      data: this.filterLabels,
      hospitals: this.hospitalList,
    };
    this.sharedService.openFilterPopup.next(data);
  }

  public async getApplicantsNotStartedReason(sessionData: ApplicantsToEnrollmentProgram[]) {
    this.applicantsReason = sessionData;
  }
}
