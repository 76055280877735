import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ElementRef,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  Inject,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  concat,
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SharedService } from '../../services/shared.service';
import { FilterItemTooltip, FilterItem } from '../../services/enum/shared.enum';
import {
  FilterItems,
  LearnerAnalytics,
} from '../model/customtypes-model.interface';
import { HospitalSharedService } from '../../services/hospital/hospital.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'app-se-filter-popup',
  templateUrl: './se-filter-popup.component.html',
  styleUrls: ['./se-filter-popup.component.scss'],
})
export class SeFilterPopupComponent implements OnInit {
  public searchInput$: Subject<string> = new Subject<string>();
  @ViewChild('filterDropdown') public filterDropdown: TemplateRef<any>;
  @ViewChild('hospitalMobilefilter')
  public hospitalMobilefilter: TemplateRef<any>;
  @ViewChild('defaultFilterComp', { read: ViewContainerRef })
  public defaultFilterComp: ViewContainerRef;
  @ViewChild('customAppend') public customAppend: TemplateRef<any>;
  @ViewChild(NgSelectComponent, { read: ElementRef })
  public ngSelectComponent!: NgSelectComponent;
  @Output() public selectedValues = new EventEmitter();
  @Input() public title: string;
  @Input() public alphaGroup: string;
  public showPopup = false;
  public ngDropdown: any;
  public filterData: any;
  public listOfHospitals: any;
  public items$: Observable<any>;
  public selectedHospital: any;
  public hospitalsLoading: boolean;
  public showMobileFilterPopup = false;
  public selectedValueFromMobileSelect: any;
  public mobilePopupClose: boolean = false;
  public filterLabel;
  @Output() public reset = new EventEmitter();
  @Output() public popUpState = new EventEmitter();
  @Input() public loading = false;
  @Input() public appendToBody: HTMLDivElement;
  public currentWindowWidth: number;
  public clickedValue: string;
  public mobileHospitalSelected: boolean = false;
  public viewContainerRef: ViewContainerRef;
  public filteredItems: any = {};
  public indexVal: any;
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  public userName: string;
  public studentEnrollmentPopup = false;
  public activeTab: string = 'yearly'; // Active tab tracking
  public isAccordionOpen: { [key: string]: boolean } = {
    Start_Year: true,
  };
  public dataDictionary: any = {};
  public quarterlyFilters = [];
  public yearlyFilters = [];
  public monthlyFilters = [];
  private filterDataBkp: any;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    viewContainerRef: ViewContainerRef,
    private readonly analytics: AngularFireAnalytics,
    public sharedService: SharedService,
    public hospitalSharedService: HospitalSharedService,
    public analyticsService: AnalyticsService
  ) {
    this.viewContainerRef = viewContainerRef;
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.showPopup = false;
        this.popClose();
      }
    });

    this.sharedService.openFilterPopup.subscribe((data) => {
      this.title = data.title;
      this.showPopup = data.status;
      this.data = data.data;
      this.hospitals = data.hospitals;
    });
  }

  @Input() set data(data: any) {
    if (data) {
      this.filterData = data;
      this.filterDataBkp = JSON.parse(JSON.stringify(data));
      this.configureData();
    }
  }
  @Input() set hospitals(data: any) {
    if (data) {
      this.listOfHospitals = JSON.parse(JSON.stringify(data));
      this.setParentOrgName(this.listOfHospitals);
      if (this.title === 'Learner Analytics') {
        this.listOfHospitals = this.removeRepeatingHospitals(
          this.listOfHospitals
        );
      }
    }
  }

  public configureData() {
    this.assignTooltipToFilterLabel(this.filterData);
    this.sortModalityByNumber();
    this.filterData = this.checkParentOrgDuplication(this.filterData);
    this.filterData.forEach((row: any) => {
      this.dataDictionary[row.filterLabel] = row;
    });
    this.setYearly(this.filterData);
    this.setQuarterly(this.filterData);
    this.setMonthly(this.filterData);
    let savedType: string;
    switch (this.title) {
      case 'Applicants to Enrollments':
        savedType = localStorage.getItem('activeTab AE') || 'yearly';
        break;
      case 'Student Enrollments':
        savedType = localStorage.getItem('activeTab SE') || 'yearly';
        break;
      case 'Alumni':
        savedType = localStorage.getItem('activeTab AP') || 'yearly';
        break;
      default:
        savedType = 'yearly';
    }
    if (savedType != this.activeTab) {
      this.resetOtherFilters(this.yearlyFilters);
      this.resetOtherFilters(this.quarterlyFilters);
      this.resetOtherFilters(this.monthlyFilters);
    }
  }

  public setQuarterly(data: any) {
    if (this.title === 'Applicants to Enrollments') {
      this.quarterlyFilters = [
        this.dataDictionary['Quarter'],
        this.dataDictionary['Start_Year'],
        this.dataDictionary['Program'],
        this.dataDictionary['Institution'],
        this.dataDictionary['Location'],
        this.dataDictionary['Hospital'],
      ];
    } else {
      this.quarterlyFilters = [
        this.dataDictionary['Quarter'],
        this.dataDictionary['Start_Year'],
        this.dataDictionary['Institution'],
        this.dataDictionary['Location'],
        this.dataDictionary['Hospital'],
      ];
    }
  }

  public setYearly(data: any) {
    if (this.title === 'Applicants to Enrollments') {
      this.yearlyFilters = [
        this.dataDictionary['Start_Year'],
        this.dataDictionary['Quarter'],
        this.dataDictionary['Program'],
        this.dataDictionary['Institution'],
        this.dataDictionary['Location'],
        this.dataDictionary['Hospital'],
      ];
    } else {
      this.yearlyFilters = [
        this.dataDictionary['Start_Year'],
        this.dataDictionary['Quarter'],
        this.dataDictionary['Institution'],
        this.dataDictionary['Location'],
        this.dataDictionary['Hospital'],
      ];
    }
  }

  public setMonthly(data: any) {
    if (this.title === 'Applicants to Enrollments') {
      this.monthlyFilters = [
        this.dataDictionary['Month'],
        this.dataDictionary['Start_Year'],
        this.dataDictionary['Program'],
        this.dataDictionary['Institution'],
        this.dataDictionary['Location'],
        this.dataDictionary['Hospital'],
      ];
    } else {
      this.monthlyFilters = [
        this.dataDictionary['Month'],
        this.dataDictionary['Start_Year'],
        this.dataDictionary['Institution'],
        this.dataDictionary['Location'],
        this.dataDictionary['Hospital'],
      ];
    }
  }

  public ngOnInit(): void {
    this.loadHospitals();
    // Event for close popup when click keypress
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.showPopup = false;
      }
    });
    this.currentWindowWidth = window.innerWidth;

    this.sharedService.mobileFilterPopupEvent$.subscribe((NavVal: any) => {
      this.showMobileFilterPopup = NavVal;
    });
  }

  public assignTooltipToFilterLabel(filterItems: any[]) {
    filterItems.forEach((fItem) => {
      let itemFoundByValue = Object.keys(FilterItem).find(
        (key) => FilterItem[key] === fItem.filterLabel
      );

      if (itemFoundByValue != null && itemFoundByValue != undefined) {
        Object.assign(fItem, { tooltip: FilterItemTooltip[itemFoundByValue] });
      } else {
        Object.assign(fItem, { tooltip: '' });
      }
    });
  }

  public openMobileFilterPopup(filter, i) {
    let index = this.filterData.findIndex(
      (item) => item.filterLabel == filter.filterLabel
    );
    const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
      this.hospitalMobilefilter
    );
    if (embeddedViewRef != null) {
      embeddedViewRef.detectChanges();
      for (const node of embeddedViewRef.rootNodes) {
        this.document.body.appendChild(node);
      }
      this.showMobileFilterPopup = true;
      this.sharedService.mobileFilterPopupEvent.next(true);
    }
    this.filterLabel = this.filterData[index].filterLabel;
    this.indexVal = index;
    this.mobilePopupClose = false;
  }

  public closeHospitalPopup() {
    this.mobilePopupClose = true;
  }

  // Click event handler function
  public alphaFilter(buttonId: string) {
    this.clickedValue = buttonId;
  }

  public resetFilters() {
    this.filteredItems = {};
    const result = {};
    switch (this.activeTab) {
      case 'yearly':
        this.yearlyFilters.forEach((row: any) => {
          row.selected = 'all';
          result[row.filterLabel] = row.selected;
        });
        break;
      case 'quarterly':
        this.quarterlyFilters.forEach((row: any) => {
          row.selected = 'all';
          result[row.filterLabel] = row.selected;
        });
        break;
      case 'monthly':
        this.monthlyFilters.forEach((row: any) => {
          row.selected = 'all';
          result[row.filterLabel] = row.selected;
        });
    }
    const data = {
      type: this.activeTab,
      filters: result,
      title: this.title,
    };
    this.sharedService.applySeFilters(data);
    this.showPopup = false;
    const dropdown: any = this.document.querySelector('.filter-card');
    dropdown?.remove();
    this.activeTab = 'yearly';
    switch (this.title) {
      case 'Applicants to Enrollments':
        localStorage.setItem('activeTab AE', 'yearly');
        break;
      case 'Student Enrollments':
        localStorage.setItem('activeTab SE', 'yearly');
        break;
      case 'Alumni':
        localStorage.setItem('activeTab AP', 'yearly');
    }
  }
  // Close popup
  public popClose() {
    this.showPopup = false;
    const dropdown: any = this.document.querySelector('.filter-card');
    dropdown?.remove();
  }
  // Open popup
  public openPopup($event: MouseEvent) {
    this.clickedValue = 'A-D';
    // this.showPopup = true;
    this.showPopup = !this.showPopup;
    if (this.showPopup && this.currentWindowWidth <= 500) {
      const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
        this.filterDropdown
      );
      embeddedViewRef.detectChanges();
      for (const node of embeddedViewRef.rootNodes) {
        this.document.body.appendChild(node);
      }
    }
    this.popUpState.emit(this.showPopup ? 'open' : 'close');
  }

  // Apply filter on basis of seleted value/checkbox

  public applyFilters() {
    this.clickedValue = '';
    const obj: any = {};
    this.filterData?.forEach((x: any) => {
      obj[x.filterLabel] = x.selected;
    });
    this.selectedValues.emit(obj);
    this.selectedHospital = null;
    this.showPopup = false;
    const dropdown: any = this.document.querySelector('.filter-card');
    dropdown?.remove();

    const result = {};
    switch (this.activeTab) {
      case 'yearly':
        this.yearlyFilters.forEach((row: any) => {
          result[row.filterLabel] = row.selected;
        });
        break;
      case 'quarterly':
        this.quarterlyFilters.forEach((row: any) => {
          result[row.filterLabel] = row.selected;
        });
        break;
      case 'monthly':
        this.monthlyFilters.forEach((row: any) => {
          result[row.filterLabel] = row.selected;
        });
    }
    const data = {
      type: this.activeTab,
      filters: result,
      title: this.title,
    };
    switch (this.title) {
      case 'Applicants to Enrollments':
        localStorage.setItem('activeTab AE', this.activeTab);
        break;
      case 'Student Enrollments':
        localStorage.setItem('activeTab SE', this.activeTab);
        break;
      case 'Alumni':
        localStorage.setItem('activeTab AP', this.activeTab);
    }
    this.sharedService.applySeFilters(data);
  }

  public getSelectedYear($event: any, index: number) {
    this.filterData[index].selected = $event;
  }

  public getFieldValuesFromMobile($event: any) {
    this.selectedValueFromMobileSelect = $event;
    this.ngDropdown = this.document.querySelector('ng-dropdown-panel');
  }

  public getSelectedValues($event: any) {
    this.ngDropdown = this.document.querySelector('ng-dropdown-panel');
    const obj: any = {};
    this.filteredItems = {};
    $event &&
      Object.keys($event.filter).forEach((x: any) => {
        switch (x) {
          case 'program_group':
            obj['program'] = 'all';
            break;
          case 'modality':
            obj['modality'] = 'all';
            break;
          case 'state':
            obj['location'] = $event.filter.state;
            break;
          default:
            obj[x] = $event.filter[x];
        }
      });
    this.filterData.forEach((x: any) => {
      if ($event) {
        x.selected =
          x.filterLabel === 'Institution'
            ? obj[x.filterLabel.toLowerCase()].toLowerCase()
            : obj[x.filterLabel.toLowerCase()];
      }
    });
    if ($event) {
      this.analytics.logEvent(
        `Hospital search - ${$event?.filter?.hospital}_${$event?.filter?.state}`
      );
    }
  }

  public loadHospitals() {
    this.items$ = concat(
      of([]), // default items
      this.searchInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.hospitalsLoading = true)),
        switchMap((term) => {
          this.hospitalsLoading = false;
          return this.search(term);
        })
      )
    );
  }

  public search(term: string): Observable<any> {
    if (term?.length > 0) {
      return of(
        this.listOfHospitals.filter((obj) =>
          obj?.filter?.hospital.toLowerCase().includes(term.toLowerCase())
        )
      );
    } else {
      return of([]);
    }
  }

  public makeActiveAlphaBtn($event: any) {
    this.clickedValue = $event;
  }

  public mobileHospitalSelection(filter, indexVal) {
    this.filteredItems[indexVal] = filter;
    this.mobileHospitalSelected = true;
    this.closeHospitalPopup();
  }

  public iterateOptions(options, label) {
    return options;
  }

  public resetFilter(filter, tabType: string) {
    if (filter.filterLabel === 'Modality') {
      const programFilter = this.filterData.find(
        (filter) => filter?.filterLabel === 'Program'
      );
      programFilter.selected = 'all';
      this.filteredItems = {};
    }
    if (tabType !== this.activeTab) {
      filter.selected = null;
    }
  }

  public async sortModalityByNumber() {
    if (this.filterData) {
      const modalityFilterIndex = this.filterData.findIndex(
        (filter) => filter?.filterLabel === 'Modality'
      );

      if (modalityFilterIndex > -1) {
        let isContainsUnderScore = this.filterData[
          modalityFilterIndex
        ].filterItems.some(function (item) {
          return item.includes('_');
        });

        if (isContainsUnderScore) {
          let updatedItems = this.filterData[modalityFilterIndex].filterItems
            .map((str) => {
              const [numStr, letter] = str.split('_');
              return { num: parseInt(numStr), letter };
            })
            .sort((a, b) => a.num - b.num)
            .map((obj) => obj.letter);

          this.filterData[modalityFilterIndex].filterItems = [];
          this.filterData[modalityFilterIndex].filterItems = updatedItems;
        }
      }
    }
  }

  public getStatusIterationOptions(options: any, modalityFilter: any) {
    return options.filter((option) => {
      let status = false;
      modalityFilter.selected.forEach((selected) => {
        if (selected === 'Course based') {
          if (!option.includes('-TP')) {
            status = true;
          }
        } else if (selected === 'Competency based') {
          if (option.includes('-TP') || option === 'all') {
            status = true;
          }
        }
      });
      return status;
    });
  }

  public checkParentOrgDuplication(filterData: FilterItems[]) {
    let organization = this.hospitalSharedService.getParentOrganizationName();
    const searchTerm:RegExp = /p-/i;

    if (filterData.length) {
      let selectedHospitalList: number = filterData.findIndex(
        (labelInfo) => labelInfo.filterLabel === 'Hospital'
      );

      if (selectedHospitalList > -1) {
        filterData[selectedHospitalList].filterItems = filterData[
          selectedHospitalList
        ].filterItems?.map((str) => {
          if (searchTerm.test(str)) {
            const restOfString = str.split(searchTerm)[1];
            if (restOfString && restOfString.trim() === organization) {
              return this.hospitalSharedService.setParentHospitalName(
                restOfString
              );
            }
          }
          return str;
        });
      }
    }

    return filterData;
  }

  public async setParentOrgName(learnerAnalytics: LearnerAnalytics[]) {
    let organization = this.hospitalSharedService.getParentOrganizationName();
    let modifiedParentOrgName =
      this.hospitalSharedService.setParentHospitalName(organization);

    let parentOrgs = learnerAnalytics.filter(
      (filterObj) => filterObj.orgId == filterObj.filter.hospitalId
    );

    if (parentOrgs.length > 0) {
      parentOrgs.forEach((learner: LearnerAnalytics) => {
        learner.filter.hospital = modifiedParentOrgName;
      });
    }
  }

  public removeRepeatingHospitals(arr: LearnerAnalytics[]) {
    const result: any = [];
    const seen: any = {};
    arr &&
      arr.forEach((x: LearnerAnalytics) => {
        const key = x?.filter?.hospital + x?.filter?.state;
        if (!seen[key]) {
          result.push(x);
          seen[key] = true;
        }
      });
    return result;
  }

  public setActiveTab(tab: string) {
    if (tab == 'yearly') {
      this.isAccordionOpen = { Start_Year: true };
    } else if (tab == 'quarterly') {
      this.isAccordionOpen = { Quarter: true };
    } else if (tab == 'monthly') {
      this.isAccordionOpen = { Month: true };
    }
    this.activeTab = tab;
    this.clearSelectionsForOtherTabs();
  }

  public clearSelectionsForOtherTabs(): void {
    this.filterData = JSON.parse(JSON.stringify(this.filterDataBkp));
    this.configureData();
  }

  public resetOtherFilters(filters: any[]) {
    filters.forEach((filter) => {
      filter.selected = 'all';
    });
  }

  // Toggle accordion
  public toggleAccordion(section: string) {
    Object.keys(this.isAccordionOpen).forEach((key) => {
      this.isAccordionOpen[key] = false;
    });

    // Then open the clicked accordion section
    this.isAccordionOpen[section] = true;
  }

  public getIndex(filterLabel: any) {
    let index = this.filterData.findIndex(
      (item) => item.filterLabel == filterLabel
    );
    return index;
  }
}
