import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { DiversityService } from '../../services/diversity/diversity.service';
import { ChartType } from '../../services/enum/shared.enum';
import { getFormattedCurrentDateTime } from '../../services/functional-handler/functional-handler';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { OrgDetail } from '../model/customtypes-model.interface';

@Component({
  selector: 'app-diversity-button',
  templateUrl: './diversity-button.component.html',
  styleUrls: ['./diversity-button.component.scss'],
})
export class DiversityButtonComponent {
  @Input() public loading = false;
  @Input() public diversityInfo: any[] = [];
  @Input() public chartType: ChartType;
  @Input() public graphTitle: string;
  @Output() public onOpenDiversity = new EventEmitter();
  public userName: string;

  constructor(
    public sharedService: SharedService,
    public diversityService: DiversityService,
    public analyticsService: AnalyticsService
  ) {}
  // Emitted button click action
  public openPopup() {
    this.sharedService.diversityDetails.next(true);
    this.onOpenDiversity.emit(true);
    this.diversityService.createDiversityTableInfo(
      this.chartType,
      this.graphTitle,
      this.diversityInfo
    );
    this.sharedService.FloatingMenuEvent.next(false);
    const orgDetails: OrgDetail = JSON.parse(
      localStorage.getItem('userDetailId')
    );
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `${this.graphTitle} Diversity`,
        time,
        sessionId
      );
    }
  }
}
