import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { ApiService } from '../../../../shared/services/api.service';
import { SharedService } from '../../../../shared/services/shared.service';
import { CommonComponent } from '../common-component';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CdnService } from '../../../../shared/services/cdn.service';
import {
  Alumni,
  AlumniAllData,
  AlumniDiversity,
  AlumniDiversityProgram,
  AlumniDynamic,
  AlumniStudentDetails,
  ChartDataCount,
  ChartOtherProgramInfo,
  DynamicProgramYear,
  FilterData,
  FilterItems,
  OrgDetail,
  ProgramDynamic,
  ProgramDynamicFilter,
  SelectedFilters,
} from '../../../../shared/components/model/customtypes-model.interface';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
import { ChartType } from '../../../../shared/services/enum/shared.enum';
import { ProgramDiversityService } from '../../../../shared/services/diversity/program-diversity/program-diversity.service';

@Component({
  selector: 'app-alumni-program',
  templateUrl: './alumni-program.component.html',
  styleUrls: ['./alumni-program.component.scss'],
})
export class AlumniProgramComponent extends CommonComponent implements OnInit {
  public chartDataCount: ChartDataCount;
  public selectedFilters: SelectedFilters;
  public filteredStudentDetails: AlumniStudentDetails = {};
  public filterLabels: FilterItems[];
  public loading: boolean;
  public otherProgramDetails: ChartOtherProgramInfo[] = [];
  public labelInfo: number[] = [];
  public totalYearWiseInfo: number[];
  public filterData: FilterData;
  public userName: string;
  public hospitalList: Alumni[];
  public sessionData: AlumniDynamic;
  public yearFormat: boolean = false;
  public alumniDynamicAll: AlumniAllData;
  public alumniChartType: ChartType = ChartType.alumni;
  public diversityInfo: any[] = [];
  public isSmallerDevice: boolean = false;
  public headerLabel: number[] | string[] = [];
  public filterType: string = 'yearly';

  constructor(
    as: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private readonly sharedService: SharedService,
    private readonly analytics: AngularFireAnalytics,
    private readonly cdnService: CdnService,
    private readonly analyticsService: AnalyticsService,
    private readonly programDiversityService: ProgramDiversityService
  ) {
    super(as, tss, ar);
    this.sharedService.applyFilters.subscribe((data) => {
      this.applyAlumniFilters(data);
    });
  }

  @Input() set alumniData(data: SelectedFilters) {
    if (data) {
      this.filterData = data;
    }
  }

  // Get labels for current organisation for studentEnrollment
  public async ngOnInit() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.filterLabels = this.sharedService.removeYearFromFilter(
        this.cdnService.appendSelectedAllToFilterData('alumniByProgramFilter'),
        format
      );
    });

    this.filterLabels = this.cdnService.changeTheLabel(
      this.filterLabels,
      'Year',
      'Start_Year'
    );
    this.yearReducer(this.filterLabels);
    // getting filter data studentEnrollment
    const alumniDynamicAll = this.cdnService.getTheInitialAllFilterData(
      'alumniByProgramDynamicAll'
    );
    this.alumniDynamicAll = await this.addCurrentYearData(alumniDynamicAll);
    this.getYearFormat();
    const defaultFilter = this.filteredStudentDetails?.data?.alumniByProgram[0];
    this.diversityInfo =
      await this.programDiversityService.createAllAlumniDiversityDetails(
        defaultFilter?.programYear
      );
    if (defaultFilter) {
      Object.assign(defaultFilter, {
        otherPrograms: defaultFilter?.programYear,
      });
      this.chartDataCount = this.defineChartObj(defaultFilter);
    }
    if (window.innerWidth < 575) {
      this.isSmallerDevice = true;
    }
    this.sessionData = this.cdnService.getSessionData('alumniByProgramDynamic');
    if (this.filterData) {
      this.getSelectedValues(this.filterData, this.filterType);
    }
  }
  // Get and arrange the data according to predefined programs, and calculate values of programs according to years
  public defineChartObj(data: Alumni) {
    const predefinedPgms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];
    const prgmData = data.programYear;
    if (prgmData && prgmData.length > 1) {
      prgmData.sort((y: any, x: any) => parseInt(y.year) - parseInt(x.year));
    }
    this.createOtherProgramDetails(data.otherPrograms ?? []);

    if (prgmData) {
      const chartLabels: number[] = prgmData.map((year: any) => year.year);
      const programCounts = {};

      predefinedPgms.forEach((program) => {
        programCounts[program] = prgmData.map(
          (entry) => entry.programsCount[program] || null
        );
      });

      const OTHERS: number[] = prgmData?.map((q: AlumniDiversityProgram) => {
        return Object.entries(q.programsCount)
          .filter((obj: [string, number]) => !predefinedPgms.includes(obj[0]))
          .map((b: any) => b[1])
          .reduce(
            (accumulator: number, reducer: number) => accumulator + reducer,
            0
          );
      });

      let chartData: any[] = Object.keys(programCounts).map((program) => ({
        labels: program,
        data: programCounts[program],
      }));

      chartData.push({ labels: 'OTHERS', data: OTHERS });
      return {
        labels: chartLabels,
        chartData,
      };
    } else {
      return null;
    }
  }
  public async addCurrentYearData(data: any) {
    const currentYear = new Date().getFullYear();
    const defaultProgramYear = {
      year: currentYear,
      programsCount: {},
      diversityDetails: {},
    };
    if (
      !data?.data?.alumniByProgram?.[0]?.calendar?.programYear?.some(
        (entry: any) => entry.year === currentYear
      )
    ) {
      data?.data?.alumniByProgram?.[0]?.calendar?.programYear?.push(
        defaultProgramYear
      );
    }

    // Check and add missing year to the fiscal section
    if (
      !data?.data?.alumniByProgram?.[0]?.fiscal?.programYear?.some(
        (entry: any) => entry.year === currentYear
      )
    ) {
      data?.data?.alumniByProgram?.[0]?.fiscal?.programYear?.push(
        defaultProgramYear
      );
    }

    return data;
  }
  // get selected filter data for user selected combinations
  public getSelectedValues($event: any, type: string) {
    this.selectedFilters = $event;
    let filterData: string = localStorage.getItem('filters');
    if (filterData == null) {
      filterData = '{}';
    }
    let selectedFilters = JSON.parse(filterData);
    selectedFilters['alumni'] = { ...this.selectedFilters };
    localStorage.setItem('filters', JSON.stringify(selectedFilters));
    this.loading = true;
    this.analytics.logEvent(
      `Alumni_Filter_${$event?.Start_Year}_${$event?.Institution}_${$event?.Location}_${$event?.Hospital}`
    );

    const orderOfKeys = [
      'fiscal_year',
      'fiscal_month',
      'institution',
      'location',
      'hospital',
    ];
    if (this.filteredStudentDetails?.data?.alumniByProgram?.length === 1) {
      if (
        this.filteredStudentDetails?.data?.alumniByProgram &&
        this.sessionData?.data?.alumniByProgram
      ) {
        this.filteredStudentDetails.data.alumniByProgram.push(
          ...this.sessionData.data.alumniByProgram
        );
      }
      this.filterLabels = this.filterLabels.map((x: any, i: number) => {
        if ($event[x.filterLabel] != null) {
          return { ...x, selected: $event[x.filterLabel] };
        }
        return { ...x, selected: 'all' };
      });
    }
    if (this.filteredStudentDetails?.data?.alumniByProgram?.length) {
      this.sharedService
        .getSelectedFilterData(
          this.selectedFilters,
          JSON.parse(
            JSON.stringify(this.filteredStudentDetails?.data?.alumniByProgram)
          ),
          orderOfKeys,
          'alumniProgram',
          this.yearFormat,
          type
        )
        .then((filter: ProgramDynamic) => {
          this.getDataForChart(filter);
        });
    }
    const orgDetails: OrgDetail[] = JSON.parse(
      localStorage.getItem('userDetailId')
    );
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Alumni_Filter_${this.analyticsService.convertArrayToString(
          $event?.Start_Year
        )}_${this.analyticsService.convertArrayToString(
          $event?.Institution
        )}_${this.analyticsService.convertArrayToString(
          $event?.Location
        )}_${this.analyticsService.convertArrayToString($event?.Hospital)}`,
        time,
        sessionId
      );
    }
  }
  public async getDataForChart(filter: ProgramDynamic) {
    if (filter) {
      this.loading = false;
      this.chartDataCount = this.defineChartObj(filter);
      if (filter.filteredData) {
        let diversityInfo =
          await this.programDiversityService.createAlumniDiversityDetails(
            JSON.parse(JSON.stringify(filter.filteredData))
          );
        this.setDiversityDetails(diversityInfo);
      } else {
        this.diversityInfo =
          await this.programDiversityService.createAllAlumniDiversityDetails(
            filter?.programYear
          );
      }
    } else {
      this.loading = false;
      this.chartDataCount = null;
    }
  }
  // Reset filter data
  public reset($event: boolean) {
    if ($event) {
      this.filterLabels = this.filterLabels.map((x: FilterItems) => {
        return { ...x, selected: 'all' };
      });
    }
  }
  // Display the filter labels in column according to the number of filter labels
  public getState($event: string) {
    if ($event === 'open') {
      this.hospitalList = this.sessionData?.data.alumniByProgram;
      this.filterLabels = this.filterLabels.map(
        (filterItems: FilterItems, i: number) => {
          if (i === 0) {
            return { ...filterItems, column: 'col-2' };
          } else if (i === 3) {
            return { ...filterItems, column: 'col-4' };
          } else {
            return { ...filterItems, column: 'col-3' };
          }
        }
      );
    }
  }

  public closedFilters($event: Object) {
    this.getSelectedValues(
      { ...this.selectedFilters, ...$event },
      this.filterType
    );
    const index: number = this.filterLabels.findIndex(
      (x: FilterItems) => x.filterLabel === Object.keys($event)[0]
    );
    this.filterLabels[index].selected = 'all';
  }

  public createOtherProgramDetails(aluminiPrograms: DynamicProgramYear[]) {
    this.labelInfo = aluminiPrograms
      .map(({ year }) => year)
      .sort((a, b) => a - b);

    this.otherProgramDetails = this.getOtherProgramDetails(aluminiPrograms);
    this.headerLabel = JSON.parse(JSON.stringify(this.labelInfo));
    if (
      this.headerLabel[this.headerLabel.length - 1] == new Date().getFullYear()
    ) {
      this.headerLabel[this.headerLabel.length - 1] = `${
        this.headerLabel[this.headerLabel.length - 1]
      }* YTD`;
    }
    this.getTotalOtherProgramCount(this.labelInfo, this.otherProgramDetails);
  }

  // To filter all other program details
  public getOtherProgramDetails(data) {
    const exclusionList = new Set(['RNBSN', 'RNMSN', 'MSN', 'DNP']);

    // Create an object to store counts for each program across years
    const programCounts = {};

    // Extract all years
    const years = data.map((entry) => entry.year);

    // Initialize counts for each program for all years to zero
    data.forEach((entry) => {
      Object.keys(entry.programsCount)
        .filter((program) => !exclusionList.has(program))
        .forEach((program) => {
          if (!programCounts[program]) {
            programCounts[program] = {};
            years.forEach((year) => {
              programCounts[program][year] = 0; // Initialize count to zero
            });
          }
        });
    });

    // Update counts based on available data
    data.forEach((entry) => {
      Object.entries(entry.programsCount)
        .filter(([program]) => !exclusionList.has(program))
        .forEach(([program, count]) => {
          programCounts[program][entry.year] = count;
        });
    });

    // Construct the final array of objects
    const result: any = Object.entries(programCounts).map(
      ([program, counts]) => {
        if (typeof counts !== 'object' || counts === null) {
          return { program }; // Only include program if counts is not an object
        }
        return {
          program,
          ...counts, // Spread counts if it's an object
        };
      }
    );

    const newData = result.map(({ program, ...rest }) => {
      const Total = Object.values(rest).reduce((acc, val) => acc + val, 0);
      return { program, Total, ...rest };
    });

    return newData;
  }

  public getTotalOtherProgramCount(yearsToSum, result) {
    this.totalYearWiseInfo = yearsToSum.map((year) => {
      const sum = result.reduce((acc, obj) => acc + (obj[year] || 0), 0);
      return sum;
    });
  }

  public yearReducer(filterLabel: FilterItems[]) {
    if (filterLabel != null && filterLabel != undefined) {
      let yearLabel: any = filterLabel.find(
        (x: FilterItems) =>
          x.filterLabel === 'Year' || x.filterLabel === 'Start_Year'
      );
      if (yearLabel?.filterItems && Array.isArray(yearLabel.filterItems)) {
        yearLabel.filterItems = yearLabel.filterItems.slice(-8);
        yearLabel.filterItems.unshift('all');
      }
    }
  }

  public checkYearFormat() {
    if (this.alumniDynamicAll?.data?.alumniByProgram?.length) {
      let tempAlumniAll = JSON.parse(
        JSON.stringify(this.alumniDynamicAll?.data?.alumniByProgram[0])
      );
      if (this.yearFormat) {
        delete tempAlumniAll.calendar;
        tempAlumniAll = {
          ...tempAlumniAll,
          programYear: tempAlumniAll?.fiscal?.programYear,
        };
        delete tempAlumniAll.fiscal;
      } else {
        delete tempAlumniAll.fiscal;
        tempAlumniAll = {
          ...tempAlumniAll,
          programYear: tempAlumniAll?.calendar?.programYear,
        };
        delete tempAlumniAll.calendar;
      }
      if (Object.keys(this.filteredStudentDetails).length == 0) {
        this.filteredStudentDetails = {
          data: {
            alumniByProgram: [tempAlumniAll],
          },
        };
      } else {
        const index =
          this.filteredStudentDetails.data.alumniByProgram.findIndex((item) => {
            const filter: ProgramDynamicFilter = item.filter;
            return (
              filter.fiscal_year == 'all' &&
              filter.institution == 'all' &&
              filter.location == 'all' &&
              filter.hospital == 'all'
            );
          });
        if (index > -1) {
          this.filteredStudentDetails.data.alumniByProgram[index] =
            tempAlumniAll;
        }
      }
    }
  }

  public getYearFormat() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.yearFormat = format;
      this.checkYearFormat();
    });
  }

  @HostListener('document:salesForceLogin', ['$event'])
  public onSalesforceLogin() {
    this.ngOnInit();
  }

  public setDiversityDetails(sessionData: AlumniDiversity[]) {
    this.diversityInfo = sessionData;
  }

  public applyAlumniFilters(data) {
    const { type, filters, title } = data;
    if (title != 'Alumni') {
      return;
    }
    this.selectedFilters = filters;
    this.filterType = type;
    this.getSelectedValues(filters, this.filterType);
  }

  public openPopup(event) {
    const orgDetails: OrgDetail = JSON.parse(
      localStorage.getItem('userDetailId')
    );
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Alumni By Programs Graph`,
        time,
        sessionId
      );
    }
    this.hospitalList = this.sessionData?.data.alumniByProgram;
    const alumniDynamicAll = this.cdnService.getSessionData(
      'alumniByProgramDynamic'
    )?.data?.alumniByProgram;
    const data = {
      status: true,
      title: 'Alumni',
      data: this.filterLabels,
      hospitals: this.hospitalList,
      popupData: alumniDynamicAll,
    };
    this.sharedService.openFilterPopup.next(data);
  }
}
