<button
  [title]="''"
  [class]="loading ? 'remove-icon-right' : null"
  class="btn pp-button"
  (click)="openPopup()"
>
  {{ label }}
  <output
    *ngIf="loading"
    class="spinner-border spinner-border-sm"
    aria-live="polite"
  ></output>
</button>
