// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  clientId: '{{clientId}}',
  baseURL: 'https://dev-apigateway.adtalem.com/',
  daasBaseURL: 'https://dev-apigateway.adtalem.com/partner/daas/v2/',
  sfBaseURL: 'https://dev-apigateway.adtalem.com/partner/sf/v1',
  redirectUrl: 'https://corp-dev-partner-app-proj.firebaseapp.com/',
  assetDirectory: 'https://partner-assets-dev.waldenu.edu/partner-portal',
  mailUrl: 'https://dev-apigateway.adtalem.com/partner/v2/',
  mailTo: ['rajasekar.deivasigamani@adtalem.com','anuradha.vaka@adtalem.com','praveen.edayilliam@mail.waldenu.edu','rintu.sunny@adtalem.com'],
  adminUsers: ['gaurav.tyagi@adtalem.com','praveen.gopalan@adtalem.com'],
  firebase: {
    apiKey: "AIzaSyBXaPBqsbzoz6WI61ZgHj_AmH8ZUS7E1us",
    authDomain: "partnerportal-dev.adtalem.com",
    projectId: "corp-dev-partner-app-proj",
    storageBucket: "corp-dev-partner-app-proj.appspot.com",
    messagingSenderId: "860895505780",
    appId: "1:860895505780:web:8703978fcebfe4483d1021",
    measurementId: "G-0Q9GMBDNV4"
  },
  channelId: "0ap6s000000CfddAAC",
  header: {
    'Ocp-Apim-Subscription-Key' : "410d51e46d5d435aa74395cb52d3ff9b"
  },
  mailHeader: {
    'Ocp-Apim-Subscription-Key': "31de2530fcc040b6bb0dd890d6b44f4b"
  },
  partnerOrganizations: '[{"id":"0018000000wqcKVAAY","name":"Mercy (MO)"},{"id":"00180000016eUqkAAE","name":"Kaiser Permanente"},{"id":"0018000000vnBq5AAE","name":"UPMC"},{"id":"0018000000wqbPXAAY","name":"HCA Central & West Texas Division"},{"id":"0018000000sKIduAAG","name":"Aveanna Healthcare"},{"id":"0018000000wqbQKAAY","name":"Universal Health Services Inc."},{"id":"0018000000vnCX6AAM","name":"Maxim Healthcare Services"},{"id":"0018000000sK7NeAAK","name":"Banner Health"},{"id":"0018000000vnBbNAAU","name":"Kaiser Permanente - Georgia Administrative Regional Office"},{"id":"0018000001Tq6tvAAB","name":"Enhabit Home Health & Hospice"},{"id":"00180000016cQXNAA2","name":"AccentCare"},{"id":"0012E00001vOH5cQAG","name":"CommonSpirit Health"},{"id":"0012E00002IckudQAB","name":"Health Carousel LLC"},{"id":"0018000000vnBoqAAE","name":"Trinity Health"},{"id":"0018000000wqbKlAAI","name":"BJC HealthCare"},{"id":"0018000000vnCOFAA2","name":"SSM Health"}]'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
