import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../shared/services/shared.service';
import { ApiService } from '../../../../shared/services/api.service';
import {
  Role,
  RolesResponse,
} from '../../../../shared/components/model/customtypes-model.interface';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.scss',
})
export class AddUserComponent implements OnInit {
  public showPopup: boolean = false;
  public email: string = '';
  public errorMsg: boolean = false;
  public errorContent: string;
  public successMsg: boolean = false;
  public successContent: string;
  public password: string = '';
  public name: string = '';
  public roles: any[] = [];
  public uniqueCategories: string[] = [];
  public selectedCategory: string = '';
  public selectedRole: string = '';
  public filteredRoles: any[] = [];
  public pageAccess: string[] = [];
  public fieldPasswordType: boolean = false;
  constructor(
    public sharedService: SharedService,
    public apiService: ApiService
  ) {}

  public ngOnInit(): void {
    this.sharedService.addUser$.subscribe((data: boolean) => {
      this.showPopup = data;
      this.rolesList();
    });
  }

  public closePopup() {
    this.sharedService.addUser.next(false);
    this.successMsg = false;
    this.errorMsg = false;
    this.name = '';
    this.email = '';
    this.password = '';
    this.selectedCategory = '';
    this.selectedRole = '';
  }

  public async rolesList() {
    this.apiService.rolesList().subscribe((data) => {
      if (data && Array.isArray(data.roles)) {
        this.roles = data.roles;
        this.uniqueCategories = [
          ...new Set(this.roles.map((role) => role.category)),
        ];
      } else {
        this.roles = [];
        this.uniqueCategories = [];
      }
    });
  }

  public onCategoryChange() {
    this.filteredRoles = this.roles.filter(
      (role) => role.category === this.selectedCategory
    );
    this.selectedRole = ''; // Reset role selection
    this.pageAccess = []; // Reset page access
  }

  // Update page access when role changes
  public onRoleChange() {
    const selectedRoleObj = this.roles.find(
      (role) => role.role_name === this.selectedRole
    );
    this.pageAccess = selectedRoleObj
      ? Object.keys(selectedRoleObj.access).filter(
          (key) => selectedRoleObj.access[key] === true
        )
      : [];
  }

  public insertUser() {
    const requestBody = {
      display_name: this.name,
      email: this.email,
      password: this.password,
      category: this.selectedCategory,
      role_name: this.selectedRole,
    };

    this.apiService.insertUser(requestBody).subscribe({
      next: (data: any) => {
        if (data.success === 'true') {
          this.successContent = data.message;
          this.successMsg = true;
          this.errorMsg = false;
          this.name = '';
          this.email = '';
          this.password = '';
          this.selectedCategory = '';
          this.selectedRole = '';
          setTimeout(() => {
            this.closePopup();
          }, 2000);
        }
      },
      error: (error) => {
        if (error.error.error_code === 404) {
          this.errorContent = error.error.message;
          this.errorMsg = true;
          this.successMsg = false;
        }
        if (error.status === 400) {
          if (
            this.name == '' ||
            this.email == '' ||
            this.password == '' ||
            this.selectedCategory == '' ||
            this.selectedRole == ''
          ) {
            this.errorContent = 'Please enter all the mandatory fields';
          } else {
            this.errorContent = error.error.message;
          }
          this.errorMsg = true;
          this.successMsg = false;
        }
      },
    });
  }

  public formatKey(key: string): string {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  public toggleTypePassword() {
    this.fieldPasswordType = !this.fieldPasswordType;
  }
}
