import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { environment } from '../../../../environments/environment';
import { ExcelSheetItem, ExportPage } from '../../services/enum/shared.enum';
import { DOCUMENT } from '@angular/common';
import {
  DashboardExcelItems,
  DashboardExcelSheetInfo,
} from '../model/customtypes-model.interface';
import { DashboardExportService } from '../../services/excel-service/dashboard/dashboard.export.service';
import { getFormattedCurrentDateTime } from '../../services/functional-handler/functional-handler';
import { AnalyticsService } from '../../services/analytics/analytics.service';
@Component({
  selector: 'app-export-filter',
  templateUrl: './export-filter.component.html',
  styleUrls: ['./export-filter.component.scss'],
})
export class ExportFilterComponent implements OnInit {
  public isShowExportPopup: boolean = false;
  public activeTab: string = 'pdf';
  public headerActive: boolean;
  public filterItems: DashboardExcelItems[] = [];
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  public userName: string;
  public yearFormat: boolean;

  constructor(
    public sharedService: SharedService,
    public elementRef: ElementRef,
    @Inject(DOCUMENT) public document: Document,
    public dashboardExcelExportService: DashboardExportService,
    public analyticsService: AnalyticsService
  ) {}
  public ngOnInit(): void {
    this.filterItems =
      this.dashboardExcelExportService.getDashboardDisplayItem();
    this.sharedService.allExportClick.subscribe((isOpend: boolean) => {
      this.isShowExportPopup = isOpend;
      this.activeTab = 'pdf';
      this.setInitialValue();
    });
    this.sharedService.isNgScrollReachedMiddle$.subscribe((x: any) => {
      this.headerActive = x;
    });
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.yearFormat = format;
    });
  }

  public closePopup() {
    this.sharedService.allExportClick.next(false);
  }

  public setActiveTab(tabName: string) {
    this.activeTab = tabName;
  }

  public downloadPDF() {
    this.sharedService.pdfDownload.next(true);
    this.sharedService.exportClicked.next(true);
    this.closePopup();
  }

  public async selectOption(selectedOption: DashboardExcelItems) {
    let selectedValue = selectedOption.name;
    if (selectedOption.name == 'all') {
      this.filterItems.forEach((item) => {
        item.selected = false;
      });
    } else {
      const selectedItem = this.filterItems.find(
        (item) => item.name === selectedValue
      );
      if (selectedItem) {
        selectedItem.selected = !selectedItem.selected;
      }
      const anySelected = this.filterItems.some(
        (item) => item.name !== 'all' && item.selected
      );
      const allItem = this.filterItems.find((item) => item.name === 'all');
      if (!anySelected) {
        allItem.selected = true;
      } else {
        allItem.selected = false;
      }
    }
    await this.checkTheAllStatus();
  }

  public async checkTheAllStatus() {
    const anySelected = this.filterItems.some((item) => item.selected);
    if (!anySelected) {
      const allItem = this.filterItems.find((item) => item.name === 'all');
      if (allItem) {
        allItem.selected = true;
      }
    }
  }

  public async downloadExcel(yearFormat: boolean) {
    let selectedItems: DashboardExcelSheetInfo[] = this.filterItems
      .filter((item) => item.selected)
      .map(({ name, sheetName }) => ({ name, sheetName }));

    if (selectedItems.find((item) => item.name == ExcelSheetItem.all)) {
      let allGrpahsInfo: DashboardExcelSheetInfo[] = this.filterItems
        .filter((item) => item.name != ExcelSheetItem.all)
        .map(({ name, sheetName }) => ({ name, sheetName }));
      await this.dashboardExcelExportService.getDashboardGrapahExcelData(
        ExportPage.dashBoard,
        allGrpahsInfo,
        yearFormat
      );
    } else {
      await this.dashboardExcelExportService.getDashboardGrapahExcelData(
        ExportPage.dashBoard,
        selectedItems,
        yearFormat
      );
    }

    this.sharedService.allExportClick.next(false);
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        'Downloaded Excel',
        time,
        sessionId
      );
    }
  }

  public setInitialValue() {
    this.filterItems.forEach((item) => {
      if (item.name === 'all') {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }

  public onClickInside(event: MouseEvent) {
    event.stopPropagation();
  }

  public onClickOutside(event: MouseEvent) {
    this.closePopup();
  }
}
