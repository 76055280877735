<ng-template #popupContainer>
  <div #modalFade class="modal-fade {{ subtitle | titlecase }}">
    <div #modalBox class="modal-main col-lg-8 col-md-10 col-sm-8 col-12">
      <h2 *ngIf="subtitle">{{ subtitle }}</h2>

      <div class="content">
        <div class="d-flex justify-content-between modal-header">
          <h5>{{ title }}</h5>
          <button (click)="close('close')">
            <img alt="close" [src]="closeButtonUrl" />
          </button>
        </div>
        <ng-scrollbar
          [pointerEventsDisabled]="false"
          [autoHeightDisabled]="false"
          [autoWidthDisabled]="false"
          [track]="scrollDirection"
        >
          <ng-content></ng-content>
        </ng-scrollbar>
      </div>
    </div>
    <ng-container *ngIf="url == 'Consultant'">
      <div class="backtohome-wrap">
        <output>
        <a (click)="close('close')" aria-live="polite">
          <span class="icon-arrow-down"></span>
        </a>
      </output>
      </div>
    </ng-container>
  </div>
</ng-template>
