<app-diversity-button
  #button
  [loading]="loading"
  [chartType]="chartType"
  [diversityInfo]="diversityInfo"
  [graphTitle]="graphTitle"
  (onOpenDiversity)="onOpenDiversity($event)"
>
</app-diversity-button>
<ng-template #diversityDetailsTemp>
  <div
    id="diversityModal"
    *ngIf="showDiversityDetails"
    #diversityModal
    class="diversity-details-wraper"
    (click)="onClickPopupWrapper()"
    (keydown)="onClickPopupWrapper()"
  >
    <div
      class="diversity-details-wrapper-sub-wrap"
      (click)="onClickPopupSubWrapper($event)"
      (keydown)="onClickPopupSubWrapper($event)"
    >
      <div class="modal-body">
        <div class="display-flex-row justify-space-between">
          <div class="popup-title-wrap">
            <h5>Diversity Details - {{ diversityData.graphTitle }}</h5>
            <p class="subtitle">Varied Statistics</p>
          </div>
          <div class="modal-header">
            <button (click)="closePopup()">
              <img alt="close" [src]="closeButtonUrl" />
            </button>
          </div>
        </div>
        <div class="content-wrap">
          <ul class="diversity-tabs">
            <li
              [class.active]="activeTab === item"
              *ngFor="let item of filterItems"
              (click)="setActiveTab(item)"
              (keydown)="setActiveTab(item)"
            >
              {{ item }}
            </li>
          </ul>
          <div class="table-wrap row">
            <ul>
              <li
                *ngFor="
                  let header of diversityData.labelInfo;
                  let isLast = last
                "
                class="display-flex"
                [ngClass]="{ 'd-none': isLast }"
              >
                <span></span>
                <div class="tooltip-wrapper">
                  {{ header.name }}
                </div>
              </li>
            </ul>
            <div class="diversity-graph col-md-12">
              <div class="row">
                <div
                  class="col-md-4"
                  *ngFor="let rowData of canvasInfo; trackBy: diversityTrack"
                >
                  <div class="diversity-title">
                    <h5>
                      {{ rowData.name }}
                      <span
                        >Total Count:
                        <span>{{ rowData.totalCount }}</span></span
                      >
                    </h5>
                    <canvas
                      baseChart
                      class="stacked-vertical-bar-chart"
                      [data]="rowData.data"
                      [options]="chartOption"
                      [type]="chartTypes"
                      height="90"
                      width="100%"
                    >
                    </canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #diversityDetailsMobileTemp>
  <div
    id="diversityCard"
    #diversityCard
    class="modal-fade width-100 margin-bottom-20"
    *ngIf="showMobileDiversityDetails"
  >
    <div class="card-wrapper wrapper-background-color width-100">
      <div class="margin-bottom-20">
        <ul class="diversity-tabs-mobile">
          <li
            [class.active]="activeTab === item"
            *ngFor="let item of filterItems"
            (click)="setActiveTab(item)"
            (keydown)="setActiveTab(item)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="cards">
        <ul>
          <li
            *ngFor="let header of diversityData.labelInfo; let isLast = last"
            class="display-flex"
            [ngClass]="{ 'd-none': isLast }"
          >
            <span></span>
            <div class="tooltip-wrapper">
              {{ header.name }}
            </div>
          </li>
        </ul>
        <div
          class="card-sub-wrapper card-border"
          *ngFor="let diversity of canvasInfo; let i = index"
        >
          <div class="card-wraper">
            <div class="card-title">
              <h5>
                {{ diversity.name }}
                <span
                  >Total Count: <span>{{ diversity.totalCount }}</span></span
                >
              </h5>
            </div>
            <div class="card-other-info-warpper width-100 flex-wrap">
              <div class="card-other-info">
                <div class="card-data">
                  <canvas
                    baseChart
                    class="stacked-vertical-bar-chart"
                    [data]="diversity.data"
                    [options]="chartOption"
                    [type]="chartTypes"
                    height="250"
                    width="100%"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
