<ng-container *ngIf="!groupByAlphabet; else alphaGroup">
  <div *ngIf="!shouldApplyAlphasort">
    <div class="after-border" *ngFor="let option of filteredData">
      <label class="filter-checkbox mb-3 pb-3">
        <input
          id="filter_{{ filterLabel + option }}"
          (change)="selectOption($event)"
          [checked]="checkBoxChecked(option)"
          [value]="option"
          class="d-none"
          type="checkbox"
          name="filter"
        />
        <span class="checkbox"></span>
        {{ label ? option[label]?.toString() : option?.toString() }}
      </label>
    </div>
  </div>
  <div *ngIf="shouldApplyAlphasort">
    <div *ngIf="filterLabel != 'Month'">
      <div
        class="after-border w-100"
        *ngFor="let option of filteredData | alphasort"
        [ngClass]="{
          'w-100': option == 'all',
          'radio-year': filterLabel == 'Start_Year',
          'radio-quarter': filterLabel == 'Quarter'
        }"
      >
        <label class="filter-checkbox mb-2">
          <input
            id="filter_{{ filterLabel + option }}"
            (change)="selectOption($event)"
            [checked]="checkBoxChecked(option)"
            [value]="option"
            class="d-none"
            type="checkbox"
            name="filter"
            [disabled]="isYearDisabled(option)"
          />
          <span class="checkbox"></span>
          <span class="label-span">{{
            label ? option[label]?.toString() : option?.toString()
          }}</span>
        </label>
      </div>
    </div>
    <div *ngIf="filterLabel == 'Month'">
      <div
        class="after-border w-100"
        *ngFor="let option of mapMonths(filteredData)"
        [ngClass]="{
          'w-100': option == 'all',
          'radio-month': filterLabel == 'Month'
        }"
      >
        <label class="filter-checkbox mb-2">
          <input
            id="filter_{{ filterLabel + option }}"
            (change)="selectOption($event)"
            [checked]="checkBoxChecked(option)"
            [value]="option"
            class="d-none"
            type="checkbox"
            name="filter"
            [disabled]="isYearDisabled(option)"
          />
          <span class="checkbox"></span>
          <span class="label-span">{{
            label ? option[label]?.toString() : option?.toString()
          }}</span>
        </label>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #alphaGroup>
  <div
    [attr.data-group]="groups"
    #alphaSection
    *ngFor="let groups of alphabetGroupIterator"
  >
    <ng-container *ngIf="alphabetGroups[groups]?.length">
      <div
        *ngFor="let option of alphabetGroups[groups] | alphasort"
        class="after-border"
      >
        <label class="filter-checkbox mb-3 pb-3">
          <input
            id="filter_{{ filterLabel + option }}"
            (change)="selectOption($event)"
            [checked]="checkBoxChecked(option)"
            [value]="option"
            class="d-none"
            type="checkbox"
            name="filter"
          />
          <span class="checkbox"></span>
          {{ label ? option[label]?.toString() : option?.toString() }}
        </label>
      </div>
    </ng-container>
  </div>
</ng-template>
