import { Injectable } from '@angular/core';
import { DetachedRouteHandle } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  CommonFilter,
  FilterItems,
  LearnerAnalytics,
} from '../components/model/customtypes-model.interface';
import { HospitalSharedService } from './hospital/hospital.service';
import { FiscalYear } from './enum/shared.enum';
import * as LZString from 'lz-string';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public routeCache = new Map<string, DetachedRouteHandle>();
  public cacheCleared = false;
  public localStorageSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public localStorageObservable = this.localStorageSubject.asObservable();
  public mobNavEvent = new BehaviorSubject({});
  public menuBarEvent = new BehaviorSubject({});
  public FloatingMenuEvent: Subject<any> = new Subject<any>();
  public FloatingMenuEvent$ = this.FloatingMenuEvent.asObservable();
  public changePasswordEvent: Subject<any> = new Subject<any>();
  public changePasswordEvent$ = this.changePasswordEvent.asObservable();
  public mobileFilterPopupEvent: Subject<any> = new Subject<any>();
  public mobileFilterPopupEvent$ = this.mobileFilterPopupEvent.asObservable();
  public hospitalAnalyticsEvent: Subject<any> = new Subject<any>();
  public hospitalAnalyticsEvent$ = this.hospitalAnalyticsEvent.asObservable();
  public hospitalDataSubject = new BehaviorSubject<string>(null);
  public hospitalData$ = this.hospitalDataSubject.asObservable();
  public productTourEvent: Subject<any> = new Subject<any>();
  public productTourEvent$ = this.productTourEvent.asObservable();
  public isNgScrollReachedBottom: Subject<any> = new Subject<any>();
  public isNgScrollReachedBottom$ = this.isNgScrollReachedBottom.asObservable();
  public lightColorMode: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public lightColorMode$ = this.lightColorMode.asObservable();
  public isNgScrollReachedMiddle: Subject<any> = new Subject<any>();
  public isNgScrollReachedMiddle$ = this.isNgScrollReachedMiddle.asObservable();
  public faqModalEvent: Subject<any> = new Subject<any>();
  public faqModalEvent$ = this.faqModalEvent.asObservable();
  public currentYearEvent: Subject<any> = new Subject<any>();
  public currentYearEvent$ = this.currentYearEvent.asObservable();
  public userDetails: BehaviorSubject<any> = new BehaviorSubject(0);
  public pdfDownload: Subject<any> = new Subject<any>();
  public pdfDownload$ = this.pdfDownload.asObservable();
  public exportClicked: Subject<any> = new Subject<any>();
  public exportClicked$ = this.exportClicked.asObservable();
  // edit order
  public EditOrderEvent: Subject<any> = new Subject<any>();
  public EditOrderEvent$ = this.EditOrderEvent.asObservable();
  // cancel order
  public CancelOrderEvent: Subject<any> = new Subject<any>();
  public CancelOrderEvent$ = this.CancelOrderEvent.asObservable();
  // exportDisable
  public exportDisabled: Subject<any> = new Subject<any>();
  public exportDisabled$ = this.exportDisabled.asObservable();
  // Other program popup
  public openOtherProgram: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public openOtherProgram$ = this.openOtherProgram.asObservable();
  public otherProgramData: BehaviorSubject<any> = new BehaviorSubject({});
  public otherProgramData$ = this.otherProgramData.asObservable();
  public allExportClick: Subject<any> = new Subject<any>();
  public allExportClick$ = this.allExportClick.asObservable();
  public openOtherProgramMob: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public openOtherProgramMob$ = this.openOtherProgramMob.asObservable();
  public diversityDetails: BehaviorSubject<any> = new BehaviorSubject(0);
  public diversityDetails$ = this.diversityDetails.asObservable();
  public faqMob: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public faqMob$ = this.faqMob.asObservable();
  public assetUrl: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public assetUrl$ = this.assetUrl.asObservable();
  public yearFormat: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public yearFormat$ = this.yearFormat.asObservable();
  public yearFormatChange: Subject<any> = new Subject<any>();
  public yearFormatChange$ = this.yearFormatChange.asObservable();
  public addOrg: Subject<any> = new Subject<any>();
  public addOrg$ = this.addOrg.asObservable();
  public openFilterPopup: Subject<any> = new Subject<any>();
  public sessionOperations: any = {
    get: (key: string) => {
      try {
        const compressedData = sessionStorage.getItem(key);
        if (compressedData) {
          const decompressedData = LZString.decompressFromUTF16(compressedData);
          if (decompressedData) {
            return JSON.parse(decompressedData);
          } else {
            return null;
          }
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
    set: (key: string, data: any) => {
      try {
        const jsonString = JSON.stringify(data);
        const compressedData = LZString.compressToUTF16(jsonString);
        sessionStorage.setItem(key, compressedData);
      } catch (error) {}
    },
  };
  public universityStausSubject = new BehaviorSubject<any[]>([]);
  public calenderPopupEvent: Subject<any> = new Subject<any>();
  public calenderPopupEvent$ = this.calenderPopupEvent.asObservable();
  public calenderDateEvent: Subject<any> = new Subject<any>();
  public calenderDateEvent$ = this.calenderDateEvent.asObservable();
  public applyFilters: Subject<any> = new Subject();
  constructor(private readonly hospitalSharedService: HospitalSharedService) { }
  public hospitalData(newData: string) {
    this.hospitalDataSubject.next(newData);
  }
  public applySeFilters(event) {
    this.applyFilters.next(event);
  }
  public updateUniversityStatus(data: any[]) {
    this.universityStausSubject.next(data);
  }
  public getUniversityStatus() {
    return this.universityStausSubject.asObservable();
  }
  public async getSelectedFilterData(
    selectedFilters: any,
    data: any,
    order: string[],
    chart: string,
    yearFormat?: boolean,
    type?: string
  ) {
    let minYear = this.getYearFromFilter();
    const dict = {
      Hospital: 'hospital',
      Institution: 'institution',
      Location: 'location',
      Start_Year: 'fiscal_year',
      Quarter: 'fiscal_month',
      Month: 'fiscal_month',
      Program: 'program_group',
    };
    const combObj: any = {};
    Object.keys(selectedFilters).forEach((key: any, i: number) => {
      const ref = dict[key] || key;
      combObj[ref] = selectedFilters[key];
    });
    try {
      if (
        Object.values(selectedFilters).every(
          (x: any) => x === 'all' || x[0] === 'all'
        )
      ) {
        const filterKeyString: any = Object.values(selectedFilters).reduce(
          (accumulator: any, reducer: any) => accumulator + reducer, ''
        );
        const filteredData = await data
          .map((x: any) => {
            return {
              ...x,
              filterString: this.getValuesBasedOnTheOrder(
                x?.filter,
                order
              ).reduce(
                (accumulator: any, reducer: any) => accumulator + reducer, ''
              ),
            };
          })
          ?.find(
            (x: any) =>
              x?.filterString.toLowerCase().replace(/ /g, '') ===
              filterKeyString.toLowerCase().replace(/ /g, '')
          );
        return filteredData;
      } else {
        switch (chart) {
          case 'studentPersistence': {
            return await this.getSumOfStudentPersistence(
              data,
              order,
              combObj,
              minYear,
              yearFormat,
              selectedFilters
            );
          }
          case 'applicantsEnrollment': {
            return await this.getSumOfApplicantEnrollment(
              data,
              order,
              combObj,
              minYear,
              yearFormat,
              selectedFilters,
              type
            );
          }
          case 'learnerAnalytics': {
            const selectedObjWithOrder = {};
            selectedObjWithOrder['hospital'] = selectedFilters?.Hospital;
            selectedObjWithOrder['institution'] = selectedFilters?.Institution;
            selectedObjWithOrder['state'] = selectedFilters?.Location;
            selectedObjWithOrder['program_group'] = selectedFilters?.Program;
            selectedObjWithOrder['modality'] = selectedFilters?.Modality;
            return this.getSumOfLearnerAnalytics(
              data,
              order,
              selectedObjWithOrder,
              minYear,
              yearFormat
            );
          }
          case 'studentEnrollment': {
            return this.getSumOfStudentEnrollment(
              data,
              order,
              combObj,
              minYear,
              yearFormat,
              selectedFilters,
              type
            );
          }
          case 'alumniProgram': {
            return this.getSumOfAlumniProgram(
              data,
              order,
              combObj,
              minYear,
              yearFormat,
              selectedFilters
            );
          }
          case 'anticipatedGradDate': {
            return this.getSumOfAnticipatedGradDate(
              data,
              order,
              combObj,
              minYear,
              yearFormat,
              selectedFilters
            );
          }
        }
      }
    } catch (error) {
      console.error('An Error Occurred');
      throw error;
    }
  }
  public async getSumOfStudentPersistence(
    data: any[],
    order: any,
    combObj: any,
    minYear: number,
    yearFormat: boolean,
    selectedFilters: any
  ) {
    let otherProgramDetails = [];
    this.getParentOrganization(data, combObj);
    let filteredArr = await this.filterDataByYear(
      data,
      minYear,
      yearFormat,
      selectedFilters
    );
    delete combObj.fiscal_year;
    delete order[0];
    filteredArr = filteredArr.filter((x: any) =>
      this.findAndReturnIfObjectHasValues(x?.filter, order, combObj, yearFormat)
    );
    const filteredData = filteredArr;
    otherProgramDetails = await this.getOtherProgramFromStudentPersistence(
      filteredData
    );
    return {
      programEnrollments: otherProgramDetails,
      filteredData: filteredArr,
    };
  }
  // getting ApplicantEnrollment chart program count
  public async getSumOfApplicantEnrollment(
    data: any[],
    order: any,
    combObj: any,
    minYear: number,
    yearFormat: boolean,
    selectedFilters: any,
    type?: string
  ) {
    let otherProgramDetails = [];
    this.getParentOrganization(data, combObj);
    let filteredArr = await this.filterDataByYear(
      data,
      minYear,
      yearFormat,
      selectedFilters
    );
    delete combObj.fiscal_year;
    delete order[0];
    filteredArr = filteredArr.filter((x: any) =>
      this.findAndReturnIfObjectHasValues(x?.filter, order, combObj, yearFormat)
    );
    filteredArr = filteredArr.map((item) => {
      item.filter.fiscal_year = String(item.filter.fiscal_year).split(' ');
      item.filter.fiscal_month = String(item.filter.fiscal_month).split(' ');
      item.filter.program_group = String(item.filter.program_group).split(' ');
      item.filter.institution = String(item.filter.institution).split(' ');
      item.filter.location = String(item.filter.location).split(' ');
      item.filter.hospital = String(item.filter.hospital).split(' ');
      return item; // Ensure to return the modified item
    });
    const filteredData = filteredArr;
    otherProgramDetails = await this.getOtherProgramFromApplicantsToEnrollment(
      filteredData
    );
    const applicantsToEnrollmentCount = await this.getApplicantsToEnrollment(
      filteredData,
      selectedFilters,
      yearFormat,
      type
    );
    return {
      ApplicantsToEnrollment: applicantsToEnrollmentCount,
      otherPrograms: otherProgramDetails,
      filteredData: filteredArr,
    };
  }

  public async getApplicantsToEnrollment(
    data,
    selectedFilters,
    yearFormat,
    type
  ) {
    const customCompare = (a, b) => a - b;
    const qV =
      selectedFilters.Quarter == 'all' ? ['all'] : selectedFilters.Quarter;
    let quarter = [...new Set(qV)].sort();
    const mV = selectedFilters.Month == 'all' ? ['all'] : selectedFilters.Month;
    let month = [...new Set(mV)].sort(customCompare);
    const pV =
      selectedFilters.Program == 'all' ? ['all'] : selectedFilters.Program;
    let program = [...new Set(pV)].sort();
    const years: any = [
      ...new Set(
        data.map((x: any) => {
          if (
            x?.ApplicantsToEnrollment &&
            x.ApplicantsToEnrollment.length > 0
          ) {
            return x?.ApplicantsToEnrollment?.[0]?.year;
          }
          return null;
        })
      ),
    ].sort(customCompare);
    const isPAll = program.find((p) => p == 'all');
    let programs = isPAll
      ? [
        ...new Set(
          data.map((x: any) => {
            if (
              x?.ApplicantsToEnrollment &&
              x?.ApplicantsToEnrollment.length > 0
            ) {
              return x?.ApplicantsToEnrollment[0]?.program;
            }
            return null;
          })
        ),
      ].sort()
      : program;
    const isQAll = quarter.find((v) => v == 'all');
    let quarters = isQAll
      ? [
        ...new Set(
          data.map((x: any) => {
            if (
              x?.ApplicantsToEnrollment &&
              x.ApplicantsToEnrollment.length > 0
            ) {
              return this.getQuarterByMonth(
                x?.ApplicantsToEnrollment?.[0]?.month,
                yearFormat
              );
            }
            return null;
          })
        ),
      ].sort()
      : quarter;
    const isMAll = month.find((v) => v == 'all');
    let months = isMAll
      ? [
        ...new Set(
          data.map((x: any) => {
            if (
              x?.ApplicantsToEnrollment &&
              x.ApplicantsToEnrollment.length > 0
            ) {
              this.getMonthByNumber(x?.ApplicantsToEnrollment?.[0]?.month);
            }
            return null;
          })
        ),
      ]
      : month;
    let finalResult = [];
    if (localStorage.getItem('activeTab AE') === 'yearly') {
      const yearPromise = years.sort().map(async (x: any) => {
        const isQAll = quarter.find((v) => v == 'all');
        const isPAll = program.find((p) => p == 'all');
        if (isQAll) {
          let yearData = [];
          if (isPAll) {
            yearData.push({
              program: x,
              programCount: await this.getOneOfAllSumForApplicantsToEnrollment(
                data.filter(
                  (z: any) => z?.ApplicantsToEnrollment?.[0]?.year === x
                )
              ),
            });
            return yearData;
          } else {
            yearData.push({
              program: x,
              programCount: {},
            });
            const programSorted = [...program].sort()
            const promises = programSorted.map(async (p) => {
              return {
                program: p,
                programCount:
                  await this.getOneOfAllSumForApplicantsToEnrollment(
                    data.filter((z: any) => {
                      return (
                        z?.ApplicantsToEnrollment?.[0]?.year === x &&
                        p == z?.ApplicantsToEnrollment?.[0]?.program
                      );
                    })
                  ),
              };
            });
            const res = await Promise.all(promises);
            return [...yearData, ...res];
          }
        }
        else {
          let yearData = [];
          yearData.push({
            program: x,
            programCount: {},
          });
          
          const promises = programs.sort().map(async (p) => {
            const promises1 = quarter.sort().map(async (q) => {
              return {
                program: q,
                programCount:
                  await this.getOneOfAllSumForApplicantsToEnrollment(
                    data.filter((z: any) => {
                      const qr = this.getQuarterByMonth(
                        z.ApplicantsToEnrollment[0].month,
                        yearFormat
                      );
                      return (
                        z?.ApplicantsToEnrollment?.[0]?.year === x &&
                        q == qr &&
                        p == z?.ApplicantsToEnrollment[0]?.program
                      );
                    })
                  ),
              };
            });
            const quarterResult = await Promise.all(promises1);
            return [
              {
                program: p,
                programCount: {},
              },
              ...quarterResult,
            ];
          });
          const res = (await Promise.all(promises)).flat();
          yearData = [...yearData, ...res];
          return yearData;
        }
      });
      const yearsData = (await Promise.all(yearPromise)).flat();
      finalResult = yearsData;
    } else if (localStorage.getItem('activeTab AE') === 'quarterly') {
      const quarterSorted = [...quarters].sort()
      const quarterPromise = quarterSorted.map(async (x: any) => {
        const isQAll = quarter.find((v) => v == 'all');
        const isPAll = program.find((p) => p == 'all');
        if (isQAll) {
          let yearData = [];
          if (isPAll) {
            yearData.push({
              program: x,
              programCount: await this.getOneOfAllSumForApplicantsToEnrollment(
                data.filter(
                  (z: any) => z?.ApplicantsToEnrollment?.[0]?.year === x
                )
              ),
            });
            return yearData;
          } else {
            yearData.push({
              program: x,
              programCount: {},
            });
           
            const promises = programs.sort().map(async (p) => {
              const promises1 = years.sort().map(async (q) => {
                return {
                  program: q,
                  programCount:
                    await this.getOneOfAllSumForApplicantsToEnrollment(
                      data.filter((z: any) => {
                        const qr = this.getQuarterByMonth(
                          z.ApplicantsToEnrollment[0].month,
                          yearFormat
                        );
                        return (
                          z?.ApplicantsToEnrollment?.[0]?.year === q &&
                          p == z?.ApplicantsToEnrollment?.[0]?.program &&
                          x == qr
                        );
                      })
                    ),
                };
              });
              const quarterResult = await Promise.all(promises1);
              return [
                {
                  program: p,
                  programCount: {},
                },
                ...quarterResult,
              ];
            });
            const res = (await Promise.all(promises)).flat();
            return [...yearData, ...res];
          }
        } else {
          let quarterData = [];
          quarterData.push({
            program: x,
            programCount: {},
          });
         
          const promises = programs.sort().map(async (p) => {
            const promises1 = years.sort().map(async (q) => {
              return {
                program: q,
                programCount:
                  await this.getOneOfAllSumForApplicantsToEnrollment(
                    data.filter((z: any) => {
                      const qr = this.getQuarterByMonth(
                        z.ApplicantsToEnrollment[0].month,
                        yearFormat
                      );
                      return (
                        z?.ApplicantsToEnrollment?.[0]?.year === q &&
                        p == z?.ApplicantsToEnrollment?.[0]?.program &&
                        x == qr
                      );
                    })
                  ),
              };
            });
            const quarterResult = await Promise.all(promises1);
            return [
              {
                program: p,
                programCount: {},
              },
              ...quarterResult,
            ];
          });
          const res = (await Promise.all(promises)).flat();
          quarterData = [...quarterData, ...res];
          return quarterData;
        }
      });
      const yearsData = (await Promise.all(quarterPromise)).flat();
      finalResult = yearsData;
    } else if (localStorage.getItem('activeTab AE') === 'monthly') {
      const yearPromise = years.sort().map(async (x: any) => {
        const isMAll = month.find((v) => v == 'all');
        const isPAll = program.find((p) => p == 'all');
        if (isMAll) {
          let yearData = [];
          if (isPAll) {
            yearData.push({
              program: x,
              programCount: await this.getOneOfAllSumForApplicantsToEnrollment(
                data.filter((z: any) => z?.ApplicantsToEnrollment[0].year === x)
              ),
            });
            return yearData;
          } else {
            yearData.push({
              program: x,
              programCount: {},
            });
          
            const filterDataByMonthAndProgram = (data, month, year, program) => {
              return data.filter((z) => {
                const qr = this.getMonthByNumber(z.ApplicantsToEnrollment[0].month);
                return (
                  z?.ApplicantsToEnrollment?.[0]?.year === year &&
                  program === z?.ApplicantsToEnrollment[0]?.program &&
                  month === qr
                );
              });
            };

            const getProgramCount = async (data, month, year, program) => {
              const filteredData = filterDataByMonthAndProgram(data, month, year, program);
              return this.getOneOfAllSumForApplicantsToEnrollment(filteredData);
            };

            const processProgramsForMonth = async (data, month, year, programSorted) => {
              const promises = programSorted.map(async (program) => {
                const programCount = await getProgramCount(data, month, year, program);
                return {
                  program,
                  programCount,
                };
              });
              return Promise.all(promises);
            };

            const processMonthsAndPrograms = async (data, months, programs, year) => {
              const monthSorted = [...months].sort(customCompare);
              const programSorted = [...programs].sort(customCompare);

              const promises = monthSorted.map(async (month) => {
                const programResults = await processProgramsForMonth(data, month, year, programSorted);
                return [
                  {
                    program: month, // Assuming you want to keep the month as the program here
                    programCount: {},
                  },
                  ...programResults,
                ];
              });

              return Promise.all(promises);
            };

            const result = await processMonthsAndPrograms(data, months, programs, x);
            const res = (await Promise.all(result)).flat();
            return [...yearData, ...res];
          }
        } else {
          let yearData = [];
          yearData.push({
            program: x,
            programCount: {},
          });
          
          const filterDataByMonthAndProgram = (data, month, year, program) => {
            return data.filter((z) => {
              const qr = this.getMonthByNumber(z.ApplicantsToEnrollment[0].month);
              return (
                z?.ApplicantsToEnrollment?.[0]?.year === year &&
                month === qr &&
                program === z?.ApplicantsToEnrollment?.[0]?.program
              );
            });
          };

          const getProgramCount = async (data, month, year, program) => {
            const filteredData = filterDataByMonthAndProgram(data, month, year, program);
            return this.getOneOfAllSumForApplicantsToEnrollment(filteredData);
          };

          const processProgramsForMonth = async (data, month, year, programSorted) => {
            const programPromises = programSorted.map(async (program) => {
              const programCount = await getProgramCount(data, month, year, program);
              return {
                program,
                programCount,
              };
            });
            return Promise.all(programPromises);
          };

          const processMonthsAndPrograms = async (data, months, programs, year) => {
            const monthSorted = [...months].sort(customCompare);
            const programSorted = [...programs].sort(customCompare);

            const monthResults = await Promise.all(monthSorted.map(async (month) => {
              const programResults = await processProgramsForMonth(data, month, year, programSorted);
              return [
                {
                  program: month, 
                  programCount: {},
                },
                ...programResults,
              ];
            }));

            return monthResults;
          };

        
          const result = await processMonthsAndPrograms(data, months, programs, x);
          const res = (await Promise.all(result)).flat();
          yearData = [...yearData, ...res];
          return yearData;
        }
      });
      const yearsData = (await Promise.all(yearPromise)).flat();
      finalResult = yearsData;
    }
    return finalResult;
  }

  // getting LearnerAnalytics chart program count
  public getSumOfLearnerAnalytics(
    data: any[],
    order: string[],
    combObj: any,
    minYear: number,
    yearFormat: boolean
  ) {
    let diversityDetails = [];
    this.getParentOrganization(data, combObj);
    let filteredArr = data.filter((x: any) =>
      this.findAndReturnIfObjectHasValues(x?.filter, order, combObj, yearFormat)
    );
    const studentDetails = filteredArr.reduce(
      (acc: any, cur: LearnerAnalytics) => {
        let filteredData = this.filterLearnerAnalyticsApplicantsCount(
          cur,
          minYear,
          yearFormat
        );
        diversityDetails.push(filteredData.data);
        return {
          Applicants: acc.Applicants + filteredData.studentDetails.Applicants,
          Students: acc.Students + filteredData.studentDetails.Students,
          Alumni: acc.Alumni + filteredData.studentDetails.Alumni,
        };
      },
      {
        Applicants: 0,
        Students: 0,
        Alumni: 0,
      }
    );
    return {
      studentDetails: {
        ...studentDetails,
      },
      filteredData: diversityDetails,
    };
  }


  public getQuarterByMonth(month: any, yearFormat: boolean) {
    return yearFormat ? this.getQuarterForYearFormat(month) : this.getQuarterForNonYearFormat(month);
  }

  public getQuarterForYearFormat(month: number): string {
    if (month >= 1 && month <= 3) {
      return 'Q3';
    }
    if (month >= 4 && month <= 6) {
      return 'Q4';
    }
    if (month >= 7 && month <= 9) {
      return 'Q1';
    }
    return 'Q2';
  }

  public getQuarterForNonYearFormat(month: number): string {
    if (month >= 1 && month <= 3) {
      return 'Q1';
    }
    if (month >= 4 && month <= 6) {
      return 'Q2';
    }
    if (month >= 7 && month <= 9) {
      return 'Q3';
    }
    return 'Q4';
  }

  // getting Student Enrollment chart program count
  public async getSumOfStudentEnrollment(
    data: any[],
    order: any,
    combObj: any,
    minYear: number,
    yearFormat: boolean,
    selectedFilters: any,
    type?: string
  ) {
    this.getParentOrganization(data, combObj);
    let filteredArr = await this.filterDataByYear(
      data,
      minYear,
      yearFormat,
      selectedFilters
    );
    delete combObj.fiscal_year;
    delete order[0];
    const qV =
      selectedFilters.Quarter == 'all' ? ['all'] : selectedFilters.Quarter;
    let quarter = [...new Set(qV)];
    const mV = selectedFilters.Month == 'all' ? ['all'] : selectedFilters.Month;
    let month = [...new Set(mV)];
    filteredArr = filteredArr.filter((x: any) =>
      this.findAndReturnIfObjectHasValues(x?.filter, order, combObj, yearFormat)
    );
    let filterResult: any;
    const years: any = [
      ...new Set(filteredArr.map((x: any) => x?.programYear[0]?.year)),
    ];
    const finalResult = [];
    const otherProgramResult = [];
    if (localStorage.getItem('activeTab SE') === 'yearly') {
      years.sort().forEach((x: any, i: number) => {
        const isQAll = quarter.find((v) => v == 'all');
        if (isQAll) {
          finalResult.push({
            year: x,
            programsCount: this.getOneOfAllSumForStudentEnrollment(
              filteredArr.filter((z: any) => z.programYear[0].year === x)
            ),
          });
        } else {
          finalResult.push({
            year: x,
            programsCount: {},
          });
          const quarterSorted = [...quarter].sort((a: any, b: any) => a - b)
          quarterSorted.forEach((q) => {
            finalResult.push({
              year: q,
              programsCount: this.getOneOfAllSumForStudentEnrollment(
                filteredArr.filter((z: any) => {
                  const qr = this.getQuarterByMonth(
                    z.programYear[0].month,
                    yearFormat
                  );
                  return z.programYear[0].year === x && q == qr;
                })
              ),
            });
          });
        }
      });
    } else if (localStorage.getItem('activeTab SE') === 'quarterly') {
      const isQAll = quarter.find((v) => v == 'all');
      if (isQAll) {
        years.sort().forEach((x: any, i: number) => {
          finalResult.push({
            year: x,
            programsCount: this.getOneOfAllSumForStudentEnrollment(
              filteredArr.filter((z: any) => z.programYear[0].year === x)
            ),
          });
        });
      } else {
        const quarterSorted = [...quarter].sort((a: any, b: any) => a - b)
        quarterSorted.forEach((q: any, i: number) => {
          finalResult.push({
            year: q,
            programsCount: {},
          });
          years.sort().forEach((x) => {
            finalResult.push({
              year: x,
              programsCount: this.getOneOfAllSumForStudentEnrollment(
                filteredArr.filter((z: any) => {
                  const qr = this.getQuarterByMonth(
                    z.programYear[0].month,
                    yearFormat
                  );
                  return z.programYear[0].year === x && q == qr;
                })
              ),
            });
          });
        });
      }
    } else if (localStorage.getItem('activeTab SE') === 'monthly') {
      const isMAll = month.find((v) => v == 'all');
      if (isMAll) {
        years.sort().forEach((x: any, i: number) => {
          finalResult.push({
            year: x,
            programsCount: this.getOneOfAllSumForStudentEnrollment(
              filteredArr.filter((z: any) => z.programYear[0].year === x)
            ),
          });
        });
      } else {
        years.sort().forEach((q: any, i: number) => {
          finalResult.push({
            year: q,
            programsCount: {},
          });
          month.forEach((x) => {
            finalResult.push({
              year: x,
              programsCount: this.getOneOfAllSumForStudentEnrollment(
                filteredArr.filter((z: any) => {
                  const qr = this.getMonthByNumber(z.programYear[0].month);
                  return z.programYear[0].year === q && x == qr;
                })
              ),
            });
          });
        });
      }
    }
    years.sort().forEach((x: any, i: number) => {
      otherProgramResult.push({
        year: x,
        programsCount: this.getOneOfAllSumForStudentEnrollment(
          filteredArr.filter((z: any) => z.programYear[0].year === x)
        ),
      });
    });
    filterResult = {
      programYear: finalResult,
      otherPrograms: otherProgramResult,
      filteredData: filteredArr,
    };
    return filterResult;
  }
  public async getSumOfAlumniProgram(
    data: any[],
    order: any,
    combObj: any,
    minYear: number,
    yearFormat: boolean,
    selectedFilters: any
  ) {
    this.getParentOrganization(data, combObj);
    let filteredArr = await this.filterDataByYear(
      data,
      minYear,
      yearFormat,
      selectedFilters
    );
    delete combObj.fiscal_year;
    delete order[0];
    const qV =
      selectedFilters.Quarter == 'all' ? ['all'] : selectedFilters.Quarter;
    let quarter = [...new Set(qV)];
    const mV = selectedFilters.Month == 'all' ? ['all'] : selectedFilters.Month;
    let month = [...new Set(mV)];
    filteredArr = filteredArr.filter((x: any) =>
      this.findAndReturnIfObjectHasValues(x?.filter, order, combObj, yearFormat)
    );
    let filterResult: any;
    const years: any = [
      ...new Set(filteredArr.map((x: any) => x?.programYear[0]?.year)),
    ];
    const finalResult = [];
    const otherProgramResult = [];
    if (localStorage.getItem('activeTab AP') === 'yearly') {
      years.sort().forEach((x: any, i: number) => {
        const isQAll = quarter.find((v) => v == 'all');
        if (isQAll) {
          finalResult.push({
            year: x,
            programsCount: this.getOneOfAllSumForStudentEnrollment(
              filteredArr.filter((z: any) => z.programYear[0].year === x)
            ),
          });
        } else {
          finalResult.push({
            year: x,
            programsCount: {},
          });
          quarter.sort().forEach((q) => {
            finalResult.push({
              year: q,
              programsCount: this.getOneOfAllSumForStudentEnrollment(
                filteredArr.filter((z: any) => {
                  const qr = this.getQuarterByMonth(
                    z.programYear[0].month,
                    yearFormat
                  );
                  return z.programYear[0].year === x && q == qr;
                })
              ),
            });
          });
        }
      });
    } else if (localStorage.getItem('activeTab AP') === 'quarterly') {
      const isQAll = quarter.find((v) => v == 'all');
      if (isQAll) {
        years.sort().forEach((x: any, i: number) => {
          finalResult.push({
            year: x,
            programsCount: this.getOneOfAllSumForStudentEnrollment(
              filteredArr.filter((z: any) => z.programYear[0].year === x)
            ),
          });
        });
      } else {
        quarter.sort((a: any, b: any) => a - b).forEach((q: any, i: number) => {
          finalResult.push({
            year: q,
            programsCount: {},
          });
          years.sort().forEach((x) => {
            finalResult.push({
              year: x,
              programsCount: this.getOneOfAllSumForStudentEnrollment(
                filteredArr.filter((z: any) => {
                  const qr = this.getQuarterByMonth(
                    z.programYear[0].month,
                    yearFormat
                  );
                  return z.programYear[0].year === x && q == qr;
                })
              ),
            });
          });
        });
      }
    } else if (localStorage.getItem('activeTab AP') === 'monthly') {
      const isMAll = month.find((v) => v == 'all');
      if (isMAll) {
        years.sort().forEach((x: any, i: number) => {
          finalResult.push({
            year: x,
            programsCount: this.getOneOfAllSumForStudentEnrollment(
              filteredArr.filter((z: any) => z.programYear[0].year === x)
            ),
          });
        });
      } else {
        years.sort().forEach((q: any, i: number) => {
          finalResult.push({
            year: q,
            programsCount: {},
          });
          month.forEach((x) => {
            finalResult.push({
              year: x,
              programsCount: this.getOneOfAllSumForStudentEnrollment(
                filteredArr.filter((z: any) => {
                  const qr = this.getMonthByNumber(z.programYear[0].month);
                  return z.programYear[0].year === q && x == qr;
                })
              ),
            });
          });
        });
      }
    }
    years.sort().forEach((x: any, i: number) => {
      otherProgramResult.push({
        year: x,
        programsCount: this.getOneOfAllSumForStudentEnrollment(
          filteredArr.filter((z: any) => z.programYear[0].year === x)
        ),
      });
    });
    filterResult = {
      programYear: finalResult,
      otherPrograms: otherProgramResult,
      filteredData: filteredArr,
    };
    return filterResult;
  }
  public getOneOfAllSumForStudentEnrollment(filteredArr: any) {
    const programYear = filteredArr
      .map((z: any) => z.programYear)
      .reduce((acc: any, cur: any) => acc?.concat(cur), []);
    const availablePrograms = [
      ...new Set(
        programYear.map((d: any) => Object.keys(d.programsCount)).flat()
      ),
    ];
    const finalObj: any = availablePrograms.reduce(
      (acc: any, currentValue: any) => {
        acc[currentValue] = 0;
        return acc;
      },
      {}
    );
    return programYear.reduce(
      (acc: any, cur: any) => {
        const obj: any = {};
        availablePrograms.forEach((pgms: any) => {
          obj[pgms] =
            acc[pgms] +
            (typeof cur?.programsCount[pgms] === 'number'
              ? cur?.programsCount[pgms]
              : 0);
        });
        return obj;
      },
      { ...finalObj }
    );
  }
  public async getOneOfAllSumForApplicantsToEnrollment(filteredArr: any) {
    const applicantsToEnrollment = filteredArr;
    const availablePrograms = [
      ...new Set(
        applicantsToEnrollment
          .map((d: any) => {
            if (
              d?.ApplicantsToEnrollment[0]?.programCount != null
            ) {
              return Object.keys(d.ApplicantsToEnrollment[0].programCount);
            }
            return null;
          })
          .flat()
      ),
    ];
    const finalObj: any = availablePrograms.reduce(
      (acc: any, currentValue: any) => {
        acc[currentValue] = 0;
        return acc;
      },
      {}
    );
    const programCount = applicantsToEnrollment.reduce(
      (acc: any, cur: any) => {
        if (
          cur?.ApplicantsToEnrollment[0]?.programCount
        ) {
          const obj: any = {};
          availablePrograms.forEach((pgms: any) => {
            obj[pgms] =
              acc[pgms] +
              (typeof cur?.ApplicantsToEnrollment[0].programCount[pgms] ===
                'number'
                ? cur?.ApplicantsToEnrollment[0].programCount[pgms]
                : 0);
          });
          return obj;
        }
        return acc;
      },
      { ...finalObj }
    );
    return [{ programCount }];
  }

  public findAndReturnIfObjectHasValues(
    obj: any,
    order: any[],
    selected: any,
    yearFormat: boolean
  ) {
    const filteredOrder = this.getFilteredOrder(order, selected);
    const conditions = filteredOrder.map(o => this.checkCondition(obj, o, selected, yearFormat));
    return conditions.every(Boolean);
  }

  public getFilteredOrder(order: any[], selected: any): any[] {
    return order.filter(v => selected[v] !== 'all' && selected[v][0] !== 'all');
  }

  public checkCondition(obj: any, key: any, selected: any, yearFormat: boolean): boolean {
    const str = obj[key]?.toString()?.toLowerCase();
    const arr = str ? str.split(',') : [];
    const selectStr = selected[key]?.toString()?.toLowerCase();
    const selectArr = selectStr ? selectStr.split(',') : [];

    return arr.some(item => this.matchesAnySelectItem(item, selectArr, key, yearFormat));
  }

  public matchesAnySelectItem(item: string, selectArr: string[], key: any, yearFormat: boolean): boolean {
    const processedValue = key === 'fiscal_month'
      ? this.getQuarterByMonth(item, yearFormat).toLowerCase()
      : item.toLowerCase();

    const alternateValue = key === 'fiscal_month'
      ? this.getMonthByNumber(item).toLowerCase()
      : item.toLowerCase();

    return selectArr.some(selectItem => processedValue === selectItem || alternateValue === selectItem);
  }



  public getValuesBasedOnTheOrder(obj: any, order: any) {
    const arr: string[] = [];
    order.forEach((x: any) => {
      arr.push(obj[x]);
    });
    return arr;
  }
  public getOrgId() {
    const org = localStorage.getItem('organization');
    return JSON.parse(environment.partnerOrganizations).find(
      (x: any) => x.name === org
    )?.id;
  }
  public paginateData(page: number, itemsPerPage: number, data: any) {
    let result = [];
    if (data?.length) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      result = data.slice(0, endIndex);
    }
    return result;
  }
  public async getOtherProgramFromApplicantsToEnrollment(data): Promise<any> {
    if (data.length) {
      return data
        .flatMap(({ ApplicantsToEnrollment }) => ApplicantsToEnrollment)
        .reduce((accumulator, programObj) => {
          // Find if the program already exists in accumulator
          const existingProgram = accumulator.find(
            (item) => item.program === programObj.program
          );
          if (existingProgram) {
            // If program exists, accumulate programCount
            existingProgram.programCount.Enrollment +=
              programObj.programCount.Enrollment;
            existingProgram.programCount.Applicants +=
              programObj.programCount.Applicants;
          } else {
            // If program doesn't exist, push a new object to accumulator
            accumulator.push({
              program: programObj.program,
              programCount: {
                Enrollment: programObj.programCount.Enrollment,
                Applicants: programObj.programCount.Applicants,
              },
            });
          }

          return accumulator;
        }, []);
    } else {
      return [];
    }
  }
  public async getOtherProgramFromStudentPersistence(data) {
    if (data.length) {
      return data
        .flatMap(({ programEnrollments }) => programEnrollments)
        .reduce((accumulator, programObj) => {
          // Find if the program already exists in accumulator
          const existingProgram = accumulator.find(
            (item) => item.program === programObj.program
          );
          if (existingProgram) {
            // If program exists, accumulate programCount
            existingProgram.studentCount.New += programObj.studentCount.New;
            existingProgram.studentCount.Continuing +=
              programObj.studentCount.Continuing;
            existingProgram.studentCount.Withdrawn +=
              programObj.studentCount.Withdrawn;
            existingProgram.studentCount.Graduated +=
              programObj.studentCount.Graduated;
          } else {
            // If program doesn't exist, push a new object to accumulator
            accumulator.push({
              program: programObj.program,
              studentCount: {
                New: programObj.studentCount.New,
                Continuing: programObj.studentCount.Continuing,
                Withdrawn: programObj.studentCount.Withdrawn,
                Graduated: programObj.studentCount.Graduated,
              },
            });
          }

          return accumulator;
        }, []);
    } else {
      return [];
    }
  }
  public async getSumOfAnticipatedGradDate(
    data: any[],
    order: any,
    combObj: any,
    minYear: number,
    yearFormat: boolean,
    selectedFilters: any
  ) {
    this.getParentOrganization(data, combObj);
    let filteredArr = await this.filterDataByYear(
      data,
      minYear,
      yearFormat,
      selectedFilters
    );
    delete combObj.fiscal_year;
    delete order[0];
    filteredArr = filteredArr.filter((x: any) =>
      this.findAndReturnIfObjectHasValues(x?.filter, order, combObj, yearFormat)
    );
    let filterResult: any;
    let filterPrgmYears: any = {};
    let result: any = [];
    filteredArr.forEach((x: any) => {
      const anticipatedGradYear = x?.programYear[0]?.anticipatedGradYear;
      if (anticipatedGradYear != null) {
        if (filterPrgmYears[anticipatedGradYear] == null) {
          filterPrgmYears[anticipatedGradYear] = {};
        }
        const anticipatedGradQuarter =
          x?.programYear[0]?.anticipatedGradQuarter;
        if (
          filterPrgmYears[anticipatedGradYear][anticipatedGradQuarter] == null
        ) {
          filterPrgmYears[anticipatedGradYear][anticipatedGradQuarter] = [];
        }
        filterPrgmYears[anticipatedGradYear][anticipatedGradQuarter].push(
          x?.programYear[0]
        );
      }
    });
    Object.keys(filterPrgmYears).forEach((year: any) => {
      const yearData = filterPrgmYears[year];
      Object.keys(yearData)
        .sort((a, b) => a.localeCompare(b))
        .forEach((quarter: any) => {
          const quarterData = filterPrgmYears[year][quarter];
          result.push({
            enrollmentYear: quarterData[0]?.enrollmentYear,
            anticipatedGradQuarter: quarter,
            anticipatedGradYear: year,
            programsCount:
              this.getOneOfAllSumForAnticipatedGradDate(quarterData),
          });
        });
    });
    filterResult = {
      programYear: result,
      filteredData: filteredArr,
    };
    return filterResult;
  }
  // Get sum of all program and display it in other in student enrollment
  public getOneOfAllSumForAnticipatedGradDate(filteredArr: any) {
    const availablePrograms = [
      ...new Set(
        filteredArr.map((d: any) => Object.keys(d.programsCount)).flat()
      ),
    ];
    const finalObj: any = availablePrograms.reduce(
      (acc: any, currentValue: any) => {
        acc[currentValue] = 0;
        return acc;
      },
      {}
    );
    return filteredArr.reduce(
      (acc: any, cur: any) => {
        const obj: any = {};
        availablePrograms.forEach((pgms: any) => {
          obj[pgms] =
            acc[pgms] +
            (typeof cur?.programsCount[pgms] === 'number'
              ? cur?.programsCount[pgms]
              : 0);
        });
        return obj;
      },
      { ...finalObj }
    );
  }
  public async getUserDetails(): Promise<string> {
    let userEmail: string = '';
    const userDetails = localStorage.getItem('user');
    if (userDetails) {
      const currentUser = JSON.parse(userDetails);
      userEmail = `${currentUser?.email}`;
    }
    return userEmail;
  }

  public getParentOrganization(data: any[], combObj: CommonFilter) {
    let org = localStorage.getItem('organization');
    if (org && /\u00A0/g.test(org)) {
      org = org.replace(/\u00A0/g, ' ');
    }
    if (combObj?.hospital === 'all' || combObj?.hospital.includes('all')) {
      combObj.hospital = org;
      this.hospitalSharedService.getParentHospitalOrg(data);
    } else {
        this.hospitalSharedService.checkDuplicatesOnChildHospital(
          data,
          combObj,
          org
        );
    }
  }

  public async filterDataByYear(
    data: any,
    startYear: number,
    yearFormat: boolean,
    selectedFilters: any
  ) {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;
    let filterYear = selectedFilters.Year || selectedFilters.Start_Year;
    if (yearFormat) {
      if (
        filterYear == 'all' ||
        filterYear == null ||
        filterYear == undefined
      ) {
        return data;
      } else if (Array.isArray(filterYear) && filterYear.length > 0) {
        return data.filter((item) => {
          const { fiscal_year, fiscal_month } = item.filter;
          return filterYear.some((year) => {
            // Start condition: year >= currentYear and month >= startMonth if year is startYear
            const startCondition =
              Number(fiscal_year) > Number(year) - 1 ||
              (Number(fiscal_year) === Number(year) - 1 &&
                fiscal_month >= FiscalYear.startMonth);
            // End condition: year <= currentYear and month <= endMonth if year is endYear
            const endCondition =
              Number(fiscal_year) < Number(year) ||
              (Number(fiscal_year) === Number(year) &&
                fiscal_month <= FiscalYear.endMonth);
            return startCondition && endCondition;
          });
        });
      } else {
        return data.filter((item) => {
          const { fiscal_year, fiscal_month } = item.filter;
          // Start condition: year >= currentYear and month >= startMonth if year is startYear
          const startCondition =
            Number(fiscal_year) > Number(filterYear) - 1 ||
            (Number(fiscal_year) === Number(filterYear) - 1 &&
              fiscal_month >= FiscalYear.startMonth);
          // End condition: year <= currentYear and month <= endMonth if year is endYear
          const endCondition =
            Number(fiscal_year) < Number(filterYear) ||
            (Number(fiscal_year) === Number(filterYear) &&
              fiscal_month <= FiscalYear.endMonth);
          return startCondition && endCondition;
        });
      }
    } else if (
      filterYear == 'all' ||
      filterYear == null ||
      filterYear == undefined
    ) {
      return data.filter((item) => {
        const { fiscal_year, fiscal_month } = item.filter;
        // Start condition: year >= (startYear + 1)
        const startCondition = Number(fiscal_year) >= Number(startYear) + 1;
        // End condition: year <= currentYear and month <= currentMonth if year is endYear
        const endCondition =
          Number(fiscal_year) < currentYear ||
          (Number(fiscal_year) === currentYear &&
            Number(fiscal_month) <= currentMonth);
        return startCondition && endCondition;
      });
    } else {
      return data.filter((item) => {
        const { fiscal_year, fiscal_month } = item.filter;
        return filterYear.some((year) => {
          // Start condition: year >= (startYear + 1)
          const startCondition =
            Number(fiscal_year) == Number(year) &&
            Number(fiscal_year) > Number(startYear);
          // End condition: year <= currentYear and month <= currentMonth if year is endYear
          const endCondition =
            Number(fiscal_year) < currentYear ||
            (Number(fiscal_year) === currentYear &&
              fiscal_month <= currentMonth);
          return startCondition && endCondition;
        });
      });
    }
  }

  public filterLearnerAnalyticsApplicantsCount(
    data: LearnerAnalytics,
    startYear: number,
    yearFormat: boolean
  ) {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;
    let studentDetails = { Applicants: 0, Students: 0, Alumni: 0 };
    const { year, month, institution } = data.filter;
    if (yearFormat) {
      // Start condition: year >= currentYear and month >= startMonth if year is startYear
      const startCondition =
        year > startYear ||
        (year === startYear && month >= FiscalYear.startMonth);
      // End condition: year <= currentYear and month <= endMonth if year is endYear
      const endCondition =
        year <= currentYear ||
        (year === currentYear && month <= FiscalYear.endMonth);
      if (startCondition && endCondition) {
        studentDetails.Alumni = data.studentDetails.Alumni;
        studentDetails.Students = data.studentDetails.Students;
      }
      if (currentMonth < FiscalYear.startMonth) {
        const applicantsStartCondition =
          year > currentYear - 1 ||
          (year === currentYear - 1 && month >= FiscalYear.startMonth);

        // End condition: year <= currentYear and month <= endMonth if year is endYear
        const applicantsEndCondition =
            year < currentYear || (year === currentYear && month <= currentMonth);
        if (applicantsStartCondition && applicantsEndCondition) {
          studentDetails.Applicants = data.studentDetails.Applicants;
        }
        return { studentDetails, data };
      } else {
        // Start condition: year >= currentYear and month >= startMonth if year is startYear
        const applicantsStartCondition =
          year > currentYear ||
          (year === currentYear && month >= FiscalYear.startMonth);
        // End condition: year <= currentYear and month <= endMonth if year is endYear
        let applicantsEndCondition: boolean
        if (institution === 'Chamberlain') {
          applicantsEndCondition = year < currentYear || (year === currentYear && month <= currentMonth);
        } else if (institution === 'Walden') {
          applicantsEndCondition = year < currentYear || (year === currentYear && month <= currentMonth) || year > currentYear;
        }
        if (applicantsStartCondition && applicantsEndCondition) {
          studentDetails.Applicants = data.studentDetails.Applicants;
        } else {
          this.setApplicantsCountToZero(data, false);
        }
        return { studentDetails, data };
      }
    } else {
      const startCondition = year >= startYear + 1;
      // End condition: year <= currentYear and month <= currentMonth if year is endYear
      const endCondition =
        year < currentYear || (year === currentYear && month <= currentMonth);
      if (startCondition && endCondition) {
        studentDetails.Applicants = data.studentDetails.Applicants;
        studentDetails.Alumni = data.studentDetails.Alumni;
        studentDetails.Students = data.studentDetails.Students;
      }
      if (data.filter.year <= startYear || data.filter.year > currentYear) {
        this.setApplicantsCountToZero(data, true);
      }
      return { studentDetails, data };
    }
  }
  public setApplicantsCountToZero(data, isAllCountZero) {
    for (const category in data.diversityDetails) {
      for (const group in data.diversityDetails[category]) {
        const { Students, Alumni } = data.diversityDetails[category][group];
        data.diversityDetails[category][group].Applicants = 0;
        if (isAllCountZero) {
          data.diversityDetails[category][group].Students = 0;
          data.diversityDetails[category][group].Alumni = 0;
        } else {
          data.diversityDetails[category][group].Students = Students;
          data.diversityDetails[category][group].Alumni = Alumni;
        }
      }
    }
  }
  public getYearFromFilter() {
    const sessionData = this.sessionOperations.get('filterData');
    const yearFilterLabels: FilterItems[] =
      sessionData?.['applicantsToEnrollmentFilter']?.data;
    if (!yearFilterLabels) {
      return null;
    }
    let year: any = yearFilterLabels.find((data) => data.filterLabel == 'Year');
    if (year?.filterItems) {
      return (
        Math.min(
          ...year.filterItems.filter((item) => typeof item === 'number')
        ) - 1
      );
    } else {
      return null;
    }
  }
  public removeYearFromFilter(filters, format: boolean) {
    const currentData = new Date();
    const currentYear = currentData.getFullYear();
    const currentMonth = currentData.getMonth() + 1;
    if (format === true) {
      if (currentMonth < FiscalYear.startMonth) {
        let filterYear = filters.find((year) => year.filterLabel === 'Year');
        if (filterYear != null && filterYear != undefined) {
          filterYear.filterItems = filterYear.filterItems.filter(
            (year) => typeof year === 'string' || year <= currentYear
          );
        }
      } else {
        let filterYear = filters.find((year) => year.filterLabel === 'Year');
        if (filterYear != null && filterYear != undefined) {
          filterYear.filterItems = filterYear.filterItems.filter(
            (year) => typeof year === 'string' || year < currentYear + 2
          );
        }
      }
    } else {
      filters.forEach((filter) => {
        if (filter.filterLabel === 'Year') {
          filter.filterItems = filter.filterItems.filter(
            (item) => typeof item === 'string' || item <= currentYear
          );
        }
      });
    }
    return filters;
  }
  public getMonthByNumber(month: any) {
    let index = month == 'all' ? 0 : +month;
    const monthNames = [
      'all',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return monthNames[index];
  }
}
