import { DOCUMENT } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GeneralConfigurationService } from './shared/services/general-config/general-config-service';
import { AuthService } from './core/services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public pageLoad = false;
  public subscriptionRefArray: Subscription[] = [];
  public country: string;
  constructor(
    public router: Router,
    public ar: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly swUpdate: SwUpdate,
    private readonly analytics: AngularFireAnalytics,
    private readonly http: HttpClient,
    public generalConfigurationService: GeneralConfigurationService,
    private readonly authService: AuthService
  ) {
    if (this.swUpdate.isEnabled) {
      this.subscriptionRefArray.push(
        this.swUpdate.versionUpdates.subscribe(() => {
          if (confirm('New version available. Load new version?')) {
            window.location.reload();
          }
        })
      );
    }
  }

  public isAndroid() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /android/i.test(userAgent.replace(/\([^)]*\)/g, ''));
  }

  public ngOnInit() {
    this.analytics
      .setAnalyticsCollectionEnabled(true)
      .then(() => { })
      .catch((error) => {
        console.error('data collection enabling failed', error);
      });
    this.analytics
      .logEvent('app_initialized')
      .then(() => { })
      .catch((error) =>
        console.error('Error initializing Firebase Analytics', error)
      );
    if (this.document.readyState === 'complete') {
      this.pageLoad = true;
    }
    window.onload = () => {
      this.pageLoad = true;
    };
    const prevInnerWidth: number = window.innerWidth;
    
    window.onresize = () => {
      if (!this.isAndroid()) {
        if (prevInnerWidth !== window.innerWidth) {
          location.reload();
        }
      }
    };
    setInterval(() => {
      location.reload();
    }, 3600000);
    this.http.get('https://ipapi.co/json').subscribe((res: any) => {
      this.country = res.country_name;
      this.setCountryUserProperty(this.country);
    });
    this.generalConfigurationService.getGeneralConfiguration(false);
  }

  public setCountryUserProperty(country: string): void {
    this.analytics.setUserProperties({ country });
  }

  public getEnvSpecificOrgId(orgObj: any) {
    if (environment.production) {
      return orgObj?.id;
    } else {
      return orgObj?.orgIdDev;
    }
  }
  public ngOnDestroy() {
    if (this.subscriptionRefArray.length) {
      this.subscriptionRefArray.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }

  @HostListener('document:mousemove', ['$event'])
  public onMouseMove(event: MouseEvent) {
    const account: string = localStorage.getItem('user');
    if (account == null || account == undefined || account == '') {
      const currentUrl = this.router.url;
      if (
        currentUrl != '/login' &&
        currentUrl != '/forgotPassword' &&
        currentUrl != '/callback' &&
        currentUrl != '/authenticating' &&
        currentUrl != '/verifyEmail'
      ) {
        this.router.navigate(['/login']);
      }
    }
  }

  @HostListener('window:mousemove', ['$event'])
  public onMouseMoves() {
    this.authService.simulateUserActivity();
  }

  @HostListener('window:keypress', ['$event'])
  public onKeyPress() {
    this.authService.simulateUserActivity();
  } 
}
