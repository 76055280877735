import { Component, Input } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import {
  ChartOtherProgramInfo,
  OtherProgramTitle,
} from '../../model/customtypes-model.interface';
import { OtherProgramService } from '../../../../shared/services/other.program.service';
import { ChartType } from '../../../../shared/services/enum/shared.enum';
@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss'],
})
export class BubbleChartComponent {
  public disableLefttrue: boolean = true;
  public disableRighttrue: boolean;
  public disableArrowTrue: boolean = false;
  public composedChartData: any[] = [];
  public filteredData = {};
  public locationCount: number;
  public colorAccessibilityMode: boolean = false;
  public programList = ['BSN', 'RNMSN', 'MSN', 'DNP', 'OTHERS'];

  @Input() public otherProgramDetails: ChartOtherProgramInfo[] = [];
  @Input() public labelInfo: string[] = [];
  @Input() public totalInfo: number[] = [];
  @Input() public totalYearWiseInfo: any = [];

  constructor(
    private readonly sharedService: SharedService,
    private readonly otherProgramService: OtherProgramService
  ) {}

  // Getting dataset chart number
  @Input() set chartDatSets(data: any) {
    this.locationCount = data?.length;
    if (this.locationCount > 5) {
      this.disableArrowTrue = true;
    } else {
      this.disableArrowTrue = false;
    }
    if (data != null && data != undefined) {
      this.composedChartData = data.flatMap(
        ({ clinicalSite, programsCount }) => {
          const locationPrograms = Object.keys(programsCount);
          return this.programList.map((program) => {
            const count = locationPrograms.includes(program)
              ? programsCount[program]
              : 0;
            return { clinicalSite, program, count };
          });
        }
      );

      this.filteredData = this.composedChartData.reduce((acc, curr) => {
        const { clinicalSite, program, count } = curr;
        if (!acc[clinicalSite]) {
          acc[clinicalSite] = [];
        }
        acc[clinicalSite].push({ program, count });
        return acc;
      }, {});
    }

    if (Object.keys(this.filteredData).length == 1) {
      this.disableArrows(true, true);
    } else if (Object.keys(this.filteredData).length == 0) {
      this.disableArrows(true, true);
    } else {
      this.disableArrows(true, false);
    }
  }

  public ngOnInit() {
    this.sharedService.lightColorMode$.subscribe((NavVal: boolean) => {
      if (NavVal === true) {
        this.colorAccessibilityMode = true;
      } else {
        this.colorAccessibilityMode = false;
      }
    });
  }

  public calculateSum(locations: any[]): number {
    return locations.reduce((sum, program) => sum + program.count, 0);
  }

  public scroll(direction: number): void {
    const container = document.querySelector('.bubble-canvas');
    if (direction === -1) {
      container.scrollLeft -= 250;
      this.disableRighttrue = false;
      if (container.scrollLeft === 0) {
        this.disableLefttrue = true;
      }
    } else if (direction === 1) {
      container.scrollLeft += 250;
      this.disableLefttrue = false;
      if (
        container.scrollLeft + 1 >=
        container.scrollWidth - container.clientWidth
      ) {
        this.disableRighttrue = true;
      }
    }
  }

  // for scroll left and right

  public onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.scrollLeft + 1 + element.clientWidth >= element.scrollWidth) {
      this.disableRighttrue = true;
      this.disableLefttrue = false;
    }
    if (element.scrollLeft === 0) {
      this.disableLefttrue = true;
      this.disableRighttrue = false;
    }
  }

  // for disable arrows
  public disableArrows(leftArrow: boolean, rightArrow: boolean) {
    this.disableLefttrue = leftArrow;
    this.disableRighttrue = rightArrow;
  }

  // for Other program popup
  public openOtherProgramPopup() {
    if (window.innerWidth < 575) {
      this.sharedService.openOtherProgramMob.next(true);
      this.sharedService.FloatingMenuEvent.next(false);
    } else {
      this.sharedService.openOtherProgram.next(true);
    }
    let titleInfo: OtherProgramTitle = {
      popupTotalTitle: '',
      popupTitle: ChartType.clinicalInsight,
    };

    this.otherProgramService.bindDataToStorage(
      titleInfo,
      this.otherProgramDetails,
      this.labelInfo,
      this.totalInfo,
      this.totalYearWiseInfo,
      true,
      false
    );
  }
}
