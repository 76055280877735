<button
  [class]="loading ? 'remove-icon-right' : null"
  class="btn div-button"
  (click)="openPopup()"
  aria-label="Diversity"
>
  <span class="icon-Group-22817"></span>
  <output
    *ngIf="loading"
    class="spinner-border spinner-border-sm"
    aria-live="polite"
  ></output>
</button>
