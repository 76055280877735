<div class="card pb-lg-0 pb-5">
  <div class="la-mob-header">
    <div>
      <h5 class="align-self-center" *ngIf="isSmallerDevice">
        Learner Analytics
      </h5>
      <p class="ps-3 pe-3" *ngIf="isSmallerDevice">
        Employees actively engaged with Chamberlain University or Walden
        University
      </p>
    </div>
    <ng-container #forMobile></ng-container>
  </div>
  <div #widgetWrapper class="d-chart-container">
    <div class="chart">
      <h5 *ngIf="!isSmallerDevice">Learner Analytics</h5>
      <p *ngIf="!isSmallerDevice">
        Employees actively engaged with Chamberlain University or Walden
        University
      </p>
      <app-info-graphic
        [chartData]="chartDataCount"
        [chartType]="'doughnut'"
        *ngIf="!checked"
      ></app-info-graphic>
      <app-info-graphic
        [chartData]="chartFunnelDataCount"
        [chartType]="'funnel'"
        *ngIf="checked"
      ></app-info-graphic>
      <div class="funnel-chart">
        Funnel View
        <label class="switch" aria-label="funnel_view">
          <input type="checkbox" (click)="toggle()" (keydown)="toggle()" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div
      class="chart-right-wrap d-flex flex-column align-self-lg-stretch filter-sec"
    >
      <div
        *ngIf="!isSmallerDevice"
        class="filter w-100 d-flex justify-content-between align-items-center border-bottom"
      >
        <ng-container #forDesktop></ng-container>
      </div>
      <div class="info-sec d-flex align-items-center" *ngIf="!checked">
        <div
          class="info-card-container flex-sm-wrap mt-lg-0 d-flex flex-row justify-content-evenly w-100"
        >
          <ng-container *ngFor="let cards of infoCards">
            <!--   INFO_CARDS-->
            <div class="info-card-block">
              <small
                class="tool-tip"
                [ngClass]="{ isScrollDisable: hideTooltip === true }"
                >{{ cards.content }}</small
              >
              <div class="icon-block" [style.backgroundColor]="cards.logoColor">
                <span class="{{ cards.logo }}"></span>
              </div>
              <div class="content-block mt-1">
                <p class="mb-2">{{ cards.name }}</p>
                <h3 class="fw-bold">{{ cards.count }}</h3>
              </div>
            </div>
            <!--  END_INFO_CARDS-->
          </ng-container>
        </div>
      </div>
      <div class="info-sec d-flex align-items-center" *ngIf="checked">
        <div
          class="info-card-container flex-sm-wrap mt-lg-0 d-flex flex-row justify-content-evenly w-100 Funnel-view-wrap"
        >
          <ng-container *ngFor="let cards of funnelCards">
            <!--   funnelCards-->
            <div class="info-card-block">
              <small class="tool-tip">{{ cards.content }}</small>
              <div
                class="icon-block funnel-block"
                [style.backgroundColor]="cards.logoColor"
              >
                <span class="{{ cards.logo }}"></span>
              </div>
              <div class="content-block mt-1">
                <p class="mb-2">{{ cards.name }}</p>
                <h3 class="fw-bold">{{ cards.count }}</h3>
              </div>
            </div>
            <!--  END_funnelCards-->
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="chart-dots-wrap">
    <ul>
      <li class="dot me-2" [ngClass]="{ active: swipeRightActive }"></li>
      <li class="dot" [ngClass]="{ active: swipeLeftActive }"></li>
    </ul>
  </div>
  <div class="chart-swipe-wrap" (click)="toggle()" (keydown)="toggle()">
    <ul>
      <li class="swipe right"></li>
      <li class="swipe left"></li>
    </ul>
  </div>
</div>
<ng-template #chips let-data>
  <app-filter-chips
    [showYear]="'hide'"
    class="{{ data }} chips"
    (emitRemovedFilter)="closedFilters($event)"
    [selectedFilters]="selectedFilters"
  ></app-filter-chips>
  <div class="d-flex" *ngIf="!isSmallerDevice; else diversityMobile">
    <app-diversity-modal
      [chartType]="learnerChartType"
      [diversityInfo]="diversityInfo"
      [loading]="false"
      [graphTitle]="'Learner Analytics'"
    ></app-diversity-modal>
    <app-filter-popup-modal
      class="specific-leaner-class"
      [alphaGroup]="'Hospital'"
      [appendToBody]="widgetWrapper"
      [loading]="loading"
      [title]="'Learner Analytics'"
      (popUpState)="getPopUpState($event)"
      [data]="filterLabels"
      [hospitals]="hospitalList"
      (selectedValues)="getSelectedValues($event)"
      (reset)="reset($event)"
    ></app-filter-popup-modal>
  </div>
  <ng-template #diversityMobile>
    <div class="diversity-align">
      <app-filter-popup-modal
        class="specific-leaner-class"
        [alphaGroup]="'Hospital'"
        [appendToBody]="widgetWrapper"
        [loading]="loading"
        [title]="'Learner Analytics'"
        (popUpState)="getPopUpState($event)"
        [data]="filterLabels"
        [hospitals]="hospitalList"
        (selectedValues)="getSelectedValues($event)"
        (reset)="reset($event)"
      ></app-filter-popup-modal>
      <app-diversity-modal
        [chartType]="learnerChartType"
        [diversityInfo]="diversityInfo"
        [loading]="false"
        [graphTitle]="'Learner Analytics'"
      ></app-diversity-modal>
    </div>
  </ng-template>
</ng-template>
