import { Component, Input } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SharedService } from '../../services/shared.service';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-title-divider',
  templateUrl: './title-divider.component.html',
  styleUrls: ['./title-divider.component.scss'],
})
export class TitleDividerComponent {
  public isShowTooltip: boolean = false;
  @Input() public title = '';
  @Input() public tooltipContent = '';
  public exportRemoval = [
    '/dashboard/lad-information-materials',
    '/dashboard/partnership-overview',
    '/dashboard/clinical-insights',
  ];
  public exportVisible = true;
  public disableExport = false;
  constructor(
    public router: Router,
    private readonly sharedService: SharedService,
    public analytics: AngularFireAnalytics
  ) {}

  public ngOnInit() {
    if (window.innerWidth < 575) {
      this.isShowTooltip = true;
    }
    const state: RouterState = this.router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;
    this.exportVisible = !this.exportRemoval.find(
      (url) => url === snapshot.url
    );
    this.sharedService.exportDisabled$.subscribe((NavVal: boolean) => {
      this.disableExport = NavVal;
    });
  }
  public openPdfDownloadPopup() {
    this.analytics.logEvent('click_PDF_Export_button');
    this.sharedService.allExportClick.next(true);
  }
}
