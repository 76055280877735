import { Injectable } from '@angular/core';
import { SharedService } from '../shared.service';
import { ChartType, DiversityFilter } from '../enum/shared.enum';
import {
  Diversity,
} from '../../components/model/customtypes-model.interface';
import { LearnerDiversityService } from './learner-analytics/learner-diversity.service';
import { ProgramDiversityService } from './program-diversity/program-diversity.service';
import { StudentEnrollmentsService } from './student-enrollments/student-enrollments.service';
import { AnticipatedGradService } from './anticipated-grad/anticipated-grad.service';

@Injectable({
  providedIn: 'root',
})
export class DiversityService {
  constructor(
    public sharedService: SharedService,
    public programDiversityService: ProgramDiversityService,
    public learanerDiversityService: LearnerDiversityService,
    public studentEnrollmentService: StudentEnrollmentsService,
    public anticipatedService: AnticipatedGradService
  ) {}

  public async createDiversityTableInfo(
    chartType: ChartType,
    graphTitle: string,
    sessionData: any
  ) {
    let tableData: Diversity[] = [];
    switch (chartType) {
      case ChartType.learnerAnalytics: {
        tableData =
          await this.learanerDiversityService.createLearnerDiversityTableInfo(
            graphTitle,
            sessionData
          );
        break;
      }
      case ChartType.applicantsToEnrollment:
      case ChartType.studentProgress:
      case ChartType.alumni:
      case ChartType.clinicalInsight: {
        tableData =
          await this.programDiversityService.createProgramDiversityTableInfo(
            graphTitle,
            sessionData
          );
        break;
      }
      case ChartType.studentEnrollment: {
        tableData =
          await this.studentEnrollmentService.createStudentEnrollmentDiversityTableInfo(
            graphTitle,
            sessionData
          );
        break;
      }
      case ChartType.anticipatedGradYear: {
        tableData =
          await this.anticipatedService.createAnticipatedGradDateDiversityTableInfo(
            graphTitle,
            sessionData
          );
        break;
      }
      default:
        this.handleDefaultCase();
    }

    this.sharedService.otherProgramData.next({ data: tableData });
  }

  public async getDiversityFilter() {
    return Object.values(DiversityFilter);
  }

  public handleDefaultCase() {
    return [];
  }
}
