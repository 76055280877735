import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonComponent } from '../../../modules/dashboard/partner-dashboard/common-component';
import { ApiService } from '../../services/api.service';
import { TransferStateService } from '@scullyio/ng-lib';
import { ActivatedRoute } from '@angular/router';
import { CdnService } from '../../services/cdn.service';
import { FilterChip } from '../model/customtypes-model.interface';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
})
export class FilterChipsComponent extends CommonComponent {
  public subscriptionRefArray: Subscription[] = [];
  public startYear: any;
  public chip: any = [];
  @Output() public emitRemovedFilter = new EventEmitter();
  @Input() public customClass: string;
  @Input() public showYear: string;
  @Input() public currentYear: string;
  @HostBinding('style.display') public blockElement: string = 'block';
  public yearFilterSelected: boolean;
  constructor(
    apiService: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private readonly cdnService: CdnService
  ) {
    super(apiService, tss, ar);
  }
  @Input() set selectedFilters(data: any) {
    if (data) {
      this.chip = [];
      Object.entries(data).forEach(([key, value]) => {
        if (key.includes('Year')) {
          if (!Array.isArray(value)) {
            value = [value];
          }
          if (value[0] === 'all') {
            if (this.startYear != null) {
              this.chip.push({
                key: key,
                value: this.currentYear
                  ? `Start Year: ${this.startYear}-Present`
                  : `Year: ${this.startYear}-Present`,
                closable: false,
              });
            }
          } else if (value['length'] <= 1) {
              this.chip.push({
                key: key,
                value: this.currentYear
                  ? `Start Year: ${value}`
                  : `Year: ${value}`,
                closable: true,
              });
              this.yearFilterSelected = true;
            } else {
              this.chip.push({
                key: key,
                value: `${value['length']} Years`,
                closable: true,
              });
              this.yearFilterSelected = true;
            }
          
        } else {
          if (!Array.isArray(value)) {
            value = [value];
          }
          if (value[0] !== 'all') {
            if (value['length'] <= 1) {
              this.chip.push({ key: key, value: value, closable: true });
            } else {
              if (key != 'Modality') {
                this.chip.push({
                  key: key,
                  value: `${value['length']} ${key}s`,
                  closable: true,
                });
              }
              if (key == 'Modality') {
                this.chip.push({
                  key: key,
                  value: `${value['length']} Modalities`,
                  closable: true,
                });
              }
            }
          }
        }
      });
    }
  }

  public ngOnInit(): void {
    // Get labels for current organization's studentPersistence chart
    const labelsSubscription = this.cdnService.appendSelectedAllToFilterData(
      'studentPersistenceFilter'
    );
    if (labelsSubscription) {
      this.startYear = labelsSubscription[0]?.filterItems
        .filter((year) => year !== 'all')
        .sort((a: number, b: number) => a - b);
      
      this.startYear = this.startYear[0];
      if (!this.showYear && !this.yearFilterSelected) {
        this.chip.unshift({
          key: 'Year',
          value: this.currentYear
            ? `Start Year: ${this.startYear}-Present`
            : `Year: ${this.startYear}-Present`,
          closable: false,
        });
      }
    }
  }

  public removeFilter(obj: FilterChip, index: number) {
    if (!obj?.key.includes('Year')) {
      this.chip.splice(index, 1);
    } else {
      this.chip[index].value = `Year: ${this.startYear}-Present`;
      this.chip[index].closable = false;
    }
    this.emitRemovedFilter.emit({ [obj?.key]: 'all' });
  }
}
