import {
  Component,
  Inject,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  AfterContentInit,
  Input,
} from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlTransformPipe } from '../../../../shared/pipe/url-transform.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SharedService } from '../../../../shared/services/shared.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  AggrementStatus,
  AggrementStatusTooltip,
  Agreement,
  AgreementTooltip,
  RenewalTooltip,
} from '../../../../shared/services/enum/shared.enum';
import { User } from '../../../../core/interfaces/user.interface';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';

@Component({
  selector: 'app-list-of-partnership',
  templateUrl: './list-of-partnership.component.html',
  styleUrls: ['./list-of-partnership.component.scss'],
})
export class ListOfPartnershipComponent implements AfterContentInit, OnDestroy {
  @ViewChild('popupContainer') public popupContainer!: TemplateRef<any>;
  public partnershipList: any;
  public env = environment.sfBaseURL;
  public contactDetailObj: any = [];
  public subscriptionRefArray: Subscription[] = [];
  public contactLoader: boolean = true;
  public isDataRetrieved: boolean;
  public partnerShipLogoImgs: any = [];
  public listData: any;
  public hospitalListPopupShow: boolean = false;
  public childHierarchyStatus: any;
  public hospitalListLoader: boolean = true;
  public popupTitle: any;
  public emptyMassage: any;
  public statusData: any = [];
  public universityTitle: any;
  public matchingAgreement: any;
  public orderArray = ['Chamberlain University', 'Walden University'];
  public agreementTooltip: any = AgreementTooltip;
  public agreementStatusTooltip: any = AggrementStatusTooltip;
  public renewalTooltip: any = RenewalTooltip;
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  public viewContainerRef: ViewContainerRef;
  public dataLoader: boolean = false;
  public user: User;
  public userName: string;
  public userEmail: string;
  public orgName: string;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly apiService: ApiService,
    public http: HttpClient,
    public url: UrlTransformPipe,
    private readonly sanitizer: DomSanitizer,
    viewContainerRef: ViewContainerRef,
    private readonly sharedService: SharedService,
    private readonly analytics: AngularFireAnalytics,
    public analyticsService: AnalyticsService
  ) {
    this.viewContainerRef = viewContainerRef;
  }

  @Input() set partnershipInfo(data: any) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.sfSubKey);
    if (data.length) {
      this.partnershipList = data;
      this.partnerShipLogoImgs = [];
      this.partnershipList.forEach((x: any) => {
        this.http
          .get(
            `${this.env}/image/${this.url.transform(
              x?.contentNodes?.bannerImage?.resourceUrl
            )}`,
            {
              headers: apiHeader,
              responseType: 'blob',
            }
          )
          .subscribe((imageUrl: any) => {
            const image = URL.createObjectURL(imageUrl);
            x.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(image);
          });
      });

      this.sortPartnerships();
      this.assignAgreementTooltip();
      this.dataLoader = true;
    }
  }

  // call this function after page initialize and getting portal user detail and org information
  public ngAfterContentInit(): void {
    this.getData();
    this.analytics.logEvent('Partnership_Overview_load');
  }
  public updateUniversityStatus(statusArray) {
    this.sharedService.updateUniversityStatus(statusArray);
  }
  // getting user detail information,account id,
  public getData() {
    this.dataLoader = true;
    this.partnershipList = [];

    if (this.isDataRetrieved) {
      this.partnershipList = this.listData;
    } else {
      this.isDataRetrieved = true;
      const userDetailId: any = localStorage.getItem('userDetailId');
      const Id = userDetailId ? JSON.parse(userDetailId)[0]?.Account?.Id : null;

      this.subscriptionRefArray.push(
        this.apiService.getServices(Id).subscribe((data: any) => {
          this.contactLoader = false;
          this.contactDetailObj = data.accountContactRelation;
        })
      );
    }
  }

  // contentKey is a unique property
  public trackByContentKey(index: number, data: any): string {
    return data.contentKey; // Assuming that userDetailId is a unique property
  }

  // Id is a unique property
  public trackById(index: number, data: any): string {
    return data.Id;
  }

  // Show HospitalList
  public hospitalList($event: any, university: any) {
    this.sharedService.menuBarEvent.next(true);
    this.sharedService.FloatingMenuEvent.next(false);
    this.childHierarchyStatus = null;
    this.emptyMassage = null;
    this.popupTitle = $event;
    this.universityTitle = university;
    this.hospitalListPopupShow = true;
    this.analytics.logEvent(`View_all_sites_clicked_${this.universityTitle}`);
    const userDetailId: any = localStorage.getItem('userDetailId');
    const Id = userDetailId ? JSON.parse(userDetailId)[0]?.Account?.Id : null;
    if (this.hospitalListPopupShow) {
      const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
        this.popupContainer
      );
      this.analytics.logEvent(
        `hospitalListPopup_opened_for_${this.universityTitle}`
      );
      embeddedViewRef.detectChanges();
      for (const node of embeddedViewRef.rootNodes) {
        this.document.body.appendChild(node);
      }
    }
    this.apiService.getPartnershipChildDetails(Id).subscribe((data: any) => {
      this.emptyMassage = data?.message;
      this.childHierarchyStatus = data?.childHierarchy
        ?.map((child: any) => {
          this.matchingAgreement = child?.contractList?.filter(
            (agreement: any) => agreement?.RecordType?.Name === $event
          );

          if (this.matchingAgreement || !child?.contractList?.length) {
            return {
              ...child,
              selectedBillAddress: child?.BillingAddress,
              selectedOrganizationName: child?.OrganizationName,
              Selectedagreement: this.matchingAgreement || null,
            };
          }
        })
        .filter((q: any) => q);
      this.hospitalListLoader = false;
      const time = getFormattedCurrentDateTime();
      const sessionId = localStorage.getItem('sessionId');
      if (localStorage.getItem('providerId') === 'firebase') {
        this.analyticsService.addAnalytics(
          this.userName,
          this.userEmail,
          this.orgName,
          `View All Sites_${this.popupTitle}`,
          time,
          sessionId
        );
      }
    });
  }

  // Close Popup
  public closePopup() {
    this.sharedService.menuBarEvent.next(false);
    this.sharedService.FloatingMenuEvent.next(true);
    const dropdown: any = this.document.querySelector('.change-password-popup');
    dropdown.remove();
    this.childHierarchyStatus = null;
    this.emptyMassage = null;
    this.universityTitle = '';
    this.popupTitle = '';
  }

  // unsubscribe subscription
  public ngOnDestroy() {
    if (this.subscriptionRefArray.length) {
      this.subscriptionRefArray.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
    this.statusData = [];
  }

  public sortByCreatedDate(a: any, b: any): number {
    const indexA: any = new Date(a.CreatedDate);
    const indexB: any = new Date(b.CreatedDate);
    return indexB - indexA;
  }

  public statusFilter(data: any) {
    const finalArr = [];
    if (data?.length) {
      const activeAgreements = data.filter((w: any) => w.Stage__c === 'Active');
      if (activeAgreements?.length) {
        finalArr.push(activeAgreements.sort(this.sortByCreatedDate)[0]);
      } else {
        finalArr.push(data.sort(this.sortByCreatedDate)[0]);
      }
    }

    return finalArr;
  }

  public assignTooltip(aggrementName: string, aggrementData: any): any[] {
    if (aggrementData.length) {
      aggrementName === Agreement[aggrementName]
        ? Object.assign(aggrementData[0], {
            agTooltip: AgreementTooltip[Agreement[aggrementName]],
          })
        : Object.assign(aggrementData[0], { agTooltip: '' });

      aggrementData[0].Agreement_End_Date__c &&
      (aggrementData[0].Stage__c === 'Active' ||
        aggrementData[0].Stage__c === 'Expired')
        ? Object.assign(aggrementData[0], {
            renewalTooltip: RenewalTooltip.renewalDate,
          })
        : Object.assign(aggrementData[0], {
            renewalTooltip: RenewalTooltip.notAvailable,
          });

      let itemFoundByValue = Object.keys(AggrementStatus).find(
        (key) => AggrementStatus[key] === aggrementData[0].Stage__c
      );

      if (itemFoundByValue != null && itemFoundByValue != undefined) {
        Object.assign(aggrementData[0], {
          statusTooltip: AggrementStatusTooltip[itemFoundByValue],
        });
      } else {
        Object.assign(aggrementData[0], { statusTooltip: '' });
      }
    }

    return aggrementData;
  }

  public async sortPartnerships() {
    this.partnershipList = this.partnershipList.sort((a: any, b: any) => {
      const indexA = this.orderArray.indexOf(a.title);
      const indexB = this.orderArray.indexOf(b.title);
      return indexA - indexB;
    });
  }

  public async assignAgreementTooltip() {
    const firstPartnerEducationalAgreement =
      this.partnershipList[0]?.status?.filter((x: any) =>
        x.RecordType.Name.includes('CU Educational')
      );
    const firstPartnerClinicalAgreement =
      this.partnershipList[1]?.status?.filter((x: any) =>
        x.RecordType.Name.includes('CU Clinical')
      );

    this.partnershipList[0].status = [
      ...this.assignTooltip(
        Agreement.educational,
        this.statusFilter(firstPartnerEducationalAgreement)
      ),
      ...this.assignTooltip(
        Agreement.clinical,
        this.statusFilter(firstPartnerClinicalAgreement)
      ),
    ];

    const secondPartnerEducationalAgreement =
      this.partnershipList[1]?.status?.filter((x: any) =>
        x.RecordType.Name.includes('WU Educational')
      );
    this.partnershipList[1].status = [
      ...this.assignTooltip(
        Agreement.educational,
        this.statusFilter(secondPartnerEducationalAgreement)
      ),
    ];
  }
}
