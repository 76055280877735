import { Injectable } from '@angular/core';
import {
  DiversityAgeHeaders,
  DiversityFilter,
  DiversityEthinicityHeaders,
  DiversityGenderHeaders,
  DiversityRaceHeaders,
  GraphColor,
} from '../../enum/shared.enum';
import {
  Diversity,
  LearnerAnalytics,
  LearnerDiversity,
  TableHeader,
} from '../../../components/model/customtypes-model.interface';
import { DiversityInfo } from '../../entity/shared.entity';
import {
  DiversityHeaderTypes,
  LearnerDiversityTypes,
} from '../../class-type/chart-type';

@Injectable({
  providedIn: 'root',
})
export class LearnerDiversityService {
  public async createLearnerDiversityTableInfo(
    chartType: string,
    sessionData: LearnerDiversity
  ): Promise<Diversity[]> {
    let tableData: Diversity[] = [];

    for (let filterType of Object.values(DiversityFilter)) {
      switch (filterType) {
        case DiversityFilter.ethnicity: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData?.ethnicity,
            DiversityEthinicityHeaders
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.race: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData?.race,
            DiversityRaceHeaders
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.gender: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData?.gender,
            DiversityGenderHeaders
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.age: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData?.age,
            DiversityAgeHeaders
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        default:
          this.handleDefaultCase();
      }
    }

    return tableData;
  }

  public async createTableHeaders(
    tableHeaders: DiversityHeaderTypes
  ): Promise<TableHeader[]> {
    let headerLabels: TableHeader[] = [];

    const raceHeaderArray = Object.keys(tableHeaders).map((key, index) => ({
      name: tableHeaders[key],
      value: key,
      color: GraphColor[index],
    }));

    headerLabels = [...raceHeaderArray];
    return headerLabels;
  }

  public async createDiversityInfo(
    diversityType: DiversityFilter,
    chartType: string,
    sessionData: LearnerDiversityTypes,
    headers: DiversityHeaderTypes
  ): Promise<DiversityInfo> {
    let diversityInfo = new DiversityInfo();
    let tableHeaderInfo = await this.createTableHeaders(headers);

    let tableDataInfo = await this.createLearnerDiversityData(
      sessionData,
      tableHeaderInfo
    );

    diversityInfo.composeDiversityData(
      diversityType,
      tableDataInfo,
      tableHeaderInfo,
      chartType
    );
    return diversityInfo;
  }

  public async createLearnerDiversityData(
    data: LearnerDiversityTypes,
    tableHeaderInfo: TableHeader[]
  ) {
    let ethnicityTypes = ['Applicants', 'Students', 'Alumni'];

    return ethnicityTypes.map((category) => {
      let item: any = {};

      if (Object.keys(data).length) {
        Object.keys(data).forEach((diversity) => {
          if (data[diversity][category]) {
            item[diversity] = data[diversity][category];
          } else {
            item[diversity] = 0;
          }
        });
      } else {
        const result = tableHeaderInfo
          .filter(
            (item) =>
              item.value !== 'totalCount' && item.value !== 'diversityName'
          )
          .reduce((acc, item) => {
            acc[item.value] = 0;
            return acc;
          }, {});
        item = { ...item, ...result };
      }

      item['totalCount'] = Object.values(item).reduce(
        (acc: number, curr: number) => acc + curr,
        0
      );
      item['diversityName'] = category;
      return item;
    });
  }

  public async createDiversityDetails(data: any[]) {
    if (data.length <= 0) {
      return {
        ethnicity: {},
        race: {},
        gender: {},
        age: {},
      };
    }

    return data.reduce((acc, { diversityDetails }) => {
      for (const category in diversityDetails) {
        if (!acc[category]) {
          acc[category] = {};
        }
        for (const group in diversityDetails[category]) {
          if (!acc[category][group]) {
            acc[category][group] = { Applicants: 0, Students: 0, Alumni: 0 };
          }
          const { Applicants, Students, Alumni } =
            diversityDetails[category][group];
          acc[category][group].Applicants += Applicants;
          acc[category][group].Students += Students;
          acc[category][group].Alumni += Alumni;
        }
      }
      return acc;
    }, {});
  }

  public handleDefaultCase() {
    return [];
  }
}
