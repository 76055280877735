<div class="faq-query-popup" *ngIf="showPopup" #popupContainer>
  <div class="faq-query-popup-sub-wrap">
    <div class="modal-header">
      <div class="modal-title">
        <h4>Add Role</h4>
      </div>
      <button (click)="closePopup()">
        <span class="icon-close-icon close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-field mb-2">
        <label for="">Enter Role</label>
        <input type="text" class="form-control" [(ngModel)]="role" />
      </div>
      <div class="form-field mb-3">
        <label for="">Select Category</label>
        <select
          class="form-control"
          (change)="onRoleChange($event)"
          [(ngModel)]="categoryRole"
        >
          <option value="" disabled selected>Select a Category</option>
          <option *ngFor="let role of roles.roles" [value]="role.category">
            {{ role.category | titlecase }}
          </option>
        </select>
      </div>
      <div class="form-field mb-2">
        <div *ngIf="selectedRole">
          <h6>Page Access</h6>
          <div *ngFor="let permission of selectedRole.access | keyvalue">
            <label>
              <input
                type="checkbox"
                [checked]="access?.[permission.key.toString()]"
                [disabled]="
                  permission.key === 'clinical_insights' ||
                  permission.key === 'admin'
                "
                (change)="toggleAccess(permission.key.toString(), $event)"
              />
              {{ formatKey(permission.key.toString()) }}
            </label>
          </div>
        </div>
      </div>
      <div class="text-success" *ngIf="successMsg">
        <span class="text-success">{{ successContent }}</span>
      </div>
      <div class="text-danger mt-2" *ngIf="errorMsg">
        <span class="text-danger">{{ errorContent }}</span>
      </div>
      <div
        class="form-button"
        [ngClass]="{ 'info-btn': isPartnerSelected || isAdminSelected }"
      >
        <div *ngIf="isPartnerSelected" class="alert alert-info">
          * Clinical Insights page can be controlled only from Clinical Status
          page.
        </div>
        <div *ngIf="isAdminSelected" class="alert alert-info">
          * Admin page can be managed only by super admin page.
        </div>
        <button (click)="addRole()">Submit</button>
      </div>
    </div>
  </div>
</div>

<div class="faq-query-popup" *ngIf="selectedRoles" #popupContainer>
  <div class="faq-query-popup-sub-wrap">
    <div class="modal-header">
      <div class="modal-title">
        <h4>Edit Role</h4>
      </div>
      <button (click)="closeEditPopup()">
        <span class="icon-close-icon close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-field mb-2">
        <label for="">Enter Role</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="selectedRoles.role_name"
          disabled
        />
      </div>
      <div class="form-field mb-3">
        <label for="">Select Category</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="selectedRoles.category"
          disabled
        />
      </div>
      <div class="form-field mb-2">
        <div>
          <h6>Page Access</h6>
          <div *ngFor="let permission of selectedRoles.access | keyvalue">
            <label>
              <input
                type="checkbox"
                [checked]="permission.value"
                [disabled]="
                  permission.key === 'clinical_insights' ||
                  permission.key === 'admin'
                "
                (change)="onAccessChange(permission.key.toString(), $event)"
              />
              {{ formatKey(permission.key.toString()) }}
            </label>
          </div>
        </div>
      </div>
      <div class="text-success" *ngIf="successMsg">
        <span class="text-success">{{ successContent }}</span>
      </div>
      <div class="text-danger mt-2" *ngIf="errorMsg">
        <span class="text-danger">{{ errorContent }}</span>
      </div>
      <div
        class="form-button"
        [ngClass]="{
          'info-btn':
            selectedRoles.category === 'partner' ||
            selectedRoles.category === 'admin'
        }"
      >
        <div
          *ngIf="selectedRoles.category === 'partner'"
          class="alert alert-info"
        >
          * Clinical Insights page can be controlled only from Clinical Status
          page.
        </div>
        <div
          *ngIf="selectedRoles.category === 'admin'"
          class="alert alert-info"
        >
          * Admin page can be managed only by super admin page.
        </div>
        <button (click)="editRole()">Update</button>
      </div>
    </div>
  </div>
</div>
