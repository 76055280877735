import { Component, OnInit } from '@angular/core';
import { RolesResponse } from '../../../../shared/components/model/customtypes-model.interface';
import { ApiService } from '../../../../shared/services/api.service';
import { SharedService } from '../../../../shared/services/shared.service';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrl: './add-role.component.scss',
})
export class AddRoleComponent implements OnInit {
  public selectedRole: any = null;
  public showPopup: boolean = false;
  public role: string = '';
  public categoryRole: string = '';
  public access: { [key: string]: boolean } = {};
  public roles: RolesResponse = { roles: [] };
  public errorMsg: boolean = false;
  public errorContent: string;
  public successMsg: boolean = false;
  public successContent: string;
  public editPopup: boolean = false;
  public selectedRoles: any = null;
  public isPartnerSelected: boolean = false;
  public isAdminSelected: boolean = false;

  constructor(
    public sharedService: SharedService,
    public apiService: ApiService
  ) {}

  public ngOnInit(): void {
    this.sharedService.newRole$.subscribe((data: boolean) => {
      this.showPopup = data;
      this.rolesList();
    });
    this.sharedService.editRole$.subscribe((data: any) => {
      if (data) {
        this.selectedRoles = { ...data };
      }
      this.rolesList();
    });
  }

  public closePopup() {
    this.sharedService.newRole.next(false);
    this.successMsg = false;
    this.errorMsg = false;
    this.role = '';
    this.categoryRole = '';
    this.selectedRole = false;
    this.isPartnerSelected = false;
    this.isAdminSelected = false;
  }

  public rolesList() {
    this.apiService.getDefaultRoles().subscribe((data) => {
      if (data && Array.isArray(data.roles)) {
        this.roles = data;
      } else {
        this.roles.roles = []; // Ensure roles is always an array
      }
    });
  }

  public onRoleChange(event: any) {
    this.selectedRole = this.roles.roles.find(
      (r) => r.category === this.categoryRole
    );
    if (this.selectedRole) {
      // Set default access based on selected role
      this.access = { ...this.selectedRole.access };
    }
    this.isPartnerSelected = this.categoryRole === 'partner';
    this.isAdminSelected = this.categoryRole === 'admin';
  }

  public toggleAccess(permission: string, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      this.access[permission] = inputElement.checked;
    }
  }

  // Submit role
  public addRole() {
    if (!this.role || !this.categoryRole) {
      this.errorMsg = true;
      this.errorContent = 'Please enter role and select category!';
      return;
    }
    const requestBody = {
      role_name: this.role,
      category: this.categoryRole,
      ...this.access,
    };
    const filteredKeys = Object.keys(this.access).filter(
      (key) => key !== 'admin' && key !== 'clinical_insights'
    );
    const allFalse = filteredKeys.every((key) => this.access[key] === false);
    if (allFalse) {
      this.errorMsg = true;
      this.errorContent = 'You have to select atleast one page access';
    } else {
      this.apiService.addRoles(requestBody).subscribe({
        next: (data: any) => {
          if (data.success === 'true') {
            this.successContent = data.message;
            this.successMsg = true;
            this.errorMsg = false;
            this.role = '';
            this.categoryRole = '';
            this.selectedRole = null;
            setTimeout(() => {
              this.closePopup();
            }, 2000);
          }
        },
        error: (error) => {
          if (error.error.error_code === 404) {
            this.errorContent = error.error.message;
            this.errorMsg = true;
            this.successMsg = false;
          }
          if (error.status === 400) {
            this.errorContent = error.error.message;
            this.errorMsg = true;
            this.successMsg = false;
          }
        },
      });
    }
  }

  public formatKey(key: string): string {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  public editRole() {
    const requestBody = {
      role_name: this.selectedRoles.role_name,
      category: this.selectedRoles.category,
      ...this.selectedRoles.access,
    };
    const filteredKeys = Object.keys(this.selectedRoles.access).filter(
      (key) => key !== 'admin' && key !== 'clinical_insights'
    );
    const allFalse = filteredKeys.every(
      (key) => this.selectedRoles.access[key] === false
    );
    if (allFalse) {
      this.errorMsg = true;
      this.errorContent = 'You have to select atleast one page access';
    } else {
      this.apiService.updateRole(requestBody).subscribe({
        next: (data: any) => {
          if (data.success === 'true') {
            this.successContent = data.message;
            this.successMsg = true;
            this.errorMsg = false;
            this.selectedRole = null;
            setTimeout(() => {
              this.closeEditPopup();
            }, 2000);
          }
        },
        error: (error) => {
          if (error.error.error_code === 404) {
            this.errorContent = error.error.message;
            this.errorMsg = true;
            this.successMsg = false;
          }
          if (error.status === 400) {
            this.errorContent = error.error.message;
            this.errorMsg = true;
            this.successMsg = false;
          }
        },
      });
    }
  }

  public closeEditPopup() {
    this.sharedService.editRole.next(false);
    this.selectedRoles = null;
    this.successMsg = false;
    this.errorMsg = false;
  }

  public onAccessChange(key: string, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.selectedRoles.access[key] = inputElement.checked;
  }
}
