import { Component, Input, ViewChild, OnInit, ElementRef } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import {
  ChartArea,
  ChartDataSets,
  ChartNumberData,
  Program,
  ChartOtherProgramInfo,
  SPBarChartData,
  SPStudentData,
  StudentData,
  OtherProgramTitle,
  StudentProgressProgram,
} from '../../model/customtypes-model.interface';
import { SharedService } from '../../../services/shared.service';
import { OtherProgramService } from '../../../../shared/services/other.program.service';
import {
  ChartType,
  StudentProgressTooltip,
} from '../../../../shared/services/enum/shared.enum';

@Component({
  selector: 'app-horizontal-bar-sp-chart',
  styleUrls: ['./horizontal-bar-sp-chart.component.scss'],
  templateUrl: './horizontal-bar-sp-chart.component.html',
})
export class HorizontalBarSpChartComponent implements OnInit {
  @Input() public chartNumbers: ChartNumberData[] = [];
  @Input() public otherProgramDetails: ChartOtherProgramInfo[] = [];
  @Input() public withDrawnReason: StudentProgressProgram[] = [];
  @ViewChild(BaseChartDirective)
  public chart: BaseChartDirective;
  @ViewChild('popup') public popup!: ElementRef;
  @ViewChild('canvasEl') public canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('tooltip', { static: true }) public tooltip: ElementRef;
  public datas: SPStudentData;
  public isActive: boolean;
  public totalOverall: number[] = [];
  public showPopup = false;
  public popupStyle = {};
  public popupContent: string;
  public popupLabel: string;
  public popupColor: { backgroundColor: string | undefined } = { backgroundColor: undefined };
  public withdrawnReasons: { reason: string, count: number }[] = [];
  public chartData: ChartData<'bar'> = {
    datasets: [
      {
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return this.getGradient(ctx, chartArea);
        },
        barThickness: 20,
        borderColor: 'white',
        borderRadius: 20,
        borderWidth: 1,
        data: [],
        hoverBackgroundColor: '#88BC40',
        hoverBorderColor: 'white',
        label: 'Students enrolled in program',
      },
      {
        backgroundColor: (context) => {
          const chart1 = context.chart;
          const { ctx, chartArea } = chart1;
          if (!chartArea) {
            return null;
          }
          return this.getGradienttwo(ctx, chartArea);
        },
        barThickness: 20,
        borderColor: 'white',
        borderRadius: 20,
        borderWidth: 1,
        data: [],
        hoverBackgroundColor: '#5A7EEF',
        hoverBorderColor: 'white',
        label: 'Students who have attended classes during selected timeframe',
      },
      {
        backgroundColor: (context) => {
          const chart1 = context.chart;
          const { ctx, chartArea } = chart1;
          if (!chartArea) {
            return null;
          }
          return this.getGradientthree(ctx, chartArea);
        },
        barThickness: 20,
        borderColor: 'white',
        borderRadius: 20,
        borderWidth: 1,
        data: [],
        hoverBackgroundColor: '#00807F',
        hoverBorderColor: 'white',
        label: 'Students withdrawn from program',
      },
      {
        backgroundColor: (context) => {
          const chart1 = context.chart;
          const { ctx, chartArea } = chart1;
          if (!chartArea) {
            return null;
          }
          return this.getGradientfour(ctx, chartArea);
        },
        barThickness: 20,
        borderColor: 'white',
        borderRadius: 20,
        borderWidth: 1,
        data: [],
        hoverBackgroundColor: '#EFB83F',
        hoverBorderColor: 'white',
        label: 'Students graduated from program',
      },
    ],
    labels: [],
  };

  public chartType: ChartConfiguration<'bar'>['type'] = 'bar';
  public chartPlugin: any = [ChartDataLabels];
  public chartOption: any = {
    animation: false,
    indexAxis: 'y',
    layout: {
      padding: 0,
    },
    interaction: {
      mode: this.setTooltipMode(),
    },
    maintainAspectRatio: false,
    plugins: {
      border: false,
      datalabels: {
        color: 'white',
        font: {
          size: 10,
          weight: 'normal',
        },
      },
      legend: {
        align: 'start',
        display: false,
        labels: {
          boxHeight: 15,
          boxWidth: 15,
          color: '#1F356C',
          padding: 30,
          pointStyle: 'rectRounded',
          textAlign: 'center',
          usePointStyle: true,
        },
        position: 'top',
      },
      text: false,
      text1: false,
      doughnutLabelsLine: false,
      customCanvasBackgroundColor: false,
      tooltip: {
        backgroundColor: 'rgba(240, 240, 240, 0.8)',
        bodyColor: '#000000',
        enabled: true,
        footerColor: '#000000',
        titleColor: '#000000',
        font: {
          weight: 'normal',
        },
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || '';
            const persentageVal = (
              (context.parsed.x / this.totalOverall[context.dataIndex]) *
              100
            ).toFixed(2);
            const totalVal = this.totalOverall[context.dataIndex];
            const label2 = `${persentageVal}% of ${totalVal}`;
            if (label) {
              label = `${label}: ${label2}`;
            }
            return label;
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        barPercentage: 2,
        border: {
          borderDash: [1, 1],
          borderDashOffset: 2,
          color: '#1F356C',
          display: true,
          width: 1,
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.75)',
          drawOnChartArea: false,
          lineWidth: 0,
          offset: true,
        },
        stacked: true,
        suggestedMax: 10,
        ticks: {
          stepSize: 20,
          beginAtZero: true,
          color: '#022069',
          font: {
            weight: 'bold',
          },
        },
        title: {
          align: 'center',
          color: '#526289',
          display: true,
          text: 'No.of Students',
        },
      },
      y: {
        border: {
          borderDash: [1, 1],
          borderDashOffset: 2,
          color: '#1F356C',
          display: true,
          width: 1,
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.75)',
          drawOnChartArea: false,
          lineWidth: 0,
          offset: false,
        },
        position: 'left',
        stacked: true,
        ticks: {
          color: '#022069',
          crossAlign: 'far',
          font: {
            weight: 'bold',
          },
        },
        title: {
          align: 'start',
          color: '#526289',
          display: true,
          text: '',
        },
      },
    },
  };

  public graphBackgroundColor;
  public graphLightColor;
  public progressInfo: any[] = [
    {
      name: 'Continuing',
      content: StudentProgressTooltip.continuing,
    },
    {
      name: 'Withdrawn',
      content: StudentProgressTooltip.withdrawn,
    },
    {
      name: 'Graduated',
      content: StudentProgressTooltip.graduated,
    },
  ];
  public programInfo: Program[];
  public tooltipPositionX: number = 0;
  public tooltipPositionY: number = 0;
  public tooltipContent: string = '';
  constructor(
    private readonly sharedService: SharedService,
    public otherProgramService: OtherProgramService
  ) {}

  // Getting chart data set number
  @Input() set chartDatSets(data: SPStudentData) {
    if (data) {
      this.calculateTotals(data);
      this.datas = data;
      this.chartData.labels = data.labels;
      this.isActive = true;
      this.chartData.datasets[0].data = data.chartData.map(
        (x: StudentData) => x.data?.Continuing
      );
      this.chartData.datasets[2].data = data.chartData.map(
        (x: StudentData) => x.data?.Withdrawn
      );
      this.chartData.datasets[3].data = data.chartData.map(
        (x: StudentData) => x.data?.Graduated
      );
      const hasData = this.chartData.datasets.every((q: ChartDataSets) =>
        !q ? null : q
      );
      if (hasData) {
        this.removeEmptyStringFromArray();
      }
    } else {
      this.removeEmptyStringFromArray();
      this.chartData.labels = ['RNBSN', 'RNMSN', 'MSN', 'DNP', 'Other'];
      this.isActive = false;
    }
    this.chart?.chart?.update();
  }

  public calculateTotals(data: SPStudentData) {
    this.totalOverall = data.chartData.map((item) => item.data.total);
  }

  public removeEmptyStringFromArray() {
    this.chartData?.datasets?.forEach((x: SPBarChartData, i: number) => {
      this.chartData.datasets[i].data = x.data?.map((q: any) =>
        !q ? null : q
      );
    });
  }

  public studentWithdrawnReason(click) {
    const points = this.chart?.chart?.getElementsAtEventForMode(click, 'nearest', {intersect: true}, true);
    if (points.length) {
      const { datasetIndex, index } = points[0];
      if (datasetIndex === 2) {
        const label = this.chart.data.labels[index] as string;
        const enrollment = this.groupOtherPrograms(this.withDrawnReason)[label];
        if (enrollment && enrollment.withdrawnReason) {
          const validReasons = Object.entries(enrollment.withdrawnReason).filter(
            ([reason, count]) => reason !== "null" && (count as number) > 0
          );
          if (validReasons.length > 0) {
            this.withdrawnReasons = validReasons.map(([reason, count]) => ({
              reason,
              count: count as number
            }));
            const dataset = this.chart.data.datasets[datasetIndex];
            const value: any = dataset.data[index];
            this.popupLabel = label;
            this.popupColor = {
              backgroundColor: Array.isArray(dataset.backgroundColor)
              ? dataset.backgroundColor[0]
              : dataset.backgroundColor,
            }
            const persentageVal = (
              (value / this.totalOverall[index]) *
              100
            ).toFixed(2);
            const totalVal = this.totalOverall[index];
            const label2 = `${persentageVal}% of ${totalVal}`;
            if (dataset.label) {
              this.popupContent = `${dataset.label}: ${label2}`;
            }
            this.chart.chart.options.plugins.tooltip.enabled = false;
            this.chart?.chart?.update();
            const element = points[0].element;
            const x = element.x;
            const y = element.y;
            const popupPadding = 5;
            const popupWidth = window.innerWidth > 575 ? 0 : 200;
            const popupHeight = window.innerWidth > 575 ? 0 : 100;
            const left = Math.min(
              Math.max(x + popupPadding, 0), 
              this.chart?.chart?.width - popupWidth
            );
            const top = Math.min(
              Math.max(y + popupPadding, 0), 
              this.chart?.chart?.height - popupHeight
            ) - 30;
            this.popupStyle = { top: `${top}px`, left: `${left}px` }; 
            this.showPopup = true;
          } else {
            this.showPopup = false;
          }
        } else {
          this.showPopup = false;
        }
      }
    } else {
      this.showPopup = false;
      this.chart.chart.options.plugins.tooltip.enabled = true;
      this.chart?.chart?.update();
    }
  }

  public ngOnInit(): void {
    this.sharedService.lightColorMode$.subscribe((NavVal: boolean) => {
      if (NavVal === true) {
        this.graphBackgroundColor = [
          '#C19A47',
          '#5A7EEF',
          '#6B2D7D',
          '#8989B6',
        ];
        this.graphLightColor = true;
      } else {
        this.graphBackgroundColor = [
          '#88BC40',
          '#5A7EEF',
          '#00807F',
          '#F48322',
        ];
        this.graphLightColor = false;
      }
      this.chartData.datasets.forEach((x, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.datasets[i].backgroundColor =
            this.graphBackgroundColor[i];
          this.chartData.datasets[i].hoverBackgroundColor =
            this.graphBackgroundColor[i];
        }
      });
      this.chart?.chart?.update();
    });
    this.programInfo = this.otherProgramService.getProgramWithContent();
    document.addEventListener('click', this.handleOutsideClick.bind(this));
  }

  public createAndApplyGradient(
    ctx: CanvasRenderingContext2D,
    chartArea: ChartArea,
    colors: string[],
    gradientWidth: number
  ) {
    let gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (
      !gradient ||
      chartWidth !== ctx.canvas.width ||
      chartHeight !== ctx.canvas.height
    ) {
      gradient = ctx.createLinearGradient(0, 0, gradientWidth, 0);
      colors.forEach((color, index) => {
        gradient.addColorStop(index, color);
      });
      ctx.fillStyle = gradient;
      ctx.fillRect(chartArea.left, chartArea.top, chartWidth, chartHeight);
    }
    return gradient;
  }

  public getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
    return this.createAndApplyGradient(
      ctx,
      chartArea,
      ['#88BC40', '#88BC40'],
      550
    );
  }

  public getGradienttwo(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
    return this.createAndApplyGradient(
      ctx,
      chartArea,
      ['#5A7EEF', '#5A7EEF'],
      350
    );
  }

  public getGradientthree(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
    return this.createAndApplyGradient(
      ctx,
      chartArea,
      ['#00807F', '#00807F'],
      350
    );
  }

  public getGradientfour(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
    return this.createAndApplyGradient(
      ctx,
      chartArea,
      ['#F48322', '#F48322'],
      350
    );
  }

  public ngAfterViewInit(): void {
    this.setupTooltip();
  }

  public setupTooltip(): void {
    const canvasEl = this.canvas.nativeElement;
    const ctx = canvasEl.getContext('2d');

    canvasEl.addEventListener('mousemove', (event) => {
      const rect = canvasEl.getBoundingClientRect();
      const mouseX = event.clientX - rect.left + 20;
      const mouseY = event.clientY - rect.top;

      const yAxisWidth = this.chart.chart.chartArea.left;

      if (mouseX <= yAxisWidth) {
        this.handleXAxisHover(mouseY, mouseX);
      } else {
        this.hideTooltip();
      }
    });

    canvasEl.addEventListener('mouseleave', () => {
      ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
      this.hideTooltip();
      this.chart.chart.update();
    });
  }

  public filterProgramByName(name: string): Program {
    const defaultProgram: Program = {
      name: '',
      content: '',
    };

    return this.programInfo.find((prg) => prg.name === name)
      ? this.programInfo.find((prg) => prg.name === name)
      : defaultProgram;
  }

  public openOtherProgramPopup() {
    if (window.innerWidth < 575) {
      this.sharedService.openOtherProgramMob.next(true);
      this.sharedService.FloatingMenuEvent.next(false);
    } else {
      this.sharedService.openOtherProgram.next(true);
    }
    let titleInfo: OtherProgramTitle = {
      popupTotalTitle: '',
      popupTitle: ChartType.studentProgress,
    };
    this.otherProgramService.bindDataToStorage(
      titleInfo,
      this.otherProgramDetails,
      ['Continuing', 'Withdrawn', 'Graduated', 'Total'],
      [],
      [],
      false,
      false
    );
  }

  public handleXAxisHover(mouseY, mouseX) {
    const labels = this.chart.chart.scales['y'].getLabels();
    const labelIndex = this.calculateLabelIndex(mouseY, labels.length);

    if (labelIndex < labels.length && labels[labelIndex]) {
      this.updateTooltipContent(labels[labelIndex]);
      this.updateTooltipPosition(mouseY, mouseX);
    } else {
      this.hideTooltip();
    }
  }

  public calculateLabelIndex(mouseY, labelsLength) {
    return Math.floor(
      ((mouseY - this.chart.chart.chartArea.top) /
        (this.chart.chart.chartArea.bottom - this.chart.chart.chartArea.top)) *
        labelsLength
    );
  }

  public updateTooltipContent(label) {
    this.tooltipContent = this.filterProgramByName(label).content;
    this.tooltip.nativeElement.style.display = 'block';
    this.tooltip.nativeElement.classList.add('prg-tooltip');
  }

  public updateTooltipPosition(mouseY, mouseX) {
    this.tooltipPositionX = mouseX;
    this.tooltipPositionY = this.calculateTooltipPositionY(mouseY);
  }

  public calculateTooltipPositionY(mouseY) {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1366) {
      return mouseY + 130;
    } else if (screenWidth < 1600 && screenWidth > 1450) {
      return mouseY + 140;
    } else if (screenWidth < 1750 && screenWidth > 1601) {
      return mouseY + 150;
    } else if (screenWidth < 1900 && screenWidth > 1751) {
      return mouseY + 160;
    } else if (screenWidth < 2100 && screenWidth > 1901) {
      return mouseY + 170;
    } else if (screenWidth < 3000 && screenWidth > 2101) {
      return mouseY + 240;
    }
  }

  public hideTooltip() {
    this.tooltip.nativeElement.style.display = 'none';
    this.tooltip.nativeElement.classList.remove('prg-tooltip');
  }

  public setTooltipMode() {
    if (window.innerWidth < 575) {
      return 'index';
    }

    return 'nearest';
  }

  public  groupOtherPrograms(programEnrollments: any[]) {
    const grouped = {
      RNBSN: { withdrawnCount: 0, withdrawnReason: {} },
      RNMSN: { withdrawnCount: 0, withdrawnReason: {} },
      MSN: { withdrawnCount: 0, withdrawnReason: {} },
      DNP: { withdrawnCount: 0, withdrawnReason: {} },
      Other: { withdrawnCount: 0, withdrawnReason: {} }
    };

    programEnrollments.forEach(program => {
      const key = ["RNBSN", "RNMSN", "MSN", "DNP"].includes(program.program) ? program.program : "Other";
      grouped[key].withdrawnCount += program.studentCount.Withdrawn || 0;
      
      // Aggregate withdrawn reasons
      if (program.withdrawnReason) {
        for (const [reason, count] of Object.entries(program.withdrawnReason)) {
          if (!grouped[key].withdrawnReason[reason]) {
            grouped[key].withdrawnReason[reason] = 0;
          }
          grouped[key].withdrawnReason[reason] += count;
        }
      }
    });

    return grouped;
  }

  public handleOutsideClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (
      this.showPopup &&
      !this.popup.nativeElement.contains(target) &&
      !this.canvas.nativeElement.contains(target)
    ) {
      this.showPopup = false;
    }
  }

  public ngOnDestroy() {
    document.removeEventListener('click', this.handleOutsideClick.bind(this));
  }
}
